import React from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane, Card } from "reactstrap";
import FirmwareUpdate from './FirmwareUpdate';
import ChargerFOTA from './ChargerFOTA';

function Tab(props) {
    const [iconPills, setIconPills] = React.useState("1");
  return (
    <div>
         <Nav className="justify-content-center" role="tablist" tabs>
        <NavItem>
          <NavLink
            className={iconPills === "1" ? "active" : ""}
            style={{ cursor: "pointer", float: "left" }}
            onClick={(e) => {
              e.preventDefault();
              setIconPills("1");
            }}
          >
            <strong>BMS</strong>
          </NavLink>
          </NavItem>
        <NavItem>
          <NavLink
            className={iconPills === "2" ? "active" : ""}
            style={{ cursor: "pointer", float: "left" }}
            onClick={(e) => {
              e.preventDefault();
              setIconPills("2");
            }}
          >
            <strong>Charger</strong>
          </NavLink>
          </NavItem>
          </Nav>
          <TabContent className="text-center" activeTab={"iconPills" + iconPills}>
        <TabPane tabId="iconPills1">
            <FirmwareUpdate 
              UpdateCode={props.UpdateCode}
              BinFileInput={props.BinFileInput}
              incrementXModem={props.incrementXModem}
              xModem128ByteChunk={props.xModem128ByteChunk}
              fotaSuccessful={props.fotaSuccessful}
              inFotaMode={props.inFotaMode}
              fotaProcess={props.fotaProcess}
              startUpdatingDisabled={props.startUpdatingDisabled}
              fileUploaded={props.fileUploaded}
              apiFOTAFile={props.apiFOTAFile}
              showLoaderInApiDownload={props.showLoaderInApiDownload}
              apiFileUploadSuccess={props.apiFileUploadSuccess}
              apiFotaFileSize={props.apiFotaFileSize}
              setApiFotaFileInputHandler={props.setApiFotaFileInputHandler}
            />
        </TabPane>
        <TabPane tabId="iconPills2">
            <ChargerFOTA 
            UpdateCode={props.UpdateCode}
            BinFileInput={props.BinFileInput}
            incrementXModem={props.incrementXModem}
            xModem128ByteChunk={props.xModem128ByteChunk}
            fotaSuccessful={props.fotaSuccessful}
            inFotaMode={props.inFotaMode}
            fotaProcess={props.fotaProcess}
            startUpdatingDisabled={props.startUpdatingDisabled}
            fileUploaded={props.fileUploaded}
            apiFOTAFile={props.apiFOTAFile}
            apiFileUploadSuccess={props.apiFileUploadSuccess}
            apiFotaFileSize={props.apiFotaFileSize}
            setApiFotaFileInputHandler={props.setApiFotaFileInputHandler}
            />
        </TabPane>
        </TabContent>
    </div>
  )
}

export default Tab