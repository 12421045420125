import React from "react";
import { Bar } from "react-chartjs-2";
import { Spinner, Card, Badge, Row, Col } from "reactstrap";

function Temperature(props) {
  if (props) {
    let Temp1 = props.Temp1;
    let Temp2 = props.Temp2;
    let Temp3 = props.Temp3;
    let Temp4 = props.Temp4;
    let Temp5 = props.Temp5;
    let Temp6 = props.Temp6;
    let Temp7 = props.Temp7;
    let Temp8 = props.Temp8;
    let IC1Temp = props.IC1Temp;
    let IC2Temp = props.IC2Temp;
    let PDUTemp = props.PDUTemp;
    let PCBTemp2 = props.PCBTemp2;
    let HeatSink1Temp = props.HeatSink1Temp;
    let HeatSink2Temp = props.HeatSink2Temp;
    let tempValues = [];
    let tempLabels = [];
    let tempColor = [];
    let cellTempData = [Temp1, Temp2, Temp3, Temp4, Temp5, Temp6, Temp7, Temp8];
    let icTempData = [IC1Temp, IC2Temp];
    let pcbTempData = [PDUTemp, PCBTemp2];
    let heatsinkTempData = [HeatSink1Temp, HeatSink2Temp];
    let NumOfTemp = props.NumOfTemp;
    let MaxCellTemp = props.MaxCellTemp;
    let MinCellTemp = props.MinCellTemp;
    // tempValues.push(70);
    // tempLabels.push("test");
    // let tempMax = Math.max(...cellTempData);
    // let tempMin = Math.min(...cellTempData);
    for (let i = 0; i < NumOfTemp; i++) {
      tempValues.push(cellTempData[i]);
      tempLabels.push("CT" + (i + 1));
      if (cellTempData[i] === MaxCellTemp) tempColor[i] = "#b8b209";
      else if (cellTempData[i] === MinCellTemp) tempColor[i] = "#078da8";
      else tempColor[i] = "rgba(10, 255, 10, 1)";
    }
    for (let i = 0; i < icTempData.length; i++) {
      tempValues.push(icTempData[i]);
      tempLabels.push("ICT" + (i + 1));
      tempColor.push("rgba(10, 255, 255, 1)");
    }
    for (let i = 0; i < pcbTempData.length; i++) {
      tempValues.push(pcbTempData[i]);
      tempLabels.push("PCBT" + (i + 1));
      tempColor.push("rgba(10, 255, 255, 1)");
    }

    const TempChart = {
      data: () => {
        return {
          labels: tempLabels,
          datasets: [
            {
              label: "Temperature (°C)",
              data: tempValues,
              backgroundColor: tempColor,
              borderColor: [],
              borderWidth: 0,
            },
          ],
        };
      },

      options: {
        legend: {
          display: false,
          position: "top",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
    return (
      <div>
        <Card style={{ padding: "5px", textAlign: "center" }}>
          <div className="card-head">Temperature</div>

          {NumOfTemp ? (
            <>
            <Row>
              <Col>
              <Badge
                style={{
                  backgroundColor: "#078da8",
                  color: "white",

                  margin: "0 10px",
                }}
              >
                Min Cell Temp: {MinCellTemp} °C
              </Badge>
              </Col>
              <Col>
              <Badge
                style={{
                  backgroundColor: "#b8b209",
                  color: "white",
                  margin: "0 10px",
                }}
              >
                Max Cell Temp: {MaxCellTemp} °C
              </Badge>
              </Col>
            </Row>
              
              

              <Bar data={TempChart.data} options={TempChart.options} />
            </>
          ) : (
            <div>
              <Spinner>Loading...</Spinner>
            </div>
          )}
        </Card>
      </div>
    );
  } else {
    return (
      <div>
        <Spinner>Loading...</Spinner>
      </div>
    );
  }
}

export default Temperature;
