import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import ReactSelect from "react-select";
import { Card, Table } from "reactstrap";
import Badge from "reactstrap/lib/Badge";
import Button from "reactstrap/lib/Button";
import Col from "reactstrap/lib/Col";
import Container from "reactstrap/lib/Container";
import PopoverBody from "reactstrap/lib/PopoverBody";
import PopoverHeader from "reactstrap/lib/PopoverHeader";
import Row from "reactstrap/lib/Row";
import UncontrolledPopover from "reactstrap/lib/UncontrolledPopover";
import { API_URL } from "variables/general";
import {
  AWS_REGION,
  AWS_IOT_ENDPOINT,
  AWS_CUSTOM_AUTH_USERNAME,
  AWS_CUSTOM_AUTH_AUTHORIZER_NAME,
  AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD,
  AWS_CUSTOM_AUTH_PASSWORD,
} from "../../views/MQTTSettings";
const iotsdk = require("aws-iot-device-sdk-v2");
const iot = iotsdk.iot;
const mqtt = iotsdk.mqtt;
// let showUi = false
let packTable;
const BmsDevices = (props) => {
  const [errorInPage, setErrorInPage] = useState(false);
  const [latestData, setLatestData] = useState("");
  const [bmsLatestData, setBmsLatestData] = useState("");
  const [bootData, setBootData] = useState("");
  // const [packData, setPackData] = useState("");
  const [idForAlias, setIdForAlias] = useState("");
  const [alias, setAlias] = useState("");
  const [bmsId, setBmsId] = useState("");
  const [bmsList, setBmsList] = useState("");

  const [showNewNotificationData, setShowNewNotificationData] = useState("");
  const [showUi, setShowUi] = useState(false);
  const [searchedItem, setSearchedItem] = useState("");
  const [showSearchedItem, setShowSearchedItem] = useState("");
  const [packTableS, setPackTableS] = useState("");
  const [packDataComplete, setPackDataComplete] = useState("");
  const [newNotificationData, setNewNotificationData] = useState("");
  let packData = [];
  useEffect(() => {
    getIotBootData()
    if (props.packData)
      packDataRecv(props.packData);
  }, []);
  const getIotBootData = (packData) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    let qParam = ''
    if (props.location.state)
      requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ subOwner: props.location.state, boot: 'iot' }),
      }
    else {
      requestOptions = {
        method: "GET",
        headers: myHeaders,
      }
      qParam = '?boot=iot'
    }
    fetch(API_URL + "/bms/boot" + qParam, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // bootRecv(result.data.data);
      })
      .catch((error) => console.log("error", error));
  };
  const getBootData = (packData) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    let qParam = ''
    if (props.location.state)
      requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ subOwner: props.location.state, boot: 'bms' }),
      }
    else {
      requestOptions = {
        method: "GET",
        headers: myHeaders,
      }
      qParam = '?boot=bms'
    }
    fetch(API_URL + "/bms/boot" + qParam, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        bootRecv(result.data.data);
      })
      .catch((error) => console.log("error", error));
  };
  const getBmsLatestData = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    props.location.state
      ? (requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ subOwner: props.location.state }),
      })
      : (requestOptions = {
        method: "GET",
        headers: myHeaders,
      });
    fetch(API_URL + "/bms/bms_latest_data", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        bmsLatestDataRecv(result.data.data);
        // setLatestData(result.data.data)
      })
      .catch((error) => console.log("error", error));
  };
  const handleLivePackCount = (val) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    }
    // fetch(API_URL + "/bms/latest_data", requestOptions)
    fetch(API_URL + "/bms/live_packs", requestOptions)
      // fetch(API_URL + "/bms/notifications_count", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setShowNewNotificationData(
          <Col md="3" lg="3" sm="12" xs="12" xl="3">
            <Row style={{ paddingTop: "5px" }}>
              <Col md="6" lg="6" sm="6" xs="6" xl="6">
                <Badge
                  style={{
                    backgroundColor: "#6da2f7",
                    color: "white",
                  }}
                >
                  <b>Total: {result.data.data.total}</b>
                </Badge>
                <br />
                <Badge
                  style={{
                    backgroundColor: "#32c85a",
                    color: "white",
                  }}
                >
                  <b>Online: {result.data.data.online}</b>
                </Badge>
                <br />
                <Badge
                  style={{
                    backgroundColor: "#ef8157",
                    color: "white",
                  }}
                >
                  <b>Offline: {result.data.data.offline}</b>
                </Badge>
              </Col>
            </Row>
          </Col>
        );
      })
      .catch((error) => console.log("error", error));
  };
  const editAlias = (val) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify({
        batteryPackId: idForAlias,
        packData: {
          alias: alias,
        },
      }),
    };
    fetch(API_URL + "/bms/bms_devices", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // console.log("dataaaa", result)
        let status = JSON.parse(result).status;
        if (status === true) alert("Update Successful");
        // window.location.reload();
      })
      .catch((error) => console.log("error", error));
  };

  function packDataRecv(data) {
    var temp_arr = [];
    for (let i = 0; i < data.length; i++) {
      temp_arr[i] = { did: data[i].batteryPackId, packData: data[i] };
    }
    packData = temp_arr;

    let bmsList = [];
    for (let i = 0; i < packData.length; i++) {
      bmsList[i + 1] = {
        value: packData[i].did,
        label: packData[i].packData.alias
          ? packData[i].packData.alias
          : packData[i].did,
      };
      bmsList[0] = { value: "All", label: "All Devices" };
    }
    setBmsList(bmsList);
    ///////for online row on top ============================================>
    let updatedArray = [];
    for (let i = 0; i < packData.length; i++) {
      if (packData[i].packData.online) {
        updatedArray.unshift(packData[i]);
      } else updatedArray.push(packData[i]);
    }
    packData = updatedArray;
    /////////////////////searched id////////////////////////

    setPackTableS(
      <table
        style={{
          overflow: "scroll",
          display: "block",
          height: "70vh",
        }}
      >
        <thead style={{ textAlign: "center" }}>
          <th style={tableHeaderStyle}>Alias</th>
          <th style={tableHeaderStyle}>BMS Device ID</th>
          <th style={tableHeaderStyle}>Battery Number</th>
          <th style={tableHeaderStyle}>Status</th>
          <th style={tableHeaderStyle}>SOC</th>
          <th style={tableHeaderStyle}>Last Updated</th>
          <th style={tableHeaderStyle}>Charging</th>
          <th style={tableHeaderStyle}>Discharging</th>
          <th style={tableHeaderStyle}>Max Cell Temp</th>
          <th style={tableHeaderStyle}>PDU Temp</th>
          <th style={tableHeaderStyle}>Voltage</th>
          <th style={tableHeaderStyle}>Cycles</th>
          <th style={tableHeaderStyle}>Energy In</th>
          <th style={tableHeaderStyle}>Energy Out</th>
          <th style={tableHeaderStyle}>SOH</th>
          <th style={tableHeaderStyle}>IoT ID</th>
          <th style={tableHeaderStyle}>Date of Deployment</th>
          <th style={tableHeaderStyle}>Battery Pack ID</th>
          <th style={tableHeaderStyle}>Battery Capacity</th>
          <th style={tableHeaderStyle}>Battery Cycle Count</th>
          <th style={tableHeaderStyle}>Battery Chemistry</th>
          <th style={tableHeaderStyle}>C-Rate</th>
          <th style={tableHeaderStyle}>Number of S</th>
          <th style={tableHeaderStyle}>Number of P</th>
          <th style={tableHeaderStyle}>Cell Model Number</th>
          <th style={tableHeaderStyle}>Battery Manufacturer</th>
          <th style={tableHeaderStyle}>BMS Boot</th>
          <th style={tableHeaderStyle}>BMS Firmware Version</th>
          <th style={tableHeaderStyle}>BMS Hardware Version</th>
          <th style={tableHeaderStyle}>BMS Config Version</th>
          {/* <th style={tableHeaderStyle}>IoT Boot</th>
          <th style={tableHeaderStyle}>IoT Firmware Version</th>
          <th style={tableHeaderStyle}>IoT Hardware Version</th>
          <th style={tableHeaderStyle}>IoT Boot Up Reason</th> */}
        </thead>
        <tbody>
          {packData.map((val, i) => (
            <tr style={{ textAlign: "center" }}>
              <td className="text-center" style={tableRowStyle}>
                <div>
                  <UncontrolledPopover
                    target="PopoverForAlias"
                    trigger="legacy"
                    placement="bottom"
                  >
                    <PopoverHeader>Edit Alias</PopoverHeader>
                    <PopoverBody>
                      <input
                        onChange={(event) => setAlias(event.target.value)}
                        placeholder="New Alias"
                      ></input>
                      <button onClick={() => editAlias(val.packData.bmsId)}>
                        Submit
                      </button>
                    </PopoverBody>
                  </UncontrolledPopover>
                  <button
                    id="PopoverForAlias"
                    style={{
                      color: "#17a2b8",
                      backgroundColor: "white",
                      borderWidth: 0,
                      float: "right",
                      fontWeight: 600,
                    }}
                    onClick={() => setIdForAlias(val.packData.bmsId)}
                  >
                    {val.packData.alias}&ensp;
                    <i class="fas fa-pen"></i>
                  </button>
                </div>
              </td>
              <td className="text-center" style={tableRowStyle}>
                <Link
                  to={{
                    pathname: "/admin/dashboard",
                    state: {
                      id: val.packData.bmsId,
                      status: val.packData.online,
                    },
                  }}
                >
                  {val.packData.bmsId}
                </Link>
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.batteryNumber ? (
                  <div> {val.packData.batteryNumber} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                <Badge color={val.packData.online ? "success" : "danger"}>
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      padding: "2px",
                    }}
                  >
                    {val.packData.online ? (
                      <div>Online</div>
                    ) : (
                      <div>Offline</div>
                    )}
                  </div>
                </Badge>
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.iotId ? <div>{val.packData.iotId} </div> : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.dateOfDeployment ? (
                  <div>
                    {val.packData.dateOfDeployment.slice(11, 19)}
                    &ensp;
                    {val.packData.dateOfDeployment.slice(8, 10)}/
                    {val.packData.dateOfDeployment.slice(5, 7)}/
                    {val.packData.dateOfDeployment.slice(0, 4)}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.batteryPackId}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.batteryCapacity ? (
                  <div>{val.packData.batteryCapacity} Ah</div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.batteryCycleCount ? (
                  <div> {val.packData.batteryCycleCount} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.batteryChemistry ? (
                  <div> {val.packData.batteryChemistry} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.cRate ? <div>{val.packData.cRate} </div> : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.noOfS ? (
                  <div>{val.packData.noOfS} </div>
                ) : val.packData.noOfS === 0 ? (
                  "0"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.noOfP ? (
                  <div>{val.packData.noOfP} </div>
                ) : val.packData.noOfP === 0 ? (
                  "0"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.cellModelNumber ? (
                  <div>{val.packData.cellModelNumber} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.batteryManufacturer ? (
                  <div>{val.packData.batteryManufacturer} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              {/* <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    );
    setShowUi(true);
    getBootData();
  }
  function bootRecv(data) {
    for (let i = 0; i < packData.length; i++) {
      for (let j = 0; j < data.length; j++) {
        if (packData[i].packData.batteryPackId === data[j].batteryPackId) {
          packData[i]["bootData"] = data[j];
          break;
        }
        if (j === data.length - 1) packData[i]["bootData"] = {};
      }
    }
    // console.log('bootrcd', packData)
    setPackTableS(
      <table
        style={{
          overflow: "scroll",
          display: "block",
          height: "70vh",
        }}
      >
        <thead style={{ textAlign: "center" }}>
          <th style={tableHeaderStyle}>Alias</th>
          <th style={tableHeaderStyle}>BMS Device ID</th>
          <th style={tableHeaderStyle}>Battery Number</th>
          <th style={tableHeaderStyle}>Status</th>
          <th style={tableHeaderStyle}>SOC</th>
          <th style={tableHeaderStyle}>Last Updated</th>
          <th style={tableHeaderStyle}>Charging</th>
          <th style={tableHeaderStyle}>Discharging</th>
          <th style={tableHeaderStyle}>Max Cell Temp</th>
          <th style={tableHeaderStyle}>PDU Temp</th>
          <th style={tableHeaderStyle}>Voltage</th>
          <th style={tableHeaderStyle}>Cycles</th>
          <th style={tableHeaderStyle}>Energy In</th>
          <th style={tableHeaderStyle}>Energy Out</th>
          <th style={tableHeaderStyle}>SOH</th>
          <th style={tableHeaderStyle}>IoT ID</th>
          <th style={tableHeaderStyle}>Date of Deployment</th>
          <th style={tableHeaderStyle}>Battery Pack ID</th>
          <th style={tableHeaderStyle}>Battery Capacity</th>
          <th style={tableHeaderStyle}>Battery Cycle Count</th>
          <th style={tableHeaderStyle}>Battery Chemistry</th>
          <th style={tableHeaderStyle}>C-Rate</th>
          <th style={tableHeaderStyle}>Number of S</th>
          <th style={tableHeaderStyle}>Number of P</th>
          <th style={tableHeaderStyle}>Cell Model Number</th>
          <th style={tableHeaderStyle}>Battery Manufacturer</th>
          <th style={tableHeaderStyle}>BMS Boot</th>
          <th style={tableHeaderStyle}>BMS Firmware Version</th>
          <th style={tableHeaderStyle}>BMS Hardware Version</th>
          <th style={tableHeaderStyle}>BMS Config Version</th>
          {/* <th style={tableHeaderStyle}>IoT Boot</th>
          <th style={tableHeaderStyle}>IoT Firmware Version</th>
          <th style={tableHeaderStyle}>IoT Hardware Version</th>
          <th style={tableHeaderStyle}>IoT Boot Up Reason</th> */}
        </thead>
        <tbody>
          {packData.map((val, i) => (
            <tr style={{ textAlign: "center" }}>
              <td className="text-center" style={tableRowStyle}>
                <div>
                  <UncontrolledPopover
                    target="PopoverForAlias"
                    trigger="legacy"
                    placement="bottom"
                  >
                    <PopoverHeader>Edit Alias</PopoverHeader>
                    <PopoverBody>
                      <input
                        onChange={(event) => setAlias(event.target.value)}
                        placeholder="New Alias"
                      ></input>
                      <button onClick={() => editAlias(val.did)}>
                        Submit
                      </button>
                    </PopoverBody>
                  </UncontrolledPopover>
                  <button
                    id="PopoverForAlias"
                    style={{
                      color: "#17a2b8",
                      backgroundColor: "white",
                      borderWidth: 0,
                      float: "right",
                      fontWeight: 600,
                    }}
                    onClick={() => setIdForAlias(val.did)}
                  >
                    {val.packData.alias}&ensp;
                    <i class="fas fa-pen"></i>
                  </button>
                </div>
              </td>
              <td className="text-center" style={tableRowStyle}>
                <Link
                  to={{
                    pathname: "/admin/dashboard",
                    state: {
                      id: val.packData.bmsId,
                      status: val.packData.online,
                    },
                  }}
                >
                  {val.packData.bmsId}
                </Link>
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.batteryNumber ? (
                  <div> {val.packData.batteryNumber} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                <Badge color={val.packData.online ? "success" : "danger"}>
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      padding: "2px",
                    }}
                  >
                    {val.packData.online ? (
                      <div>Online</div>
                    ) : (
                      <div>Offline</div>
                    )}
                  </div>
                </Badge>
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.iotId ? <div>{val.packData.iotId} </div> : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.dateOfDeployment ? (
                  <div>
                    {val.packData.dateOfDeployment.slice(11, 19)}
                    &ensp;
                    {val.packData.dateOfDeployment.slice(8, 10)}/
                    {val.packData.dateOfDeployment.slice(5, 7)}/
                    {val.packData.dateOfDeployment.slice(0, 4)}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.batteryPackId}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.batteryCapacity ? (
                  <div>{val.packData.batteryCapacity} Ah</div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.batteryCycleCount ? (
                  <div> {val.packData.batteryCycleCount} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.batteryChemistry ? (
                  <div> {val.packData.batteryChemistry} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.cRate ? <div>{val.packData.cRate} </div> : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.noOfS ? (
                  <div>{val.packData.noOfS} </div>
                ) : val.packData.noOfS === 0 ? (
                  "0"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.noOfP ? (
                  <div>{val.packData.noOfP} </div>
                ) : val.packData.noOfP === 0 ? (
                  "0"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.cellModelNumber ? (
                  <div>{val.packData.cellModelNumber} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.batteryManufacturer ? (
                  <div>{val.packData.batteryManufacturer} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.tms ? (
                  <div>
                    {val.bootData.tms.slice(11, 19)}&ensp;
                    {val.bootData.tms.slice(8, 10)}/
                    {val.bootData.tms.slice(5, 7)}/
                    {val.bootData.tms.slice(0, 4)}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.firmwareVersion
                  ? val.bootData.firmwareVersion
                  : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.hardwareVersion
                  ? val.bootData.hardwareVersion
                  : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.bmsconfigVersion
                  ? val.bootData.bmsconfigVersion
                  : "-"}
              </td>
              {/* <td className="text-center" style={tableRowStyle}>
                {val.bootData.iotBoot.tms ? (
                  <div>
                    {val.bootData.iotBoot.tms.slice(11, 19)}&ensp;
                    {val.bootData.iotBoot.tms.slice(8, 10)}/
                    {val.bootData.iotBoot.tms.slice(5, 7)}/
                    {val.bootData.iotBoot.tms.slice(0, 4)}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.iotBoot.firmwareVersion
                  ? val.bootData.iotBoot.firmwareVersion
                  : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.iotBoot.hardwareVersion
                  ? val.bootData.iotBoot.hardwareVersion
                  : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {getIotBootReason(val.bootData.iotBoot.bootUpReason)}
                {iotBootReason ? iotBootReason : "-"}
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    );
    getBmsLatestData();
  }
  function bmsLatestDataRecv(data) {
    for (let i = 0; i < packData.length; i++) {
      for (let j = 0; j < data.length; j++) {
        if (packData[i].packData.batteryPackId === data[j].batteryPackId) {
          packData[i]["bmsValues"] = data[j];
          break;
        }
        if (j === data.length - 1) packData[i]["bmsValues"] = {};
      }
    }
    // console.log('bmsLatestDataRecv', packData)
    setPackDataComplete(packData)
    setPackTableS(
      <table
        style={{
          overflow: "scroll",
          display: "block",
          height: "70vh",
        }}
      >
        <thead style={{ textAlign: "center" }}>
          <th style={tableHeaderStyle}>Alias</th>
          <th style={tableHeaderStyle}>BMS Device ID</th>
          <th style={tableHeaderStyle}>Battery Number</th>
          <th style={tableHeaderStyle}>Status</th>
          <th style={tableHeaderStyle}>SOC</th>
          <th style={tableHeaderStyle}>Last Updated</th>
          <th style={tableHeaderStyle}>Charging</th>
          <th style={tableHeaderStyle}>Discharging</th>
          <th style={tableHeaderStyle}>Max Cell Temp</th>
          <th style={tableHeaderStyle}>PDU Temp</th>
          <th style={tableHeaderStyle}>Voltage</th>
          <th style={tableHeaderStyle}>Cycles</th>
          <th style={tableHeaderStyle}>Energy In</th>
          <th style={tableHeaderStyle}>Energy Out</th>
          <th style={tableHeaderStyle}>SOH</th>
          <th style={tableHeaderStyle}>IoT ID</th>
          <th style={tableHeaderStyle}>Date of Deployment</th>
          <th style={tableHeaderStyle}>Battery Pack ID</th>
          <th style={tableHeaderStyle}>Battery Capacity</th>
          <th style={tableHeaderStyle}>Battery Cycle Count</th>
          <th style={tableHeaderStyle}>Battery Chemistry</th>
          <th style={tableHeaderStyle}>C-Rate</th>
          <th style={tableHeaderStyle}>Number of S</th>
          <th style={tableHeaderStyle}>Number of P</th>
          <th style={tableHeaderStyle}>Cell Model Number</th>
          <th style={tableHeaderStyle}>Battery Manufacturer</th>
          <th style={tableHeaderStyle}>BMS Boot</th>
          <th style={tableHeaderStyle}>BMS Firmware Version</th>
          <th style={tableHeaderStyle}>BMS Hardware Version</th>
          <th style={tableHeaderStyle}>BMS Config Version</th>
          {/* <th style={tableHeaderStyle}>IoT Boot</th>
          <th style={tableHeaderStyle}>IoT Firmware Version</th>
          <th style={tableHeaderStyle}>IoT Hardware Version</th>
          <th style={tableHeaderStyle}>IoT Boot Up Reason</th> */}
        </thead>
        <tbody>
          {packData.map((val, i) => (
            <tr style={{ textAlign: "center" }}>
              <td className="text-center" style={tableRowStyle}>
                <div>
                  <UncontrolledPopover
                    target="PopoverForAlias"
                    trigger="legacy"
                    placement="bottom"
                  >
                    <PopoverHeader>Edit Alias</PopoverHeader>
                    <PopoverBody>
                      <input
                        onChange={(event) => setAlias(event.target.value)}
                        placeholder="New Alias"
                      ></input>
                      <button onClick={() => editAlias(val.packData.batteryPackId)}>
                        Submit
                      </button>
                    </PopoverBody>
                  </UncontrolledPopover>
                  <button
                    id="PopoverForAlias"
                    style={{
                      color: "#17a2b8",
                      backgroundColor: "white",
                      borderWidth: 0,
                      float: "right",
                      fontWeight: 600,
                    }}
                    onClick={() => setIdForAlias(val.packData.batteryPackId)}
                  >
                    {val.packData.alias}&ensp;
                    <i class="fas fa-pen"></i>
                  </button>
                </div>
              </td>
              <td className="text-center" style={tableRowStyle}>
                <Link
                  to={{
                    pathname: "/admin/dashboard",
                    state: {
                      id: val.packData.batteryPackId,
                      status: val.packData.online,
                    },
                  }}
                >
                  {val.packData.batteryPackId}
                </Link>
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.batteryNumber ? (
                  <div>{val.bootData.batteryNumber}</div>
                ) : val.packData.batteryNumber ? (
                  <div> {val.packData.batteryNumber} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                <Badge color={val.packData.online ? "success" : "danger"}>
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      padding: "2px",
                    }}
                  >
                    {val.packData.online ? (
                      <div>Online</div>
                    ) : (
                      <div>Offline</div>
                    )}
                  </div>
                </Badge>
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bmsValues.soC ? (
                  <div>{val.bmsValues.soC} % </div>
                ) : val.bmsValues.soC === 0 ? (
                  "0 %"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bmsValues.tms ? (
                  <div>
                    {val.bmsValues.tms.slice(11, 19)}&ensp;
                    {val.bmsValues.tms.slice(8, 10)}/
                    {val.bmsValues.tms.slice(5, 7)}/
                    {val.bmsValues.tms.slice(0, 4)}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bmsValues.chargeFet ? (
                  <div>{val.bmsValues.chargeFet === 1 ? "On" : "Off"}</div>
                ) : (
                  "Off"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bmsValues.dischargeFet ? (
                  <div>{val.bmsValues.dischargeFet === 1 ? "On" : "Off"}</div>
                ) : (
                  "Off"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bmsValues.cellTemp ? (
                  <div>
                    {Math.max(...JSON.parse(val.bmsValues.cellTemp))} °C
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bmsValues.pcbTemp ? (
                  <div>{Math.max(...JSON.parse(val.bmsValues.pcbTemp))} °C</div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bmsValues.packVoltage ? (
                  <div>{val.bmsValues.packVoltage / 1000} V</div>
                ) : val.bmsValues.packVoltage === 0 ? (
                  "0 V"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bmsValues.cycleCount ? (
                  <div>{val.bmsValues.cycleCount} </div>
                ) : val.bmsValues.cycleCount === 0 ? (
                  "0"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bmsValues.energyIn ? (
                  <div> {val.bmsValues.energyIn} W </div>
                ) : val.bmsValues.energyIn === 0 ? (
                  "0 W"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bmsValues.energyOut ? (
                  <div> {val.bmsValues.energyOut} W </div>
                ) : val.bmsValues.energyOut === 0 ? (
                  "0 W"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bmsValues.soH ? (
                  <div>{val.bmsValues.soH} % </div>
                ) : val.bmsValues.soH === 0 ? (
                  "0 %"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.iotId ? <div>{val.packData.iotId} </div> : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.dateOfDeployment ? (
                  <div>
                    {val.packData.dateOfDeployment.slice(11, 19)}
                    &ensp;
                    {val.packData.dateOfDeployment.slice(8, 10)}/
                    {val.packData.dateOfDeployment.slice(5, 7)}/
                    {val.packData.dateOfDeployment.slice(0, 4)}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.batteryPackId}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.batteryCapacity ? (
                  <div>{val.packData.batteryCapacity} Ah</div>
                ) : val.packData.batteryCapacity === 0 ? (
                  "0 Ah"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.batteryCycleCount ? (
                  <div> {val.packData.batteryCycleCount} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.batteryChemistry ? (
                  <div> {val.packData.batteryChemistry} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.cRate ? (
                  <div>{val.packData.cRate} </div>
                ) : val.packData.cRate === 0 ? (
                  "0"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.noOfS ? (
                  <div>{val.packData.noOfS} </div>
                ) : val.packData.noOfS === 0 ? (
                  "0"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.noOfP ? (
                  <div>{val.packData.noOfP} </div>
                ) : val.packData.noOfP === 0 ? (
                  "0"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.cellModelNumber ? (
                  <div>{val.packData.cellModelNumber} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.batteryManufacturer ? (
                  <div>{val.packData.batteryManufacturer} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.tms ? (
                  <div>
                    {val.bootData.tms.slice(11, 19)}&ensp;
                    {val.bootData.tms.slice(8, 10)}/
                    {val.bootData.tms.slice(5, 7)}/
                    {val.bootData.tms.slice(0, 4)}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.firmwareVersion
                  ? val.bootData.firmwareVersion
                  : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.hardwareVersion
                  ? val.bootData.hardwareVersion
                  : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.bmsconfigVersion
                  ? val.bootData.bmsconfigVersion
                  : "-"}
              </td>
              {/* <td className="text-center" style={tableRowStyle}>
                {val.bootData.iotBoot.tms ? (
                  <div>
                    {val.bootData.iotBoot.tms.slice(11, 19)}&ensp;
                    {val.bootData.iotBoot.tms.slice(8, 10)}/
                    {val.bootData.iotBoot.tms.slice(5, 7)}/
                    {val.bootData.iotBoot.tms.slice(0, 4)}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.iotBoot.firmwareVersion
                  ? val.bootData.iotBoot.firmwareVersion
                  : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.iotBoot.hardwareVersion
                  ? val.bootData.iotBoot.hardwareVersion
                  : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {getIotBootReason(val.bootData.iotBoot.bootUpReason)}
                {iotBootReason ? iotBootReason : "-"}
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    );
    if (!props.location.state) handleLivePackCount();
  }

  const getSearchedItem = (val) => {
    let searchedArray = []
    for (let i = 0; i < packDataComplete.length; i++) {
      if (val.value === packDataComplete[i].did) {
        searchedArray = packDataComplete[i]
      }
    }


    if (val.value === "All") {
      setPackTableS(
        <table
          style={{
            overflow: "scroll",
            display: "block",
            height: "40vh",
          }}
        >
          <thead style={{ textAlign: "center" }}>
            <th style={tableHeaderStyle}>Alias</th>
            <th style={tableHeaderStyle}>BMS Device ID</th>
            <th style={tableHeaderStyle}>Battery Number</th>
            <th style={tableHeaderStyle}>Status</th>
            <th style={tableHeaderStyle}>SOC</th>
            <th style={tableHeaderStyle}>Last Updated</th>
            <th style={tableHeaderStyle}>Charging</th>
            <th style={tableHeaderStyle}>Discharging</th>
            <th style={tableHeaderStyle}>Max Cell Temp</th>
            <th style={tableHeaderStyle}>PDU Temp</th>
            <th style={tableHeaderStyle}>Voltage</th>
            <th style={tableHeaderStyle}>Cycles</th>
            <th style={tableHeaderStyle}>Energy In</th>
            <th style={tableHeaderStyle}>Energy Out</th>
            <th style={tableHeaderStyle}>SOH</th>
            <th style={tableHeaderStyle}>IoT ID</th>
            <th style={tableHeaderStyle}>Date of Deployment</th>
            <th style={tableHeaderStyle}>Battery Pack ID</th>
            <th style={tableHeaderStyle}>Battery Capacity</th>
            <th style={tableHeaderStyle}>Battery Cycle Count</th>
            <th style={tableHeaderStyle}>Battery Chemistry</th>
            <th style={tableHeaderStyle}>C-Rate</th>
            <th style={tableHeaderStyle}>Number of S</th>
            <th style={tableHeaderStyle}>Number of P</th>
            <th style={tableHeaderStyle}>Cell Model Number</th>
            <th style={tableHeaderStyle}>Battery Manufacturer</th>
            <th style={tableHeaderStyle}>BMS Boot</th>
            <th style={tableHeaderStyle}>BMS Firmware Version</th>
            <th style={tableHeaderStyle}>BMS Hardware Version</th>
            <th style={tableHeaderStyle}>BMS Config Version</th>
            {/* <th style={tableHeaderStyle}>IoT Boot</th>
            <th style={tableHeaderStyle}>IoT Firmware Version</th>
            <th style={tableHeaderStyle}>IoT Hardware Version</th>
            <th style={tableHeaderStyle}>IoT Boot Up Reason</th> */}
          </thead>
          <tbody>
            {packDataComplete.map((val, i) => (
              <tr style={{ textAlign: "center" }}>
                <td className="text-center" style={tableRowStyle}>
                  <div>
                    <UncontrolledPopover
                      target="PopoverForAlias"
                      trigger="legacy"
                      placement="bottom"
                    >
                      <PopoverHeader>Edit Alias</PopoverHeader>
                      <PopoverBody>
                        <input
                          onChange={(event) => setAlias(event.target.value)}
                          placeholder="New Alias"
                        ></input>
                        <button onClick={() => editAlias(val.packData.bmsId)}>
                          Submit
                        </button>
                      </PopoverBody>
                    </UncontrolledPopover>
                    <button
                      id="PopoverForAlias"
                      style={{
                        color: "#17a2b8",
                        backgroundColor: "white",
                        borderWidth: 0,
                        float: "right",
                        fontWeight: 600,
                      }}
                      onClick={() => setIdForAlias(val.packData.bmsId)}
                    >
                      {val.packData.alias}&ensp;
                      <i class="fas fa-pen"></i>
                    </button>
                  </div>
                </td>
                <td className="text-center" style={tableRowStyle}>
                  <Link
                    to={{
                      pathname: "/admin/dashboard",
                      state: {
                        id: val.packData.bmsId,
                        status: val.packData.online,
                      },
                    }}
                  >
                    {val.packData.bmsId}
                  </Link>
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.bootData.batteryNumber ? (
                    <div>{val.bootData.batteryNumber}</div>
                  ) : val.packData.batteryNumber ? (
                    <div> {val.packData.batteryNumber} </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  <Badge color={val.packData.online ? "success" : "danger"}>
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "12px",
                        padding: "2px",
                      }}
                    >
                      {val.packData.online ? (
                        <div>Online</div>
                      ) : (
                        <div>Offline</div>
                      )}
                    </div>
                  </Badge>
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.bmsValues.soC ? (
                    <div>{val.bmsValues.soC} % </div>
                  ) : val.bmsValues.soC === 0 ? (
                    "0 %"
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.bmsValues.tms ? (
                    <div>
                      {val.bmsValues.tms.slice(11, 19)}&ensp;
                      {val.bmsValues.tms.slice(8, 10)}/
                      {val.bmsValues.tms.slice(5, 7)}/
                      {val.bmsValues.tms.slice(0, 4)}
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.bmsValues.chargeFet ? (
                    <div>{val.bmsValues.chargeFet === 1 ? "On" : "Off"}</div>
                  ) : (
                    "Off"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.bmsValues.dischargeFet ? (
                    <div>{val.bmsValues.dischargeFet === 1 ? "On" : "Off"}</div>
                  ) : (
                    "Off"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.bmsValues.cellTemp ? (
                    <div>
                      {Math.max(...JSON.parse(val.bmsValues.cellTemp))} °C
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.bmsValues.pcbTemp ? (
                    <div>{Math.max(...JSON.parse(val.bmsValues.pcbTemp))} °C</div>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.bmsValues.packVoltage ? (
                    <div>{val.bmsValues.packVoltage / 1000} V</div>
                  ) : val.bmsValues.packVoltage === 0 ? (
                    "0 V"
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.bmsValues.cycleCount ? (
                    <div>{val.bmsValues.cycleCount} </div>
                  ) : val.bmsValues.cycleCount === 0 ? (
                    "0"
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.bmsValues.energyIn ? (
                    <div> {val.bmsValues.energyIn} W </div>
                  ) : val.bmsValues.energyIn === 0 ? (
                    "0 W"
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.bmsValues.energyOut ? (
                    <div> {val.bmsValues.energyOut} W </div>
                  ) : val.bmsValues.energyOut === 0 ? (
                    "0 W"
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.bmsValues.soH ? (
                    <div>{val.bmsValues.soH} % </div>
                  ) : val.bmsValues.soH === 0 ? (
                    "0 %"
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.packData.iotId ? <div>{val.packData.iotId} </div> : "-"}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.packData.dateOfDeployment ? (
                    <div>
                      {val.packData.dateOfDeployment.slice(11, 19)}
                      &ensp;
                      {val.packData.dateOfDeployment.slice(8, 10)}/
                      {val.packData.dateOfDeployment.slice(5, 7)}/
                      {val.packData.dateOfDeployment.slice(0, 4)}
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.packData.batteryPackId}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.packData.batteryCapacity ? (
                    <div>{val.packData.batteryCapacity} Ah</div>
                  ) : val.packData.batteryCapacity === 0 ? (
                    "0 Ah"
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.packData.batteryCycleCount ? (
                    <div> {val.packData.batteryCycleCount} </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.packData.batteryChemistry ? (
                    <div> {val.packData.batteryChemistry} </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.packData.cRate ? (
                    <div>{val.packData.cRate} </div>
                  ) : val.packData.cRate === 0 ? (
                    "0"
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.packData.noOfS ? (
                    <div>{val.packData.noOfS} </div>
                  ) : val.packData.noOfS === 0 ? (
                    "0"
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.packData.noOfP ? (
                    <div>{val.packData.noOfP} </div>
                  ) : val.packData.noOfP === 0 ? (
                    "0"
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.packData.cellModelNumber ? (
                    <div>{val.packData.cellModelNumber} </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.packData.batteryManufacturer ? (
                    <div>{val.packData.batteryManufacturer} </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.bootData.tms ? (
                    <div>
                      {val.bootData.tms.slice(11, 19)}&ensp;
                      {val.bootData.tms.slice(8, 10)}/
                      {val.bootData.tms.slice(5, 7)}/
                      {val.bootData.tms.slice(0, 4)}
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.bootData.firmwareVersion
                    ? val.bootData.firmwareVersion
                    : "-"}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.bootData.hardwareVersion
                    ? val.bootData.hardwareVersion
                    : "-"}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.bootData.bmsconfigVersion
                    ? val.bootData.bmsconfigVersion
                    : "-"}
                </td>
                {/* <td className="text-center" style={tableRowStyle}>
                  {val.bootData.iotBoot.tms ? (
                    <div>
                      {val.bootData.iotBoot.tms.slice(11, 19)}&ensp;
                      {val.bootData.iotBoot.tms.slice(8, 10)}/
                      {val.bootData.iotBoot.tms.slice(5, 7)}/
                      {val.bootData.iotBoot.tms.slice(0, 4)}
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.bootData.iotBoot.firmwareVersion
                    ? val.bootData.iotBoot.firmwareVersion
                    : "-"}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {val.bootData.iotBoot.hardwareVersion
                    ? val.bootData.iotBoot.hardwareVersion
                    : "-"}
                </td>
                <td className="text-center" style={tableRowStyle}>
                  {getIotBootReason(val.bootData.iotBoot.bootUpReason)}
                  {iotBootReason ? iotBootReason : "-"}
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      );
    } else {
      setPackTableS(
        <table
          style={{
            overflow: "scroll",
            display: "block",
            height: "40vh",
          }}
        >
          <thead style={{ textAlign: "center" }}>
            <th style={tableHeaderStyle}>Alias</th>
            <th style={tableHeaderStyle}>BMS Device ID</th>
            <th style={tableHeaderStyle}>Battery Number</th>
            <th style={tableHeaderStyle}>Status</th>
            <th style={tableHeaderStyle}>SOC</th>
            <th style={tableHeaderStyle}>Last Updated</th>
            <th style={tableHeaderStyle}>Charging</th>
            <th style={tableHeaderStyle}>Discharging</th>
            <th style={tableHeaderStyle}>Max Cell Temp</th>
            <th style={tableHeaderStyle}>PDU Temp</th>
            <th style={tableHeaderStyle}>Voltage</th>
            <th style={tableHeaderStyle}>Cycles</th>
            <th style={tableHeaderStyle}>Energy In</th>
            <th style={tableHeaderStyle}>Energy Out</th>
            <th style={tableHeaderStyle}>SOH</th>
            <th style={tableHeaderStyle}>IoT ID</th>
            <th style={tableHeaderStyle}>Date of Deployment</th>
            <th style={tableHeaderStyle}>Battery Pack ID</th>
            <th style={tableHeaderStyle}>Battery Capacity</th>
            <th style={tableHeaderStyle}>Battery Cycle Count</th>
            <th style={tableHeaderStyle}>Battery Chemistry</th>
            <th style={tableHeaderStyle}>C-Rate</th>
            <th style={tableHeaderStyle}>Number of S</th>
            <th style={tableHeaderStyle}>Number of P</th>
            <th style={tableHeaderStyle}>Cell Model Number</th>
            <th style={tableHeaderStyle}>Battery Manufacturer</th>
            <th style={tableHeaderStyle}>BMS Boot</th>
            <th style={tableHeaderStyle}>BMS Firmware Version</th>
            <th style={tableHeaderStyle}>BMS Hardware Version</th>
            <th style={tableHeaderStyle}>BMS Config Version</th>
            {/* <th style={tableHeaderStyle}>IoT Boot</th>
            <th style={tableHeaderStyle}>IoT Firmware Version</th>
            <th style={tableHeaderStyle}>IoT Hardware Version</th>
            <th style={tableHeaderStyle}>IoT Boot Up Reason</th> */}
          </thead>
          <tbody>

            <tr style={{ textAlign: "center" }}>
              <td className="text-center" style={tableRowStyle}>
                <div>
                  <UncontrolledPopover
                    target="PopoverForAlias"
                    trigger="legacy"
                    placement="bottom"
                  >
                    <PopoverHeader>Edit Alias</PopoverHeader>
                    <PopoverBody>
                      <input
                        onChange={(event) => setAlias(event.target.value)}
                        placeholder="New Alias"
                      ></input>
                      <button onClick={() => editAlias(searchedArray.packData.bmsId)}>
                        Submit
                      </button>
                    </PopoverBody>
                  </UncontrolledPopover>
                  <button
                    id="PopoverForAlias"
                    style={{
                      color: "#17a2b8",
                      backgroundColor: "white",
                      borderWidth: 0,
                      float: "right",
                      fontWeight: 600,
                    }}
                    onClick={() => setIdForAlias(searchedArray.packData.bmsId)}
                  >
                    {searchedArray.packData.alias}&ensp;
                    <i class="fas fa-pen"></i>
                  </button>
                </div>
              </td>
              <td className="text-center" style={tableRowStyle}>
                <Link
                  to={{
                    pathname: "/admin/dashboard",
                    state: {
                      id: searchedArray.packData.bmsId,
                      status: searchedArray.packData.online,
                    },
                  }}
                >
                  {searchedArray.packData.bmsId}
                </Link>
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bootData.batteryNumber ? (
                  <div>{searchedArray.bootData.batteryNumber}</div>
                ) : searchedArray.packData.batteryNumber ? (
                  <div> {searchedArray.packData.batteryNumber} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                <Badge color={searchedArray.packData.online ? "success" : "danger"}>
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      padding: "2px",
                    }}
                  >
                    {searchedArray.packData.online ? (
                      <div>Online</div>
                    ) : (
                      <div>Offline</div>
                    )}
                  </div>
                </Badge>
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bmsValues.soC ? (
                  <div>{searchedArray.bmsValues.soC} % </div>
                ) : searchedArray.bmsValues.soC === 0 ? (
                  "0 %"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bmsValues.tms ? (
                  <div>
                    {searchedArray.bmsValues.tms.slice(11, 19)}&ensp;
                    {searchedArray.bmsValues.tms.slice(8, 10)}/
                    {searchedArray.bmsValues.tms.slice(5, 7)}/
                    {searchedArray.bmsValues.tms.slice(0, 4)}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bmsValues.chargeFet ? (
                  <div>{searchedArray.bmsValues.chargeFet === 1 ? "On" : "Off"}</div>
                ) : (
                  "Off"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bmsValues.dischargeFet ? (
                  <div>{searchedArray.bmsValues.dischargeFet === 1 ? "On" : "Off"}</div>
                ) : (
                  "Off"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bmsValues.cellTemp ? (
                  <div>
                    {Math.max(...JSON.parse(searchedArray.bmsValues.cellTemp))} °C
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bmsValues.pcbTemp ? (
                  <div>{Math.max(...JSON.parse(searchedArray.bmsValues.pcbTemp))} °C</div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bmsValues.packVoltage ? (
                  <div>{searchedArray.bmsValues.packVoltage / 1000} V</div>
                ) : searchedArray.bmsValues.packVoltage === 0 ? (
                  "0 V"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bmsValues.cycleCount ? (
                  <div>{searchedArray.bmsValues.cycleCount} </div>
                ) : searchedArray.bmsValues.cycleCount === 0 ? (
                  "0"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bmsValues.energyIn ? (
                  <div> {searchedArray.bmsValues.energyIn} W </div>
                ) : searchedArray.bmsValues.energyIn === 0 ? (
                  "0 W"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bmsValues.energyOut ? (
                  <div> {searchedArray.bmsValues.energyOut} W </div>
                ) : searchedArray.bmsValues.energyOut === 0 ? (
                  "0 W"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bmsValues.soH ? (
                  <div>{searchedArray.bmsValues.soH} % </div>
                ) : searchedArray.bmsValues.soH === 0 ? (
                  "0 %"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.packData.iotId ? <div>{searchedArray.packData.iotId} </div> : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.packData.dateOfDeployment ? (
                  <div>
                    {searchedArray.packData.dateOfDeployment.slice(11, 19)}
                    &ensp;
                    {searchedArray.packData.dateOfDeployment.slice(8, 10)}/
                    {searchedArray.packData.dateOfDeployment.slice(5, 7)}/
                    {searchedArray.packData.dateOfDeployment.slice(0, 4)}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.packData.batteryPackId}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.packData.batteryCapacity ? (
                  <div>{searchedArray.packData.batteryCapacity} Ah</div>
                ) : searchedArray.packData.batteryCapacity === 0 ? (
                  "0 Ah"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.packData.batteryCycleCount ? (
                  <div> {searchedArray.packData.batteryCycleCount} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.packData.batteryChemistry ? (
                  <div> {searchedArray.packData.batteryChemistry} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.packData.cRate ? (
                  <div>{searchedArray.packData.cRate} </div>
                ) : searchedArray.packData.cRate === 0 ? (
                  "0"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.packData.noOfS ? (
                  <div>{searchedArray.packData.noOfS} </div>
                ) : searchedArray.packData.noOfS === 0 ? (
                  "0"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.packData.noOfP ? (
                  <div>{searchedArray.packData.noOfP} </div>
                ) : searchedArray.packData.noOfP === 0 ? (
                  "0"
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.packData.cellModelNumber ? (
                  <div>{searchedArray.packData.cellModelNumber} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.packData.batteryManufacturer ? (
                  <div>{searchedArray.packData.batteryManufacturer} </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bootData.tms ? (
                  <div>
                    {searchedArray.bootData.tms.slice(11, 19)}&ensp;
                    {searchedArray.bootData.tms.slice(8, 10)}/
                    {searchedArray.bootData.tms.slice(5, 7)}/
                    {searchedArray.bootData.tms.slice(0, 4)}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bootData.firmwareVersion
                  ? searchedArray.bootData.firmwareVersion
                  : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bootData.hardwareVersion
                  ? searchedArray.bootData.hardwareVersion
                  : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bootData.bmsconfigVersion
                  ? searchedArray.bootData.bmsconfigVersion
                  : "-"}
              </td>
              {/* <td className="text-center" style={tableRowStyle}>
                {searchedArray.bootData.iotBoot.tms ? (
                  <div>
                    {searchedArray.bootData.iotBoot.tms.slice(11, 19)}&ensp;
                    {searchedArray.bootData.iotBoot.tms.slice(8, 10)}/
                    {searchedArray.bootData.iotBoot.tms.slice(5, 7)}/
                    {searchedArray.bootData.iotBoot.tms.slice(0, 4)}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bootData.iotBoot.firmwareVersion
                  ? searchedArray.bootData.iotBoot.firmwareVersion
                  : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bootData.iotBoot.hardwareVersion
                  ? searchedArray.bootData.iotBoot.hardwareVersion
                  : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {getIotBootReason(searchedArray.bootData.iotBoot.bootUpReason)}
                {iotBootReason ? iotBootReason : "-"}
              </td> */}
            </tr>
          </tbody>
        </table>
      );
    }
  };
  let enumForIotBootReason = [
    "ApplicationReset",
    "FirmwareUpdate",
    "LocalReset",
    "PowerUp",
    "RemoteReset",
    "ScheduledReset",
    "Triggered",
    "Unknown",
    "Watchdog",
  ];
  let iotBootReason;
  const getIotBootReason = (second) => {
    iotBootReason = enumForIotBootReason[second];
  };
  const tableHeaderStyle = {
    position: "sticky",
    top: 0,
    backgroundColor: "#6da2f7",
    color: "#000",
    padding: "5px",
  };

  const tableRowStyle = {
    color: "#30428c",
    fontWeight: "700",
    padding: "5px",
    borderBottom: "2px solid #c1d6c6",
  };
  // if (errorInPage) {
  //   return <Redirect to='/error-page'></Redirect>
  // }
  // else
  if (showUi) {
    return (
      <div>
        <Card style={{ padding: "15px", margin: "10px" }}>
          <Row style={{ padding: "0 15px 15px 15px", marginBottom: "10px" }}>
            <Col md="3" lg="3" sm="12" xs="12" xl="3">
              <ReactSelect options={bmsList} onChange={getSearchedItem} />
            </Col>
            {showNewNotificationData}
          </Row>
          {packTableS}
        </Card>
      </div>
    );
  } else
    return (
      <div className="content">
        <div className="loadingContainer">
          <div className="ball1"></div>
          <div className="ball2"></div>
          <div className="ball3"></div>
          <div className="ball4"></div>
        </div>
      </div>
    );
};

export default BmsDevices;
