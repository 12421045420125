import React, { useEffect, useState, useContext } from "react";
import { Card, Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, UncontrolledPopover, PopoverHeader, PopoverBody, Button } from "reactstrap";
import Parallel from "./Parallel";
import ParallelGraphs from "./ParallelGraphs";
import { API_URL } from "variables/general";
import SingleMapPro from "components/MapPro.js/SingleMapPro";
import DataLogging from "./DataLogging";
function ParallelTab(props) {
  const [iconPills, setIconPills] = useState("1");
  const [serverData, setServerData] = useState("");
  const [alias, setAlias] = useState(props.history.location.state.alias);
  const [idForAlias, setIdForAlias] = useState("");
  const [locationDataM, setLocationDataM] = useState("");
  const IotId = props.history.location.state.id;
  useEffect(() => {
    getParallelData();
  }, [])

  function getParallelData() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        did: IotId,
      }),
      redirect: "follow",
    };
    fetch(API_URL + "/bms/parallel_data", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setServerData(result.data);
      })
      .catch((error) => console.log("error", error));
  }
  const editAlias = (val) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify({
        batteryPackId: idForAlias,
        packData: {
          alias: alias,
        },
      }),
    };
    fetch(API_URL + "/bms/bms_devices", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // console.log("dataaaa", result)
        let status = JSON.parse(result).status;
        if (status === true) alert("Update Successful");
        // window.location.reload();
      })
      .catch((error) => console.log("error", error));
  };
  if (serverData)
    return (
      <>
        <Card
          style={{
            marginTop: "10px",
            textAlign: "center",
            padding: "5px",
            marginRight: "10px",
          }}
        >
          <Row>
            <Col md="6" xs="6" sm="6" lg="6">
              <div style={{ fontWeight: "bold" }}>Device ID</div>
              <div>
                {IotId}
              </div>
            </Col>
            <Col md="6" xs="6" sm="6" lg="6">
              <UncontrolledPopover
                target="PopoverForAlias"
                trigger="legacy"
                placement="bottom"
              >
                <PopoverHeader>Edit Alias</PopoverHeader>
                <PopoverBody>
                  <input
                    onChange={(event) => setAlias(event.target.value)}
                    placeholder="New Alias"
                  ></input>
                  <Button onClick={() => editAlias(IotId)}>
                    Submit
                  </Button>
                </PopoverBody>
              </UncontrolledPopover>
              <div style={{ fontWeight: "bold" }}>Alias</div>
              <div>
                {alias}
                <span
                  id="PopoverForAlias"
                  onClick={() => setIdForAlias(IotId)}
                  style={{
                    color: "#17a2b8",
                    backgroundColor: "white",
                    borderWidth: 0,
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                >
                  <i class="fas fa-pen"></i>
                </span>
              </div>
            </Col>
          </Row>
        </Card>
        <div className="section section-tabs">
          <Nav className="justify-content-center" role="tablist" tabs>
            <NavItem>
              <NavLink
                className={iconPills === "1" ? "active" : ""}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIconPills("1");
                }}
              >
                <strong>Home</strong>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconPills === "2" ? "active" : ""}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIconPills("2");
                }}
              >
                <strong>Analytics</strong>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconPills === "3" ? "active" : ""}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIconPills("3");
                }}
              >
                <strong>GPS Tracking</strong>
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                className={iconPills === "4" ? "active" : ""}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIconPills("4");
                }}
              >
                <strong>Data Logging</strong>
              </NavLink>
            </NavItem> */}
          </Nav>
          <TabContent
            className="text-center"
            activeTab={"iconPills" + iconPills}
          >
            <TabPane tabId="iconPills1">
              <Parallel serverData={serverData} IotId={IotId} setLocationDataM={setLocationDataM}/>
            </TabPane>
            <TabPane tabId="iconPills2">
              <ParallelGraphs serverData={serverData} IotId={IotId} />
            </TabPane>
            <TabPane tabId="iconPills3">
              <SingleMapPro IotId={IotId} locationDataM={locationDataM} evTracker={true}/>
            </TabPane>
            {/* <TabPane tabId="iconPills4">
            <DataLogging serverData={serverData} IotId={IotId}/>
            </TabPane> */}
          </TabContent>
        </div>
      </>
    );
  else return <div>loading...</div>
}

export default ParallelTab;
