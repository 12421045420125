import { Card, Col, Row, table } from "reactstrap";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { API_URL } from "variables/general";

const Notifications = (props) => {
  const [notificationData, setNotificationData] = useState("");
  const [bmsList, setBmsList] = useState("");
  const [expandFault, setExpandFault] = useState(false);
  const [expandAlarm, setExpandAlarm] = useState(false);
  const [expandWarning, setExpandWarning] = useState(false);
  const [expandGeoFence, setExpandGeoFence] = useState(false);
  const [heading, setHeading] = useState("All Notifications");
  const [subHeading, setSubHeading] = useState("");
  const [subUserData, setSubUserData] = useState("");
  useEffect(() => {
    getNotification(props.location.state ? props.location.state : null);
    if (window.localStorage.getItem("role") === "sub_owner") getBmsList();
    else {
      getSubUser();
    }
  }, []);
  const getSubUser = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(API_URL + "/bms/manage_bms_subuser", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSubUserData(result.data);
      })
      .catch((error) => console.log("error", error));
  };
  const getBmsList = (val) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    val
      ? (requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ subUser: val }),
      })
      : (requestOptions = {
        method: "GET",
        headers: myHeaders,
      });
    fetch(API_URL + "/bms/bms_sub_user", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setBmsList(result.data);
      })
      .catch((error) => console.log("error", error));
  };
  const getNotification = (val) => {
    if (val) setHeading(val);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    val
      ? (requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ id: val }),
      })
      : (requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      });
    fetch(API_URL + "/bms/notifications", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setNotificationData(result.data);
        if (val) getBmsList(val);
      })
      .catch((error) => console.log("error", error));
  };
  const getNotificationForSubOwner = (val) => {
    if (val)
      if (val === "All") {
        setHeading("All Notifications")
        setSubHeading(null)
      }
      else setHeading(val);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    val === "All"
      ? (requestOptions = {
        method: "GET",
        headers: myHeaders,
      })
      : (requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ id: val }),
      });
    fetch(API_URL + "/bms/notifications", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setNotificationData(result.data);
        getBmsList(val);
      })
      .catch((error) => console.log("error", error));
  };
  const getNotificationForBms = (val) => {
    if (val) setSubHeading(val.label);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ bmsId: val.value }),
    };
    fetch(API_URL + "/bms/notifications", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setNotificationData(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  const cardStyle = {
    marginBottom: "2%",
    padding: "15px",
    height: "40vh",
  };
  const hStyle = {
    textAlign: "center",
  };
  const tableHeader = {
    position: "sticky",
    backgroundColor: "white",
    top: 0,
    padding: "5px",
    textAlign: "center",
  };
  const tableBody = {
    color: "#30428c",
    fontWeight: "700",
    padding: "5px",
    borderBottom: "2px solid #c1d6c6",
    textAlign: "center",
    zIndex: -1,
  };
  let gridContainer;
  if (expandFault)
    gridContainer = {
      display: "grid",
      gridTemplateAreas: `'gridItem1 gridItem1 gridItem1'
      'gridItem2 gridItem3 gridItem4'`,
      gap: "10px",
    };
  else if (expandAlarm)
    gridContainer = {
      display: "grid",
      gridTemplateAreas: `'gridItem2 gridItem2 gridItem2'
      'gridItem1 gridItem3 gridItem4'`,
      gap: "10px",
    };
  else if (expandWarning)
    gridContainer = {
      display: "grid",
      gridTemplateAreas: `'gridItem3 gridItem3 gridItem3'
      'gridItem1 gridItem2 gridItem4'`,
      gap: "10px",
    };
  else if (expandGeoFence)
    gridContainer = {
      display: "grid",
      gridTemplateAreas: `'gridItem4 gridItem4 gridItem4'
      'gridItem1 gridItem2 gridItem3'`,
      gap: "10px",
    };
  else
    gridContainer = {
      display: "grid",
      gridTemplateAreas: `'gridItem1 gridItem2'
      'gridItem3 gridItem4'`,
      gap: "10px",
    };
  const gridItem1 = { gridArea: "gridItem1" };
  const gridItem2 = { gridArea: "gridItem2" };
  const gridItem3 = { gridArea: "gridItem3" };
  const gridItem4 = { gridArea: "gridItem4" };
  let lastFault;
  function lastFaultCheck(val) {
    if (val === "0") lastFault = "No Fault";
    else if (val === "1") lastFault = "Over Pack Voltage Fault";
    else if (val === "2") lastFault = "Over Pack Voltage";
    else if (val === "3") lastFault = "Under Pack Voltage Fault";
    else if (val === "4") lastFault = "Over OCD1 Current";
    else if (val === "5") lastFault = "Over OCD2 Current";
    else if (val === "6") lastFault = "Over Charge Current";
    else if (val === "7") lastFault = "Over Discharge Current";
    else if (val === "8") lastFault = "Short Circuit";
    else if (val === "9") lastFault = "Cell Open Wire Fault";
    else if (val === "10") lastFault = "Voltage Reference Fault";
    else if (val === "11") lastFault = "Voltage Regulator Fault";
    else if (val === "12") lastFault = "Temperature Multiplexer Error";
    else if (val === "13") lastFault = "Under Temperature Fault";
    else if (val === "14") lastFault = "Load Over Temperature";
    else if (val === "15") lastFault = "Oscillator Fault";
    else if (val === "16") lastFault = "Watchdog Timeout Fault";
    else if (val === "17") lastFault = "Under Cell Voltage Fault";
    else if (val === "18") lastFault = "Over Cell Voltage Fault";
    else if (val === "19") lastFault = "Open Wire Fault";
    else if (val === "20") lastFault = "Over Temperature Fault";
    else if (val === "21") lastFault = "Cell Unbalance Pro";
    else if (val === "22") lastFault = "SCD Latch High";
    else lastFault = "Error";
  }
  let Alarm = [
    "NoneFault",
    "OverPackVoltageAlarm",
    "UnderPackVoltageAlarm",
    "OverChargeCurrentAlarm",
    "OverDisChargeCurrentAlarm",
    "CellUnderTempAlarm",
    "CellOverTempAlarm",
    "PDUOverTempAlarm",
    "UnderCellVoltageAlarm",
    "OverCellVoltageAlarm",
    "CellUnBalProAlarm",
    "FullChargeAlarm",
    "FullDischargeAlarm",
    "ReachReserve1Alarm",
    "ReachReserve2Alarm",
  ];

  if (
    window.localStorage.getItem("role") === "sub_owner"
      ? notificationData
      : notificationData && subUserData
  ) {
    let subUserList = [];
    if (subUserData) {
      for (let i = 0; i < subUserData.users.length; i++) {
        subUserList[i + 1] = {
          value: subUserData.users[i].id,
          label: subUserData.users[i].userProfile.name,
        };
      }
      subUserList[0] = {
        value: "All",
        label: "All Sub Operators",
      };
    }
    let subUserBmsList = [];
    if (bmsList) {
      if (window.localStorage.getItem("role") === "sub_owner") {
        for (let i = 0; i < bmsList.length; i++) {
          subUserBmsList[i + 1] = {
            value: bmsList[i].bmsId,
            label: bmsList[i].alias ? bmsList[i].alias : bmsList[i].bmsId,
          };
        }
        subUserBmsList[0] = {
          value: "All",
          label: "All Devices"
        }
      } else
        for (let i = 0; i < bmsList.length; i++) {
          subUserBmsList[i] = {
            value: bmsList[i].bmsId,
            label: bmsList[i].alias ? bmsList[i].alias : bmsList[i].bmsId,
          };
        }
    }
    let faultArray = [];
    let alarmArray = [];
    let warningArray = [];
    let geoFenceArray = [];
    for (let key in notificationData) {
      if (notificationData[key].type === "fault")
        faultArray.push(notificationData[key]);
      else if (notificationData[key].type === "alarm")
        alarmArray.push(notificationData[key]);
      else if (notificationData[key].type === "warning")
        warningArray.push(notificationData[key]);
      else if (notificationData[key].type === "geo_fence")
        geoFenceArray.push(notificationData[key]);
    }
    function handleSearchValue(val) {
      setBmsList(null);
      setSubHeading(null)
      getNotificationForSubOwner(val.value);
    }
    function handleBmsSearchValue(val) {
      if (val.value === 'All') {
        if (window.localStorage.getItem("role") === "sub_owner") {
          getNotification(props.location.state ? props.location.state : null);
          setSubHeading(null);
        }
      }
      else getNotificationForBms(val);
    }
    return (
      <>
        <div className="main-margin">
          <h5 style={{ textAlign: "center" }}>{heading}</h5>
          <h6 style={{ textAlign: "center" }}>{subHeading}</h6>
          <Row>

            {window.localStorage.getItem("role") === "sub_owner" ? (
              ""
            ) : (<Col md="3" lg="3" sm="12" xs="12" xl="3">
              <div style={{ margin: '5px' }}><Select options={subUserList} onChange={handleSearchValue} /></div> </Col>
            )}

            <Col md="3" lg="3" sm="12" xs="12" xl="3">
              {bmsList ? (
                <div style={{ margin: '5px' }}>
                  <Select
                    options={subUserBmsList}
                    onChange={handleBmsSearchValue}
                  /></div>
              ) : (
                ""
              )}
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>
          <Row><Col md="6" lg="6" sm="12" xs="12" xl="6"><div>
            <Card style={cardStyle}>
              <h5 style={hStyle}>
                Fault
              </h5>
              {faultArray.length > 0 ? (
                <table
                  style={{
                    overflow: "scroll",
                    width: "100%",
                    display: expandFault ? "" : "block",
                    height: expandFault ? "" : "40vh",
                  }}
                >
                  <thead style={tableHeader}>
                    <th style={tableHeader}>S.No</th>
                    <th style={tableHeader}>Device Id</th>
                    <th style={tableHeader}>Time</th>
                    <th style={tableHeader}>Content</th>
                  </thead>
                  <tbody style={tableBody}>
                    {faultArray.map((val, i) => (
                      <tr>
                        <td style={tableBody}>{i + 1}</td>
                        <td style={tableBody}>{val.did}</td>
                        <td style={tableBody}>
                          {val.tms ? (
                            <>
                              {val.tms.slice(11, 19)}&ensp;
                              {val.tms.slice(8, 10)}/{val.tms.slice(5, 7)}/
                              {val.tms.slice(0, 4)}
                            </>
                          ) : null}
                        </td>
                        <td style={tableBody}>
                          {lastFaultCheck(val.content)}
                          {lastFault}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h4
                  style={{
                    textAlign: "center",
                    margin: "10%",
                    color: "rgba(0,0,0,0.3)",
                  }}
                >
                  No data yet
                </h4>
              )}
            </Card>
          </div></Col><Col md="6" lg="6" sm="12" xs="12" xl="6"><div>
            <Card style={cardStyle}>
              <h5 style={hStyle}>
                Alarm
              </h5>
              {alarmArray.length > 0 ? (
                <table
                  style={{
                    overflow: "scroll",
                    width: "100%",
                    display: expandAlarm ? "" : "block",
                    height: expandAlarm ? "" : "40vh",
                  }}
                >
                  <thead style={tableHeader}>
                    <th style={tableHeader}>S.No</th>
                    <th style={tableHeader}>Device Id</th>
                    <th style={tableHeader}>Time</th>
                    <th style={tableHeader}>Content</th>
                  </thead>
                  <tbody style={tableBody}>
                    {alarmArray.map((val, i) => (
                      <tr>
                        <td style={tableBody}>{i + 1}</td>
                        <td style={tableBody}>{val.did}</td>
                        <td style={tableBody}>
                          {val.tms ? (
                            <>
                              {val.tms.slice(11, 19)}&ensp;
                              {val.tms.slice(8, 10)}/{val.tms.slice(5, 7)}/
                              {val.tms.slice(0, 4)}
                            </>
                          ) : null}
                        </td>
                        <td style={tableBody}>{val.content}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h4
                  style={{
                    textAlign: "center",
                    margin: "10%",
                    color: "rgba(0,0,0,0.3)",
                  }}
                >
                  No data yet
                </h4>
              )}
            </Card>
          </div></Col></Row>
          <Row><Col md="6" lg="6" sm="12" xs="12" xl="6"><div>
            <Card style={cardStyle}>
              <h5 style={hStyle}>
                Warning
              </h5>
              {warningArray.length > 0 ? (
                <table
                  style={{
                    overflow: "scroll",
                    width: "100%",
                    display: expandWarning ? "" : "block",
                    height: expandWarning ? "" : "40vh",
                  }}
                >
                  <thead style={tableHeader}>
                    <th style={tableHeader}>S.No</th>
                    <th style={tableHeader}>Device Id</th>
                    <th style={tableHeader}>Time</th>
                    <th style={tableHeader}>Content</th>
                  </thead>
                  <tbody style={tableBody}>
                    {warningArray.map((val, i) => (
                      <tr>
                        <td style={tableBody}>{i + 1}</td>
                        <td style={tableBody}>{val.did}</td>
                        <td style={tableBody}>
                          {val.tms ? (
                            <>
                              {val.tms.slice(11, 19)}&ensp;
                              {val.tms.slice(8, 10)}/{val.tms.slice(5, 7)}/
                              {val.tms.slice(0, 4)}
                            </>
                          ) : null}
                        </td>
                        <td style={tableBody}>{val.content}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h4
                  style={{
                    textAlign: "center",
                    margin: "10%",
                    color: "rgba(0,0,0,0.3)",
                  }}
                >
                  No data yet
                </h4>
              )}
            </Card>
          </div></Col><Col md="6" lg="6" sm="12" xs="12" xl="6"><div>
            <Card style={cardStyle}>
              <h5 style={hStyle}>
                Geo Fence
              </h5>
              {geoFenceArray.length > 0 ? (
                <table
                  style={{
                    overflow: "scroll",
                    width: "100%",
                    display: expandGeoFence ? "" : "block",
                    height: expandGeoFence ? "" : "40vh",
                  }}
                >
                  <thead style={tableHeader}>
                    <th style={tableHeader}>S.No</th>
                    <th style={tableHeader}>Device Id</th>
                    <th style={tableHeader}>Time</th>
                    <th style={tableHeader}>Content</th>
                  </thead>
                  <tbody style={tableBody}>
                    {geoFenceArray.map((val, i) => (
                      <tr>
                        <td style={tableBody}>{i + 1}</td>
                        <td style={tableBody}>{val.did}</td>
                        <td style={tableBody}>
                          {val.tms ? (
                            <>
                              {val.tms.slice(11, 19)}&ensp;
                              {val.tms.slice(8, 10)}/{val.tms.slice(5, 7)}/
                              {val.tms.slice(0, 4)}
                            </>
                          ) : null}
                        </td>
                        <td style={tableBody}>{val.content}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h4
                  style={{
                    textAlign: "center",
                    margin: "10%",
                    color: "rgba(0,0,0,0.3)",
                  }}
                >
                  No data yet
                </h4>
              )}
            </Card>
          </div></Col></Row>
        </div>
      </>
    );
  } else
    return (
      <div className="content">
        <div className="loadingContainer">
          <div className="ball1"></div>
          <div className="ball2"></div>
          <div className="ball3"></div>
          <div className="ball4"></div>
        </div>
      </div>
    );
};

export default Notifications;
