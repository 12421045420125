import React from "react";
import { Row, Col, Card } from "reactstrap";
const decoder = new TextDecoder("utf-8");
function Header(props) {
  let BatChar_1 = props.BatChar_1;
  let BatChar_2 = props.BatChar_2;
  let BatChar_3 = props.BatChar_3;
  let BatChar_4 = props.BatChar_4;
  let BatterySerialInt = props.BatterySerialInt;
  let BMSModelID_Char1 = props.BMSModelID_Char1;
  let BMSModelID_Char2 = props.BMSModelID_Char2;
  let BMSModelID_Char3 = props.BMSModelID_Char3;
  let BMSModelID_Char4 = props.BMSModelID_Char4;
  let BMSModelID_Char5 = props.BMSModelID_Char5;
  let BMSModelID_Char6 = props.BMSModelID_Char6;
  let BMSModelID_Char7 = props.BMSModelID_Char7;
  let PDUHardwareVersion_Char1 = props.PDUHardwareVersion_Char1;
  let PDUHardwareVersion_Char2 = props.PDUHardwareVersion_Char2;
  let PDUHardwareVersion_Char3 = props.PDUHardwareVersion_Char3;
  let PDUHardwareVersion_Char4 = props.PDUHardwareVersion_Char4;
  let PDUHardwareVersion_Char5 = props.PDUHardwareVersion_Char5;
  let PDUHardwareVersion_Char6 = props.PDUHardwareVersion_Char6;
  let PDUHardwareVersion_Char7 = props.PDUHardwareVersion_Char7;
  let PDUHardwareVersion_Char8 = props.PDUHardwareVersion_Char8;
  let BMSHardwareVersion_Char1 = props.BMSHardwareVersion_Char1;
  let BMSHardwareVersion_Char2 = props.BMSHardwareVersion_Char2;
  let BMSHardwareVersion_Char3 = props.BMSHardwareVersion_Char3;
  let BMSHardwareVersion_Char4 = props.BMSHardwareVersion_Char4;
  let BMSHardwareVersion_Char5 = props.BMSHardwareVersion_Char5;
  let BMSHardwareVersion_Char6 = props.BMSHardwareVersion_Char6;
  let BMSHardwareVersion_Char7 = props.BMSHardwareVersion_Char7;
  let BMSHardwareVersion_Char8 = props.BMSHardwareVersion_Char8;
  let BMSFirmwareVersion_Char1 = props.BMSFirmwareVersion_Char1;
  let BMSFirmwareVersion_Char2 = props.BMSFirmwareVersion_Char2;
  let BMSFirmwareVersion_Char3 = props.BMSFirmwareVersion_Char3;
  let BMSFirmwareVersion_Char4 = props.BMSFirmwareVersion_Char4;
  let BMSFirmwareVersion_Char5 = props.BMSFirmwareVersion_Char5;
  let BMSFirmwareVersion_Char6 = props.BMSFirmwareVersion_Char6;
  let BMSFirmwareVersion_Char7 = props.BMSFirmwareVersion_Char7;
  let BMSFirmwareVersion_Char8 = props.BMSFirmwareVersion_Char8;
  let Year = props.Year;
  let month = props.month;
  let day = props.day;
  let hour = props.hour;
  let minutes = props.minutes;
  let seconds = props.seconds;

  let batterySerialArray = decoder.decode(
    new Uint8Array([BatChar_1, BatChar_2, BatChar_3, BatChar_4])
  );
  let bmsModelId = decoder.decode(
    new Uint8Array([
      BMSModelID_Char1,
      BMSModelID_Char2,
      BMSModelID_Char3,
      BMSModelID_Char4,
      BMSModelID_Char5,
      BMSModelID_Char6,
      BMSModelID_Char7,
    ])
  );
  let bmsHardwareVersion = decoder.decode(
    new Uint8Array([
      BMSHardwareVersion_Char1,
      BMSHardwareVersion_Char2,
      BMSHardwareVersion_Char3,
      BMSHardwareVersion_Char4,
      BMSHardwareVersion_Char5,
      BMSHardwareVersion_Char6,
      BMSHardwareVersion_Char7,
      BMSHardwareVersion_Char8,
    ])
  );
  let PDUHardwareVersion = decoder.decode(
    new Uint8Array([
      PDUHardwareVersion_Char1,
      PDUHardwareVersion_Char2,
      PDUHardwareVersion_Char3,
      PDUHardwareVersion_Char4,
      PDUHardwareVersion_Char5,
      PDUHardwareVersion_Char6,
      PDUHardwareVersion_Char7,
      PDUHardwareVersion_Char8,
    ])
  );
  let FirmwareVersion = decoder.decode(
    new Uint8Array([
      BMSFirmwareVersion_Char1,
      BMSFirmwareVersion_Char2,
      BMSFirmwareVersion_Char3,
      BMSFirmwareVersion_Char4,
      BMSFirmwareVersion_Char5,
      BMSFirmwareVersion_Char6,
      BMSFirmwareVersion_Char7,
      BMSFirmwareVersion_Char8,
    ])
  );
  return (
    <div>
      <Card
        style={{
          padding: "1rem 2rem",
          backgroundColor: "#f0f8ff",
          color: "green",
          fontWeigth: "bolder",
          margin: "1rem",
          textAlign: "center",
        }}
      >
        <>
          <Row>
            <Col md="6" lg="6" sm="6" xs="6" xl="6">
              <strong>
                Date: {day}/{month}/{Year}
              </strong>
              &ensp;
              &ensp;
              &ensp;
              <strong>
                Time: {hour}:{minutes}:{seconds}
              </strong>
            </Col>
            <Col md="6" lg="6" sm="6" xs="6" xl="6">
              Battery Pack ID
              <br />
              <strong>
                {props.batteryPackId1}
                {props.batteryPackId2}
              </strong>
            </Col>
          </Row>
          <Row>
            <Col md="3" lg="3" sm="6" xs="6" xl="3">
              Battery Pack Serial Number
              <br />
              <strong>
                {batterySerialArray}
                {BatterySerialInt}
              </strong>
            </Col>
            <Col md="3" lg="3" sm="6" xs="6" xl="3">
              BMS model id
              <br />
              <strong>{bmsModelId}</strong>
            </Col>
            <Col md="3" lg="3" sm="6" xs="6" xl="3">
              BMS Hardware Version
              <br />
              <strong>
                {bmsHardwareVersion}--
                {PDUHardwareVersion}
              </strong>
            </Col>
            <Col md="3" lg="3" sm="6" xs="6" xl="3">
              Firmware version
              <br />
              <strong>{FirmwareVersion}</strong>
            </Col>
          </Row>
        </>
        {/* ) : (
          <Row>
            <Col style={{ textAlign: "center" }}>Waiting for data...</Col>
          </Row>
        )} */}
      </Card>
    </div>
  );
}

export default Header;
