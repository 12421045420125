import React, { useEffect, useState } from "react";
import { API_URL } from "variables/general";
import { Button, Row, Col, Spinner, Card } from "reactstrap";
import ReactSelect from "react-select";

function AddEVTracker() {
  useEffect(() => {
    getSubUser()
  }, [])
  const [subUserList, setSubUserList] = useState([])
  const [ownerId, setOwnerId] = useState('')
  const [subUserId, setSubUserId] = useState('')
  const [EVTrackerID, setEVTrackerID] = useState('')
  const [alias, setAlias] = useState('')
  const [manufacturingDate, setManufacturingDate] = useState('')
  const getSubUser = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(API_URL + "/bms/manage_bms_subuser", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSubUserList(result.data.users)
        setOwnerId(result.data.users[0].ownerId)
      })
      .catch((error) => console.log("error", error));
  };
  const sendData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body:JSON.stringify({
        "ownerId":ownerId,
        "did":EVTrackerID,
        "subOwner":subUserId,
        "manufacturingDate":manufacturingDate + 'T00:00:00Z',
        "alias":alias,
      })
    };
    if(ownerId&&EVTrackerID&&subUserId&&manufacturingDate){
    fetch(API_URL + "/iot/devices", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if(result.status){
          alert('Saved Data')
        }
        else alert('Error')
      })
      .catch((error) => console.log("error", error));}
      else alert('Incomplete Data')
  };

  const getSubUserId = (event) => {
    setSubUserId(event.value)
  }

  if (subUserList.length > 0) {
    let subUserListOptions = []
    subUserList.map((val, i) => (
      subUserListOptions[i] = {
        value: val.id,
        label: val.userProfile.name
      }
    ))
    return <div className="main-margin">
      <Card
        style={{
          padding: "5px 50px",
          border: "solid",
          margin: "10px",
          borderColor: "#32C85A",
        }}
      >
        <h5 style={{ textAlign: "center" }}>Add New EV Tracker</h5>
        <Row>
          <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
            <strong className="adt-blue-color">Select Sub Operator</strong>
            <br />
            <ReactSelect
              onChange={getSubUserId}
              options={subUserListOptions}
            ></ReactSelect>
          </Col>
          <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
            <strong className="adt-blue-color">Enter EV Tracker ID</strong>
            <br />
            <input className="adt-input"
              type="text"
              placeholder="ID Here"
              value={EVTrackerID}
              onChange={(event) => setEVTrackerID(event.target.value)}
            />
          </Col>
          <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
            <strong className="adt-blue-color">Enter Alias</strong>
            <br />
            <input className="adt-input"
              type="text"
              placeholder="Alias Here"
              value={alias}
              onChange={(event) => setAlias(event.target.value)}
            />
          </Col>
          <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
            <strong className="adt-blue-color">Enter Battery Manufacturing Date</strong>
            <br />
            <input className="adt-input"
              type="date"
              value={manufacturingDate}
              onChange={(event) => setManufacturingDate(event.target.value)}
            />
          </Col>
        </Row>
        <Row><Col><Button
              onClick={sendData}
              style={{
                color: "#f4f3ef",
                backgroundColor: "#009846",
                width: "10rem"
              }}
            >
              Save new Device
            </Button>
            </Col></Row>
      </Card>
    </div>
  }
  else return (<div className="main-margin" style={{ textAlign: "center" }}>
    <Spinner >
      Loading...
    </Spinner>
  </div>)
}

export default AddEVTracker;
