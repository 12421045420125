import React from 'react'

function Error() {
  return (
    <div>
        <h1>Error</h1>
        <h6>What you can do:</h6>
        <span>1. Check your internet connection</span>
        <br />
        <span>2. See if your computer is not our of RAM</span>
        <br />
        If the problem still Persists:<h6>Email us at info@aeidth.com</h6>
    </div>
  )
}

export default Error