import { Card, Col, Row } from "reactstrap";
import React, { useState } from "react";

import AddDevice from "./AddDevice";
import CreateSubUser from "./CreateSubUser";
import ManageDevice from "./ManageDevice";
import ManageSubUser from "./ManageSubUser";
import MapSubUserBMS from "./MapSubUserBMS";
import AddEVTracker from "./AddEVTracker";
import ManageEVTracker from "./ManageEVTracker";

function UserSettings() {
  const [showCreateSubUser, setShowCreateSubUser] = useState(false);
  const [showManageSubUser, setShowManageSubUser] = useState(false);
  const [showMapSubUserBMS, setShowMapSubUserBMS] = useState(false);
  const [showManageDevice, setShowManageDevice] = useState(false);
  const [showAddDevice, setShowAddDevice] = useState(false);
  const [showAddEVTracker, setShowAddEVTracker] = useState(false);
  const [showManageEVTracker, setShowManageEVTracker] = useState(false);
  return (
    <div>
      <h4 style={{ textAlign: "center" }}>User Settings</h4>
      <Row>
        <Col xs="6" sm="4" md="3" lg="3" xl="2">
          <Card
            style={{
              padding: "15px",
              margin: "10px",
              backgroundColor: "white",
              fontWeight: showCreateSubUser ? "bolder" : "",
              color: showCreateSubUser ? "#32C85A" : "#0faef2",
              textAlign: "center",
              borderRadius: "25px",
              border: "solid",
              borderColor: showCreateSubUser ? "#32C85A" : "",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowCreateSubUser(true);
              setShowManageSubUser(false);
              setShowMapSubUserBMS(false);
              setShowManageDevice(false);
              setShowAddDevice(false);
              setShowAddEVTracker(false);
              setShowManageEVTracker(false);
            }}
          >
            <i class="fas fa-user-plus fa-3x"></i> Create Sub Operator
          </Card>
        </Col>
        <Col xs="6" sm="4" md="3" lg="3" xl="2">
          <Card
            style={{
              padding: "15px",
              margin: "10px",
              backgroundColor: "white",
              fontWeight: showManageSubUser ? "bolder" : "",
              color: showManageSubUser ? "#32C85A" : "#0faef2",
              textAlign: "center",
              borderRadius: "25px",
              border: "solid",
              borderColor: showManageSubUser ? "#32C85A" : "",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowCreateSubUser(false);
              setShowManageSubUser(true);
              setShowMapSubUserBMS(false);
              setShowManageDevice(false);
              setShowAddDevice(false);
              setShowAddEVTracker(false);
              setShowManageEVTracker(false);
            }}
          >
            <i class="fas fa-user-edit fa-3x"></i> Manage Sub Operator
          </Card>
        </Col>
        <Col xs="6" sm="4" md="3" lg="3" xl="2">
          <Card
            style={{
              padding: "15px",
              margin: "10px",
              backgroundColor: "white",
              fontWeight: showMapSubUserBMS ? "bolder" : "",
              color: showMapSubUserBMS ? "#32C85A" : "#0faef2",
              textAlign: "center",
              borderRadius: "25px",
              border: "solid",
              borderColor: showMapSubUserBMS ? "#32C85A" : "",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowCreateSubUser(false);
              setShowManageSubUser(false);
              setShowMapSubUserBMS(true);
              setShowManageDevice(false);
              setShowAddDevice(false);
              setShowAddEVTracker(false);
              setShowManageEVTracker(false);
            }}
          >
            <i class="fas fa-user-tag fa-3x"></i> Map Sub Operator to Battery Tracker
          </Card>
        </Col>
        <Col xs="6" sm="4" md="3" lg="3" xl="2">
          <Card
            style={{
              padding: "15px",
              margin: "10px",
              backgroundColor: "white",
              fontWeight: showManageDevice ? "bolder" : "",
              color: showManageDevice ? "#32C85A" : "#0faef2",
              textAlign: "center",
              borderRadius: "25px",
              border: "solid",
              borderColor: showManageDevice ? "#32C85A" : "",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowCreateSubUser(false);
              setShowManageSubUser(false);
              setShowMapSubUserBMS(false);
              setShowManageDevice(true);
              setShowAddDevice(false);
              setShowAddEVTracker(false);
              setShowManageEVTracker(false);
            }}
          >
            <i class="fas fa-pen-square fa-3x"></i> Manage Battery Tracker
          </Card>
        </Col>

        {window.localStorage.getItem('role') === 'admin' ?
          <>
            <Col xs="6" sm="4" md="3" lg="3" xl="2">
              <Card
                style={{
                  padding: "15px",
                  margin: "10px",
                  backgroundColor: "white",
                  fontWeight: showAddEVTracker ? "bolder" : "",
                  color: showAddEVTracker ? "#32C85A" : "#0faef2",
                  textAlign: "center",
                  borderRadius: "25px",
                  border: "solid",
                  borderColor: showAddEVTracker ? "#32C85A" : "",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowCreateSubUser(false);
                  setShowManageSubUser(false);
                  setShowMapSubUserBMS(false);
                  setShowManageDevice(false);
                  setShowAddDevice(false);
                  setShowAddEVTracker(true);
                  setShowManageEVTracker(false);
                }}
              >
                <i class="fas fa-plus-square fa-3x"></i> Add new EV Tracker
              </Card>
            </Col>
            <Col xs="6" sm="4" md="3" lg="3" xl="2">
              <Card
                style={{
                  padding: "15px",
                  margin: "10px",
                  backgroundColor: "white",
                  fontWeight: showAddDevice ? "bolder" : "",
                  color: showAddDevice ? "#32C85A" : "#0faef2",
                  textAlign: "center",
                  borderRadius: "25px",
                  border: "solid",
                  borderColor: showAddDevice ? "#32C85A" : "",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowCreateSubUser(false);
                  setShowManageSubUser(false);
                  setShowMapSubUserBMS(false);
                  setShowManageDevice(false);
                  setShowAddDevice(true);
                  setShowAddEVTracker(false);
                  setShowManageEVTracker(false);
                }}
              >
                <i class="fas fa-plus-square fa-3x"></i> Add new Battery Tracker
              </Card>
            </Col>
            <Col xs="6" sm="4" md="3" lg="3" xl="2">
              <Card
                style={{
                  padding: "15px",
                  margin: "10px",
                  backgroundColor: "white",
                  fontWeight: showManageEVTracker ? "bolder" : "",
                  color: showManageEVTracker ? "#32C85A" : "#0faef2",
                  textAlign: "center",
                  borderRadius: "25px",
                  border: "solid",
                  borderColor: showManageEVTracker ? "#32C85A" : "",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowCreateSubUser(false);
                  setShowManageSubUser(false);
                  setShowMapSubUserBMS(false);
                  setShowManageDevice(false);
                  setShowAddDevice(false);
                  setShowAddEVTracker(false);
                  setShowManageEVTracker(true);
                }}
              >
                <i class="fas fa-pen-square fa-3x"></i> Manage EV Tracker
              </Card>
            </Col> </> : ''}


      </Row>

      {showCreateSubUser ? <CreateSubUser /> : ""}
      {showManageSubUser ? <ManageSubUser /> : ""}
      {showMapSubUserBMS ? <MapSubUserBMS /> : ""}
      {showManageDevice ? <ManageDevice /> : ""}
      {showManageEVTracker ? <ManageEVTracker /> : ""}
      {showAddDevice ? <AddDevice /> : ""}
      {showAddEVTracker ? <AddEVTracker /> : ""}
    </div>
  );
}

export default UserSettings;
