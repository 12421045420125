import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  Row,
  Col,
} from "reactstrap";
import { API_URL } from "variables/general";
import { bmsIdContext } from "../../views/Dashboard";
function NewConfiguration(props) {
  useEffect(()=>{
    getBmsConfiguration()
  },[])
  const bmsId = useContext(bmsIdContext);

  const [iconPills, setIconPills] = useState("1");
  const [bmsConfigurationData, setBmsConfigurationData] = useState("");
  const TypeOfCellEnum = ["Unknown", "LCO", "LMO", "NMC", "LFP", "NCA", "LTO"];
  const TrueFalseEnum = [false,true]


  const getBmsConfiguration = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        batteryPackId: bmsId,
      }),
      redirect: "follow",
    };
    fetch(API_URL + "/bms/settings_fetch", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setBmsConfigurationData(result.data);
      })
      .catch((error) => console.log("error", error));
  };
  if (bmsConfigurationData) {
    const configurationDetails = {
      cell: [
        {
          name: "Balance Start Voltage",
          value: bmsConfigurationData.balanceStartVoltage,
          limit: "0-5000",
          unit: "mV",
          id: "BalanceStartVoltage",
        },
        {
          name: "Balance Window",
          value: bmsConfigurationData.balanceWindow,
          limit: "0-255",
          unit: "mV",
          id: "BalanceWindow",
        },
        {
          name: "Cell Full Charge Voltage",
          value: bmsConfigurationData.cellFullChrVoltage,
          limit: "0-5000",
          unit: "mV",
          id: "CellFullChrVoltage",
        },
        {
          name: "Cell Nominal Voltage",
          value: bmsConfigurationData.cellNominalVoltage,
          limit: "0-5000",
          unit: "mV",
          id: "CellNominalVoltage",
        },
        {
          name: "Cell Under Voltage",
          value: bmsConfigurationData.cellUnderVoltage,
          limit: "0-5000",
          unit: "mV",
          id: "CellUnderVoltage",
        },
        {
          name: "Cell Over Voltage",
          value: bmsConfigurationData.cellOverVoltage,
          limit: "0-5000",
          unit: "mV",
          id: "CellOverVoltage",
        },
        {
          name: "Cell Over Voltage Release",
          value: bmsConfigurationData.cellOVRelease,
          limit: "0-5000",
          unit: "mV",
          id: "CellOVRelease",
        },
        {
          name: "Cell Over Voltage Delay",
          value: bmsConfigurationData.cellOVDelay / 10,
          limit: "0-65534",
          unit: "s",
          id: "CellOVDelay",
        },
        {
          name: "Hard Cell Over Voltage",
          value: bmsConfigurationData.hardCellOverVoltage,
          limit: "0-5000",
          unit: "mV",
          id: "HardCellOverVoltage",
        },
        {
          name: "Cell Under Voltage Release",
          value: bmsConfigurationData.cellUVRelease,
          limit: "0-5000",
          unit: "mV",
          id: "CellUVRelease",
        },
        {
          name: "Cell Under Voltage Delay",
          value: bmsConfigurationData.cellUVDelay / 10,
          limit: "0-65535",
          unit: "s",
          id: "CellUVDelay",
        },
        {
          name: "Hard Cell Under Voltage",
          value: bmsConfigurationData.hardCellUnderVoltage,
          limit: "0-5000",
          unit: "mV",
          id: "HardCellUnderVoltage",
        },
      ],
      pack: [
        {
          name: "CAN Baudrate",
          value: bmsConfigurationData.packNum,
          limit: "125-1000",
          unit: "kbps",
          id: "Baudrate",
        },
        {
          name: "Number Of Temp",
          value: bmsConfigurationData.numberOfTemp,
          limit: "0-255",
          unit: "",
          id: "NumberOfTemp",
        },
        {
          name: "Type Of Cell",
          value: TypeOfCellEnum[bmsConfigurationData.typeOfCell],
          limit: "0-20",
          unit: "",
          id: "TypeOfCell",
        },
        {
          name: "Design Capacity",
          value: bmsConfigurationData.designCapacity,
          limit: "0-655355",
          unit: "mAh",
          id: "DesignCapacity",
        },
        {
          name: "Cycle Capacity",
          value: bmsConfigurationData.cycleCapacity,
          limit: "0-655355",
          unit: "",
          id: "CycleCapacity",
        },
        {
          name: "Full Charge Voltage",
          value: bmsConfigurationData.fullChargeVol,
          limit: "0-655355",
          unit: "mV",
          id: "FullChargeVol",
        },
        {
          name: "Charge End Voltage",
          value: bmsConfigurationData.chargeEndVol,
          limit: "0-655355",
          unit: "mV",
          id: "ChargeEndVol",
        },
        {
          name: "Discharging Rate",
          value: bmsConfigurationData.dischargingRate,
          limit: "0-50",
          unit: "C",
          id: "DischargingRate",
        },
        {
          name: "Pack Over Voltage",
          value: bmsConfigurationData.packOverVoltage,
          limit: "0-655355",
          unit: "mV",
          id: "PackOverVoltage",
        },
        {
          name: "Pack Over Voltage Release",
          value: bmsConfigurationData.packOVRelease,
          limit: "0-655355",
          unit: "mV",
          id: "PackOVRelease",
        },
        {
          name: "Pack Over Voltage Delay",
          value: bmsConfigurationData.packOVDelay / 10,
          limit: "0-65535",
          unit: "s",
          id: "PackOVDelay",
        },
        {
          name: "Hard Pack Over Voltage",
          value: bmsConfigurationData.hardPackOverVoltage,
          limit: "0-655355",
          unit: "mV",
          id: "HardPackOverVoltage",
        },
        {
          name: "Pack Under Voltage",
          value: bmsConfigurationData.packUnderVoltage,
          limit: "0-655355",
          unit: "mV",
          id: "PackUnderVoltage",
        },
        {
          name: "Pack Under Voltage Release",
          value: bmsConfigurationData.packUVRelease,
          limit: "0-655355",
          unit: "mV",
          id: "PackUVRelease",
        },
        {
          name: "Pack Under Voltage Delay",
          value: bmsConfigurationData.packUVDelay / 10,
          limit: "0-65535",
          unit: "s",
          id: "PackUVDelay",
        },
        {
          name: "Hard Pack Under Voltage",
          value: bmsConfigurationData.hardPackUnderVoltage,
          limit: "0-655355",
          unit: "mV",
          id: "HardPackUnderVoltage",
        },
      ],
      temp: [
        {
          name: "Charge Over Temperature",
          value: bmsConfigurationData.chgOverTemp,
          limit: "-40-120",
          unit: "°C",
          id: "ChgOverTemp",
        },
        {
          name: "Charge Over Temperature Release",
          value: bmsConfigurationData.chgOTRelease,
          limit: "-40-120",
          unit: "°C",
          id: "ChgOTRelease",
        },
        {
          name: "Charge Over Temperature Delay",
          value: bmsConfigurationData.chgOTDelay / 10,
          limit: "0-65535",
          unit: "s",
          id: "ChgOTDelay",
        },
        {
          name: "Charge Under Temperature",
          value: bmsConfigurationData.chgUnderTemp,
          limit: "-40-120",
          unit: "°C",
          id: "ChgLowTemp",
        },
        {
          name: "Charge Under Temperature Release",
          value: bmsConfigurationData.chgUTRelease,
          limit: "-40-120",
          unit: "°C",
          id: "ChgUTRelease",
        },
        {
          name: "Charge Under Temperature Delay",
          value: bmsConfigurationData.chgUTDelay / 10,
          limit: "0-65535",
          unit: "s",
          id: "ChgUTDelay",
        },
        {
          name: "Discharge Over Temp",
          value: bmsConfigurationData.disOverTemp,
          limit: "-40-120",
          unit: "°C",
          id: "DisOverTemp",
        },
        {
          name: "Discharge Over Temperature Release",
          value: bmsConfigurationData.dsgOTRelease,
          limit: "-40-120",
          unit: "°C",
          id: "DsgOTRelease",
        },
        {
          name: "Discharge Over Temperature Delay",
          value: bmsConfigurationData.dsgOTDelay / 10,
          limit: "0-65535",
          unit: "s",
          id: "DsgOTDelay",
        },
        {
          name: "Discharge Low Temp",
          value: bmsConfigurationData.disLowTemp,
          limit: "-40-120",
          unit: "°C",
          id: "DisLowTemp",
        },
        {
          name: "Discharge Under Temperature Release",
          value: bmsConfigurationData.dsgUTRelease,
          limit: "-40-120",
          unit: "°C",
          id: "DsgUTRelease",
        },
        {
          name: "Discharge Under Temperature Delay",
          value: bmsConfigurationData.dsgUTDelay / 10,
          limit: "0-65535",
          unit: "s",
          id: "DsgUTDelay",
        },
      ],
      pdu: [
        {
          name: "Over Charge Current",
          value: bmsConfigurationData.overChargeCurrent,
          limit: "0 - 655355",
          unit: "mA",
          id: "OverChargeCurrent",
        },
        {
          name: "Charge Over Current Delay",
          value: bmsConfigurationData.chgOCDelay / 10,
          limit: "0-65535",
          unit: "s",
          id: "ChgOCDelay",
        },
        {
          name: "Charge Over Current R Delay",
          value: bmsConfigurationData.chgOCRDelay / 10,
          limit: "0-65535",
          unit: "s",
          id: "ChgOCRDelay",
        },
        {
          name: "Hard Charge Over Current",
          value: bmsConfigurationData.hardChgOverCurrent,
          limit: "0 - 655355",
          unit: "mA",
          id: "HardChgOverCurrent",
        },
        {
          name: "Over Discharge Current",
          value: bmsConfigurationData.overDisCurrent,
          limit: "0 - 655355",
          unit: "mA",
          id: "OverDisCurrent",
        },
        {
          name: "Discharge Over Current Delay",
          value: bmsConfigurationData.dsgOCDelay / 10,
          limit: "0-65535",
          unit: "s",
          id: "DsgOCDelay",
        },
        {
          name: "Discharge Over Current R Delay",
          value: bmsConfigurationData.dsgOCRDelay / 10,
          limit: "0-65535",
          unit: "s",
          id: "DsgOCRDelay",
        },
        {
          name: "Hard Discharge Over Current",
          value: bmsConfigurationData.hardDsgOverCurrent,
          limit: "0 - 655355",
          unit: "mA",
          id: "HardDsgOverCurrent",
        },
        {
          name: "Reserve Capacity S1",
          value: bmsConfigurationData.reserveCapacityS1,
          limit: "0 - 100",
          unit: "%",
          id: "ReserveCapacityS1",
        },
        {
          name: "Reserve Capacity S2",
          value: bmsConfigurationData.reserveCapacityS2,
          limit: "0 - 100",
          unit: "%",
          id: "ReserveCapacityS2",
        },
        {
          name: "S C Release Time",
          value: bmsConfigurationData.scReleaseTime,
          limit: "0 - 65535",
          unit: "s",
          id: "SCReleaseTime",
        },
        {
          name: "Hard Time",
          value: bmsConfigurationData.hardTime,
          limit: "0 - 65535",
          unit: "s",
          id: "HardTime",
        },
      ],

other:      [
        {
          name: "Number Of Cells",
          value: bmsConfigurationData.numberOfCells,
          limit: "0 - 96",
          unit: "",
          id: "numberOfCells",
        },
        {
          name: "Cell Manufacturer",
          value: bmsConfigurationData.cellManufacturer,
          limit: " - ",
          unit: "",
          id: "cellManufacturer",
        },
        {
          name: "Can Status",
          value: bmsConfigurationData.canStatus,
          limit: " - ",
          unit: "",
          id: "canStatus",
        },
        {
          name: "BLE Status",
          value: bmsConfigurationData.bleStatus,
          limit: " - ",
          unit: "",
          id: "bleStatus",
        },

        {
          name: "IOT Status",
          value: bmsConfigurationData.iotStatus,
          limit: " - ",
          unit: "",
          id: "iotStatus",
        },

        {
          name: "IMU Status",
          value: bmsConfigurationData.imuStatus,
          limit: " - ",
          unit: "",
          id: "imuStatus",
        },

        {
          name: "Bar Graph Status",
          value: bmsConfigurationData.barGraphStatus,
          limit: " - ",
          unit: "",
          id: "barGraphStatus",
        },

        {
          name: "Memory Size",
          value: bmsConfigurationData.memorySize,
          limit: "0-1024",
          unit: "kB",
          id: "memorySize",
        },

        {
          name: "Design Capacity",
          value: bmsConfigurationData.designCapacity,
          limit: " - ",
          unit: "Ah",
          id: "designCapacity",
        },

        {
          name: "Cycle Count",
          value: bmsConfigurationData.cycleCount,
          limit: " - ",
          unit: "",
          id: "cycleCount",
        },

        {
          name: "Load Over Temp",
          value: bmsConfigurationData.loadOverTemp,
          limit: "-40-120",
          unit: "°C",
          id: "loadOverTemp",
        },

        {
          name: "Load Over Temp Release",
          value: bmsConfigurationData.loadOTRelease,
          limit: "-40-120",
          unit: "°C",
          id: "loadOTRelease",
        },

        {
          name: "Load Low Temp",
          value: bmsConfigurationData.loadLowTemp,
          limit: "-40-120",
          unit: "°C",
          id: "loadLowTemp",
        },

        {
          name: "Load Under Temp Release",
          value: bmsConfigurationData.loadUTRelease,
          limit: "-40-120",
          unit: "°C",
          id: "loadUTRelease",
        },

        {
          name: "Sense Resistor",
          value: bmsConfigurationData.senseResistor,
          limit: " - ",
          unit: "",
          id: "senseResistor",
        },

        {
          name: "Battery Config",
          value: bmsConfigurationData.batteryConfig,
          limit: " - ",
          unit: "",
          id: "batteryConfig",
        },

        {
          name: "NTC Config",
          value: bmsConfigurationData.ntcConfig,
          limit: " - ",
          unit: "",
          id: "ntcConfig",
        },

        {
          name: "FET Ctrl Time Set",
          value: bmsConfigurationData.fetCtrlTimeSet,
          limit: "0-65535",
          unit: "s",
          id: "fetCtrlTimeSet",
        },

        {
          name: "LED Disp Time Set",
          value: bmsConfigurationData.ledDispTimeSet,
          limit: "0-65535",
          unit: "s",
          id: "ledDispTimeSet",
        },

        {
          name: "Voltage Cap 80",
          value: bmsConfigurationData.voltageCap80,
          limit: "0-655355",
          unit: "mV",
          id: "voltageCap80",
        },

        {
          name: "Voltage Cap 60",
          value: bmsConfigurationData.voltageCap60,
          limit: "0-655355",
          unit: "mV",
          id: "voltageCap60",
        },

        {
          name: "Voltage Cap 40",
          value: bmsConfigurationData.voltageCap40,
          limit: "0-655355",
          unit: "mV",
          id: "voltageCap40",
        },

        {
          name: "Voltage Cap 20",
          value: bmsConfigurationData.voltageCap20,
          limit: "0-655355",
          unit: "mV",
          id: "voltageCap20",
        },

        {
          name: "GPS Time",
          value: bmsConfigurationData.gpsTime,
          limit: "0 - 65535",
          unit: "s",
          id: "gpsTime",
        },

        {
          name: "GPS Voltage",
          value: bmsConfigurationData.gpsVoltage,
          limit: "0-655355",
          unit: "mV",
          id: "gpsVoltage",
        },

        {
          name: "Precharge Time",
          value: bmsConfigurationData.prechargeTime,
          limit: "0 - 65535",
          unit: "s",
          id: "prechargeTime",
        },
      ],
    };
    return (
      <div>
        <div>
          <UncontrolledTooltip target="BalanceStartVoltage">
            Balance Start after this max cell voltage
          </UncontrolledTooltip>
          <UncontrolledTooltip target="BalanceWindow">
            Balance window min max cell
          </UncontrolledTooltip>
          <UncontrolledTooltip target="CellFullChrVoltage ">
            Cell full charge Voltage
          </UncontrolledTooltip>
          <UncontrolledTooltip target="CellNominalVoltage">
            Cell Nominal Voltage
          </UncontrolledTooltip>
          <UncontrolledTooltip target="CellUnderVoltage">
            Cell Under Voltage
          </UncontrolledTooltip>
          <UncontrolledTooltip target="CellOverVoltage">
            Cell Over Voltage
          </UncontrolledTooltip>
          <UncontrolledTooltip target="CellOVRelease">
            Cell Over Voltage Release
          </UncontrolledTooltip>
          <UncontrolledTooltip target="CellOVDelay">
            Cell Over Voltage delay
          </UncontrolledTooltip>
          <UncontrolledTooltip target="HardCellOverVoltage">
            Instant Cut Over voltage
          </UncontrolledTooltip>
          <UncontrolledTooltip target="CellUnderVoltage">
            Cell Under Voltage
          </UncontrolledTooltip>
          <UncontrolledTooltip target="CellUVRelease">
            Cell Under Voltage Release
          </UncontrolledTooltip>
          <UncontrolledTooltip target="CellUVDelay">
            Cell Under Voltage delay
          </UncontrolledTooltip>
          <UncontrolledTooltip target="HardCellUnderVoltage">
            Instant Cut Under voltage
          </UncontrolledTooltip>
          <UncontrolledTooltip target="Baudrate">
            CAN Communication Baudrate
          </UncontrolledTooltip>
          <UncontrolledTooltip target="NumberOfTemp">
            Number of temp Used
          </UncontrolledTooltip>
          <UncontrolledTooltip target="TypeOfCell">
            Type of cell chemistry (Details Below)
          </UncontrolledTooltip>
          <UncontrolledTooltip target="DesignCapacity">
            Pack Capacity (mAh)
          </UncontrolledTooltip>
          <UncontrolledTooltip target="CycleCapacity">
            Max charge discharge cycle
          </UncontrolledTooltip>
          <UncontrolledTooltip target="FullChargeVol">
            Pack Full Charge Voltage
          </UncontrolledTooltip>
          <UncontrolledTooltip target="ChargeEndVol">
            Charge End Voltage
          </UncontrolledTooltip>
          <UncontrolledTooltip target="DischargingRate">
            Max Discharge Rate
          </UncontrolledTooltip>
          <UncontrolledTooltip target="PackOverVoltage">
            Pack Over voltage
          </UncontrolledTooltip>
          <UncontrolledTooltip target="PackOVRelease">
            Pack over voltage Release
          </UncontrolledTooltip>
          <UncontrolledTooltip target="PackOVDelay">
            Pack Over voltage Delay
          </UncontrolledTooltip>
          <UncontrolledTooltip target="HardPackOverVoltage">
            Instant Cut Pack Over voltage
          </UncontrolledTooltip>
          <UncontrolledTooltip target="PackUnderVoltage">
            Pack Under voltage
          </UncontrolledTooltip>
          <UncontrolledTooltip target="PackUVRelease">
            Pack Under voltage Release
          </UncontrolledTooltip>
          <UncontrolledTooltip target="PackUVDelay">
            Pack Under voltage Delay
          </UncontrolledTooltip>
          <UncontrolledTooltip target="HardPackUnderVoltage">
            Instant Cut Pack Under voltage
          </UncontrolledTooltip>
          <UncontrolledTooltip target="ChgOverTemp">
            Cell Over Temperature Limit at the time of charging
          </UncontrolledTooltip>
          <UncontrolledTooltip target="ChgOTRelease">
            Cell Over Temperature Release Limit at the time of charging
          </UncontrolledTooltip>
          <UncontrolledTooltip target="ChgOTDelay">
            Cell Over Temperature Limit delay at the time of charging
          </UncontrolledTooltip>
          <UncontrolledTooltip target="ChgLowTemp">
            Cell Under Temperature Limit at the time of charging
          </UncontrolledTooltip>
          <UncontrolledTooltip target="ChgUTRelease">
            Cell Under Temperature Release Limit at the time of charging
          </UncontrolledTooltip>
          <UncontrolledTooltip target="ChgUTDelay ">
            Cell Under Temperature Limit delay at the time of charging
          </UncontrolledTooltip>
          <UncontrolledTooltip target="DisOverTemp">
            Cell Over Temperature Limit at the time of Discharging
          </UncontrolledTooltip>
          <UncontrolledTooltip target="DsgOTRelease">
            Cell Over Temperature Release Limit at the time of Discharging
          </UncontrolledTooltip>
          <UncontrolledTooltip target="DsgOTDelay">
            Cell Over Temperature Limit delay at the time of Discharging
          </UncontrolledTooltip>
          <UncontrolledTooltip target="DisLowTemp">
            Cell Under Temperature Limit at the time of Discharging
          </UncontrolledTooltip>
          <UncontrolledTooltip target="DsgUTRelease">
            Cell Under Temperature Release Limit at the time of Discharging
          </UncontrolledTooltip>
          <UncontrolledTooltip target="DsgUTDelay">
            Cell Under Temperature Limit delay at the time of Discharging
          </UncontrolledTooltip>
          <UncontrolledTooltip target="OverChargeCurrent ">
            Over Charging Current
          </UncontrolledTooltip>
          <UncontrolledTooltip target="ChgOCDelay">
            Over Charging Current delay
          </UncontrolledTooltip>
          <UncontrolledTooltip target="ChgOCRDelay">
            Over Charging Release Time if current is below release Value
          </UncontrolledTooltip>
          <UncontrolledTooltip target="HardChgOverCurrent">
            Instant current CutOff Limit at the time of Charging
          </UncontrolledTooltip>
          <UncontrolledTooltip target="OverDisCurrent">
            Over DisCharging Current
          </UncontrolledTooltip>
          <UncontrolledTooltip target="DsgOCDelay">
            Over DisCharging Current delay
          </UncontrolledTooltip>
          <UncontrolledTooltip target="DsgOCRDelay">
            Over DisCharging Release Time if current is below release Value
          </UncontrolledTooltip>
          <UncontrolledTooltip target="HardDsgOverCurrent">
            Instant current CutOff Limit at the time of DisCharging
          </UncontrolledTooltip>
          <UncontrolledTooltip target="ReserveCapacityS1">
            Reserve 1 Enable Value
          </UncontrolledTooltip>
          <UncontrolledTooltip target="ReserveCapacityS2">
            Reserve 2 Enable Value
          </UncontrolledTooltip>
          <UncontrolledTooltip target="SCReleaseTime">
            Not Use
          </UncontrolledTooltip>
          <UncontrolledTooltip target="HardTime">Not Use</UncontrolledTooltip>


<UncontrolledTooltip target="numberOfCells">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="cellManufacturer">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="canStatus">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="bleStatus">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="iotStatus">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="imuStatus">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="barGraphStatus">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="memorySize">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="designCapacity">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="cycleCount">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="loadOverTemp">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="loadOTRelease">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="loadLowTemp">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="loadUTRelease">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="senseResistor">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="batteryConfig">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="ntcConfig">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="fetCtrlTimeSet">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="ledDispTimeSet">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="voltageCap80">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="voltageCap60">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="voltageCap40">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="voltageCap20">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="gpsTime">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="gpsVoltage">
  ----
</UncontrolledTooltip>
<UncontrolledTooltip target="prechargeTime">
  ----
</UncontrolledTooltip>
        </div>
        <Nav className="justify-content-center" role="tablist" tabs>
          <NavItem>
            <NavLink
              className={iconPills === "1" ? "active" : ""}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setIconPills("1");
              }}
            >
              <strong>Cell Settings</strong>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={iconPills === "2" ? "active" : ""}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setIconPills("2");
              }}
            >
              <strong>Pack Settings</strong>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={iconPills === "3" ? "active" : ""}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setIconPills("3");
              }}
            >
              <strong>Temperature Settings</strong>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={iconPills === "4" ? "active" : ""}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setIconPills("4");
              }}
            >
              <strong>PDU card Details</strong>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={iconPills === "5" ? "active" : ""}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setIconPills("5");
              }}
            >
              <strong>Other Configurations</strong>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="text-center" activeTab={"iconPills" + iconPills}>
          <TabPane tabId="iconPills1">
            <Row>
              {configurationDetails.cell.map((val, i) => (
                <Col md="4" lg="4" xl="4" xs="12" sm="12">
                  <Card
                    style={{
                      margin: "5px",
                      padding: "5px",
                      backgroundColor: "#e3fcee",
                    }}
                  >
                    <strong className="adt-blue-color">{val.name}</strong>

                    <div
                      style={{ margin: " 1rem" }}
                      className="adt-input adt-blue-color"
                    >
                      <strong>
                        {val.value} {val.unit}
                      </strong>{" "}
                      <d style={{ fontSize: "10px" }}>({val.limit})</d>
                      <i
                        class="fa fa-question-circle"
                        aria-hidden="true"
                        id={val.id}
                      ></i>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </TabPane>
        </TabContent>
        <TabContent className="text-center" activeTab={"iconPills" + iconPills}>
          <TabPane tabId="iconPills2">
            <Row>
              {configurationDetails.pack.map((val, i) => (
                <Col md="4" lg="4" xl="4" xs="12" sm="12">
                  <Card
                    style={{
                      margin: "5px",
                      padding: "5px",
                      backgroundColor: "#e3fcee",
                    }}
                  >
                    <strong className="adt-blue-color">{val.name}</strong>

                    <div
                      style={{ margin: " 1rem" }}
                      className="adt-input adt-blue-color"
                    >
                      <strong>
                        {val.value} {val.unit}
                      </strong>{" "}
                      <d style={{ fontSize: "10px" }}>({val.limit})</d>
                      <i
                        class="fa fa-question-circle"
                        aria-hidden="true"
                        id={val.id}
                      ></i>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </TabPane>
        </TabContent>
        <TabContent className="text-center" activeTab={"iconPills" + iconPills}>
          <TabPane tabId="iconPills3">
            <Row>
              {configurationDetails.temp.map((val, i) => (
                <Col md="4" lg="4" xl="4" xs="12" sm="12">
                  <Card
                    style={{
                      margin: "5px",
                      padding: "5px",
                      backgroundColor: "#e3fcee",
                    }}
                  >
                    <strong className="adt-blue-color">{val.name}</strong>

                    <div
                      style={{ margin: " 1rem" }}
                      className="adt-input adt-blue-color"
                    >
                      <strong>
                        {val.value} {val.unit}
                      </strong>{" "}
                      <d style={{ fontSize: "10px" }}>({val.limit})</d>
                      <i
                        class="fa fa-question-circle"
                        aria-hidden="true"
                        id={val.id}
                      ></i>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </TabPane>
        </TabContent>
        <TabContent className="text-center" activeTab={"iconPills" + iconPills}>
          <TabPane tabId="iconPills4">
            <Row>
              {configurationDetails.pdu.map((val, i) => (
                <Col md="4" lg="4" xl="4" xs="12" sm="12">
                  <Card
                    style={{
                      margin: "5px",
                      padding: "5px",
                      backgroundColor: "#e3fcee",
                    }}
                  >
                    <strong className="adt-blue-color">{val.name}</strong>

                    <div
                      style={{ margin: " 1rem" }}
                      className="adt-input adt-blue-color"
                    >
                      <strong>
                        {val.value} {val.unit}
                      </strong>{" "}
                      <d style={{ fontSize: "10px" }}>({val.limit})</d>{" "}
                      <i
                        class="fa fa-question-circle"
                        aria-hidden="true"
                        id={val.id}
                      ></i>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </TabPane>
        </TabContent>
        <TabContent className="text-center" activeTab={"iconPills" + iconPills}>
          <TabPane tabId="iconPills5">
            <Row>
              {configurationDetails.other.map((val, i) => (
                <Col md="4" lg="4" xl="4" xs="12" sm="12">
                  <Card
                    style={{
                      margin: "5px",
                      padding: "5px",
                      backgroundColor: "#e3fcee",
                    }}
                  >
                    <strong className="adt-blue-color">{val.name}</strong>

                    <div
                      style={{ margin: " 1rem" }}
                      className="adt-input adt-blue-color"
                    >
                      <strong>
                        {val.value} {val.unit}
                      </strong>{" "}
                      <d style={{ fontSize: "10px" }}>({val.limit})</d>{" "}
                      <i
                        class="fa fa-question-circle"
                        aria-hidden="true"
                        id={val.id}
                      ></i>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  } else return <h4>No Data</h4>;
}

export default NewConfiguration;
