import { Badge, Button, Card, Col, Spinner, Row } from "reactstrap";
import React, { Component } from "react";

import { API_URL } from "variables/general";
import ReactSelect from "react-select";

class ManageSubUser extends Component {
  constructor() {
    super();
    this.state = {
      password: null,
      age: null,
      email: null,
      mobileNumber: null,
      fullName: null,
      id: null,
      tried: null,
      edit: null,
      company: null,
      checkMessage: "",
      disabled: true,
      getData: "",
    };
  }
  componentDidMount() {
    this.getSubUser();
  }

  editSubUser = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify({
        password: this.state.password,
        userProfile: {
          email: this.state.email,
          mobile: this.state.mobileNumber,
          name: this.state.fullName,
          company: this.state.company,
        },
        id: this.state.id,
        edit:this.state.edit
      }),
    };

    fetch(API_URL + "/bms/sub_user_creation", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let statusResult = JSON.parse(result);
        if (statusResult.status === true) alert(statusResult.message);
        else alert("Something went wrong");
        this.setState({
          userData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  getName = (event) => {
    this.setState({
      fullName: event,
    });
  };
  getId = (event) => {
    this.setState({
      id: event,
    });
    this.getSubUserData(event);
  };
  getAge = (event) => {
    this.setState({
      age: event,
    });
  };
  getEmail = (event) => {
    this.setState({
      email: event,
    });
  };
  getMobileNumber = (event) => {
    this.setState({
      mobile: event,
    });
  };
  getPassword = (event) => {
    this.setState({
      password: event,
    });
  };

  getCompany = (event) => {
    this.setState({
      company: event,
    });
  };
  getEdit = (event) => {
    this.setState({edit:event.target.value});
  };

  getSubUser = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(API_URL + "/bms/manage_bms_subuser", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          getData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };
  deleteSubUser = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: JSON.stringify({ id: this.state.id }),
    };

    fetch(API_URL + "/bms/sub_user_creation", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let statusResult = JSON.parse(result);
        if (statusResult.status === true) {
          alert(statusResult.message);
          window.location.reload();
        } else alert("Something went wrong");
      })
      .catch((error) => console.log("error", error));
  };
  getSubUserData = (event) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        id: event,
      }),
    };

    fetch(API_URL + "/bms/manage_bms_subuser", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let getDataItem = JSON.parse(result).data;
        this.setState({
          email:getDataItem.subUser.userProfile.email,
          password: getDataItem.subUser.password,
          edit: getDataItem.subUser.edit,
          mobile: getDataItem.subUser.userProfile.mobile,
          fullName: getDataItem.subUser.userProfile.name,
          company: getDataItem.subUser.userProfile.company,
        });
      })
      .catch((error) => console.log("error", error));
  };
  showBmsList = (second) => {
    this.getId(second.value);
    this.setState({ disabled: false })
  }
  render() {
    const inputStyle = {
      padding: "10px",
    };
    if (this.state.getData) {
      let getData = JSON.parse(this.state.getData).data.users;
      let subUserListOptions = []
      getData.map((val, i) => (
        subUserListOptions[i] = {
          value: val.id,
          label: val.userProfile.name
        }
      ))

      return (
        <div>
          <Card
            style={{
              padding: "5px 25px",
              margin: "5px 25px",
              border: "solid",
              borderColor: "#32C85A",
            }}
          >
            <h5 style={{ textAlign: "center" }}>Manage Sub Users</h5>
            <Row>
              <Col md="4" lg="4" xl='4' sm="12" xs="12">
                <div>
                  <strong className="adt-blue-color">Select Sub Operator</strong>
                </div>
                <ReactSelect
                  onChange={this.showBmsList}
                  options={subUserListOptions}
                ></ReactSelect>
              </Col>
            </Row>

            <Row>
              <Col md="4" lg="4" xl='4' sm="12" xs="12" style={inputStyle}>
                <div>
                  <strong className="adt-blue-color">Full Name</strong>
                </div>
                <input className="adt-input"
                  type="text"
                  placeholder="Full Name"
                  value={this.state.fullName}
                  onChange={(event) => this.getName(event.target.value)}
                  disabled={this.state.disabled ? "disabled" : ""}
                />
              </Col>
              <Col md="4" lg="4" xl='4' sm="12" xs="12" style={inputStyle}>
                <div>
                  <strong className="adt-blue-color">Company</strong>
                </div>
                <input className="adt-input"
                  type="text"
                  placeholder="Company"
                  value={this.state.company}
                  onChange={(event) => this.getCompany(event.target.value)}
                  disabled={this.state.disabled ? "disabled" : ""}
                />
              </Col>
              <Col md="4" lg="4" xl='4' sm="12" xs="12" style={inputStyle}>
                <div>
                  <strong className="adt-blue-color">Edit</strong>
                </div>
                <select onChange={this.getEdit} >
                  <option selected disabled>Select to change</option>
                  <option value='edit'>Edit</option>
                  <option value='no'>Non-Edit</option>
                </select>
              </Col>
              <Col md="4" lg="4" xl='4' sm="12" xs="12" style={inputStyle}>
                <div>
                  <strong className="adt-blue-color">Mobile Number</strong>
                </div>
                <input className="adt-input"
                  type="number"
                  placeholder="Mobile Number"
                  value={this.state.mobile}
                  onChange={(event) => this.getMobileNumber(event.target.value)}
                  disabled={this.state.disabled ? "disabled" : ""}
                />
              </Col>
              <Col md="4" lg="4" xl='4' sm="12" xs="12" style={inputStyle}>
                <div>
                  <strong className="adt-blue-color">Password</strong>
                </div>
                <input className="adt-input"
                  type="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={(event) => this.getPassword(event.target.value)}
                  disabled={this.state.disabled ? "disabled" : ""}
                />
              </Col>
              <Col md="4" lg="4" xl='4' sm="12" xs="12" style={inputStyle}>
                <Button
                  onClick={this.editSubUser}
                  disabled={this.state.disabled ? "disabled" : ""}
                  style={{
                    color: "#f4f3ef",
                    backgroundColor: "#009846",
                    width: "10rem"
                  }}
                >
                  Update User
                </Button>
              </Col>
              <Col md="4" lg="4" xl='4' sm="12" xs="12" style={inputStyle}>
                <Button
                  onClick={this.state.id ? this.deleteSubUser : <></>}
                  disabled={this.state.disabled ? "disabled" : ""}
                  style={{
                    color: "#f4f3ef",
                    backgroundColor: "crimson",
                    width: "10rem"
                  }}
                >
                  Delete User
                </Button>
              </Col>
            </Row>
          </Card>
        </div>
      );
    } else return (<div className="main-margin" style={{ textAlign: "center" }}>
      <Spinner color="red">
        Loading...
      </Spinner>
    </div>)
  }
}
export default ManageSubUser;
