import React, { useState } from "react";
import { Row, Col, Card } from "reactstrap";
import { Redirect } from "react-router-dom";
import logoImage from "assets/img/logo-white-unbg.png";

function ModeSelectionPage(props) {
  const [navigateTo, setNavigateTo] = useState("");
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  const [isHoverCAN, setIsHoverCan] = useState(false);

  const handleMouseEnterCAN = () => {
    setIsHoverCan(true);
  };
  const handleMouseLeaveCAN = () => {
    setIsHoverCan(false);
  };
  const [isHoverInventory, setIsHoverInventory] = useState(false);
  const [isHoverBLE, setIsHoverBLE] = useState(false);
  const [isHoverEVTracker, setIsHoverEVTracker] = useState(false);

  const handleMouseEnterInventory = () => {
    setIsHoverInventory(true);
  };
  const handleMouseLeaveInventory = () => {
    setIsHoverInventory(false);
  };
  const handleMouseEnterBLE = () => {
    setIsHoverBLE(true);
  };
  const handleMouseLeaveBLE = () => {
    setIsHoverBLE(false);
  };
  const handleMouseEnterEVTracker = () => {
    setIsHoverEVTracker(true);
  };
  const handleMouseLeaveEVTracker = () => {
    setIsHoverEVTracker(false);
  };
  const logOut = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };
  const hoveredStyle = {
    margin: "auto",
    backgroundColor: "#98c5f2",
    padding: "3rem",
    cursor: "pointer",
    fontSize: "24px",
    color: "white",
  };
  const defaultStyle = {
    margin: "1rem",
    backgroundColor: "#6bd098",
    padding: "2rem",
    cursor: "pointer",
    fontSize: "16px",
  };
  if (navigateTo === "IoT") {
    return (
      <Redirect
        to={{
          pathname: "/admin/sub-users",
        }}
      />
    );
  } else if (navigateTo === "CAN") {
    return (
      <Redirect
        to={{
          pathname: "/can-home",
        }}
      />
    );
  } else if (navigateTo === "Inventory") {
    return (
      <Redirect
        to={{
          pathname: "/inventory",
          state: { adminId: props.adminId },
        }}
      />
    );
  } else if (navigateTo === "BLE") {
    return (
      <Redirect
        to={{
          pathname: "/ble",
          state: { adminId: props.adminId },
        }}
      />
    );
  } else if (navigateTo === "EVIoT") {
    return (
      <Redirect
        to={{
          pathname: "/eviot",
          state: { adminId: props.adminId },
        }}
      />
    );
  // } else if (navigateTo === "EVTracker") {
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: "/evtracker",
  //         state: { adminId: props.adminId },
  //       }}
  //     />
  //   );
  } else
    return (
      <div style={{ textAlign: "center" }}>
        <div style={{ backgroundColor: "#6bd098" }}>
        {window.localStorage.getItem("jio_zypp") === "true"?"": <img src={logoImage} alt="" style={{ height: "6rem" }}></img>}
          <i
            className="nc-icon nc-button-power"
            style={{
              float: "right",
              fontWeight: "900",
              textAlign: "center",
              margin: "2rem",
              fontSize: "24px",
              cursor: "pointer",
            }}
            onClick={logOut}
          ></i>
        </div>
        <div
          style={{
            padding: "6rem 2rem",
          }}
        >
          <h3>Select Mode</h3>
          <Row>
           {window.localStorage.getItem("jio_zypp") === "true"? <Col md="3" lg="3" xl="3" xs="12" sm="12">
              <Card
              style={defaultStyle}
                onClick={() => setNavigateTo("EVIoT")}
              >
                <strong>JIO EV IoT</strong>
              </Card>
            </Col>:<><Col md="3" lg="3" xl="3" xs="12" sm="12">
              <Card
                onClick={() => setNavigateTo("IoT")}
                style={isHover ? hoveredStyle : defaultStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <strong>IoT Communication</strong>
              </Card>
            </Col>
            <Col md="3" lg="3" xl="3" xs="12" sm="12">
              <Card
                onClick={() => setNavigateTo("CAN")}
                style={isHoverCAN ? hoveredStyle : defaultStyle}
                onMouseEnter={handleMouseEnterCAN}
                onMouseLeave={handleMouseLeaveCAN}
              >
                <strong>CAN Communication</strong>
              </Card>
            </Col>
            {window.localStorage.getItem("role") === "admin"?<Col md="3" lg="3" xl="3" xs="12" sm="12">
              <Card
                onClick={() => setNavigateTo("Inventory")}
                style={isHoverInventory ? hoveredStyle : defaultStyle}
                onMouseEnter={handleMouseEnterInventory}
                onMouseLeave={handleMouseLeaveInventory}
              >
                <strong>Inventory Management</strong>
              </Card>
            </Col>:''}
            <Col md="3" lg="3" xl="3" xs="12" sm="12">
              <Card
                onClick={() => setNavigateTo("BLE")}
                style={isHoverBLE ? hoveredStyle : defaultStyle}
                onMouseEnter={handleMouseEnterBLE}
                onMouseLeave={handleMouseLeaveBLE}
              >
                <strong>View BLE App Data</strong>
              </Card>
            </Col>
            {/* <Col md="3" lg="3" xl="3" xs="12" sm="12">
              <Card
              // style={defaultStyle}
                onClick={() => setNavigateTo("EVTracker")}
                style={isHoverEVTracker ? hoveredStyle : defaultStyle}
                onMouseEnter={handleMouseEnterEVTracker}
                onMouseLeave={handleMouseLeaveEVTracker}
              >
                <strong>EV Tracker</strong>
              </Card>
            </Col> */}
            </>}
          </Row>
        </div>
      </div>
    );
}

export default ModeSelectionPage;
