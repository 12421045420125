import Temperature from "./Temperature";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Spinner,
} from "reactstrap";
import BatteryCells from "./BatteryCells";
import Faults from "./Faults";
import BatteryCellFor24SPlus from "./BatteryCellFor24SPlus";
import TemperatureFor24SPlus from "./TemperatureFor24SPlus";
function Home(props) {
  let batteryVoltage = props.batteryVoltage;
  let current = props.current;
  let SOC = props.SOC;
  let DisFET = props.DisFET;
  let ChgFET = props.ChgFET;
  let AvailableCapacity = props.AvailableCapacity;
  let CyclesCount = props.CyclesCount;
  let SOH = props.SOH;
  let SOP = props.SOP;
  let MaxCellVolt = props.MaxCellVolt;
  let MinCellVolt = props.MinCellVolt;
  let NumberOfCell = props.NumberOfCell;
  let Cell1 = props.Cell1;
  let Cell2 = props.Cell2;
  let Cell3 = props.Cell3;
  let cell4 = props.cell4;
  let Cell5 = props.Cell5;
  let Cell6 = props.Cell6;
  let Cell7 = props.Cell7;
  let cell8 = props.cell8;
  let Cell9 = props.Cell9;
  let Cell10 = props.Cell10;
  let Cell11 = props.Cell11;
  let cell12 = props.cell12;
  let Cell13 = props.Cell13;
  let Cell14 = props.Cell14;
  let Cell15 = props.Cell15;
  let cell16 = props.cell16;
  let Cell17 = props.Cell17;
  let Cell18 = props.Cell18;
  let Cell19 = props.Cell19;
  let cell20 = props.cell20;
  let Cell21 = props.Cell21;
  let Cell22 = props.Cell22;
  let Cell23 = props.Cell23;
  let Cell24 = props.Cell24;
  let bmsPowerMode = props.bmsPowerMode;
  let packCurrentState = props.packCurrentState;
  let EnergyOut = props.EnergyOut;
  let EnergyIn = props.EnergyIn;
  let soCBG;
  let soHBG;
  let soPBG;
  if (SOC >= 50) soCBG = "#32c85a";
  else if (SOC < 50 && SOC >= 20) soCBG = "#c7af1e";
  else if (SOC < 20) soCBG = "#c84432";
  else soCBG = "#32c8be";
  if (SOH >= 50) soHBG = "#32c85a";
  else if (SOH < 50 && SOH >= 20) soHBG = "#c7af1e";
  else if (SOH < 20) soHBG = "#c84432";
  else soHBG = "#32c8be";
  if (SOP >= 50) soPBG = "#32c85a";
  else if (SOP < 50 && SOP >= 20) soPBG = "#c7af1e";
  else if (SOP < 20) soPBG = "#c84432";
  else soPBG = "#32c8be";
  const soCChart = {
    data: (canvas) => {
      return {
        labels: [1, 2, 3],
        datasets: [
          {
            label: "Emails",
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: ["#e3e3e3", soCBG],
            borderWidth: 0,

            data: [100 - SOC, SOC],
          },
        ],
      };
    },
    options: {
      legend: {
        display: false,
      },

      pieceLabel: {
        render: "percentage",
        fontColor: ["white"],
        precision: 2,
      },

      tooltips: {
        enabled: false,
      },

      scales: {
        yAxes: [
          {
            ticks: {
              display: false,
            },
            gridLines: {
              drawBorder: false,
              zeroLineColor: "transparent",
              color: "rgba(255,255,255,0.05)",
            },
          },
        ],

        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(255,255,255,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              display: false,
            },
          },
        ],
      },
    },
  };

  const soHChart = {
    data: (canvas) => {
      return {
        labels: [1, 2, 3],
        datasets: [
          {
            label: "Emails",
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: ["#e3e3e3", soHBG],
            borderWidth: 0,

            data: [100 - SOH, SOH],
          },
        ],
      };
    },
    options: {
      legend: {
        display: false,
      },

      pieceLabel: {
        render: "percentage",
        fontColor: ["white"],
        precision: 2,
      },

      tooltips: {
        enabled: false,
      },

      scales: {
        yAxes: [
          {
            ticks: {
              display: false,
            },
            gridLines: {
              drawBorder: false,
              zeroLineColor: "transparent",
              color: "rgba(255,255,255,0.05)",
            },
          },
        ],

        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(255,255,255,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              display: false,
            },
          },
        ],
      },
    },
  };
  const soPChart = {
    data: (canvas) => {
      return {
        labels: [1, 2, 3],
        datasets: [
          {
            label: "Emails",
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: ["#e3e3e3", soPBG],
            borderWidth: 0,

            data: [100 - SOP, SOP],
          },
        ],
      };
    },
    options: {
      legend: {
        display: false,
      },

      pieceLabel: {
        render: "percentage",
        fontColor: ["white"],
        precision: 2,
      },

      tooltips: {
        enabled: false,
      },

      scales: {
        yAxes: [
          {
            ticks: {
              display: false,
            },
            gridLines: {
              drawBorder: false,
              zeroLineColor: "transparent",
              color: "rgba(255,255,255,0.05)",
            },
          },
        ],

        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(255,255,255,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              display: false,
            },
          },
        ],
      },
    },
  };
  const bmsPowerModeArray = [
    "Active Mode",
    "Sleep Mode",
    "Deep Sleep Mode",
    "Sleep Wake Up Sleep",
  ];
  const packCurrentStateArray = [
    "None",
    "Charging",
    "Discharging",
    "Normal",
    "Ideal",
    "Current Sleep",
  ];
  return (
    <div>
      <Row>
        <Col xs="12" sm="12" md="3">
          <Card className="hero-cards">
            <Row>
              <Col>
                <div className="card-head">Pack Voltage </div>
                {batteryVoltage ? (
                  <div className="card-foot">
                    {Math.round((batteryVoltage / 100) * 100) / 100}V
                  </div>
                ) : batteryVoltage === 0 ?
                  <div className="card-foot"> 0 V</div>
                  : (
                    <div>
                      <Spinner />
                    </div>
                  )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs="12" sm="12" md="3">
          <Card className="hero-cards">
            <Row>
              <Col>
                <div className="card-head">Instantaneous Current</div>
                {current ? (
                  <div className="card-foot">
                    {Math.round((current / 100) * 100) / 100} A
                  </div>
                ) : current === 0 ? (
                  <div className="card-foot"> 0 A</div>
                ) : (
                  <div>
                    <Spinner />
                  </div>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs="12" sm="12" md="3">
          <Card className="hero-cards">
            <Row>
              <Col>
                <div className="card-head">Instantaneous Power</div>
                {current && batteryVoltage ? (
                  <div className="card-foot">
                    {Math.round(((current * batteryVoltage) / 10000000) * 100) /
                      100}
                    kW
                  </div>
                ) : current === 0 || batteryVoltage === 0 ? (
                  <div className="card-foot">0 kW</div>
                ) : (
                  <div>
                    <Spinner />
                  </div>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs="12" sm="12" md="3">
          <Card className="hero-cards">
            <Row>
              <Col>
                <Faults Fault={props.Fault} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs="6" sm="6" md="3">
          <Card className="hero-cards">
            <Row>
              <Col>
                <div className="card-head">Charging</div>
                {ChgFET === 1 ? (
                  <div style={{ padding: "5px" }} className="card-foot">
                    On
                  </div>
                ) : (

                  <div style={{ padding: "5px" }} className="card-foot">
                    Off
                  </div>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs="6" sm="6" md="3">
          <Card className="hero-cards">
            <Row>
              <Col>
                <div className="card-head">Discharging</div>
                {DisFET === 1 ? (
                  <div style={{ padding: "5px" }} className="card-foot">
                    On
                  </div>
                ) : (

                  <div style={{ padding: "5px" }} className="card-foot">
                    Off
                  </div>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs="6" sm="6" md="3">
          <Card className="hero-cards">
            <Row>
              <Col>
                <div className="card-head">Pack Power Mode</div>
                {bmsPowerMode ? (
                  <div
                    style={{ padding: "4px", textTransform: "uppercase" }}
                    className="card-foot"
                  >
                    {bmsPowerModeArray[bmsPowerMode]}
                  </div>
                ) : bmsPowerMode === 0 ? (
                  <div
                    style={{ padding: "4px", textTransform: "uppercase" }}
                    className="card-foot"
                  >
                    {bmsPowerModeArray[0]}
                  </div>
                ) : (
                  <div>
                    <Spinner />
                  </div>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs="6" sm="6" md="3">
          <Card className="hero-cards">
            <Row>
              <Col>
                <div className="card-head">Pack Current State</div>
                {packCurrentState ? (
                  <div
                    style={{ padding: "4px", textTransform: "uppercase" }}
                    className="card-foot"
                  >
                    {packCurrentStateArray[packCurrentState]}
                  </div>
                ) : packCurrentState === 0 ? (
                  <div
                    style={{ padding: "4px", textTransform: "uppercase" }}
                    className="card-foot"
                  >
                    {packCurrentStateArray[0]}
                  </div>
                ) : (
                  <div>
                    <Spinner />
                  </div>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="12" md="7">
          {props.showUiFor24SPlus ? <BatteryCellFor24SPlus
              cellVoltageFor24SPlus={props.cellVoltageFor24SPlus}
              BMS_NoOfCellAvailable={props.BMS_NoOfCellAvailable}
              MaxCellVolt={props.MaxCellVolt}
              MinCellVolt={props.MinCellVolt}
            />:<BatteryCells
            Cell1={Cell1}
            Cell2={Cell2}
            Cell3={Cell3}
            cell4={cell4}
            Cell5={Cell5}
            Cell6={Cell6}
            Cell7={Cell7}
            cell8={cell8}
            Cell9={Cell9}
            Cell10={Cell10}
            Cell11={Cell11}
            cell12={cell12}
            Cell13={Cell13}
            Cell14={Cell14}
            Cell15={Cell15}
            cell16={cell16}
            Cell17={Cell17}
            Cell18={Cell18}
            Cell19={Cell19}
            cell20={cell20}
            Cell21={Cell21}
            Cell22={Cell22}
            Cell23={Cell23}
            Cell24={Cell24}
            MaxCellVolt={MaxCellVolt}
            MinCellVolt={MinCellVolt}
            NumberOfCell={NumberOfCell}
          />
            }
        </Col>
        <Col xs="12" sm="12" md="5">
          <Row>
            <Col>
              {props.showUiFor24SPlus ?<TemperatureFor24SPlus
                  MaxCellTemp={props.MaxCellTemp}
                  MinCellTemp={props.MinCellTemp}
                  cellTempFor24SPlus={props.cellTempFor24SPlus}
                  extrTempFor24SPlus={props.extrTempFor24SPlus}
                  BMS_NoOfExterTempAvailable={props.BMS_NoOfExterTempAvailable}
                  BMS_NoOfTempAvailable={props.BMS_NoOfTempAvailable}
                />: <Temperature
                Temp1={props.Temp1}
                Temp2={props.Temp2}
                Temp3={props.Temp3}
                Temp4={props.Temp4}
                Temp5={props.Temp5}
                Temp6={props.Temp6}
                Temp7={props.Temp7}
                Temp8={props.Temp8}
                IC1Temp={props.IC1Temp}
                IC2Temp={props.IC2Temp}
                PDUTemp={props.PDUTemp}
                PCBTemp2={props.PCBTemp2}
                HeatSink1Temp={props.HeatSink1Temp}
                HeatSink2Temp={props.HeatSink2Temp}
                NumOfTemp={props.NumOfTemp}
                MaxCellTemp={props.MaxCellTemp}
                MinCellTemp={props.MinCellTemp}
                CAN={true}
              />
                }
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Card>
            <CardHeader>
              <CardTitle tag="h6">State of Charge</CardTitle>
            </CardHeader>
            {SOC ? (
              <CardBody>
                <div className="card-foot">{SOC} %</div>
                <Doughnut data={soCChart.data} options={soCChart.options} />
              </CardBody>
            ) : (
              <div>
                <Spinner />
              </div>
            )}
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <CardHeader>
              <CardTitle tag="h6">State of Health</CardTitle>
            </CardHeader>
            {SOH ? (
              <CardBody>
                <div className="card-foot">{SOH} %</div>
                <Doughnut data={soHChart.data} options={soHChart.options} />
              </CardBody>
            ) : (
              <div>
                <Spinner />
              </div>
            )}
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <CardHeader>
              <CardTitle tag="h6">State of Power</CardTitle>
            </CardHeader>
            {SOP ? (
              <CardBody>
                <div className="card-foot">{SOP} %</div>
                <Doughnut data={soPChart.data} options={soPChart.options} />
              </CardBody>
            ) : (
              <div>
                <Spinner />
              </div>
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs="6" sm="6" md="3">
          <Card className="hero-cards">
            <Row>
              <Col>
                <div className="card-head">Cycle count</div>
                {CyclesCount ? (
                  <div className="card-foot">{CyclesCount}</div>
                ) : CyclesCount === 0 ? (
                  <div className="card-foot">0</div>
                ) : (
                  <div>
                    <Spinner />
                  </div>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs="6" sm="6" md="3">
          <Card className="hero-cards">
            <Row>
              <Col>
                <div className="card-head">Energy In</div>
                {EnergyIn ? (
                  <div className="card-foot">{EnergyIn} W</div>
                ) : EnergyIn === 0 ? (
                  <div className="card-foot">0 W</div>
                ) : (
                  <div>
                    <Spinner />
                  </div>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs="6" sm="6" md="3">
          <Card className="hero-cards">
            <Row>
              <Col>
                <div className="card-head">Energy Out</div>
                {EnergyOut ? (
                  <div className="card-foot">{EnergyOut} W</div>
                ) : EnergyOut === 0 ? (
                  <div className="card-foot">0 W</div>
                ) : (
                  <div>
                    <Spinner />
                  </div>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs="6" sm="6" md="3">
          <Card className="hero-cards">
            <Row>
              <Col>
                <div className="card-head">Remaining Capacity</div>
                {AvailableCapacity ? (
                  <div className="card-foot">
                    {AvailableCapacity * 10}
                    mAh
                  </div>
                ) : AvailableCapacity === 0 ? (
                  <div className="card-foot">0 mAh</div>
                ) : (
                  <div>
                    <Spinner />
                  </div>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Home;
