import React from 'react'
import Parser from 'papaparse'
import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import Select from 'react-select'
import { Col, Row } from 'reactstrap'
import Card from 'reactstrap/lib/Card';
function ReadCSVFile() {
    const [dataLog, setDataLog] = useState('')
    const [tempData, setTempData] = useState('')
    const [cellData, setCellData] = useState('')
    const [fuelData, setFuelData] = useState('')
    const [faultData, setFaultData] = useState('')
    const [showMessage, setShowMessage] = useState('')
    const [x, setX] = useState(15)
    const [y, setY] = useState(0)
    const [filterData, setFilterData] = useState('')
    const changeHandler = (event) => {
        if (event.target.files[0].name.slice(0, 7) === 'DATALOG') {
            Parser.parse(event.target.files[0], {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    setDataLog(results.data)
                    console.log('hi', results.data)
                },
            });
        }
        else if (event.target.files[0].name.slice(0, 7) === 'FAULTDA') {
            Parser.parse(event.target.files[0], {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    setFaultData(results.data)
                },
            });
        }
        else if (event.target.files[0].name.slice(0, 7) === 'FUELDAT') {
            Parser.parse(event.target.files[0], {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    setFuelData(results.data)
                },
            });
        }
        else if (event.target.files[0].name.slice(0, 7) === 'CELLDAT') {
            console.log(event.target.files[0])
            Parser.parse(event.target.files[0], {
                header: false,
                skipEmptyLines: true,
                complete: function (results) {
                    console.log('parsed', results.data)
                    setCellData(results.data)
                },
            });
        }
        else if (event.target.files[0].name.slice(0, 7) === 'TEMPDAT') {
            Parser.parse(event.target.files[0], {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    setTempData(results.data)
                    console.log('temp', results.data)
                },
            });
        }
        else setShowMessage('Invalid file name or format')
    };
    // Temperature Data
    let tmsTemp = []
    if (tempData)
        console.log(tempData)
    else if (cellData)
        console.log('cellData', cellData)
    // for (let i = 0; i < tempData.length; i++) {
    //     tmsTemp[i] = tempData[i];

    // }


    let datasetArr = []
    let yAxesArr = []
    let timeLabel = []
    let avgCellVoltage = []
    let avgCellTemp = []
    let current = []
    let fault = []
    let pduTemp = []
    let packVoltage = []
    let powerMode = []
    let soc = []
    let graphData
    let graphOptions
    if (dataLog) {
        for (let i = dataLog.length - x, j = 0; (i < dataLog.length - y && j < 15); i++, j++) {
            timeLabel[j] = dataLog[dataLog.length - i - 1]['Date Time']
            avgCellVoltage[j] = dataLog[dataLog.length - i - 1]['Average Cell Voltage']
            avgCellTemp[j] = dataLog[dataLog.length - i - 1]['Avg Cell Temp']
            current[j] = dataLog[dataLog.length - i - 1]['Current']
            fault[j] = dataLog[dataLog.length - i - 1]['Fault']
            pduTemp[j] = dataLog[dataLog.length - i - 1]['PDU Temp']
            packVoltage[j] = dataLog[dataLog.length - i - 1]['Pack Voltage']
            powerMode[j] = dataLog[dataLog.length - i - 1]['Power Mode']
            soc[j] = dataLog[dataLog.length - i - 1]['SOC %']
        }

        graphData = {
            data: {
                labels: timeLabel,
                datasets: datasetArr,
            },
            options: {
                legend: {
                    display: false,
                    position: "top",
                },
            },
        };
        graphOptions = {
            // legend: {
            //   labels: {
            //     fontColor: "blue",
            //     fontSize: 18
            //   }
            // },
            scales: {
                yAxes: yAxesArr,
            },
        };
    }
    const checkBoxData = (event) => {
        setFilterData(event)
    }
    const left = (second) => {
        if (x < dataLog.length) {
            setX(x + 15)
            setY(y + 15)
        }
    }
    const right = (second) => {
        if (y >= 15) {
            setX(x - 15)
            setY(y - 15)
        }
    }
    if (filterData)
        for (let i = 0; i < filterData.length; i++) {
            if (filterData[i].value === 'avgCellVoltage') {
                datasetArr[i] = {
                    label: "Average Cell Voltage",
                    data: avgCellVoltage,
                    fill: false,
                    borderColor: "red",
                    backgroundColor: "transparent",
                    pointBorderColor: "red",
                    pointRadius: 1,
                    pointHoverRadius: 10,
                    pointBorderWidth: 0,
                    yAxisID: "y-axis-1",
                }
                yAxesArr[i] = {
                    type: "linear",
                    display: true,
                    position: "left",
                    id: "y-axis-1",
                    ticks: {
                        fontColor: "red",
                        fontSize: 10,
                    }
                }
            }
            else if (filterData[i].value === 'avgCellTemp') {
                datasetArr[i] = {
                    label: "Avg Cell Temp",
                    data: avgCellTemp,
                    fill: false,
                    borderColor: "green",
                    backgroundColor: "transparent",
                    pointBorderColor: "green",
                    pointRadius: 1,
                    pointHoverRadius: 10,
                    pointBorderWidth: 0,
                    yAxisID: "y-axis-2",

                }
                yAxesArr[i] = {
                    type: "linear",
                    display: true,
                    position: "left",
                    id: "y-axis-2",
                    ticks: {
                        fontColor: "green",
                        fontSize: 10,
                    }
                }
            }
            else if (filterData[i].value === 'current') {
                datasetArr[i] = {
                    label: "Current",
                    data: current,
                    fill: false,
                    borderColor: "blue",
                    backgroundColor: "transparent",
                    pointBorderColor: "blue",
                    pointRadius: 1,
                    pointHoverRadius: 10,
                    pointBorderWidth: 0,
                    yAxisID: "y-axis-3",

                }
                yAxesArr[i] = {
                    type: "linear",
                    display: true,
                    position: "left",
                    id: "y-axis-3",
                    ticks: {
                        fontColor: "blue",
                        fontSize: 10,
                    }
                }
            }
            else if (filterData[i].value === 'pduTemp') {
                datasetArr[i] = {
                    label: "PDU Temp",
                    data: pduTemp,
                    fill: false,
                    borderColor: "#6D351A",
                    backgroundColor: "transparent",
                    pointBorderColor: "#6D351A",
                    pointRadius: 1,
                    pointHoverRadius: 10,
                    pointBorderWidth: 0,
                    yAxisID: "y-axis-4",
                }
                yAxesArr[i] = {
                    type: "linear",
                    display: true,
                    position: "left",
                    id: "y-axis-4",
                    ticks: {
                        fontColor: "#6D351A",
                        fontSize: 10,
                    }
                }
            }
            else if (filterData[i].value === 'packVoltage') {
                datasetArr[i] = {
                    label: "Pack Voltage",
                    data: packVoltage,
                    fill: false,
                    borderColor: "magenta",
                    backgroundColor: "transparent",
                    pointBorderColor: "magenta",
                    pointRadius: 1,
                    pointHoverRadius: 10,
                    pointBorderWidth: 0,
                    yAxisID: "y-axis-5",
                }
                yAxesArr[i] = {
                    type: "linear",
                    display: true,
                    position: "left",
                    id: "y-axis-5",
                    ticks: {
                        fontColor: "magenta",
                        fontSize: 10,
                    }
                }
            }
            else if (filterData[i].value === 'soc') {
                datasetArr[i] = {
                    label: "SOC %",
                    data: soc,
                    fill: false,
                    borderColor: "aquamarine",
                    backgroundColor: "transparent",
                    pointBorderColor: "aquamarine",
                    pointRadius: 1,
                    pointHoverRadius: 10,
                    pointBorderWidth: 0,
                    yAxisID: "y-axis-6",
                }
                yAxesArr[i] = {
                    type: "linear",
                    display: true,
                    position: "left",
                    id: "y-axis-6",
                    ticks: {
                        fontColor: "aquamarine",
                        fontSize: 10,
                    }
                }
            }
        }

    const colourOptions = [
        { value: 'avgCellVoltage', label: 'Average Cell Voltage' },
        { value: 'avgCellTemp', label: 'Avg Cell Temp' },
        { value: 'current', label: 'Current' },
        { value: 'pduTemp', label: 'PDU Temp' },
        { value: 'packVoltage', label: 'Pack Voltage' },
        { value: 'soc', label: 'SOC %' },
    ]

    let heading = <div style={{ margin: "10px 10%", alignItems: "center", paddingTop: "5px", backgroundColor: "white", borderColor: "crimson", borderWidth: "2px", borderRadius: "15px" }}><h3 style={{ textAlign: "center" }}>Upload CSV file to analyze data</h3></div>
    let inputField = <input
        type="file"
        name="file"
        accept=".csv"
        onChange={changeHandler}
        style={{ display: "block", margin: "0 auto" }}
    />
    let showData
    if (dataLog)
        showData = <>
            <Row style={{ padding: "5px" }}>
                <Col></Col>
                <Col md='4' lg='4' sm='12' xs='12' xl='4' >
                    <Select
                        // defaultValue={colourOptions[5]}
                        isMulti
                        name="colors"
                        options={colourOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={checkBoxData}
                    /> </Col>
                <Col></Col>
            </Row>
            <Card>
                <Row>
                    <Col md='1' lg='1' sm='1' xs='1' xl='1' style={{ textAlign: "center" }}>
                        <button style={{ textAlign: "center", marginTop: "40%" }} onClick={left}>
                            <i class="fas fa-chevron-left"></i>
                        </button>
                    </Col>
                    <Col>
                        <Line
                            data={graphData.data}
                            options={graphOptions}
                        />
                        <strong
                            style={{ marginLeft: "30%" }}
                        >
                            Sample Time (s)
                        </strong></Col>
                    <Col md='1' lg='1' sm='1' xs='1' xl='1' style={{ textAlign: "center" }}>
                        <button style={{ textAlign: "center", marginTop: "40%" }} onClick={right}>
                            <i class="fas fa-chevron-right"></i>
                        </button>
                    </Col>
                </Row>
            </Card>
        </>
    else if (tempData) showData = <></>
    else if (cellData) showData = <></>
    else if (fuelData) showData = <></>
    else if (faultData) showData = <></>
    else showData = showMessage
    return (
        <div>{heading}
            <div>
                {inputField}
            </div>
        </div>
    );
}

export default ReadCSVFile