import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Spinner,
} from "reactstrap";
import { API_URL } from "variables/general";
function EditTemplate() {
  useEffect(() => {
    getField();
  }, []);
  const [fieldName, setFieldName] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [fieldType, setFieldType] = useState("text");
  const [invType, setInvType] = useState("bp");
  const [fieldDefaultValue, setFieldDefaultValue] = useState("");
  const [fieldData, setFieldData] = useState("");
  const [deleteAPIResponse, setDeleteAPIResponse] = useState("");
  const [patchAPIResponse, setPatchAPIResponse] = useState("");
  const [spinner, setSpinner] = useState(false);
  const getFieldName = (val) => {
    setDisabled(false);
    setFieldName(val.target.value);
  };
  const getInvType = (val) => {
    setInvType(val.target.value);
  };
  const getFieldType = (val) => {
    setFieldType(val.target.value);
  };
  const getFieldDefaultValue = (val) => {
    setFieldDefaultValue(val.target.value);
  };
  const getField = (second) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(API_URL + "/bms/template", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let status = result.status;
        if (status === true) setFieldData(result.data);
      })
      .catch((error) => console.log("error", error));
  };
  const deleteField = (second) => {
    setSpinner(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: JSON.stringify({
        invType: invType,
        customField: {
          fieldName: fieldName,
        },
      }),
    };
    fetch(API_URL + "/bms/template", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let status = result.status;
        if (status === true) alert(result.message);
        setSpinner(false);
      })
      .catch((error) => console.log("error", error));
  };
  const updateField = (second) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify({
        invType: invType,
        customField: {
          fieldName: fieldName,
          fieldDefaultValue: fieldDefaultValue,
          fieldType: fieldType,
        },
      }),
    };
    fetch(API_URL + "/bms/template", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let status = result.status;
        if (status === true) alert(result.message);
      })
      .catch((error) => {
        alert("Error");
        console.log("error", error);
      });
  };
  if (fieldData) {
    let showArray = [];
    if (invType === "bp") {
      if (fieldData.bp) showArray = fieldData.bp.customField;
    } else if (invType === "bl") {
      if (fieldData.bl) showArray = fieldData.bl.customField;
    } else if (invType === "pd") {
      if (fieldData.pd) showArray = fieldData.pd.customField;
    }
    return (
      <div>
        <Row>
          <Col>
            <Card>
              <div>
                <Row>
                  <Col md="4" lg="4" xl="4" sm="2" xs="1"></Col>
                  <Col md="4" lg="4" xl="4" sm="8" xs="10">
                    <Form>
                      <FormGroup>
                        {/* <Label for="invType">
                      <strong>Inventory Type : </strong>
                    </Label>
                    <Input
                      id="invType"
                      name="select"
                      type="select"
                      onChange={getInvType}
                    >
                      <option value="bp">Battery Pack</option>
                      <option value="bl">Balancer Card</option>
                      <option value="pd">Power Card</option>
                    </Input> */}
                        <Label for="fieldName">
                          <strong>Field Name : </strong>
                        </Label>
                        <Input
                          id="fieldName"
                          type="select"
                          onChange={getFieldName}
                        >
                          <option default>Select Field Name</option>
                          {showArray.map((val, i) => (
                            <option>{val.fieldName}</option>
                          ))}
                        </Input>
                        {/* <Label for="defaultValue">
                      <strong>Default Value : </strong>
                    </Label>

                    <Input
                      id="defaultValue"
                      type="text"
                      onChange={getFieldDefaultValue}
                      disabled={disabled}
                    ></Input>
                    <Label for="invType">
                      <strong>Field Type : </strong>
                    </Label>
                    <Input
                      id="invType"
                      name="select"
                      type="select"
                      onChange={getFieldType}
                      disabled={disabled}
                    >
                      <option value="text">Text</option>
                      <option value="number">Number</option>
                    </Input> */}
                      </FormGroup>
                    </Form>

                    {/* <Button
                  onClick={updateField}
                  disabled={disabled}
                >
                  Update Field
                </Button> */}
                    {spinner ? (
                      <Spinner>Loading...</Spinner>
                    ) : (
                      <Button onClick={deleteField} disabled={disabled}>
                        Delete Field
                      </Button>
                    )}
                  </Col>
                  <Col md="4" lg="4" xl="4" sm="2" xs="1"></Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  } else return <div>loading...</div>;
}

export default EditTemplate;
