import React from 'react'
import DailyAnalysis from './DailyAnalysis'
import LifeTimeAnalysis from './LifeTimeAnalysis'
import {Card, Nav, NavItem, NavLink} from 'reactstrap'
function AnalysisTab() {
    const [iconPills, setIconPills] = React.useState("1");
    const [showContent,setShowContent] = React.useState(<div>
      <LifeTimeAnalysis />
      </div>)
  return (
    <div className="section section-tabs">

      <Nav className="justify-content-center" role="tablist" tabs>
        <NavItem>
          <NavLink
            className={iconPills === "1" ? "active" : ""}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              setIconPills('1')
              setShowContent(
                <LifeTimeAnalysis />
                );
            }}
          >
            <i class="fa fa-home" aria-hidden="true"></i>&ensp;{" "}
            <strong>Lifetime Analysis</strong>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={iconPills === "2" ? "active" : ""}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              setIconPills('2')
              setShowContent(
                <DailyAnalysis />
                );
            }}
          >
            <i class="fa fa-home" aria-hidden="true"></i>&ensp;{" "}
            <strong>Daily Analysis</strong>
          </NavLink>
        </NavItem>
        </Nav>
        <div style={{textAlign:'center'}}>
            {showContent}
          </div>
    </div>
  )
}

export default AnalysisTab