import React, { useState } from "react";
import {
  Table,
} from "reactstrap";

function History(props) {
  const inventoryData = props.inventoryData;
  const batteryPackId = props.batteryPackId;
  const Case = props.Case;
  let batteryPackData = [];
  for (let i = 0; i < inventoryData.packs.length; i++) {
    if (batteryPackId === inventoryData.packs[i].bpId)
      batteryPackData = inventoryData.packs[i];
  }
  let balancerCardData = [];
  for (let i = 0; i < inventoryData.balancerCard.length; i++) {
    if (batteryPackData.blId === inventoryData.balancerCard[i].blId)
      balancerCardData = inventoryData.balancerCard[i];
  }
  let powerCardData = [];
  for (let i = 0; i < inventoryData.powerCard.length; i++) {
    if (batteryPackData.pdId === inventoryData.powerCard[i].pdId)
      powerCardData = inventoryData.powerCard[i];
  }
  console.log("batterypackData", batteryPackData);
  console.log("balancerData", balancerCardData);
  console.log("powerData", powerCardData);
  console.log(Case);
  let showTime;
  switch (Case) {
    case "bp":
      showTime = (
        <Table hover>
          <thead>
            <tr>
              <th scope="row">Reason</th>
              <th scope="row">Comment</th>
              <th scope="row">Last Updated</th>
            </tr>
          </thead>
          <tbody>
            {batteryPackData.history
              ? batteryPackData.history.map((val, i) => (
                  <tr>
                    <th>{val.changes.reason}</th>
                    <th>{val.changes.comment}</th>
                    <th>
                      {val.changes.lastUpdated}
                    </th>
                  </tr>
                ))
              : ""}
          </tbody>
        </Table>
      );
      break;
    case "bl":
      showTime = (
        <Table hover>
          <thead>
            <tr>
              <th scope="row">Reason</th>
              <th scope="row">Comment</th>
              <th scope="row">Last Updated</th>
            </tr>
          </thead>
          <tbody>
            {balancerCardData.history
              ? balancerCardData.history.map((val, i) => (
                  <tr>
                    <th>{val.changes.reason}</th>
                    <th>{val.changes.comment}</th>
                    <th>
                    {val.changes.lastUpdated}
                    </th>
                  </tr>
                ))
              : ""}
          </tbody>
        </Table>
      );
      break;
    case "pd":
      showTime = (
        <Table hover>
          <thead>
            <tr>
              <th scope="row">Reason</th>
              <th scope="row">Comment</th>
              <th scope="row">Last Updated</th>
            </tr>
          </thead>
          <tbody>
            {powerCardData.history
              ? powerCardData.history.map((val, i) => (
                  <tr>
                    <th>{val.changes.reason}</th>
                    <th>{val.changes.comment}</th>
                    <th>
                    {val.changes.lastUpdated}
                    </th>
                  </tr>
                ))
              : ""}
          </tbody>
        </Table>
      );
      break;
    default:
      break;
  }
  return (
    <div>
      <h6>History</h6>
      {showTime}
    </div>
  );
}

export default History;
