import React, { useState } from "react";
import ReactSelect from "react-select";
import { Row, Col, Card, Button, Badge, Table, Spinner } from "reactstrap";
import BatteryPackStock from "assets/img/batterypack.png";
import { API_URL } from "variables/general";
import { useEffect } from "react";
import { serverUrl } from "variables/general";
import { CSVLink } from "react-csv";
import History from "./History";
import "./Inventory.css";
function BatteryPackDetails(props) {
  const batteryPackId = props.batteryPackId;
  const inventoryData = props.InventoryData;
  const [inventoryDetails, setInventoryDetails] = useState("");
  const [batteryPackClicked, setBatteryPackClicked] = useState(true);
  const [balancerCardClicked, setBalancerCardClicked] = useState(false);
  const [PDUCardClicked, setPDUCardClicked] = useState(false);
  const [iotCardClicked, setIotCardClicked] = useState(false);
  const [Case, setCase] = useState("bp");
  const [blCardList, setBlCardList] = useState("");
  const [pdCardList, setPdCardList] = useState("");
  const [iotCardList, setIoTCardList] = useState("");
  const [blCard, setBlCard] = useState("");
  const [pdCard, setPdCard] = useState("");
  const [iotCard, setIoTCard] = useState("");
  const [spinner, setSpinner] = useState("");
  const [reportData, setReportData] = useState("");
  const [showReportPage, setShowReportPage] = useState(false);
  useEffect(() => {
    getInventory();
  }, []);

  const selectedSpanStyle = {
    color: "#32C85A",
    cursor: "pointer",
    fontWeight: "900",
    fontSize: "18px",
  };
  const spanStyle = {
    marginLeft: "8px",
    color: "#0faef2",
    cursor: "pointer",
  };

  const mapInventory = (val) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    if (val === "bl")
      requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: JSON.stringify({
          type: "mapping",
          data: {
            changes: {
              comment: "",
              reason: "balancer card added",
            },
          },
          bpId: batteryPackId,
          mapping: { blId: blCard },
        }),
      };
    else if (val === "pd")
      requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: JSON.stringify({
          type: "mapping",
          data: {
            changes: {
              comment: "",
              reason: "power card added",
            },
          },
          bpId: batteryPackId,
          mapping: { pdId: pdCard },
        }),
      };
    else if (val === "iot")
      requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: JSON.stringify({
          type: "mapping",
          data: {
            changes: {
              comment: "",
              reason: "iot card added",
            },
          },
          bpId: batteryPackId,
          mapping: { iotId: iotCard },
        }),
      };
    fetch(API_URL + "/bms/inventory_data", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert(result.message);
      })
      .catch((error) => console.log("error", error));
  };
  const getInventory = (val) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      API_URL + "/bms/inventory_data?bp_id=" + batteryPackId,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setInventoryDetails(result.data);
      })
      .catch((error) => console.log("error", error));
  };
  const getReport = (val) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      API_URL + "/testjig/test_report?did=" + val,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setReportData(result.data)
        setShowReportPage(true)
      })
      .catch((error) => console.log("error", error));
  };
  const getCardList = (val) => {
    setSpinner(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    if (val === "bl")
      fetch(API_URL + "/bms/inventory_data?bl=unassigned", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setBlCardList(result.data.bl);
          if (result.data.bl.length === 0) alert("No Balancer Card Found");
          setSpinner(false);
        })
        .catch((error) => console.log("error", error));
    else if (val === "pd")
      fetch(API_URL + "/bms/inventory_data?pd=unassigned", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setPdCardList(result.data.pd);
          if (result.data.pd.length === 0) alert("No Power Card Found");
          setSpinner(false);
        })
        .catch((error) => console.log("error", error));
    else if (val === "iot")
      fetch(API_URL + "/bms/inventory_data?iot=unassigned", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setIoTCardList(result.data.iot);
          if (result.data.iot.length === 0) alert("No IoT Card Found");
          setSpinner(false);
        })
        .catch((error) => console.log("error", error));
  };

  const getBlCard = (second) => {
    setBlCard(second.value);
  };
  const getPdCard = (second) => {
    setPdCard(second.value);
  };
  const getIoTCard = (second) => {
    setIoTCard(second.value);
  };

  let blCardListOptions = [];
  if (blCardList) {
    for (let i = 0; i < blCardList.length; i++) {
      blCardListOptions[i] = {
        value: blCardList[i].blId,
        label: blCardList[i].blId,
      };
    }
  }
  let pdCardListOptions = [];
  if (pdCardList) {
    for (let i = 0; i < pdCardList.length; i++) {
      pdCardListOptions[i] = {
        value: pdCardList[i].pdId,
        label: pdCardList[i].pdId,
      };
    }
  }
  let iotCardListOptions = [];
  if (iotCardList) {
    for (let i = 0; i < iotCardList.length; i++) {
      iotCardListOptions[i] = {
        value: iotCardList[i].iot,
        label: iotCardList[i].iot,
      };
    }
  }
  let showDetails;
  let customFieldData = []
  if (inventoryDetails) {
    let balancerData;
    for (let index = 0; index < inventoryData.balancerCard.length; index++) {
      if (inventoryDetails.blId === inventoryData.balancerCard[index].blId)
        balancerData = inventoryData.balancerCard[index];
    }

    let powerData;
    for (let index = 0; index < inventoryData.powerCard.length; index++) {
      if (inventoryDetails.pdId === inventoryData.powerCard[index].pdId)
        powerData = inventoryData.powerCard[index];
    }
    let iotData;
    // for (let index = 0; index < inventoryData.iotCard.length; index++) {
    //   if (inventoryDetails.iotId === inventoryData.iotCard[index].iotId)
    //     iotData = inventoryData.iotCard[index];
    // }

    for (const [key, value] of Object.entries(inventoryDetails.customField)) {
      customFieldData.push(<tr>
        <th scope="row">{key}</th>
        <td scope="row">{value}</td>
      </tr>)
    }


    switch (Case) {
      case "bp":
        showDetails = (
          <Card>
            <Row>
              <Col md="5" lg="5" xl="5" xxl="5" sm="12" xs="12">
                <div style={{ padding: "2rem 1rem", textAlign: "center" }}>
                  <img
                    src={
                      inventoryDetails.data.url
                        ? serverUrl + inventoryDetails.data.url
                        : BatteryPackStock
                    }
                    alt="pack"
                    style={{ maxWidth: "20rem", maxHeight: "20rem" }}
                  ></img>
                </div>
              </Col>
              <Col md="7" lg="7" xl="7" xxl="7" sm="12" xs="12">
                <div>
                  <Table hover>
                    <thead>
                      <tr>
                        <th scope="row">Item</th>
                        <th>Battery Pack</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Alias</th>
                        <td>{inventoryDetails.data.batteryPackAlias}</td>
                      </tr>
                      <tr>
                        <th scope="row">Battery pack ID</th>
                        <td>{inventoryDetails.bpId}</td>
                      </tr>
                      <tr>
                        <th scope="row">Balancer Card ID</th>
                        <td>{inventoryDetails.blId}</td>
                      </tr>
                      <tr>
                        <th scope="row">Power Card ID</th>
                        <td>{inventoryDetails.pdId}</td>
                      </tr>
                      <tr>
                        <th scope="row">Description</th>
                        <td style={{ wordWrap: "break-word" }}>
                          {inventoryDetails.data.description}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Last Update</th>
                        <td>{inventoryDetails.data.lastUpdated}</td>
                      </tr>
                      {customFieldData
                      }
                      <tr>
                        <th scope="row">Status</th>
                        <td>
                          {inventoryDetails.data.status.map((val, i) => (
                            <Badge
                              color="info"
                              pill
                              style={{ padding: "10px", margin: "8px" }}
                            >
                              {val}
                            </Badge>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Card>
        );
        break;
      case "bl":
        if (balancerData) {
          showDetails = (
            <Card>
              <Row>
                <Col md="5" lg="5" xl="5" xxl="5" sm="12" xs="12">
                  <div style={{ padding: "2rem 1rem", textAlign: "center" }}>
                    <img
                      src={
                        balancerData.url
                          ? serverUrl + balancerData.url
                          : BatteryPackStock
                      }
                      alt="pack"
                      style={{ width: "10rem" }}
                    ></img>
                  </div>
                </Col>
                <Col md="7" lg="7" xl="7" xxl="7" sm="12" xs="12">
                  <div>
                    <Table hover>
                      <thead>
                        <tr>
                          <th scope="row">Item</th>
                          <th>Balancer Card</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Balancer Card ID</th>
                          <td>{balancerData.blId}</td>
                        </tr>
                        <tr>
                          <th scope="row">Mac Address</th>
                          <td>{balancerData.data.macAddress}</td>
                        </tr>
                        <tr>
                          <th scope="row">Description</th>
                          <td style={{ wordWrap: "break-word" }}>
                            {balancerData.data.description}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Last Update</th>
                          <td>{balancerData.data.lastUpdated}</td>
                        </tr>
                        <tr>
                          <th scope="row">Status</th>
                          <td>
                            {balancerData.data.status
                              ? balancerData.data.status.map((val, i) => (
                                <Badge
                                  color="info"
                                  pill
                                  style={{ padding: "10px", margin: "8px" }}
                                >
                                  {val}
                                </Badge>
                              ))
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          {/* <th scope="row">Report</th> */}
                          <td>
                            <Button onClick={() => getReport(balancerData.blId)}>View Report</Button>
                            <Button> <CSVLink
                            style={{textTransform:'none'}}
              data={reportData.toString()}
              filename={`${reportData.did}_report`}
            >
              Download Report
            </CSVLink></Button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Card>
          );
        } else
          showDetails = (
            <div style={{ textAlign: "center" }}>
              <Row>
                <Col md="8" lg="8" xs="12" sm="12" xl="8">
                  <h4>No Balancer Card Assigned</h4>
                </Col>
                <Col md="4" lg="4" xs="12" sm="12" xl="4">
                  {spinner ? (
                    <Spinner>Loading...</Spinner>
                  ) : (
                    <>
                      {blCardList ? (
                        <>
                          <ReactSelect
                            options={blCardListOptions}
                            onChange={getBlCard}
                          />
                          <Button onClick={() => mapInventory("bl")}>
                            Assign
                          </Button>
                        </>
                      ) : (
                        <Button onClick={() => getCardList("bl")}>
                          Get Balancer Card List
                        </Button>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </div>
          );
        break;
      case "pd":
        if (powerData) {
          showDetails = (
            <Card>
              <Row>
                <Col md="5" lg="5" xl="5" xxl="5" sm="12" xs="12">
                  <div style={{ padding: "2rem 1rem", textAlign: "center" }}>
                    <img
                      src={
                        powerData.url
                          ? serverUrl + powerData.url
                          : BatteryPackStock
                      }
                      alt="pack"
                      style={{ width: "10rem" }}
                    ></img>
                  </div>
                </Col>
                <Col md="7" lg="7" xl="7" xxl="7" sm="12" xs="12">
                  <div>
                    <Table hover>
                      <thead>
                        <tr>
                          <th scope="row">Item</th>
                          <th>Power Card</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Power Card ID</th>
                          <td>{powerData.pdId}</td>
                        </tr>
                        <tr>
                          <th scope="row">Description</th>
                          <td style={{ wordWrap: "break-word" }}>
                            {powerData.data.description}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Last Update</th>
                          <td>{powerData.data.lastUpdated}</td>
                        </tr>
                        <tr>
                          <th scope="row">Status</th>
                          <td>
                            {powerData.data.status
                              ? powerData.data.status.map((val, i) => (
                                <Badge
                                  color="info"
                                  pill
                                  style={{ padding: "10px", margin: "8px" }}
                                >
                                  {val}
                                </Badge>
                              ))
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Card>
          );
        } else
          showDetails = (
            <div style={{ textAlign: "center" }}>
              <Row>
                <Col md="8" lg="8" xs="12" sm="12" xl="8">
                  <h4>No Power Card Assigned</h4>
                </Col>
                <Col md="4" lg="4" xs="12" sm="12" xl="4">
                  {spinner ? (
                    <Spinner>Loading...</Spinner>
                  ) : (
                    <>
                      {pdCardList ? (
                        <>
                          <ReactSelect
                            options={pdCardListOptions}
                            onChange={getPdCard}
                          />
                          <Button onClick={() => mapInventory("pd")}>
                            Assign
                          </Button>
                        </>
                      ) : (
                        <Button onClick={() => getCardList("pd")}>
                          Get Power Card List
                        </Button>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </div>
          );
        break;
      case "iot":
        if (iotData) {
          showDetails = (
            <Card>
              <Row>
                <Col md="5" lg="5" xl="5" xxl="5" sm="12" xs="12">
                  <div style={{ padding: "2rem 1rem", textAlign: "center" }}>
                    <img
                      src={
                        iotData.url ? serverUrl + iotData.url : BatteryPackStock
                      }
                      alt="pack"
                      style={{ width: "10rem" }}
                    ></img>
                  </div>
                </Col>
                <Col md="7" lg="7" xl="7" xxl="7" sm="12" xs="12">
                  <div>
                    <Table hover>
                      <thead>
                        <tr>
                          <th scope="row">Item</th>
                          <th>IoT Card</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">IoT Card ID</th>
                          <td>{iotData.blId}</td>
                        </tr>
                        <tr>
                          <th scope="row">Description</th>
                          <td style={{ wordWrap: "break-word" }}>
                            {iotData.data.description}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Last Update</th>
                          <td>{iotData.data.lastUpdated}</td>
                        </tr>
                        <tr>
                          <th scope="row">Status</th>
                          <td>
                            {iotData.data.status
                              ? iotData.data.status.map((val, i) => (
                                <Badge
                                  color="info"
                                  pill
                                  style={{ padding: "10px", margin: "8px" }}
                                >
                                  {val}
                                </Badge>
                              ))
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Card>
          );
        } else
          showDetails = (
            <div style={{ textAlign: "center" }}>
              <Row>
                <Col md="8" lg="8" xs="12" sm="12" xl="8">
                  <h4>No IoT Card Assigned</h4>
                </Col>
                <Col md="4" lg="4" xs="12" sm="12" xl="4">
                  {spinner ? (
                    <Spinner>Loading...</Spinner>
                  ) : (
                    <>
                      {iotCardList ? (
                        <>
                          <ReactSelect
                            options={iotCardListOptions}
                            onChange={getIoTCard}
                          />
                          <Button onClick={() => mapInventory("iot")}>
                            Assign
                          </Button>
                        </>
                      ) : (
                        <Button onClick={() => getCardList("iot")}>
                          Get IoT Card List
                        </Button>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </div>
          );
        break;
      default:
        break;
    }
    if (showReportPage) {
      if (reportData)
        return <div className='test-report-container'>
          <span
            onClick={() => setShowReportPage(false)}
            style={{ cursor: "pointer", fontSize: "12px", color:'black', textTransform:'none', fontWeight:'normal'}}
          >
            <i class="fas fa-arrow-left"></i> Return
          </span>
          <h5>Card ID: {reportData.did}</h5>
          <Row className="test-report-main-row">
            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>Power Rail Test</h6>
                <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">
                  <strong>+5V0#</strong><br />
                <strong>+5V0</strong><br />
                <strong>+VCAN</strong><br />
                <strong>+3.0V</strong><br />
                <strong>3P3 (ISL TOP)</strong><br />
                <strong>3P3 (ISL BOTTOM)</strong><br />
                <strong>+5V_MCU</strong>
                  </Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.powerRailTest.powerRail1?'#37fa71':'#fa3737'}}>{reportData.powerRailTest.powerRail1 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.powerRailTest.powerRail2?'#37fa71':'#fa3737'}}>{reportData.powerRailTest.powerRail2 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.powerRailTest.powerRail3?'#37fa71':'#fa3737'}}>{reportData.powerRailTest.powerRail3 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.powerRailTest.powerRail4?'#37fa71':'#fa3737'}}>{reportData.powerRailTest.powerRail4 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.powerRailTest.powerRail5?'#37fa71':'#fa3737'}}>{reportData.powerRailTest.powerRail5 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.powerRailTest.powerRail6?'#37fa71':'#fa3737'}}>{reportData.powerRailTest.powerRail6 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.powerRailTest.powerRail7?'#37fa71':'#fa3737'}}>{reportData.powerRailTest.powerRail7 ? 'passed' : 'failed'}</span>
                  </Col>
                </Row>

            

            </Col>
            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>Peripheral Test</h6>
                <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">
                  <strong>EEPROM (I2C comm.)</strong><br />
                <strong>BLE (UART comm.)</strong><br />
                <strong>ISL (SPI comm.)</strong><br />
                <strong>ADC (I2C comm.)</strong><br />
                <strong>CAN</strong><br />
                <strong>Balancing NTC</strong><br />
                <strong>RTC in sleep mode</strong>
                  </Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.perpherialTest.perpherialTest1?'#37fa71':'#fa3737'}}>{reportData.perpherialTest.perpherialTest1 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.perpherialTest.perpherialTest2?'#37fa71':'#fa3737'}}>{reportData.perpherialTest.perpherialTest2 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.perpherialTest.perpherialTest3?'#37fa71':'#fa3737'}}>{reportData.perpherialTest.perpherialTest3 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.perpherialTest.perpherialTest4?'#37fa71':'#fa3737'}}>{reportData.perpherialTest.perpherialTest4 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.perpherialTest.perpherialTest5?'#37fa71':'#fa3737'}}>{reportData.perpherialTest.perpherialTest5 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.perpherialTest.perpherialTest6?'#37fa71':'#fa3737'}}>{reportData.perpherialTest.perpherialTest6 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.perpherialTest.perpherialTest7?'#37fa71':'#fa3737'}}>{reportData.perpherialTest.perpherialTest7 ? 'passed' : 'failed'}</span>
                  </Col>
                </Row>
             

            </Col>

            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>PDU Connector Pin Test</h6>
              <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">
                  <strong>latRstPinTest</strong><br />
                <strong>scdAlertPinTest</strong><br />
                <strong>pchgEnPinTest</strong><br />
                <strong>dsgEnPinTest</strong><br />
                <strong>chgEnPinTest</strong><br />
                <strong>ntc100KPinTest</strong><br />
                <strong>drvVadcPinTest</strong><br />
                <strong>csnAdcPinTest</strong>
</Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.pduConnectorPinTest.latRstPinTest?'#37fa71':'#fa3737'}}>{reportData.pduConnectorPinTest.latRstPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.pduConnectorPinTest.scdAlertPinTest?'#37fa71':'#fa3737'}}>{reportData.pduConnectorPinTest.scdAlertPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.pduConnectorPinTest.pchgEnPinTest?'#37fa71':'#fa3737'}}>{reportData.pduConnectorPinTest.pchgEnPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.pduConnectorPinTest.dsgEnPinTest?'#37fa71':'#fa3737'}}>{reportData.pduConnectorPinTest.dsgEnPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.pduConnectorPinTest.chgEnPinTest?'#37fa71':'#fa3737'}}>{reportData.pduConnectorPinTest.chgEnPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.pduConnectorPinTest.ntc100KPinTest?'#37fa71':'#fa3737'}}>{reportData.pduConnectorPinTest.ntc100KPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.pduConnectorPinTest.drvVadcPinTest?'#37fa71':'#fa3737'}}>{reportData.pduConnectorPinTest.drvVadcPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.pduConnectorPinTest.csnAdcPinTest?'#37fa71':'#fa3737'}}>{reportData.pduConnectorPinTest.csnAdcPinTest ? 'passed' : 'failed'}</span>
</Col>
                </Row>
           

            </Col>
            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>GPIO Connector Pin Test</h6>
                <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">
                  <strong>ignitionPinTest</strong><br />
                <strong>reservePinTest</strong><br />
                <strong>gsmOnOffPinTest</strong><br />
                <strong>out1PinTest</strong><br />
                <strong>buzzLedPinTest</strong><br />
                <strong>out2PinTest</strong><br />
                <strong>out3PinTest</strong>
</Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.gpioConnectorPinTest.ignitionPinTest ?'#37fa71':'#fa3737'}}>{reportData.gpioConnectorPinTest.ignitionPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.gpioConnectorPinTest.reservePinTest ?'#37fa71':'#fa3737'}}>{reportData.gpioConnectorPinTest.reservePinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.gpioConnectorPinTest.gsmOnOffPinTest ?'#37fa71':'#fa3737'}}>{reportData.gpioConnectorPinTest.gsmOnOffPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.gpioConnectorPinTest.out1PinTest ?'#37fa71':'#fa3737'}}>{reportData.gpioConnectorPinTest.out1PinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.gpioConnectorPinTest.buzzLedPinTest ?'#37fa71':'#fa3737'}}>{reportData.gpioConnectorPinTest.buzzLedPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.gpioConnectorPinTest.out2PinTest ?'#37fa71':'#fa3737'}}>{reportData.gpioConnectorPinTest.out2PinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.gpioConnectorPinTest.out3PinTest ?'#37fa71':'#fa3737'}}>{reportData.gpioConnectorPinTest.out3PinTest ? 'passed' : 'failed'}</span>
</Col>
                </Row>
                

            </Col>



            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>Cell Voltage Test</h6>
                <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">
                  <strong>Cell 1</strong><br />
                <strong>Cell 2</strong><br />
                <strong>Cell 3</strong><br />
                <strong>Cell 4</strong><br />
                <strong>Cell 5</strong><br />
                <strong>Cell 6</strong><br />
                <strong>Cell 7</strong><br />
                <strong>Cell 8</strong>
                  </Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell1 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell2 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell3 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell4 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell5 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell6 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell7 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell8 ? 'passed' : 'failed'}</span>

                  </Col>
                </Row>
               

            </Col>
            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>Cell Voltage Test</h6>
                <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">
                  <strong>Cell 9</strong><br />
                <strong>Cell 10</strong><br />
                <strong>Cell 11</strong><br />
                <strong>Cell 12</strong><br />
                <strong>Cell 13</strong><br />
                <strong>Cell 14</strong><br />
                <strong>Cell 15</strong><br />
                <strong>Cell 16</strong>
                  </Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell9 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell10 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell11 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell12 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell13 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell14 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell15 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell16 ? 'passed' : 'failed'}</span>
                  </Col>
                </Row>
              

            </Col>
            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>Cell Voltage Test</h6>
                <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">
                  <strong>Cell 17</strong><br />
                <strong>Cell 18</strong><br />
                <strong>Cell 19</strong><br />
                <strong>Cell 20</strong><br />
                <strong>Cell 21</strong><br />
                <strong>Cell 22</strong><br />
                <strong>Cell 23</strong><br />
                <strong>Cell 24</strong>
                  </Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell17 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell18 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell19 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell20 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell21 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell22 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell23 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell24 ? 'passed' : 'failed'}</span>
</Col>
                </Row>

               

            </Col>
            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>NTC Value Test</h6>
               <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">
                  <strong>Temp 1</strong><br />
                <strong>Temp 2</strong><br />
                <strong>Temp 3</strong><br />
                <strong>Temp 4</strong><br />
                <strong>Temp 5</strong><br />
                <strong>Temp 6</strong><br />
                <strong>Temp 7</strong><br />
                <strong>Temp 8</strong>
</Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.analogFrontendTest.ntcValuesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.ntcValuesTest.temp1 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.ntcValuesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.ntcValuesTest.temp2 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.ntcValuesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.ntcValuesTest.temp3 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.ntcValuesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.ntcValuesTest.temp4 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.ntcValuesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.ntcValuesTest.temp5 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.ntcValuesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.ntcValuesTest.temp6 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.ntcValuesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.ntcValuesTest.temp7 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.ntcValuesTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.ntcValuesTest.temp8 ? 'passed' : 'failed'}</span>
</Col>
                </Row>
                

            </Col>
            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>Cell Balancing Test</h6>
              <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">


                  <strong>Cell 1</strong><br />
                <strong>Cell 2</strong><br />
                <strong>Cell 3</strong><br />
                <strong>Cell 4</strong><br />
                <strong>Cell 5</strong><br />
                <strong>Cell 6</strong><br />
                <strong>Cell 7</strong><br />
                <strong>Cell 8</strong>
                 
</Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell1 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell2 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell3 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell4 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell5 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell6 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell7 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell8 ? 'passed' : 'failed'}</span>
</Col>
                </Row>
                

            </Col>
            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>Cell Balancing Test</h6>
               <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col"><strong>Cell 9</strong><br />
                <strong>Cell 10</strong><br />
                <strong>Cell 11</strong><br />
                <strong>Cell 12</strong><br />
                <strong>Cell 13</strong><br />
                <strong>Cell 14</strong><br />
                <strong>Cell 15</strong><br />
                <strong>Cell 16</strong>
</Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col"><span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell9 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell10 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell11 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell12 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell13 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell14 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell15 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell16 ? 'passed' : 'failed'}</span>
</Col>
                </Row>
                

            </Col>
            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>Cell Balancing Test</h6>
               <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">
                  <strong>Cell 17</strong><br />
                <strong>Cell 18</strong><br />
                <strong>Cell 19</strong><br />
                <strong>Cell 20</strong><br />
                <strong>Cell 21</strong><br />
                <strong>Cell 22</strong><br />
                <strong>Cell 23</strong><br />
                <strong>Cell 24</strong>
</Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell17 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell18 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell19 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell20 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell21 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell22 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell23 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell24 ? 'passed' : 'failed'}</span>
</Col>
                </Row>
                

            </Col>
           
          </Row>
      </div>
      else return <div> No Report Data</div>
    }
    else
      return (
        <div style={{ padding: "2rem" }}>
          <span
            onClick={() => props.setBatteryPackId("")}
            style={{ cursor: "pointer", fontSize: "12px" }}
          >
            <i class="fas fa-arrow-left"></i> Battery Pack List
          </span>
          <Row>
            <Col md="9" lg="9" xl="9" xxl="9" sm="12" xs="12">
              {showDetails}
            </Col>
            <Col md="3" lg="3" xl="3" xxl="3" sm="12" xs="12">
              <Card style={{ padding: "1rem" }}>
                <span
                  onClick={() => {
                    setBatteryPackClicked(true);
                    setBalancerCardClicked(false);
                    setPDUCardClicked(false);
                    setIotCardClicked(false);
                    setCase("bp");
                  }}
                  style={batteryPackClicked ? selectedSpanStyle : spanStyle}
                >
                  Battery Pack 
                  <span style={{ fontSize: "12px", color: "GrayText" }}>
                    - {batteryPackId}
                  </span>
                </span>
                <span
                  onClick={() => {
                    setBatteryPackClicked(false);
                    setBalancerCardClicked(true);
                    setPDUCardClicked(false);
                    setIotCardClicked(false);
                    setCase("bl");
                  }}
                  style={balancerCardClicked ? selectedSpanStyle : spanStyle}
                >
                  Balancer Card 
                  <span style={{ fontSize: "12px", color: "GrayText" }}>
                    - {inventoryDetails.blId ? inventoryDetails.blId : "n/a"}
                  </span>
                </span>
                <span
                  onClick={() => {
                    setBatteryPackClicked(false);
                    setBalancerCardClicked(false);
                    setPDUCardClicked(true);
                    setIotCardClicked(false);
                    setCase("pd");
                  }}
                  style={PDUCardClicked ? selectedSpanStyle : spanStyle}
                >
                  PDU Card 
                  <span style={{ fontSize: "12px", color: "GrayText" }}>
                    - {inventoryDetails.pdId ? inventoryDetails.pdId : "n/a"}
                  </span>
                </span>
                <span
                  onClick={() => {
                    setBatteryPackClicked(false);
                    setBalancerCardClicked(false);
                    setPDUCardClicked(false);
                    setIotCardClicked(true);
                    setCase("iot");
                  }}
                  style={iotCardClicked ? selectedSpanStyle : spanStyle}
                >
                  IoT Card 
                  <span style={{ fontSize: "12px", color: "GrayText" }}>
                    - {inventoryDetails.iotId ? inventoryDetails.iotId : "n/a"}
                  </span>
                </span>
              </Card>
            </Col>
          </Row>
          <div>
            <History
              inventoryData={inventoryData}
              Case={Case}
              batteryPackId={batteryPackId}
            />
          </div>
        </div>
      );
  } else return <div>loading...</div>;
}
export default BatteryPackDetails;
