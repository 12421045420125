import React from "react";
import { Button } from "reactstrap";

function FirmwareUpdate(props) {
  return (
    <div>
      <br />
      {props.apiFileUploadSuccess ? (
        props.apiFotaFileSize === 122496|| props.apiFotaFileSize === 122240? 'Firmware File Ready':'Firmware File Size Not Correct' 
      ) : (
        <>
        <strong>Enter Firmware File Name</strong><br />
        <input type='text' onChange={(e)=>props.setApiFotaFileInputHandler(e.target.value)}></input> &ensp;
        {props.showLoaderInApiDownload?<div>Loading File...</div>: <Button onClick={props.apiFOTAFile}>Get Update File</Button> }
          <br />
          or
          <br />
          <input
            type="file"
            accept=".bin"
            onChange={props.BinFileInput}
          ></input>
        </>
      )}
      <br />
      <br />
      {props.fotaProcess ? (
        props.fotaProcess
      ) : (
        <Button
          onClick={props.UpdateCode}
          disabled={props.startUpdatingDisabled || !props.fileUploaded}
        >
          Start Updating
        </Button>
      )}
      {props.incrementXModem ? (
        <h3>
          {Math.ceil(
            (props.incrementXModem / props.xModem128ByteChunk.length) * 100
          )}
          %
        </h3>
      ) : (
        ""
      )}
      {props.fotaSuccessful ? <div>Update Successful</div> : <></>}
    </div>
  );
}

export default FirmwareUpdate;
