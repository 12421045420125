import { Card, Col, Row, Table } from "react-bootstrap";
import React, { useState } from "react";

import { API_URL } from "variables/general";
import { AWS_URL } from "variables/general";
import { Container } from "reactstrap";
import Enlarge from "assets/img/enlarge.png";

class Alarm extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.getAlarm();
    this.getAlarm = setInterval(this.getAlarm, 60000);
  }

  stopInterval() {
    clearInterval(this.getAlarm);
  }
  componentWillUnmount() {
    this.stopInterval();
  }
  getAlarm = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(API_URL + "/bms/latest_data", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          deviceData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  render() {
    let alarmTable = [];
    let deviceData = this.state.deviceData;
    if (deviceData) {
      let valueDataItem = JSON.parse(deviceData).data;
      // for (let i = 0; i < valueDataItem.length; i++) {
      //   if (valueDataItem[i].location.alarm === 1) {
      //     alarmTable[i] = valueDataItem[i].location;
      //   }
      // }
      // console.log(alarmTable);
      return (
        <div>
          <h3 style={{ marginTop: "15px" }}>Notifications</h3>
          <Card style={{ padding: "0 20px" }}>
            <h3>Alarm</h3>
            No Alarm Yet
            {/* {alarmTable.length === 0 ? (
              <div>No Alarms at the moment</div>
            ) : (
              <Table responsive>
                <thead>
                  <th>S.No</th>
                  <th>Start Time</th>
                  <th>BMS</th>
                  <th>Type</th>
                  <th>Severity</th>
                  <th>Status</th>
                </thead>
                <tbody>
                  {alarmTable.map((val, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{val.tms}</td>
                      <td>{val.did}</td>
                      <td>Geo Fence Breach</td>
                      <td>Critical</td>
                      <td>Active</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )} */}
          </Card>
        </div>
      );
    } else
      return (
        <div className="content">
          <div className="loadingContainer">
            <div className="ball1"></div>
            <div className="ball2"></div>
            <div className="ball3"></div>
            <div className="ball4"></div>
          </div>
        </div>
      );
  }
}

export default Alarm;
