
import MapPro from "components/MapPro.js/MapPro";
import Notifications from "views/Notifications.js";
import ProGeoFence from "components/Settings/ProGeoFencing";
import SubUsers from "views/SubUsers";
import UserSettings from "components/Settings/UserSettings";
import ReadCSVFile from "components/ReadCSVFile/ReadCSVFile";
import FOTAIoT from "components/FOTA-IoT/FOTAIoT";
import UploadBinFile from "components/UploadBinFile/UploadBinFile";
import Parallel from "components/Parallel/Parallel";
// import IoTParent from "components/IoT-Parent/IoTParent";
import Test from "views/Test";
import MainMap from "components/MapPro.js/MainMap";
import IoTParent from "components/IoT-Parent/IoTParent";
import test2 from "components/IoT-Parent/test2";
import Test3 from "components/IoT-Parent/Test3";


 const adminRoutes = [
    {
      path: "/sub-users",
      name: "Dashboard",
      icon: "nc-icon nc-bullet-list-67",
      component: SubUsers,
      layout: "/admin",
    },
    // {
    //   path: "/testing",
    //   name: "Test",
    //   icon: "nc-icon nc-bank",
    //   component: Test,
    //   layout: "/admin",
    // },
    // {
    //   path: "/icons",
    //   name: "Icons",
    //   icon: "nc-icon nc-diamond",
    //   component: Icons,
    //   layout: "/admin",
    // },
    {
      path: "/mappro",
      name: "Map",
      icon: "nc-icon nc-pin-3",
      component: MainMap,
      layout: "/admin",
    },
    {
      path: "/pro-geo-fence",
      name: "Geo Fence Config",
      icon: "fas fa-map-marked",
      component: ProGeoFence,
      layout: "/admin",
    },
    {
      path: "/notifications",
      name: "Notifications",
      icon: "nc-icon nc-bell-55",
      component: Notifications,
      layout: "/admin",
    },
    {
      path: "/settings",
      name: "User Settings",
      icon: "nc-icon nc-settings-gear-65",
      component: UserSettings,
      layout: "/admin",
    },
    // {
    //   path: "/user-page",
    //   name: "User Profile",
    //   icon: "nc-icon nc-single-02",
    //   component: UserPage,
    //   layout: "/admin",
    // },
    {
      path: "/read-csv-file",
      name: "Get Data From CSV file",
      icon: "nc-icon nc-cloud-upload-94",
      component: ReadCSVFile,
      layout: "/admin",
    },
    {
      path: "/fota-iot",
      name: "FOTA IoT",
      icon: "nc-icon nc-cloud-upload-94",
      component: FOTAIoT,
      layout: "/admin",
    },
    {
      path: "/upload-bin",
      name: "Upload Bin File",
      icon: "nc-icon nc-cloud-upload-94",
      component: UploadBinFile,
      layout: "/admin",
    },
    // {
    //   path: "/iot-parent",
    //   name: "IoT-Parent",
    //   icon: "nc-icon nc-cloud-upload-94",
    //   component: IoTParent,
    //   layout: "/admin",
    // },
    {
      path: "/testing2",
      name: "Test 2",
      icon: "nc-icon nc-cloud-upload-94",
      component: test2,
      layout: "/admin",
    },
    {
      path: "/testing3",
      name: "Test 3",
      icon: "nc-icon nc-cloud-upload-94",
      component: Test3,
      layout: "/admin",
    },
    // {
    //   path: "/get-usb",
    //   name: "Get USB",
    //   icon: "nc-icon nc-cloud-upload-94",
    //   component: USB,
    //   layout: "/admin",
    // },
    // {
    //   path: "/tables",
    //   name: "Table List",
    //   icon: "nc-icon nc-tile-56",
    //   component: TableList,
    //   layout: "/admin",
    // },
  ];

const  ownerRoutes = [
    {
      path: "/sub-users",
      name: "Dashboard",
      icon: "nc-icon nc-bullet-list-67",
      component: SubUsers,
      layout: "/admin",
    },
    {
      path: "/mappro",
      name: "Map",
      icon: "nc-icon nc-pin-3",
      component: MainMap,
      layout: "/admin",
    },
    {
      path: "/pro-geo-fence",
      name: "Geo Fence Config",
      icon: "fas fa-map-marked",
      component: ProGeoFence,
      layout: "/admin",
    },
    {
      path: "/notifications",
      name: "Notifications",
      icon: "nc-icon nc-bell-55",
      component: Notifications,
      layout: "/admin",
    },
    {
      path: "/settings",
      name: "User Settings",
      icon: "nc-icon nc-settings-gear-65",
      component: UserSettings,
      layout: "/admin",
    },
    {
      path: "/read-csv-file",
      name: "Get Data From CSV file",
      icon: "nc-icon nc-cloud-upload-94",
      component: ReadCSVFile,
      layout: "/admin",
    }
  ];
export {adminRoutes,ownerRoutes}