import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import ReactSelect from "react-select";
import axios from "axios";
import { API_URL } from "variables/general";
function AddInventory() {
  useEffect(() => {
    getCustomFields();
  }, []);
  let todayDate = new Date();
  const [spinner, setSpinner] = useState(false);
  const [inventoryType, setInventoryType] = useState("bp");
  const [batteryPackId, setBatteryPackId] = useState("");
  const [batteryPackAlias, setBatteryPackAlias] = useState("");
  const [batteryPackStatus, setBatteryPackStatus] = useState("");
  const [batteryPackUpdateDate, setBatteryPackUpdateDate] = useState(
    todayDate.getFullYear() +
      "-" +
      todayDate.getMonth() +
      "-" +
      todayDate.getDate()
  );
  const [batteryPackDescription, setBatteryPackDescription] = useState("");
  const [batteryPackImage, setBatteryPackImage] = useState("NA");
  const [bpCustomFields, setBpCustomFields] = useState("");
  const getBatteryPackAlias = (e) => {
    setBatteryPackAlias(e.target.value);
  };
  const getBatteryPackId = (e) => {
    setBatteryPackId(e.target.value);
  };

  const getBatteryPackDescription = (e) => {
    setBatteryPackDescription(e.target.value);
  };

  const getBatteryPackImage = (e) => {
    setBatteryPackImage(e.target.files[0]);
  };

  const getBatteryPackUpdateDate = (e) => {
    setBatteryPackUpdateDate(e.target.value);
  };
  const getCustomFields = (second) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(API_URL + "/bms/template", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let status = result.status;
        if (status === true) {
          let data = result.data;
          if (data.bp) setBpCustomFields(data.bp.customField);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const submit = async (e) => {
    setSpinner(true);
    const formData = new FormData();
    if (e === "bp") {
      let data = {
        batteryPackAlias: batteryPackAlias,
        description: batteryPackDescription,
        status: batteryPackStatus,
        lastUpdated: batteryPackUpdateDate,
      };
      formData.append("invType", e);
      formData.append("bpId", batteryPackId);
      formData.append("data", JSON.stringify(data));
      formData.append("imageBp", batteryPackImage);
      let customObject = {};
      for (let i = 0; i < bpCustomFields.length; i++) {
        customObject[bpCustomFields[i].fieldName] =
          bpCustomFields[i].fieldDefaultValue;
      }
      formData.append("customField", JSON.stringify(customObject));
      if (!batteryPackId) {
        setSpinner(false);
        alert("Please Add Battery Pack Id");
      } else if (!batteryPackStatus) {
        setSpinner(false);
        alert("Please select Battery Pack status");
      } else {
        try {
          await axios.post(API_URL + "/bms/inventory_data", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + window.localStorage.getItem("token"),
            },
          });
          setSpinner(false);
          alert("Added Successfully!");
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const getStatus = (second) => {
    if (inventoryType === "bp") {
      let array = [];
      for (let index = 0; index < second.length; index++) {
        array[index] = second[index].value;
      }
      setBatteryPackStatus(array);
    }
  };
  const handleFormChangeBp = (index, event) => {
    let data = [...bpCustomFields];
    data[index].fieldDefaultValue = event.target.value;
    setBpCustomFields(data);
  };

  const bpStatusList = [
    { value: "Operational", label: "Operational" },
    { value: "Not Operational", label: "Not Operational" },
    { value: "Replaced", label: "Replaced" },
    { value: "Faulty", label: "Faulty" },
    { value: "In warranty", label: "In warranty" },
    { value: "Out Of warranty", label: "Out Of warranty" },
    { value: "Repaired", label: "Repaired" },
  ];
  return (
    <div>
      <h5 style={{ textAlign: "center" }}>Add Battery Pack</h5>
      <Card>
        <Row>
          <Col md="2" lg="2" xl="2" sm="2" xs="12"></Col>
          <Col md="8" lg="8" xl="8" sm="8" xs="12">
            <Form>
              <FormGroup row>
                <Label for="bpId" sm={4}>
                  <strong>Battery Pack Id</strong>
                </Label>
                <Col sm={8}>
                  <Input
                    id="bpId"
                    placeholder="Battery Pack Id"
                    type="text"
                    onChange={getBatteryPackId}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="alias" sm={4}>
                  <strong>Alias</strong>
                </Label>
                <Col sm={8}>
                  <Input
                    id="alias"
                    placeholder="Alias"
                    type="text"
                    onChange={getBatteryPackAlias}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="desc" sm={4}>
                  <strong>Description</strong>
                </Label>
                <Col sm={8}>
                  <Input
                    id="desc"
                    placeholder="Description"
                    type="textarea"
                    onChange={getBatteryPackDescription}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="bpimg" sm={4}>
                  <strong>Battery Pack Image</strong>
                </Label>
                <Col sm={8}>
                  <Input
                    id="bpimg"
                    placeholder="Battery Pack Image"
                    type="file"
                    onChange={getBatteryPackImage}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="bpId" sm={4}>
                  <strong>Status</strong>
                </Label>
                <Col sm={8}>
                  <ReactSelect
                    // defaultValue={[bpStatusList[0]]}
                    isMulti
                    name="colors"
                    options={bpStatusList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={getStatus}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="date" sm={4}>
                  <strong>Update Date</strong>
                </Label>
                <Col sm={8}>
                  <Input
                    id="date"
                    placeholder="Update Date"
                    type="date"
                    onChange={getBatteryPackUpdateDate}
                  />
                </Col>
              </FormGroup>
              {bpCustomFields
                ? bpCustomFields.map((val, index) => (
                    <FormGroup row>
                      <Label for={index} sm={4}>
                        <strong style={{ textTransform: "capitalize" }}>
                          {val.fieldName}
                        </strong>
                      </Label>
                      <Col sm={8}>
                        <Input
                          type="text"
                          id={index}
                          value={val.fieldValue}
                          placeholder={val.fieldValue}
                          onChange={(event) => handleFormChangeBp(index, event)}
                        ></Input>
                      </Col>
                    </FormGroup>
                  ))
                : ""}
            </Form>
            {spinner ? (
              <Spinner>Loading...</Spinner>
            ) : (
              <Button
                onClick={() => submit("bp")}
                style={{ width: "10rem" }}
                color="success"
              >
                Submit
              </Button>
            )}
          </Col>
          <Col md="2" lg="2" xl="2" sm="2" xs="12"></Col>
        </Row>
      </Card>
    </div>
  );
}
export default AddInventory;
