import React from "react";
import { useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Spinner,
} from "reactstrap";
import { API_URL } from "variables/general";
function AddTemplate() {
  const [fieldName, setFieldName] = useState("");
  const [fieldType, setFieldType] = useState("text");
  const [invType, setInvType] = useState("bp");
  const [fieldDefaultValue, setFieldDefaultValue] = useState("");
  const [responseApi, setResponseApi] = useState("");
  const [spinner, setSpinner] = useState(false);
  const getFieldName = (val) => {
    setFieldName(val.target.value);
  };
  const getInvType = (val) => {
    setInvType(val.target.value);
  };
  const getFieldType = (val) => {
    setFieldType(val.target.value);
  };
  const getFieldDefaultValue = (val) => {
    setFieldDefaultValue(val.target.value);
  };
  const addField = (second) => {
    setSpinner(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        invType: invType,
        customField: {
          fieldName: fieldName,
          fieldDefaultValue: fieldDefaultValue,
          fieldType: fieldType,
        },
      }),
    };
    fetch(API_URL + "/bms/template", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let status = result.status;
        if (status === true) alert(result.message);
        setSpinner(false);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <div>
      <Row>
        <Col>
          <Card>
            <div>
              <Row>
                <Col md="4" lg="4" xl="4" sm="2" xs="1"></Col>
                <Col md="4" lg="4" xl="4" sm="8" xs="10">
                  <Form>
                    <FormGroup>
                      {/* <Label for="invType">
                    <strong>Inventory Type : </strong>
                  </Label>
                  <Input
                    id="invType"
                    name="select"
                    type="select"
                    onChange={getInvType}
                  >
                    <option value="bp">Battery Pack</option>
                    <option value="bl">Balancer Card</option>
                    <option value="pd">Power Card</option>
                  </Input> */}
                      <Label for="fieldName">
                        <strong>Field Name : </strong>
                      </Label>
                      <Input
                        id="fieldName"
                        type="text"
                        onChange={getFieldName}
                      ></Input>
                      <Label for="defaultValue">
                        <strong>Default Value : </strong>
                      </Label>

                      <Input
                        id="defaultValue"
                        type="text"
                        onChange={getFieldDefaultValue}
                      ></Input>
                      <Label for="invType">
                        <strong>Field Type : </strong>
                      </Label>
                      <Input
                        id="invType"
                        name="select"
                        type="select"
                        onChange={getFieldType}
                      >
                        <option value="text">Text</option>
                        <option value="number">Number</option>
                      </Input>
                    </FormGroup>
                  </Form>
                  {spinner ? (
                    <Spinner>Loading...</Spinner>
                  ) : (
                    <Button onClick={addField}>Add Field</Button>
                  )}
                </Col>
                <Col md="4" lg="4" xl="4" sm="2" xs="1"></Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default AddTemplate;
