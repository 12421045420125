import React from "react";
import { useState } from "react";
import { useContext } from "react";
import {
  UncontrolledPopover,
  UncontrolledTooltip,
  PopoverHeader,
  PopoverBody,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Badge,
} from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { userDetailsContext } from "layouts/Admin";
import { subUserDetailsContext, notificationCountContext } from "layouts/Admin";

function TopBar(props) {
  const userData = useContext(userDetailsContext);
  const subUserDetails = useContext(subUserDetailsContext);
  const notificationCount = useContext(notificationCountContext);
  const [navigateBack, setNavigateBack] = useState(false);
  const logout = (second) => {
    window.localStorage.clear();
    window.location.href = "/";
  };
  // console.log(subUserDetails,
  //     notificationCount)
  let NotificationCount = 0;
  if (subUserDetails) {
    for (let i = 0; i < subUserDetails.length; i++)
      if (subUserDetails[i].unreadNotifications)
        NotificationCount =
          NotificationCount + subUserDetails[i].unreadNotifications;
  }
  const navigateToModeSelectionPage = (second) => {
    setNavigateBack(true);
  };
  if (navigateBack) return <Redirect to="/mode-selection-page"></Redirect>;
  else
    return (
      <div
        style={{
          justifyContent: "center",
          textAlign: "center",
          backgroundColor: "#32c85a",
          color: "white",
          fontWeight: "bolder",
          padding: "15px",
          display: "block",
          minHeight: "4rem",
        }}
      >
        <Row>
          <Col md="1" xl="1" lg="1" sm="1" xs="1">
            
          </Col>
          <Col md="8" xl="8" lg="8" sm="11" xs="11">
            <c
              style={{
                fontWeight: "bolder",
                fontSize: "25px",
                textTransform: "uppercase",
              }}
            >
              {userData ? userData.user.userProfile.name : ""}
            </c>
          </Col>
          <Col
            md="3"
            xl="3"
            lg="12"
            sm="12"
            xs="12"
            style={{ marginTop: "8px" }}
          >
            <c
              style={{
                margin: "5px 15px",
                backgroundColor: "#32c85a",
                fontWeight: "bolder",
                cursor: "pointer",
              }}
            >
              <i className="nc-icon lg  nc-single-02" id="PopoverLegacy"></i>
            </c>
            {window.localStorage.getItem("role") === "sub_owner" ? (
              <c
                style={{
                  margin: "5px 15px",
                  backgroundColor: "#32c85a",
                  fontWeight: "bolder",
                  cursor: "pointer",
                }}
              >
                <Link to="/admin/notifications" style={{ color: "white" }}>
                  <i className="nc-icon lg  nc-bell-55"></i>{" "}
                  <c
                    style={{
                      position: "absolute",
                      top: "1px",
                    }}
                  >
                    <Badge
                      style={{
                        backgroundColor: "red",
                        color: "white",
                      }}
                    >
                      {notificationCount
                        ? notificationCount.unreadNotifications === 0
                          ? ""
                          : notificationCount.unreadNotifications
                        : ""}
                    </Badge>
                  </c>
                </Link>
              </c>
            ) : (
              <c
                style={{
                  margin: "5px 15px",
                  backgroundColor: "#32c85a",
                  fontWeight: "bolder",
                  cursor: "pointer",
                }}
              >
                <Link to="/admin/notifications" style={{ color: "white" }}>
                  <i className="nc-icon lg  nc-bell-55"></i>
                </Link>{" "}
                <c
                  style={{
                    position: "absolute",
                    top: "-12px",
                  }}
                >
                  <Badge
                    style={{
                      backgroundColor: "red",
                      color: "white",
                    }}
                  >
                    {NotificationCount === 0 ? "" : "!"}
                  </Badge>
                </c>
              </c>
            )}
            <UncontrolledTooltip placement="bottom" target="switchCommMode">Switch Communication Mode</UncontrolledTooltip>
            <c
              style={{
                margin: "5px 15px",
                backgroundColor: "#32c85a",
                fontWeight: "bolder",
                cursor: "pointer",
              }}
              id='switchCommMode'
            >
              <i 
              class="fas fa-retweet"
              onClick={navigateToModeSelectionPage}
            ></i>
            </c>
            <c
              style={{
                margin: "5px 15px",
                backgroundColor: "#32c85a",
                fontWeight: "bolder",
                cursor: "pointer",
              }}
            >
              <i className="nc-icon lg  nc-button-power" onClick={logout}></i>
            </c>
          </Col>
        </Row>
        <UncontrolledPopover
          placement="bottom"
          target="PopoverLegacy"
          trigger="legacy"
        >
          <PopoverHeader>User Profile</PopoverHeader>
          <PopoverBody>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <Input
                      placeholder="Email"
                      disabled
                      defaultValue={
                        userData ? userData.user.userProfile.email : ""
                      }
                      type="email"
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>Company</label>
                    <Input
                      defaultValue={
                        userData ? userData.user.userProfile.company : ""
                      }
                      disabled
                      placeholder="Company"
                      type="text"
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>Contact</label>
                    <Input
                      defaultValue={
                        userData ? userData.user.userProfile.mobile : ""
                      }
                      placeholder="Contact Number"
                      type="number"
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </PopoverBody>
        </UncontrolledPopover>
      </div>
    );
}

export default TopBar;
