import React from "react";
import {
  AWS_REGION,
  AWS_IOT_ENDPOINT,
  AWS_CUSTOM_AUTH_USERNAME,
  AWS_CUSTOM_AUTH_AUTHORIZER_NAME,
  AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD,
  AWS_CUSTOM_AUTH_PASSWORD,
} from "../../views/MQTTSettings";
import { API_URL } from "variables/general";
import { useState } from "react";
import { Row, Col, Card, Container } from "reactstrap";
import { CSVLink } from "react-csv";
import { useEffect } from "react";
import { bmsIdContext } from "views/Dashboard";
import { useContext } from "react";
const iotsdk = require("aws-iot-device-sdk-v2");
const iot = iotsdk.iot;
const mqtt = iotsdk.mqtt;
let connection = null;
function ChargerData() {
  useEffect(() => {
    main();
    getChargerData();
  }, []);
  const IotId = useContext(bmsIdContext);
  const [chargerServerData, setChargerServerData] = useState('')
  const [chargerServerDataList, setChargerServerDataList] = useState('')
  const [showDownloadButton, setShowDownloadButton] = useState(false)
  const [selectedTime, setSelectedTime] = useState('')
  const CANBaudRate = ["125 Kbps", "250 Kbps", "500 Kbps", "1 Mbps"];
  const ChargingMode = ["None", "Pre-Charge", "CC", "CV", "Charging Completed"];
  const ChargerState = [
    "Ideal",
    "Charging",
    "Standby",
    "Battery Failure",
    "Charger Failure",
  ];

  const chargerFault = [
   "GFCI Fault",
    "Over Temperature Fault",
    "Over Current Fault",
    "Short Circuit Fault",
    "Over Voltage Fault",
    "Under Voltage Fault",
    "AC Under Voltage Fault",
    "AC Over Voltage Fault",
    "PFC Fault",
    "CAN Communication Failure",
    "Open Load Fault",
    "No Current Fault",
  ];
  const getSelectedTime = (val) => {
    setSelectedTime(val.target.value)
  }
  const getChargerDataList = (val) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        batteryPackId: IotId,
        tms: selectedTime,
        limit: 100000
      }),
    };
    fetch(API_URL + "/bms/charger_data", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let chargerValues = result.data.bmsChargerValues
        let sNo = 0
        chargerValues.forEach(element => {
          element["S No"] = ++sNo
          element["Date Time"] = element["tms"]
          element["Device Id"] = element["batteryPackId"]
          element["Charger Id"] = element["chargerId"]
          element["Firmware Version"] = element["firmwareVersion"]
          element["Hardware Version"] = element["hardwareVersion"]
          element["Charger Current(mA)"] = element["chargerCurrent"]
          element["Charger Voltage(mV)"] = element["chargerVoltage"]
          element["Charger AC Voltage(mV)"] = element["chargerAcVoltage"]
          element["Charger AC Current(mA)"] = element["chargerAcCurrent"]
          element["Charger State"] = ChargerState[element.chargerState]
          element["Charger CAN Baudrate"] = CANBaudRate[element.chargerCanBaudrate]
          element["Charging Mode"] = ChargingMode[element.chargingMode]
          delete element["chargerState"]
          delete element["chargerCanBaudrate"]
          delete element["chargingMode"]

          delete element["chargerId"]

          delete element["firmwareVersion"]

          delete element["hardwareVersion"]

          delete element["batteryPackId"]

          delete element["tms"]
          delete element["index"]
          let chargerFaultArray = JSON.parse(element.chargerFault)
          for (let index = 0; index < chargerFault.length; index++) {
            element[chargerFault[index]] = chargerFaultArray[index]
          }
          delete element["chargerFault"]
          let tempArray = JSON.parse(element.chargerTemp)
          let t = 0
          tempArray.forEach(temp => {
            element["Charger Temp " + ++t + "(°C))"] = temp
          })
          delete element["chargerTemp"]

          delete element["chargerCurrent"]
          delete element["chargerVoltage"]
          delete element["chargerAcVoltage"]
          delete element["chargerAcCurrent"]
          // element["chargerFault"] = element.chargerCanBaudrate
        });
        // console.log('--------chargerValues------------', chargerValues)


        setShowDownloadButton(true)
        setChargerServerDataList(chargerValues)
      })
      .catch((error) => console.log("error", error));
  };
  const getChargerData = (val) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        batteryPackId: IotId,
        // tms:"2023-10-30T12:56:00Z",
        latest: true
      }),
    };
    fetch(API_URL + "/bms/charger_data", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setChargerServerData(result.data.bmsChargerValues)
      })

      .catch((error) => console.log("error", error));
  };
  const chargerTemp = ["Temperature 1", "Temperature 2"];
  const [mqttConnectionStatus, setMqttConnectionStatus] =
    useState("Not Connected");
  const [chargerMqttData, setChargerMqttData] = useState('');
  async function connect_websocket() {
    return new Promise((resolve, reject) => {
      let config = iot.AwsIotMqttConnectionConfigBuilder.new_default_builder()
        .with_clean_session(true)
        .with_client_id(new Date())
        .with_endpoint(AWS_IOT_ENDPOINT)
        .with_custom_authorizer(
          AWS_CUSTOM_AUTH_USERNAME,
          AWS_CUSTOM_AUTH_AUTHORIZER_NAME,
          AWS_CUSTOM_AUTH_PASSWORD,
          AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD
        )
        .with_keep_alive_seconds(30)
        .build();

      // console.log("Connecting custom authorizer...");
      setMqttConnectionStatus("Connecting...");
      const client = new mqtt.MqttClient();

      connection = client.new_connection(config);
      connection.on("connect", (session_present) => {
        setMqttConnectionStatus("Connected");
        // console.log("Connected", session_present);
        resolve(connection);
      });
      connection.on("interrupt", (error) => {
        setMqttConnectionStatus("Connection Interrupted");
        // console.log(`Connection interrupted: error=${error}`);
      });
      connection.on("resume", (return_code, session_present) => {
        setMqttConnectionStatus("Connection Resumed");
        // console.log(
        //   `Resumed: rc: ${return_code} existing session: ${session_present}`
        // );
      });
      connection.on("disconnect", () => {
        setMqttConnectionStatus("Disconnected");
        main();
        // console.log("Disconnected");
      });
      connection.on("error", (error) => {
        setMqttConnectionStatus("Error Occured");
        reject(error);
      });
      connection.connect();
    });
  }
  async function main(val) {
    connect_websocket()
      .then((connection) => {
        connection.subscribe(
          `adtiot/bms1.0/pub/bt/${IotId}`,
          mqtt.QoS.AtLeastOnce,
          (topic, payload, dup, qos, retain) => {
            const decoder = new TextDecoder("utf8");
            let message = decoder.decode(new Uint8Array(payload));
            // console.log("message", message);
            let parsed = JSON.parse(message);
            if (parsed.evc === "479") {
              // console.log("parsed", parsed);
              setChargerMqttData(parsed);
            }
          }
        );

        // .then((publish) => {
        //     return connection.publish('publish.topic', "Hello World!", publish.qos);
        // });
      })
      .catch((reason) => {
        console.log(`Error while connecting: ${reason}`);
      });
  }
  if(chargerServerData){
  if (chargerMqttData) {
    return (
      <div>
        <Row>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Communication</div>
              <div className="card-foot">Live Data</div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Last Updated</div>
              <div className="card-foot">
                {chargerMqttData.tms.slice(11, 19)}{" "}
                {chargerMqttData.tms.slice(8, 10)}-
                {chargerMqttData.tms.slice(5, 7)}-
                {chargerMqttData.tms.slice(0, 4)}
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Download CSV</div>
              <div className="card-foot">
                <input type="datetime-local" onChange={getSelectedTime}></input>&ensp;
                <button onClick={getChargerDataList}>Submit</button>&ensp;
                {showDownloadButton ? <CSVLink data={chargerServerDataList} filename={selectedTime}>
                  <i class="fas fa-download"></i>
                </CSVLink> : ''}
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Model Rating</div>
              <div className="card-foot">
                {chargerMqttData.ext[0].chargerID}
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Firmware Version</div>
              <div className="card-foot">
                {chargerMqttData.ext[0].firmwareVersion}
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Hardware Version</div>
              <div className="card-foot">
                {chargerMqttData.ext[0].hardwareVersion}
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Charger Current</div>
              <div className="card-foot">
                {chargerMqttData.ext[0].chargerData.chargerCurrent / 1000} A
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Charger DC Voltage</div>
              <div className="card-foot">
                {chargerMqttData.ext[0].chargerData.chargerVoltage / 1000} V
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Charger AC Voltage</div>
              <div className="card-foot">
                {chargerMqttData.ext[0].chargerData.chargerACVoltage / 1000} V
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Charger AC Current</div>
              <div className="card-foot">
                {chargerMqttData.ext[0].chargerData.chargerACCurrent / 1000} V
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Charger State</div>
              <div className="card-foot">
                {ChargerState[chargerMqttData.ext[0].chargerData.chargerState]}
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Charger Charging Mode</div>
              <div className="card-foot">
                {ChargingMode[chargerMqttData.ext[0].chargerData.chargingMode]}
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Charger CAN Baud Rate</div>
              <div className="card-foot">
                {
                  CANBaudRate[
                  chargerMqttData.ext[0].chargerData.chargerCANBaudrate
                  ]
                }
              </div>
            </Card>
          </Col>
          {chargerMqttData.ext[0].chargerData.chargerFault.map((val, i) => (
            <Col xs="12" sm="12" md="4" lg="4" xl="4">
              <Card className="hero-cards">
                <div className="card-head">{chargerFault[i]}</div>
                <div className="card-foot" style={{ color: val === 1 ? 'red' : '' }}>{val === 1 ? "Yes" : "No"}</div>
              </Card>
            </Col>
          ))}
          {chargerMqttData.ext[0].chargerData.chargerTemp.map((val, i) => (
            <Col xs="12" sm="12" md="4" lg="4" xl="4">
              <Card className="hero-cards">
                <div className="card-head">{chargerTemp[i]}</div>
                <div className="card-foot">{val} °C</div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
  else if (chargerServerData.length > 0) {
    return (
      <div>
        <Row>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Communication</div>
              <div className="card-foot">Server Data</div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Last Updated</div>
              <div className="card-foot">
                {chargerServerData[0].tms.slice(11, 19)}{" "}
                {chargerServerData[0].tms.slice(8, 10)}-
                {chargerServerData[0].tms.slice(5, 7)}-
                {chargerServerData[0].tms.slice(0, 4)}
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Download CSV</div>
              <div className="card-foot">
                <input type="datetime-local" onChange={getSelectedTime}></input>&ensp;
                <button onClick={getChargerDataList}>Submit</button>&ensp;
                {showDownloadButton ? <CSVLink data={chargerServerDataList} filename={selectedTime}>
                  <i class="fas fa-download"></i>
                </CSVLink> : ''}
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Model Rating</div>
              <div className="card-foot">
                {chargerServerData[0].chargerId}
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Firmware Version</div>
              <div className="card-foot">
                {chargerServerData[0].firmwareVersion}
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Hardware Version</div>
              <div className="card-foot">
                {chargerServerData[0].hardwareVersion}
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Charger Current</div>
              <div className="card-foot">
                {chargerServerData[0].chargerCurrent / 1000} A
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Charger DC Voltage</div>
              <div className="card-foot">
                {chargerServerData[0].chargerVoltage / 1000} V
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Charger AC Voltage</div>
              <div className="card-foot">
                {chargerServerData[0].chargerAcVoltage / 1000} V
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Charger AC Current</div>
              <div className="card-foot">
                {chargerServerData[0].chargerAcCurrent / 1000} V
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Charger State</div>
              <div className="card-foot">
                {ChargerState[chargerServerData[0].chargerState]}
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Charger Charging Mode</div>
              <div className="card-foot">
                {ChargingMode[chargerServerData[0].chargingMode]}
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <Card className="hero-cards">
              <div className="card-head">Charger CAN Baud Rate</div>
              <div className="card-foot">
                {
                  CANBaudRate[
                  chargerServerData[0].chargerCanBaudrate
                  ]
                }
              </div>
            </Card>
          </Col>
          {JSON.parse(chargerServerData[0].chargerFault).map((val, i) => (
            <Col xs="12" sm="12" md="4" lg="4" xl="4">
              <Card className="hero-cards">
                <div className="card-head">{chargerFault[i]}</div>
                <div className="card-foot" style={{ color: val === 1 ? 'red' : '' }}>{val === 1 ? "Yes" : "No"}</div>
              </Card>
            </Col>
          ))}
          {JSON.parse(chargerServerData[0].chargerTemp).map((val, i) => (
            <Col xs="12" sm="12" md="4" lg="4" xl="4">
              <Card className="hero-cards">
                <div className="card-head">{chargerTemp[i]}</div>
                <div className="card-foot">{val} °C</div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
  else if (chargerServerData.length === 0) {
    return <div>No Data yet</div>
  }}
  else return <div>Loading...</div>
}

export default ChargerData;
