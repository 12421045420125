import React from "react";
import { Line } from "react-chartjs-2";
import { Row, Col, Card } from "reactstrap";

function ParallelGraphs(props) {
  const serverData = props.serverData;
  // console.log("serverData", serverData);
  let grouped = [];
  if (serverData) {
    let batteryPackNoArr = [];
    let parallelArr = [];
    for (let i = 0; i < serverData.parallelValue.length; i++) {
      let batteryPackNo = serverData.parallelValue[i].batteryPackNo;
      if (!batteryPackNoArr.includes(batteryPackNo))
        batteryPackNoArr.push(batteryPackNo);
    }
    for (let j = 0; j < batteryPackNoArr.length; j++) {
      let batteryPackNo = batteryPackNoArr[j];
      for (let i = 0; i < serverData.parallelValue.length; i++) {
        if (batteryPackNo === serverData.parallelValue[i].batteryPackNo)
          parallelArr.push(serverData.parallelValue[i]);
      }
    }
    grouped = Object.values(
      parallelArr.reduce((acc, item) => {
        // Append the item to the array for each country
        acc[item.batteryPackNo] = [...(acc[item.batteryPackNo] || []), item];
        return acc;
      }, {})
    );
    // console.log("grouped", grouped);
    // let arrayLen=[]
    // arrayLen[index]=grouped[index].length
    // }
    let colorArray = [
      "#5D8AA8",
      "#FFBF00",
      "#E32636",
      "#A4C639",
      "#FBCEB1",
      "#7FFFD4",
      "#4B5320",
      "#3B444B",
      "#E9D66B",
      "#6D351A",
      "#007FFF",
      "#0000FF",
      "#DE5D83",
      "#CC0000",
      "#66FF00",
      "#C32148",
      "#FF007F",
      "#08E8DE",
      "#004225",
      "#800020",
      "#FF0800",
      "#FF3800",
      "#8B008B",
      "#03C03C",
      "#C04000",
      "#191970",
      "#77DD77",
    ];
    let packVoltageArr = [];
    let socArr = [];
    let avgPackCurrentArr = [];
    let instantPackCurrentArr = [];
    let instantPackPowerArr = [];
    let maxPackCurrentArr = [];
    let tmsArr = [];
    let tmsSmallest=[]
    // Use a loop to create and add n empty arrays to the outer array
    for (let i = 0; i < grouped.length; i++) {
      packVoltageArr.push([]);
      socArr.push([]);
      avgPackCurrentArr.push([]);
      instantPackCurrentArr.push([]);
      instantPackPowerArr.push([]);
      maxPackCurrentArr.push([]);
      tmsArr.push([]);
    }
    for (let ind = 0; ind < grouped.length; ind++) {
      for (let ind1 = 0; ind1 < grouped[ind].length; ind1++) {
        packVoltageArr[ind][ind1] = grouped[ind][ind1].packVoltage;
        socArr[ind][ind1] = grouped[ind][ind1].soc;
        avgPackCurrentArr[ind][ind1] = grouped[ind][ind1].avgPackCurrent;
        instantPackCurrentArr[ind][ind1] =
          grouped[ind][ind1].instantPackCurrent;
        instantPackPowerArr[ind][ind1] = grouped[ind][ind1].instantPackPower;
        maxPackCurrentArr[ind][ind1] = grouped[ind][ind1].maxPackCurrent;
        tmsArr[ind][ind1] =
          grouped[ind][ind1].tms.slice(11, 19) +
          " " +
          grouped[ind][ind1].tms.slice(8, 10) +
          "/" +
          grouped[ind][ind1].tms.slice(5, 7) +
          "/" +
          grouped[ind][ind1].tms.slice(0, 4);
      }
    }
    for (let t=0;t<tmsArr.length;t++){
      let smallest = tmsArr[0].length
      tmsSmallest=tmsArr[0]
      if(smallest > tmsArr[t].length){
        smallest = tmsArr[t].length
        tmsSmallest = tmsArr[t]
      }
    }
    
    // console.log("asdasdasd  packVoltage", packVoltageArr);
    let PackVoltageDataSets = [];
    let socDataSets = [];
    let avgPackCurrentDataSets = [];
    let instantPackCurrentDataSets = [];
    let instantPackPowerDataSets = [];
    let maxPackCurrentDataSets = [];
    for (let p = 0; p < packVoltageArr.length; p++) {
      PackVoltageDataSets[p] = {
        label: grouped[p][0].batteryPackNo,
        data: packVoltageArr[p],
        fill: false,
        borderColor: colorArray[p],
        backgroundColor: "transparent",
        pointBorderColor: colorArray[p],
        pointRadius: 1,
        pointHoverRadius: 10,
        pointBorderWidth: 0,
      };
      socDataSets[p] = {
        label: grouped[p][0].batteryPackNo,
        data: socArr[p],
        fill: false,
        borderColor: colorArray[p],
        backgroundColor: "transparent",
        pointBorderColor: colorArray[p],
        pointRadius: 1,
        pointHoverRadius: 10,
        pointBorderWidth: 0,
      };
      avgPackCurrentDataSets[p] = {
        label: grouped[p][0].batteryPackNo,
        data: avgPackCurrentArr[p],
        fill: false,
        borderColor: colorArray[p],
        backgroundColor: "transparent",
        pointBorderColor: colorArray[p],
        pointRadius: 1,
        pointHoverRadius: 10,
        pointBorderWidth: 0,
      };
      instantPackCurrentDataSets[p] = {
        label: grouped[p][0].batteryPackNo,
        data: instantPackCurrentArr[p],
        fill: false,
        borderColor: colorArray[p],
        backgroundColor: "transparent",
        pointBorderColor: colorArray[p],
        pointRadius: 1,
        pointHoverRadius: 10,
        pointBorderWidth: 0,
      };
      instantPackPowerDataSets[p] = {
        label: grouped[p][0].batteryPackNo,
        data: instantPackPowerArr[p],
        fill: false,
        borderColor: colorArray[p],
        backgroundColor: "transparent",
        pointBorderColor: colorArray[p],
        pointRadius: 1,
        pointHoverRadius: 10,
        pointBorderWidth: 0,
      };
      maxPackCurrentDataSets[p] = {
        label: grouped[p][0].batteryPackNo,
        data: maxPackCurrentArr[p],
        fill: false,
        borderColor: colorArray[p],
        backgroundColor: "transparent",
        pointBorderColor: colorArray[p],
        pointRadius: 1,
        pointHoverRadius: 10,
        pointBorderWidth: 0,
      };
    }
    const PackVoltageChart = {
      data: {
        labels: tmsSmallest,
        datasets: PackVoltageDataSets,
      },
      options: {
        tooltips: {
          xAlign: "center",
          mode: "label",
          multiKeyBackground: "rgba(256,256,256,0.7)",
          backgroundColor: "rgba(256,256,256,0.7)",
          bodyFontColor: "#000",
        },
        elements: {
          line: {
            tension: 0.3,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
              },
            },
          ],
        },
        legend: {
          display: true,
          labels: {
            boxWidth: 10,
            fontSize: 10,
          },
        },
      },
    };
    const socDataChart = {
      data: {
        labels: tmsSmallest,
        datasets: socDataSets,
      },
      options: {
        tooltips: {
          xAlign: "center",
          mode: "label",
          multiKeyBackground: "rgba(256,256,256,0.7)",
          backgroundColor: "rgba(256,256,256,0.7)",
          bodyFontColor: "#000",
        },
        elements: {
          line: {
            tension: 0.3,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
              },
            },
          ],
        },
        legend: {
          display: true,
          labels: {
            boxWidth: 10,
            fontSize: 10,
          },
        },
      },
    };
    const avgPackCurrentDataChart = {
      data: {
        labels: tmsSmallest,
        datasets: avgPackCurrentDataSets,
      },
      options: {
        tooltips: {
          xAlign: "center",
          mode: "label",
          multiKeyBackground: "rgba(256,256,256,0.7)",
          backgroundColor: "rgba(256,256,256,0.7)",
          bodyFontColor: "#000",
        },
        elements: {
          line: {
            tension: 0.3,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
              },
            },
          ],
        },
        legend: {
          display: true,
          labels: {
            boxWidth: 10,
            fontSize: 10,
          },
        },
      },
    };
    const instantPackCurrentDataChart = {
      data: {
        labels: tmsSmallest,
        datasets: instantPackCurrentDataSets,
      },
      options: {
        tooltips: {
          xAlign: "center",
          mode: "label",
          multiKeyBackground: "rgba(256,256,256,0.7)",
          backgroundColor: "rgba(256,256,256,0.7)",
          bodyFontColor: "#000",
        },
        elements: {
          line: {
            tension: 0.3,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
              },
            },
          ],
        },
        legend: {
          display: true,
          labels: {
            boxWidth: 10,
            fontSize: 10,
          },
        },
      },
    };
    const instantPackPowerDataChart = {
      data: {
        labels: tmsSmallest,
        datasets: instantPackPowerDataSets,
      },
      options: {
        tooltips: {
          xAlign: "center",
          mode: "label",
          multiKeyBackground: "rgba(256,256,256,0.7)",
          backgroundColor: "rgba(256,256,256,0.7)",
          bodyFontColor: "#000",
        },
        elements: {
          line: {
            tension: 0.3,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
              },
            },
          ],
        },
        legend: {
          display: true,
          labels: {
            boxWidth: 10,
            fontSize: 10,
          },
        },
      },
    };
    const maxPackCurrentDataChart = {
      data: {
        labels: tmsSmallest,
        datasets: maxPackCurrentDataSets,
      },
      options: {
        tooltips: {
          xAlign: "center",
          mode: "label",
          multiKeyBackground: "rgba(256,256,256,0.7)",
          backgroundColor: "rgba(256,256,256,0.7)",
          bodyFontColor: "#000",
        },
        elements: {
          line: {
            tension: 0.3,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
              },
            },
          ],
        },
        legend: {
          display: true,
          labels: {
            boxWidth: 10,
            fontSize: 10,
          },
        },
      },
    };
    return (
      <div>
        <Row>
          <Col md="6" lg="6" xl="6" sm="12" xs="12">
            <Card>
              <h6>Pack Voltage</h6>
              <Line
                data={PackVoltageChart.data}
                options={PackVoltageChart.options}
              />
            </Card>
          </Col>
          <Col md="6" lg="6" xl="6" sm="12" xs="12">
            <Card>
              <h6>SOC</h6>
              <Line data={socDataChart.data} options={socDataChart.options} />
            </Card>
          </Col>
          <Col md="6" lg="6" xl="6" sm="12" xs="12">
            <Card>
              <h6>Average Pack Current</h6>
              <Line
                data={avgPackCurrentDataChart.data}
                options={avgPackCurrentDataChart.options}
              />
            </Card>
          </Col>
          <Col md="6" lg="6" xl="6" sm="12" xs="12">
            <Card>
              <h6>Instant Pack Current</h6>
              <Line
                data={instantPackCurrentDataChart.data}
                options={instantPackCurrentDataChart.options}
              />
            </Card>
          </Col>
          <Col md="6" lg="6" xl="6" sm="12" xs="12">
            <Card>
              <h6>Instant Pack Power</h6>
              <Line
                data={instantPackPowerDataChart.data}
                options={instantPackPowerDataChart.options}
              />
            </Card>
          </Col>
          <Col md="6" lg="6" xl="6" sm="12" xs="12">
            <Card>
              <h6>Max Pack Current</h6>
              <Line
                data={maxPackCurrentDataChart.data}
                options={maxPackCurrentDataChart.options}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  } else return <div>Loading...</div>;
}

export default ParallelGraphs;
