import React from "react";
import { Card, Row, Col, Spinner, Table } from "reactstrap";

function Faults(props) {
  if (props) {
    let Fault = props.Fault;
    const FaultArray = [
      "No Fault",
      "Over Pack Voltage Fault",
      "Over Pack Voltage",
      "Under Pack Voltage Fault",
      "Over OCD1 Current",
      "Over OCD2 Current",
      "Over Charge Current",
      "Over Discharge Current",
      "Short Circuit",
      "Cell Open Wire Fault",
      "Voltage Reference Fault",
      "Voltage Regulator Fault",
      "Temperature Multiplexer Error",
      "Under Temperature Fault",
      "Load Over Temperature",
      "Oscillator Fault",
      "Watchdog Timeout Fault",
      "Under Cell Voltage Fault",
      "Over Cell Voltage Fault",
      "Open Wire Fault",
      "Over Temperature Fault",
      "Cell Unbalance Pro",
      "SCD Latch High",
      "SPI Communication Fault",
      "I2C Communication Fault",
      "MCU Reset Fault",
      "Data CRC Error Fault",
      "Data Ready Fault",
      "Charger Authentication Failed",
      "Thermal Runaway Due To Temperature",
      "Thermal Runaway Due To Voltage",
      "MOSFET Drive Voltage Fault",
      "Reserve Condition Reached",
	"Battery Empty Reached",
	"Battery Full Charged Reached"
    ];
    return (
      <div>
        <div className="card-head">
          Last Fault
          {Fault ? (
            <div className="card-foot">{FaultArray[Fault]}</div>
          ) : Fault === 0 ? (
            <div className="card-foot">{FaultArray[Fault]}</div>
          ) : (
            <div>
              <Spinner />
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Spinner>Loading...</Spinner>
      </div>
    );
  }
}

export default Faults;
