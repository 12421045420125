import { API_URL } from "variables/general";
import { CSVLink } from "react-csv";
import React, { useContext, useState, useEffect } from "react";
import { bmsIdContext } from "./Dashboard";

function CSVDownload(props) {
  const [dataItem, setDataItem] = useState(null);
  const [locationValues, setLocationValues] = useState(null);
  // const [parallelDownload, setParallelDownload] = useState('');
  const [bmsDataFileName, setBmsDataFileName] = useState(null);
  const [bmsLocationFileName, setBmsLocationFileName] = useState(null);
  const [newData, setNewData] = useState(null);
  const [showSubmit, setShowSubmit] = useState(false);
  const [bTms, setbTms] = useState(null);
  const [eTms, seteTms] = useState(null);
  let showDownloadButtonForParallel
  // let [submitDisabled, setSubmitDisabled] = useState(true);
  const [serverData, setServerData] = useState("");
  const bmsId = useContext(bmsIdContext);
  const sTms = props.sTms
  const getbTms = (event) => {
    // setNewData(false);
    console.log(event, event)

    setbTms(event);
  };

  const FaultArray = [
    "No Fault",
    "Over Pack Voltage Fault",
    "Over Pack Voltage",
    "Under Pack Voltage Fault",
    "Over OCD1 Current",
    "Over OCD2 Current",
    "Over Charge Current",
    "Over Discharge Current",
    "Short Circuit",
    "Cell Open Wire Fault",
    "Voltage Reference Fault",
    "Voltage Regulator Fault",
    "Temperature Multiplexer Error",
    "Under Temperature Fault",
    "Load Over Temperature",
    "Oscillator Fault",
    "Watchdog Timeout Fault",
    "Under Cell Voltage Fault",
    "Over Cell Voltage Fault",
    "Open Wire Fault",
    "Over Temperature Fault",
    "Cell Unbalance Pro",
    "SCD Latch High",
    "SPI Communication Fault",
    "I2C Communication Fault",
    "MCU Reset Fault",
    "Data CRC Error Fault",
    "Data Ready Fault",
    "Charger Authentication Failed",
    "Thermal Runaway Due To Temperature",
    "Thermal Runaway Due To Voltage",
    "MOSFET Drive Voltage Fault",
    "Reserve Condition Reached",
    "Battery Empty Reached",
    "Battery Full Charged Reached",
  ];
  // const getDataLog = () => {
  //     setShowSubmit(true);
  //     var myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");
  //     myHeaders.append(
  //       "Authorization",
  //       "Bearer " + window.localStorage.getItem("token")
  //     );
  //     var requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       redirect: "follow",
  //       body: JSON.stringify({
  //         batteryPackId: bmsId,
  //         tms: sTms,
  //       }),
  //     };
  //     fetch(API_URL + "/bms/v2/bms_values", requestOptions)
  //       .then((response) => response.json())
  //       .then((result) => {
  //         setDataItem(result.data.bmsValues);
  //         download_data_logs(result.data.bmsValues);
  //       })
  //       .catch((error) => console.log("error", error));
  // };
  const getLocationValues = () => {
    if (bTms === null) alert("Enter Valid Start Date");
    // else if (eTms === null) alert("Enter Valid End Date");
    else {
      setShowSubmit(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + window.localStorage.getItem("token")
      );
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({
          did: bmsId,
          tms_gt: bTms.slice(0, 11) + '00:00:01Z',
          tms_lt: bTms.slice(0, 11) + '23:59:59Z'
        }),
      };

      fetch(API_URL + "/bms/bms_location", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setLocationValues(result.data.bmsLocation)
          setShowSubmit(false);
        }).catch((error) => console.log("error", error));
    }
  };
  const getParallelLogs = () => {
    if (bTms === null) alert("Enter Valid Start Date");
    // else if (eTms === null) alert("Enter Valid End Date");
    else {
      setShowSubmit(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + window.localStorage.getItem("token")
      );
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({
          batteryPackId: bmsId,
          bTms: bTms,
          eTms: bTms.slice(0, 11) + '23:59:59Z'
        }),
      };
      fetch(API_URL + "/bms/parallel_data", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setServerData(result.data);
          parallelDataRecv(result.data)
        }).catch((error) => console.log("error", error));
    }
  };

  function getDownloadFiles() {
    // getDataLog();
    getLocationValues()
    // getParallelLogs()
  }
  let grouped = [];
  function parallelDataRecv(val) {
    let batteryPackNoArr = [];
    let parallelArr = [];
    for (let i = 0; i < val.parallelValue.length; i++) {
      let batteryPackNo = val.parallelValue[i].batteryPackNo;
      if (!batteryPackNoArr.includes(batteryPackNo))
        batteryPackNoArr.push(batteryPackNo);
    }

    for (let j = 0; j < batteryPackNoArr.length; j++) {
      let batteryPackNo = batteryPackNoArr[j];
      for (let i = 0; i < val.parallelValue.length; i++) {
        if (batteryPackNo === val.parallelValue[i].batteryPackNo)
          parallelArr.push(val.parallelValue[i]);
      }
    }
    grouped = Object.values(
      parallelArr.reduce((acc, item) => {
        // Append the item to the array for each country
        acc[item.batteryPackNo] = [...(acc[item.batteryPackNo] || []), item];
        return acc;
      }, {})
    );
    for (let l = 0; l < grouped.length; l++) {
      for (let m = 0; m < grouped[l].length; m++) {
        grouped[l][m]["Pack Voltage (mV)"] = grouped[l][m]["packVoltage"];
        grouped[l][m]["BatteryPackNo"] = grouped[l][m]["batteryPackNo"];
        grouped[l][m]["SoC (%)"] = grouped[l][m]["soc"];
        grouped[l][m]["Average Pack Current (mA)"] = grouped[l][m]["avgPackCurrent"];
        grouped[l][m]["Instant Pack Current (mA)"] = grouped[l][m]["instantPackCurrent"];
        grouped[l][m]["Max Pack Current (mA)"] = grouped[l][m]["maxPackCurrent"];
        grouped[l][m]["Parallel CAN Ignition"] = grouped[l][m]["parallelCanIgnition"];
        grouped[l][m]["Charge Bit"] = grouped[l][m]["chargeBit"];
        grouped[l][m]["Discharge Bit"] = grouped[l][m]["dischargeBit"];
        grouped[l][m]["Full Charger Bit"] = grouped[l][m]["fullChargerBit"];
        grouped[l][m]["High Cell Bit"] = grouped[l][m]["highCellBit"];
        grouped[l][m]["High Pack Bit"] = grouped[l][m]["highPackBit"];
        grouped[l][m]["Ignition State"] = grouped[l][m]["ignitionState"];
        grouped[l][m]["Last Fault"] = grouped[l][m]["lastFault"];
        grouped[l][m]["Last Fault"] = FaultArray[grouped[l][m]["Last Fault"]]

        grouped[l][m]["Low Cell Bit"] = grouped[l][m]["lowCellBit"];
        grouped[l][m]["Pack Current State"] = grouped[l][m]["packCurrentState"];
        grouped[l][m]["Instant Pack Power (W)"] = grouped[l][m]["instantPackPower"];
        delete grouped[l][m]["packVoltage"];
        delete grouped[l][m]["batteryPackNo"];
        delete grouped[l][m]["soc"];
        delete grouped[l][m]["avgPackCurrent"];
        delete grouped[l][m]["instantPackCurrent"];
        delete grouped[l][m]["maxPackCurrent"];
        delete grouped[l][m]["parallelCanIgnition"];
        delete grouped[l][m]["chargeBit"];
        delete grouped[l][m]["dischargeBit"];
        delete grouped[l][m]["fullChargerBit"];
        delete grouped[l][m]["highCellBit"];
        delete grouped[l][m]["highPackBit"];
        delete grouped[l][m]["ignitionState"];
        delete grouped[l][m]["lastFault"];
        delete grouped[l][m]["lowCellBit"];
        delete grouped[l][m]["packCurrentState"];
        delete grouped[l][m]["instantPackPower"];
      }
    }

    showDownloadButtonForParallel = grouped.map((val, i) => (
      <button>
        <CSVLink
          data={val}
          filename={`${bmsId}_${val[0]["BatteryPackNo"]}`}
        >
          Download for Battery Pack Number: {val[0]["BatteryPackNo"]}
        </CSVLink>
      </button>
    ))
    //  setParallelDownload(showDownloadButtonForParallel)    
  }
  const download_data_logs = (dataItem) => {
    let updated_array_item_download = [];
    dataItem.forEach((element) => {
      let ind_item;
      ind_item = element;
      let cell_temp = JSON.parse(element.cellTemp);

      for (let i = 0; i < cell_temp.length; i++) {
        ind_item["Cell Temp " + (i + 1)] = cell_temp[i];
      }

      delete ind_item["cellTemp"];
      let cell_voltage = JSON.parse(element.cellVoltage);
      let maxCellVoltage = cell_voltage[0];
      let minCellVoltage = cell_voltage[0];
      let averageCellVoltage;
      let sumOfCellVoltages = 0;
      for (let i = 0; i < cell_voltage.length; i++) {
        sumOfCellVoltages = sumOfCellVoltages + cell_voltage[i];
        ind_item["Cell Voltage " + (i + 1)] = cell_voltage[i];
        if (maxCellVoltage < cell_voltage[i]) {
          maxCellVoltage = cell_voltage[i];
        }
        if (minCellVoltage > cell_voltage[i]) {
          minCellVoltage = cell_voltage[i];
        }
      }
      ind_item["maxCellVoltage"] = maxCellVoltage;
      ind_item["minCellVoltage"] = minCellVoltage;
      averageCellVoltage = sumOfCellVoltages / cell_voltage.length;
      ind_item["averageCellVoltage"] = averageCellVoltage;
      let cellVoltageDifference = maxCellVoltage - minCellVoltage;
      ind_item["cellVoltageDifference"] = cellVoltageDifference;
      delete ind_item["cellVoltage"];
      let ic_temp = JSON.parse(element.icTemp);
      for (let i = 0; i < ic_temp.length; i++) {
        ind_item["IC Temp " + (i + 1)] = ic_temp[i];
      }
      delete ind_item["icTemp"];
      let pcb_temp = JSON.parse(element.pcbTemp);
      for (let i = 0; i < pcb_temp.length; i++) {
        ind_item["pcb temp " + (i + 1)] = pcb_temp[i];
      }
      delete ind_item["pcbTemp"];
      let heatsink = JSON.parse(element.heatsink);
      for (let i = 0; i < heatsink.length; i++) {
        ind_item["Temp Cell " + (i + 1)] = heatsink[i];
      }
      delete ind_item["heatsink"];
      updated_array_item_download.push(ind_item);
    });
    for (let k = 0; k < updated_array_item_download.length; k++) {
      updated_array_item_download[k]["lastFault"] = FaultArray[updated_array_item_download[k]["lastFault"]]
    }
    // console.log(updated_array_item_download);
    setNewData(updated_array_item_download);
    setShowSubmit(false);
    setBmsLocationFileName(props.id + "_BMS-Location_" + bTms);
    setBmsDataFileName(props.id + "_BMS-Data_" + bTms);
  };
  return (
    <div className="main-margin">
      <strong>Start Date: </strong>
      <input
        type="datetime-local"
        onChange={(event) => getbTms(event.target.value)}
        value={bTms}
        placeholder="Start Date"
      ></input>
      &ensp;
      <button onClick={getDownloadFiles}>
        {showSubmit ? "Loading ..." : "Submit"}
      </button>
      &ensp;
      <br />
      {newData ? (
        <>
          <button>
            <CSVLink data={newData} filename={bmsDataFileName}>
              Download BMS Data CSV
            </CSVLink>
          </button>
        </>
      ) : (
        <></>
      )}
      {locationValues ? (
        <>
          <button>
            <CSVLink data={locationValues} filename={bmsLocationFileName}>
              Download Location Data CSV
            </CSVLink>
          </button></>
      ) : (
        <></>
      )}
    </div>
  );
}
export default CSVDownload;
