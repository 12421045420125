import React, { useEffect, useState } from 'react'
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2'
import { Card, Col, Row } from 'reactstrap'
import { API_URL } from 'variables/general'

function Test3() {
    useEffect(() => {
        getAnalysisData()
    }, [])
    const date = ['2024-05-01T00:00:00Z']
    const id = 'ABT865577074929713'
    const [analysisData, setAnalysisData] = useState()
    function getAnalysisData(payload) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Bearer " + window.localStorage.getItem('token')
        );
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(API_URL + "/bms/v2/analysis?date=" + date + "&batteryPackId=" + id, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setAnalysisData(result.data.analysisValues)
            })
            .catch((error) => console.log("error", error));
    }
    const FaultArray = [
        "No Fault",
        "Over Pack Voltage Fault",
        "Over Pack Voltage",
        "Under Pack Voltage Fault",
        "Over OCD1 Current",
        "Over OCD2 Current",
        "Over Charge Current",
        "Over Discharge Current",
        "Short Circuit",
        "Cell Open Wire Fault",
        "Voltage Reference Fault",
        "Voltage Regulator Fault",
        "Temperature Multiplexer Error",
        "Under Temperature Fault",
        "Load Over Temperature",
        "Oscillator Fault",
        "Watchdog Timeout Fault",
        "Under Cell Voltage Fault",
        "Over Cell Voltage Fault",
        "Open Wire Fault",
        "Over Temperature Fault",
        "Cell Unbalance Pro",
        "SCD Latch High",
        "SPI Communication Fault",
        "I2C Communication Fault",
        "MCU Reset Fault",
        "Data CRC Error Fault",
        "Data Ready Fault",
        "Charger Authentication Failed",
        "Thermal Runaway Due To Temperature",
        "Thermal Runaway Due To Voltage",
        "MOSFET Drive Voltage Fault",
        "Reserve Condition Reached",
        "Battery Empty Reached",
        "Battery Full Charged Reached"
    ]
    if (analysisData) {
        const labelObj = Object.keys(analysisData[0].analysis.bmsfaultCounts)
        let updatedLabel = []
        labelObj.forEach(element => {
            updatedLabel.push(FaultArray[parseInt(element)])
        });
        const data = Object.values(analysisData[0].analysis.bmsfaultCounts)
        const faultChart = {
            data: (canvas) => {
                return {
                    labels: updatedLabel,
                    datasets: [
                        {
                            label: "Faults",
                            pointRadius: 0,
                            pointHoverRadius: 0,
                            backgroundColor: ['#36384c', '#613a43', '#97a989', '#eee3d6', '#e3b9b3',],
                            //   borderWidth: 1,
                            //   borderColor:'yellow',
                            data: data,
                        },
                    ],
                };
            },
            options: {
                legend: {
                    // display: false,
                },
                pieceLabel: {
                    render: "percentage",
                    fontColor: ["white"],
                    precision: 2,
                },
                tooltips: {
                    // enabled: false,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                display: false,
                            },
                            gridLines: {
                                drawBorder: false,
                                zeroLineColor: "transparent",
                                color: "rgba(255,255,255,0.00)",
                            },
                        },
                    ],
                    xAxes: [
                        {
                            barPercentage: 1,
                            gridLines: {
                                // drawBorder: false,
                                color: "rgba(255,255,255,0.0)",
                                zeroLineColor: "transparent",
                            },
                            ticks: {
                                display: false,
                            },
                        },
                    ],
                },
            },
        };

        return (
            <div>
                <Row>
                    <Col md='4'>faultChart<Pie data={faultChart.data} options={faultChart.options} /></Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Peak Current Charging </div>
                            <div className="card-foot">
                                {analysisData[0].analysis.peakCurrentCharging} mA
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Peak Current Discharging </div>
                            <div className="card-foot">
                                {analysisData[0].analysis.peakCurrentDischarging} mA
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Peak Current Regen </div>
                            <div className="card-foot">
                                {analysisData[0].analysis.peakRegen} mA
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Lowest Voltage Reached</div>
                            <div className="card-foot">
                                {analysisData[0].analysis.lowestVoltage} mV
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Peak Power Charging</div>
                            <div className="card-foot">
                                {analysisData[0].analysis.peakPowerCharging} W
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Peak Power Discharging</div>
                            <div className="card-foot">
                                {analysisData[0].analysis.peakPowerDischarging} W
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Max Cell Temperature</div>
                            <div className="card-foot">
                                {analysisData[0].analysis.maxCellTemp} °C
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Max PDU Temperature</div>
                            <div className="card-foot">
                                {analysisData[0].analysis.maxPduTemp} °C
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
    else return <div>loading..</div>
}

export default Test3