import React, { useEffect, useState, useContext } from 'react'
import { bmsIdContext } from "../../views/Dashboard";
import BmsBootLogs from './BmsBootLogs';
import IoTLogs from './IoTLogs';
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { API_URL } from 'variables/general';
function BootLogsTab() {
    useEffect(() => fetchBmsValues(), [])
    const id = useContext(bmsIdContext);
    const [iconPills, setIconPills] = useState("1");
    const [noOfSamples, setNoOfSamples] = useState(20)
    const [tms, setTms] = useState('')
    const [bmsBootData, setBmsBootData] = useState()
    // const [iotBootData, setIotBootData] = useState()
    const [showRecent, setShowRecent] = useState('Recent ')
    const [loader, setLoader] = useState(true)
    const fetchBmsValues = (val, lt) => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Bearer " + window.localStorage.getItem("token")
        );
        let requestOptions;
      if (lt === "LT") {
          requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
              batteryPackId: id,
              limit: parseInt(noOfSamples),
              tms_lt: val,
              boot:'bms'
            }),
          };
        
      } else 
        if (lt=== 'TMS') {
          requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
              id: id,
              limit: parseInt(noOfSamples),
              tms: val,
              boot:'bms'
            }),
          };
        } else
          requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
              id: id,
              limit: parseInt(noOfSamples),
              boot:'bms'
            }),
            redirect: "follow",
          };
        fetch(API_URL + "/bms/v2/boot_history", requestOptions)
            .then((response) => response.json())
            .then((result) => {
              let bms = (result.data.data.sort((a, b) => new Date(a.tms) - new Date(b.tms))).reverse();
              // let iot = (result.data.iot.sort((a, b) => new Date(a.tms) - new Date(b.tms))).reverse();
                setBmsBootData(bms)
                // setIotBootData(iot)
                setLoader(false)
            }).catch((error) => console.log("error", error));
    };
    const getTms = (val) => {
      setTms(val.target.value)
    }
    const previousClicked = (val) => {
      setShowRecent('')
      fetchBmsValues(val, "LT");
    };
    const nextClicked = (val) => {
      setShowRecent('')
      fetchBmsValues(val,"TMS");
    };
    const getCenter = () => {
      fetchBmsValues();
      setShowRecent('Recent ')
    };
    const changeNoOfSample = (val) => {
      setNoOfSamples(val)
    };
    const getDataForTms = () => {
      fetchBmsValues(tms,"TMS");
    }
    const buttonBasics = {
      borderWidth: 0,
      borderRadius: "20px",
      backgroundColor: "#B6BDBB",
      color: "white",
      padding: "5px 15px 5px 15px",
      margin: "5px 50px",
      fontWeight: "bold",
    };
    if(!loader)
  return (
    <div>
                     <p>
            No Of Entries
            <b style={{ margin: "5px" }}>
              <select
                onChange={(e) => changeNoOfSample(e.target.value)}
                value={noOfSamples}
              >
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
              </select>
            </b>&ensp;
            <button
              onClick={getCenter}
            >
              Set
            </button>

            &ensp;
            <input type="datetime-local" onChange={getTms} value={tms}></input> &ensp;
            <button
              onClick={getDataForTms}
            >
              Set
            </button>
          </p>
          <div className="main-margin">
                <h6 style={{ cursor: "pointer" }}>
                  <button
                    style={buttonBasics}
                    onClick={() =>
                      previousClicked(bmsBootData[0].tms)
                    }
                  >
                    <i class="fas fa-chevron-left"></i>
                  </button>
                  <a onClick={getCenter}>{showRecent}Boot Logs</a>
                  <button
                    style={buttonBasics}
                    onClick={() =>
                      nextClicked(
                        bmsBootData[
                          bmsBootData.length - 1
                        ].tms
                      )
                    }
                  >
                    <i class="fas fa-chevron-right"></i>
                  </button>
                </h6>
              </div>
                <BmsBootLogs bmsBootData={bmsBootData}/>
    </div>
  )
  else return <div>Loading...</div>
}

export default BootLogsTab