import React from "react";
import { useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { API_URL } from "variables/general";
import AddTemplate from "./AddTemplate";
import EditTemplate from "./EditTemplate";
function Template() {
  const [iconPills, setIconPills] = React.useState("1");
  return (
    <div>
      <Nav className="justify-content-center" role="tablist" tabs>
        <NavItem>
          <NavLink
            className={iconPills === "1" ? "active" : ""}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              setIconPills("1");
            }}
          >
            <strong>Add Field</strong>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={iconPills === "2" ? "active" : ""}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              setIconPills("2");
            }}
          >
            <strong>Delete Field</strong>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent className="text-center" activeTab={"iconPills" + iconPills}>
        <TabPane tabId="iconPills1">
          <AddTemplate />
        </TabPane>
        <TabPane tabId="iconPills2">
          <EditTemplate />
        </TabPane>
      </TabContent>
    </div>
  );
}

export default Template;
