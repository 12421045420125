import {
  Card,
  CardTitle,
  Col,
} from "reactstrap";
import {
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import React from "react";

import { API_URL } from "variables/general";
import Two from "assets/img/Two.png";
import Maintenance from "assets/img/maintenance.png";

class Login extends React.Component {
  constructor(props) {
    super();

    this.state = {
      isLoaded: false,
      buttonLoader: false,
      loggedIn: false,
      underMaintenance: false,
    };

    // method binding
    this.getPassword = this.getPassword.bind(this);
    this.getNumber = this.getNumber.bind(this);
    this.loginFunction = this.loginFunction.bind(this);
  }

  shouldComponentUpdate() {
    // There will be logic here to compare table data to see if its changed..
    return true;
  }
  getNumber = (event) => {
    let val = event.target.value;
    this.setState({ Number: val });
  };

  getPassword = (event) => {
    let val = event.target.value;
    this.setState({ password: val });
  };
  //login handler
  loginFunction = () => {
    this.setState({ buttonLoader: true });
    let Number = this.state.Number;
    let password = this.state.password;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      // redirect: "follow",
      body: JSON.stringify({
        email: Number.toLowerCase(),
        password: password,
      }),
    };
    fetch(API_URL + "/bms/loginwithpassword", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let userDetail = JSON.parse(result);
        if (!userDetail.status) {
          this.setState({ loggedIn: false });
          alert(
            "Login Credentials are incorrect, Please check the login ID and password and try again."
          );
          this.setState({ buttonLoader: false});
          // window.location.reload();
        }
        window.localStorage.setItem("token", userDetail.data.auth.token);
        window.localStorage.setItem("role", userDetail.data.role);
        // if (userDetail.data.role === "jio_zypp")
        //   window.localStorage.setItem("jio_zypp", true);
        // else window.localStorage.setItem("jio_zypp", false);

        this.setState({
          status: userDetail.statusCode,
          subUserName: userDetail.data.userProfile.name,
          loggedIn: true,
          adminId: userDetail.id,
          appOnly: userDetail.data.appOnly,
          isFieldUser: userDetail.data.isFieldUser,
        });
      })
      .catch((error) => console.log("error", error));
  };
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.loginFunction();
    }
  };

  render() {
    if (this.state.underMaintenance) {
      return (
        <div
          className="content"
          style={{
            backgroundColor: "grey",
            height: "100vh",
            color: "whitesmoke",
          }}
        >
          <div style={{ margin: "auto", width: "50%", padding: "10%" }}>
            <img
              src={Maintenance}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              alt="settings..."
            />
            <h4>Under Maintenance. Services will be resumed shortly.</h4>
            <h6>We are sorry for the inconvenience.</h6>
          </div>
        </div>
      );
    } else {
      if (this.state.loggedIn) {
        if (this.state.appOnly === true) {
          return <div>Restricted Access</div>;
        } else if (window.localStorage.getItem("role") === "super_user") {
          return (
            <Redirect
              to={{
                pathname: "/admin/edit-settings",
              }}
            />
          );
        } else
          return (
            <Redirect
              to={{
                pathname: "/mode-selection-page",
                state: { adminId: this.state.adminId },
              }}
            />
          );
      } else
        return (
          <Col
            className=""
            style={{
              backgroundImage: `url(${Two})`,

              /* Full height */
              height: "auto",
              maxWidth: "100%",

              /* Center and scale the image nicely */
              backgroundPosition: "center",
              backgroundRepeat: "noRepeat",
              backgroundSize: "cover",
            }}
          >
            <Card
              style={{
                backgroundColor: "rgb(0,0,0,0)",
                height: "auto",
                maxWidth: "100%",
              }}
            >
              <Col
                lg="3"
                md="3"
                xs="8"
                style={{ margin: "5rem 0rem 25rem 5rem" }}
              >
                <h1 style={{ color: "white" }}>Welcome to AEIDTH Dashboard!</h1>
                <h1 style={{ color: "white" }}>Login</h1>
                <CardTitle tag="p">
                  <input
                    type="text"
                    className="form-control form-control-user"
                    id="exampleInputNumber"
                    aria-describedby="NumberHelp"
                    placeholder="Enter Login ID"
                    onChange={this.getNumber}
                    onKeyPress={this.handleKeyPress}
                  />
                </CardTitle>
                <CardTitle tag="p">
                  <input
                    className="form-control form-control-user"
                    id="exampleInputPassword"
                    type="password"
                    placeholder="Password"
                    onChange={this.getPassword}
                    onKeyPress={this.handleKeyPress} />
                </CardTitle>
                <button
                  className="btn btn-primary btn-user btn-block"
                  onClick={() => this.loginFunction()}
                >
                  {this.state.buttonLoader ? "Logging In" : "Log In"}
                </button>
                <p style={{ float: "right" }}>
                  Support&ensp;
                  <a href="mailto:info@aeidth.com" style={{ color: "crimson" }}>
                    info@aeidth.com
                  </a>
                </p>
                {/* <br /> */}
                <p>Version: 1.6.0</p>
              </Col>
            </Card>
          </Col>
        );
    }
  }
}
export default Login;
