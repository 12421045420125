import React, { useEffect, useState } from 'react'
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2'
import { Card, Col, Row } from 'reactstrap'
import { API_URL } from 'variables/general'

function Test2() {
    useEffect(() => {
        // getCDProfile()
        // getAnalysisData()
        getLtAnalysisData()
    }, [])

    const [ltAnalysisData, setLtAnalysisData] = useState()
    const [energyData, setEnergyData] = useState()
    const [cdProfileData, setCdProfileData] = useState()



    function getLtAnalysisData(payload) {
        let batteryPackId = 'ABT865577074929713'
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Bearer " + window.localStorage.getItem('token')
        );
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(API_URL + "/bms/v2/lt_analysis?batteryPackId=" + batteryPackId, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setLtAnalysisData(result.data.analysisValues.data)
                let energyData = result.data.energyData.sort((a, b) => new Date(a.tms) - new Date(b.tms))
                setEnergyData(energyData)
                // setAnalysisData(result.data)
            })
            .catch((error) => console.log("error", error));
    }
    function getCDProfile(payload) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Bearer " + window.localStorage.getItem('token')
        );
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: JSON.stringify({
                batteryPackId: "ABT865577074929713",
                tms: "2024-05-08T00:00:00Z",
                tms_lt: "2024-05-10T00:00:00Z"
            })
        };
        fetch(API_URL + "/bms/v2/cd_profile", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setCdProfileData(result.data.cdCycles)
                // setAnalysisData(result.data)
            })
            .catch((error) => console.log("error", error));
    }
    const FaultArray = [
        "No Fault",
        "Over Pack Voltage Fault",
        "Over Pack Voltage",
        "Under Pack Voltage Fault",
        "Over OCD1 Current",
        "Over OCD2 Current",
        "Over Charge Current",
        "Over Discharge Current",
        "Short Circuit",
        "Cell Open Wire Fault",
        "Voltage Reference Fault",
        "Voltage Regulator Fault",
        "Temperature Multiplexer Error",
        "Under Temperature Fault",
        "Load Over Temperature",
        "Oscillator Fault",
        "Watchdog Timeout Fault",
        "Under Cell Voltage Fault",
        "Over Cell Voltage Fault",
        "Open Wire Fault",
        "Over Temperature Fault",
        "Cell Unbalance Pro",
        "SCD Latch High",
        "SPI Communication Fault",
        "I2C Communication Fault",
        "MCU Reset Fault",
        "Data CRC Error Fault",
        "Data Ready Fault",
        "Charger Authentication Failed",
        "Thermal Runaway Due To Temperature",
        "Thermal Runaway Due To Voltage",
        "MOSFET Drive Voltage Fault",
        "Reserve Condition Reached",
        "Battery Empty Reached",
        "Battery Full Charged Reached"
    ]

    if (ltAnalysisData && energyData) {
        const labelObj = Object.keys(ltAnalysisData.ltFaultCount)
        let updatedLabel = []
        labelObj.forEach(element => {
            updatedLabel.push(FaultArray[parseInt(element)])
        });
        const data = Object.values(ltAnalysisData.ltFaultCount)
        const faultChart = {
            data: (canvas) => {
                return {
                    labels: updatedLabel,
                    datasets: [
                        {
                            label: "Count",
                            pointRadius: 0,
                            pointHoverRadius: 0,
                            backgroundColor: ['#36384c', '#613a43', '#97a989', '#eee3d6', '#e3b9b3',],
                            //   borderWidth: 1,
                            //   borderColor:'yellow',
                            data: data,
                        },
                    ],
                };
            },
            options: {
                legend: {
                    // display: false,
                },
                pieceLabel: {
                    render: "percentage",
                    fontColor: ["white"],
                    precision: 2,
                },
                tooltips: {
                    // enabled: false,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                display: false,
                            },
                            gridLines: {
                                drawBorder: false,
                                zeroLineColor: "transparent",
                                color: "rgba(255,255,255,0.00)",
                            },
                        },
                    ],
                    xAxes: [
                        {
                            barPercentage: 1,
                            gridLines: {
                                // drawBorder: false,
                                color: "rgba(255,255,255,0.0)",
                                zeroLineColor: "transparent",
                            },
                            ticks: {
                                display: false,
                            },
                        },
                    ],
                },
            },
        };
        const lifeTimeFaultChart = {
            data: () => {
                return {
                    labels: updatedLabel,
                    datasets: [
                        {
                            label: "Count",
                            data: data,
                            backgroundColor: ['#36384c', '#613a43', '#97a989', '#eee3d6', '#e3b9b3',],
                            borderColor: [],
                            borderWidth: 0,
                        },
                    ],
                };
            },
            options: {
                legend: {
                    display: false,
                    position: "top",
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
            },
        };

        //ltUCVFault
        const ltUCVFaultLabel = Object.keys(ltAnalysisData.ltUcvFault.data)
        let ltUCVFaultLabelUpdated = []
        ltUCVFaultLabel.forEach((element, index) => {
            ltUCVFaultLabelUpdated.push('Cell ' + element)
        })
        const ltUCVFaultData = Object.values(ltAnalysisData.ltUcvFault.data)
        const ltUCVFaultChart = {
            data: () => {
                return {
                    labels: ltUCVFaultLabelUpdated,
                    datasets: [
                        {
                            label: "Count",
                            data: ltUCVFaultData,
                            backgroundColor: ['#36384c', '#613a43', '#97a989', '#eee3d6', '#e3b9b3',],
                            borderColor: [],
                            borderWidth: 0,
                        },
                    ],
                };
            },
            options: {
                legend: {
                    display: false,
                    position: "top",
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
            },
        };
        //ltOCVFault
        const ltOCVFaultLabel = Object.keys(ltAnalysisData.ltOcvFault.data)
        let ltOCVFaultLabelUpdated = []
        ltOCVFaultLabel.forEach((element, index) => {
            ltOCVFaultLabelUpdated.push('Cell ' + element)
        })
        const ltOCVFaultData = Object.values(ltAnalysisData.ltOcvFault.data)
        const ltOCVFaultChart = {
            data: () => {
                return {
                    labels: ltOCVFaultLabelUpdated,
                    datasets: [
                        {
                            label: "Count",
                            data: ltOCVFaultData,
                            backgroundColor: ['#36384c', '#613a43', '#97a989', '#eee3d6', '#e3b9b3',],
                            borderColor: [],
                            borderWidth: 0,
                        },
                    ],
                };
            },
            options: {
                legend: {
                    display: false,
                    position: "top",
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
            },
        };
        //ltOverCellTempChargingObj
        const ltOverCellTempChargingObjLabel = Object.keys(ltAnalysisData.ltOverCellTempChargingObj.data)
        let ltOverCellTempChargingObjLabelUpdated = []
        ltOverCellTempChargingObjLabel.forEach((element, index) => {
            ltOverCellTempChargingObjLabelUpdated.push('Zone ' + element)
        })
        const ltOverCellTempChargingObjData = Object.values(ltAnalysisData.ltOverCellTempChargingObj.data)
        const ltOverCellTempChargingObjChart = {
            data: () => {
                return {
                    labels: ltOverCellTempChargingObjLabelUpdated,
                    datasets: [
                        {
                            label: "Count",
                            data: ltOverCellTempChargingObjData,
                            backgroundColor: ['#36384c', '#613a43', '#97a989', '#eee3d6', '#e3b9b3',],
                            borderColor: [],
                            borderWidth: 0,
                        },
                    ],
                };
            },
            options: {
                legend: {
                    display: false,
                    position: "top",
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
            },
        };
        //ltOverCellTempDisChargingObj
        const ltOverCellTempDisChargingObjLabel = Object.keys(ltAnalysisData.ltOverCellTempDisChargingObj.data)
        let ltOverCellTempDisChargingObjLabelUpdated = []
        ltOverCellTempDisChargingObjLabel.forEach((element, index) => {
            ltOverCellTempDisChargingObjLabelUpdated.push('Zone ' + element)
        })
        const ltOverCellTempDisChargingObjData = Object.values(ltAnalysisData.ltOverCellTempDisChargingObj.data)
        const ltOverCellTempDisChargingObjChart = {
            data: () => {
                return {
                    labels: ltOverCellTempDisChargingObjLabelUpdated,
                    datasets: [
                        {
                            label: "Count",
                            data: ltOverCellTempDisChargingObjData,
                            backgroundColor: ['#36384c', '#613a43', '#97a989', '#eee3d6', '#e3b9b3',],
                            borderColor: [],
                            borderWidth: 0,
                        },
                    ],
                };
            },
            options: {
                legend: {
                    display: false,
                    position: "top",
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
            },
        };
        //ltFaultCount
        const ltFaultCountLabel = Object.keys(ltAnalysisData.ltFaultCount)
        let ltFaultCountLabelUpdated = []
        ltFaultCountLabel.forEach((element, index) => {
            ltFaultCountLabelUpdated.push(FaultArray[element])
        })
        const ltFaultCountData = Object.values(ltAnalysisData.ltFaultCount)
        const ltFaultCountChart = {
            data: () => {
                return {
                    labels: ltFaultCountLabelUpdated,
                    datasets: [
                        {
                            label: "Count",
                            data: ltFaultCountData,
                            backgroundColor: ['#36384c', '#613a43', '#97a989', '#eee3d6', '#e3b9b3',],
                            borderColor: [],
                            borderWidth: 0,
                        },
                    ],
                };
            },
            options: {
                legend: {
                    display: false,
                    position: "top",
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
            },
        };
        //energyIn

        let energyInData = []
        let energyInLabel = []
        let energyOutData = []
        let energyOutLabel = []
        let energyInDataIncrement = []
        let initialEnergyIn = 0
        let initialEnergyOut = 0
        let energyOutDataIncrement = []
        energyData.forEach((element) => {
            initialEnergyIn += element.energyIn
            energyInDataIncrement.push(initialEnergyIn/1000)
            initialEnergyOut += element.energyOut
            energyOutDataIncrement.push(initialEnergyOut/1000)
            energyInData.push(element.energyIn/1000)
            let time = element.tms.split('T')[0].split('-')[2] + '/' + element.tms.split('T')[0].split('-')[1]
            energyInLabel.push(time)
            energyOutData.push(element.energyOut/1000)
            energyOutLabel.push(time)
        })
        const energyInChart =
        {
            data: {
                labels: energyInLabel,
                datasets: [
                    {
                        label: "Energy In (Ah)",
                        data: energyInData,
                        fill: false,
                        borderColor: "#0ff",
                        backgroundColor: "transparent",
                        pointBorderColor: "#0ff",
                        pointRadius: 1,
                        pointHoverRadius: 10,
                        pointBorderWidth: 0,
                    },
                    {
                        label: "Energy Out (Ah)",
                        data: energyOutData,
                        fill: false,
                        borderColor: "#f00",
                        backgroundColor: "transparent",
                        pointBorderColor: "#f00",
                        pointRadius: 1,
                        pointHoverRadius: 10,
                        pointBorderWidth: 0,
                    },
                ],
            },
            options: {
                elements: {
                    line: {
                        tension: 0.3,
                    },
                },
                scales: {
                    yAxes: [
                        {
                        },
                    ],
                },
                legend: {
                    display: true,
                    position: "top",
                },
            },
        };
        const energyOutChart =
        {
            data: {
                labels: energyOutLabel,
                datasets: [
                    {
                        label: "Energy Out (Ah)",
                        data: energyOutData,
                        fill: false,
                        borderColor: "#0ff",
                        backgroundColor: "transparent",
                        pointBorderColor: "#0ff",
                        pointRadius: 1,
                        pointHoverRadius: 10,
                        pointBorderWidth: 0,
                    },
                ],
            },
            options: {
                elements: {
                    line: {
                        tension: 0.3,
                    },
                },
                scales: {
                    yAxes: [
                        {
                        },
                    ],
                },
                legend: {
                    display: true,
                    position: "top",
                },
            },
        };
        const energyInIncrementChart =
        {
            data: {
                labels: energyInLabel,
                datasets: [
                    {
                        label: "Energy In (Ah)",
                        data: energyInDataIncrement,
                        fill: false,
                        borderColor: "#0ff",
                        backgroundColor: "transparent",
                        pointBorderColor: "#0ff",
                        pointRadius: 1,
                        pointHoverRadius: 10,
                        pointBorderWidth: 0,
                    },
                ],
            },
            options: {
                elements: {
                    line: {
                        tension: 0.3,
                    },
                },
                scales: {
                    yAxes: [
                        {
                        },
                    ],
                },
                legend: {
                    display: true,
                    position: "top",
                },
            },
        };
        const energyOutIncrementChart =
        {
            data: {
                labels: energyOutLabel,
                datasets: [
                    {
                        label: "Energy Out (Ah)",
                        data: energyOutDataIncrement,
                        fill: false,
                        borderColor: "#0ff",
                        backgroundColor: "transparent",
                        pointBorderColor: "#0ff",
                        pointRadius: 1,
                        pointHoverRadius: 10,
                        pointBorderWidth: 0,
                    },
                ],
            },
            options: {
                elements: {
                    line: {
                        tension: 0.3,
                    },
                },
                scales: {
                    yAxes: [
                        {
                        },
                    ],
                },
                legend: {
                    display: false,
                    position: "top",
                },
            },
        };



        return (
            <div>
                <Row>
                    <Col md='6'>
                        <Card style={{ padding: "1rem" }} className="hero-cards">
                            <h6>Daily Usage</h6>
                            <Line data={energyInChart.data} options={energyInChart.options} />
                        </Card>
                    </Col>
                    <Col md='6'>
                        <Card style={{ padding: "1rem" }} className="hero-cards">
                            <h6>Cummulative Usage <span style={{fontSize:'80%', textTransform:'none'}}>(Ah)</span></h6>
                            <Line data={energyOutIncrementChart.data} options={energyOutIncrementChart.options} />
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Peak Current Charging </div>
                            <div className="card-foot">
                                {ltAnalysisData.ltPeakChargingCurrent/1000} A
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Peak Current Discharging </div>
                            <div className="card-foot">
                                {ltAnalysisData.ltPeakDischargingCurrent/1000} A
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Peak Current Regen </div>
                            <div className="card-foot">
                                {ltAnalysisData.ltPeakRegen/1000} A
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Lowest Voltage Reached</div>
                            <div className="card-foot">
                                {ltAnalysisData.ltLowestVoltage/1000} V
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Peak Power Charging</div>
                            <div className="card-foot">
                                {ltAnalysisData.ltPeakPowerCharging} W
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Peak Power Discharging</div>
                            <div className="card-foot">
                                {ltAnalysisData.ltPeakPowerDischarging} W
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Max Cell Temperature</div>
                            <div className="card-foot">
                                {ltAnalysisData.ltMaxCellTemp} °C
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Max PDU Temperature</div>
                            <div className="card-foot">
                                {ltAnalysisData.ltMaxPduTemp} °C
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    {ltAnalysisData.ltFaultCount == {} ? '' :
                        <Col md='4'>
                            <Card style={{ padding: "1rem" }} className="hero-cards">
                                <h6>Lifetime Faults Occured</h6>
                                <Bar data={lifeTimeFaultChart.data} options={lifeTimeFaultChart.options} />
                            </Card>
                        </Col>
                    }
                    {ltAnalysisData.ltOcvFault.total > 0 ? <Col md='4'>
                        <Card style={{ padding: "1rem" }} className="hero-cards">
                            <h6>Lifetime Over Cell Voltage Faults Occured</h6>
                            <Bar data={ltOCVFaultChart.data} options={ltOCVFaultChart.options} />
                        </Card>
                    </Col> : ""}
                    {ltAnalysisData.ltUcvFault.total ? <Col md='4'>
                        <Card style={{ padding: "1rem" }} className="hero-cards">
                            <h6>Lifetime Under Cell Voltage Faults Occured</h6>
                            <Bar data={ltUCVFaultChart.data} options={ltUCVFaultChart.options} />
                        </Card>
                    </Col> : ""}
                    {ltAnalysisData.ltOverCellTempChargingObj.total ? <Col md='4'>
                        <Card style={{ padding: "1rem" }} className="hero-cards">
                            <h6>Lifetime Over Cell Temperature Faults Occured While Charging</h6>
                            <Bar data={ltOverCellTempChargingObjChart.data} options={ltOverCellTempChargingObjChart.options} />
                        </Card>
                    </Col> : ""}
                    {ltAnalysisData.ltOverCellTempDisChargingObj.total ? <Col md='4'>
                        <Card style={{ padding: "1rem" }} className="hero-cards">
                            <h6>Lifetime Over Cell Temperature Faults Occured While Discharging</h6>
                            <Bar data={ltOverCellTempDisChargingObjChart.data} options={ltOverCellTempDisChargingObjChart.options} />
                        </Card>
                    </Col> : ""}
                </Row>
            </div>
        )
    }
    else return <div>loading...</div>
}

export default Test2