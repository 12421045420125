import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import axios from "axios";
import { Row, Col, Container, Button, Card } from "reactstrap";
import {
  AWS_REGION,
  AWS_IOT_ENDPOINT,
  AWS_CUSTOM_AUTH_USERNAME,
  AWS_CUSTOM_AUTH_AUTHORIZER_NAME,
  AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD,
  AWS_CUSTOM_AUTH_PASSWORD,
} from "../../../views/MQTTSettings";
import { API_URL } from "variables/general";
const iotsdk = require("aws-iot-device-sdk-v2");
const iot = iotsdk.iot;
const mqtt = iotsdk.mqtt;
function MQTTdata() {
  useEffect(() => {
    getSubUsers();
  }, []);
  const [mqttData, setMqttData] = useState('');
  const [mqttConnectionStatus, setMqttConnectionStatus] =
    useState("Not Connected");
  const [modelData, setModelData] = useState();
  const [subUserId, setSubUserId] = useState("");
  const [subUserListData, setSubUserListData] = useState();
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");
  const [macAddress, setMacAddress] = useState("");
  const [modelDataArray, setModelDataArray] = useState("");
  let connection = null;
  async function connect_websocket() {
    return new Promise((resolve, reject) => {
      let config = iot.AwsIotMqttConnectionConfigBuilder.new_default_builder()
        .with_clean_session(true)
        .with_client_id(new Date())
        .with_endpoint(AWS_IOT_ENDPOINT)
        .with_custom_authorizer(
          AWS_CUSTOM_AUTH_USERNAME,
          AWS_CUSTOM_AUTH_AUTHORIZER_NAME,
          AWS_CUSTOM_AUTH_PASSWORD,
          AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD
        )
        .with_keep_alive_seconds(30)
        .build();

      console.log("Connecting custom authorizer...");
      setMqttConnectionStatus("Connecting...");
      const client = new mqtt.MqttClient();

      connection = client.new_connection(config);
      connection.on("connect", (session_present) => {
        setMqttConnectionStatus("Connected");
        console.log("Connected", session_present);
        resolve(connection);
      });
      connection.on("interrupt", (error) => {
        setMqttConnectionStatus("Connection Interrupted");
        console.log(`Connection interrupted: error=${error}`);
      });
      connection.on("resume", (return_code, session_present) => {
        setMqttConnectionStatus("Connection Resumed");
        console.log(
          `Resumed: rc: ${return_code} existing session: ${session_present}`
        );
      });
      connection.on("disconnect", () => {
        setMqttConnectionStatus("Disconnected");
        console.log("Disconnected");
      });
      connection.on("error", (error) => {
        setMqttConnectionStatus("Error Occured");
        reject(error);
      });
      connection.connect();
    });
  }
  async function main(val) {
    connect_websocket()
      .then((connection) => {
        connection.subscribe(
          `adtiot/bms1.0/sub/bt/Test_inv_id`,
          mqtt.QoS.AtLeastOnce,
          (topic, payload, dup, qos, retain) => {
            const decoder = new TextDecoder("utf8");
            let message = decoder.decode(new Uint8Array(payload));
            // console.log("message", message);
            let parsed = JSON.parse(message);
            setMqttData(parsed);
            getModelInfo(parsed.data.model);
          }
        );

        // .then((publish) => {
        //     return connection.publish('publish.topic', "Hello World!", publish.qos);
        // });
      })
      .catch((reason) => {
        console.log(`Error while connecting: ${reason}`);
      });
  }
  const getModelInfo = (val) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;

    requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(API_URL + "/bms/model?id=" + val, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setModelData(result.data);
        let model = [];
        Object.keys(result.data).forEach((key) =>
          model.push(key + " : " + result.data[key])
        );
        setModelDataArray(model);
      })
      .catch((error) => console.log("error", error));
  };
  const getSubUsers = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(API_URL + "/bms/manage_bms_subuser?sub_user=All", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSubUserListData(result.data.users);
      })
      .catch((error) => console.log("error", error));
  };
  const getMacAddress = (val) => {
    setMacAddress(val);
  };
  async function addToInventory() {
    const formData = new FormData();
    if (modelData.invType === "bl") {
      let data = {
        description: description,
        status: status,
        lastUpdated: lastUpdated,
        macAddress: macAddress,
        img1: mqttData.data.img1,
        img2: mqttData.data.img2,
      };
      formData.append("invType", modelData.invType);
      formData.append("blId", mqttData.data.id);
      formData.append("modelId", mqttData.data.model);
      formData.append("subOwner", subUserId);
      formData.append("data", JSON.stringify(data));
      await axios
        .post(API_URL + "/bms/inventory_data", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((result) => {
          alert(result.data.message);
        });
    } else if (modelData.invType === "pd") {
      let data = {
        description: description,
        status: status,
        lastUpdated: lastUpdated,
        img1: mqttData.data.img1,
        img2: mqttData.data.img2,
      };
      formData.append("invType", modelData.invType);
      formData.append("pdId", mqttData.data.id);
      formData.append("modelId", mqttData.data.model);
      formData.append("subOwner", subUserId);
      formData.append("data", JSON.stringify(data));
      await axios
        .post(API_URL + "/bms/inventory_data", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((result) => {
          alert(result.data.message);
        });
    } else if (modelData.invType === "iot") {
      let data = {
        description: description,
        status: status,
        lastUpdated: lastUpdated,
        img1: mqttData.data.img1,
        img2: mqttData.data.img2,
      };
      formData.append("invType", modelData.invType);
      formData.append("iotId", mqttData.data.id);
      formData.append("modelId", mqttData.data.model);
      formData.append("subOwner", subUserId);
      formData.append("data", JSON.stringify(data));
      await axios
        .post(API_URL + "/bms/inventory_data", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((result) => {
          alert(result.data.message);
        });
    }
  }
  const getSubUserId = (second) => {
    setSubUserId(second.value);
  };
  const refresh = () =>{
    setMqttData('')
  }
  if (subUserListData) {
    let subUserList = [];
    for (let i = 0; i < subUserListData.length; i++) {
      subUserList[i] = {
        value: subUserListData[i].id,
        label: subUserListData[i].userProfile.name,
      };
    }
    return (
      <div>
        <div style={{ padding: "1rem" }}>
          <Row style={{ margin: "2rem" }}>
            <Col md="4" lg="3" sm="6" xs="12">
              <Button onClick={main}>Establish Mobile Connection</Button>
              <br />
              <strong>Status:</strong>&ensp;<span>{mqttConnectionStatus}</span>
            </Col>
            <Col md="4" lg="3" sm="6" xs="12">
              <strong>Select Sub User</strong>
              <ReactSelect options={subUserList} onChange={getSubUserId} />
            </Col>
          </Row>
          {mqttData ? (
            <Card style={{ backgroundColor: "#e3cae3", padding: "1rem" }}>
              <Row>
                <Col>
                  <strong>Front Image</strong>
                  <br />
                  <img alt="img" src={'https://cpo.aeidth.com'+mqttData.data.img1}></img>
                </Col>
                <Col>
                  <strong>Unique ID:</strong>&ensp;<span>{mqttData.data.id}</span>
                  <br />
                  {modelData ? (
                    <>
                      {modelData.invType === "bl" ? (
                        <>
                          <strong>Mac Address:</strong>
                          <br />
                          <input
                            placeholder="mac address"
                            onChange={(event) =>
                              getMacAddress(event.target.value)
                            }
                          ></input>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Col>
                <Col>
                  {modelDataArray ? (
                    <div>
                      <h6>Model Info:</h6>
                      <Row>
                        <Col>
                          {modelDataArray.map((val, i) => (
                            <>
                              <span key={i}>{val}</span>
                              <br />
                            </>
                          ))}
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
                <Col>
                <strong>Back Image</strong>
                  <br />
                  <img alt="img" src={'https://cpo.aeidth.com'+mqttData.data.img2}></img>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button onClick={addToInventory}>Add to Inventory</Button>
                </Col>
                <Col>
                  <Button onClick={refresh}>Refresh</Button>
                </Col>
              </Row>
            </Card>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  } else return <div>Loading...</div>;
}

export default MQTTdata;
