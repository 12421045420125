import { Button, Card, Col, Input, InputGroup, InputGroupText, Row, Spinner } from "reactstrap";
import React, { useEffect, useState } from "react";
import { API_URL } from "variables/general";
import ReactSelect from "react-select";
function ManageDevice() {
  const [IOTID, setIOTID] = useState('')
  const [BPID, setBPID] = useState('')
  const [batteryNumber, setbatteryNumber] = useState('')
  const [batteryCapacity, setbatteryCapacity] = useState('')
  const [batteryCycleCount, setbatteryCycleCount] = useState('')
  const [batteryChemistry, setbatteryChemistry] = useState('')
  const [cRate, setcRate] = useState('')
  const [noOfS, setnoOfS] = useState('')
  const [noOfP, setnoOfP] = useState('')
  const [cellModelNumber, setcellModelNumber] = useState('')
  const [batteryManufacturer, setbatteryManufacturer] = useState('')
  const [batteryManufacturingDate, setbatteryManufacturingDate] = useState('')
  const [batteryDeploymentDate, setbatteryDeploymentDate] = useState('')
  const [alias, setalias] = useState('')
  const [outOfOrder, setoutOfOrder] = useState('')
  const [subUserList, setsubUserList] = useState('')
  const [deviceList, setDeviceList] = useState('')
  const [BMSID, setBMSID] = useState('')
  const [showSpinner, setShowSpinner] = useState(false)
  const [showBmsListSelector, setShowBmsListSelector] = useState(false)

  useEffect(() => {
    getSubOperatorList();
  }, [])
  const getSubOperatorList = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(API_URL + "/bms/manage_bms_subuser", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setsubUserList(result.data)
      })
      .catch((error) => console.log("error", error));
  };
  const getBMSList = (subOwnerId) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ subUser: subOwnerId })
    };
    fetch(API_URL + "/bms/bms_sub_user", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDeviceList(result.data)
        setShowBmsListSelector(true)
      })
      .catch((error) => console.log("error", error));
  };
  const getDeviceData = (bmsId) => {
    let apiHeaders = new Headers();
    apiHeaders.append("Content-Type", "application/json");
    apiHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    requestOptions = {
      method: "GET",
      headers: apiHeaders,
    };
    fetch(API_URL + "/bms/bms_devices?battery_pack_id=" + bmsId, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIOTID(result.data.iotId)
        setBPID(result.data.batteryPackId)
        setbatteryManufacturingDate(result.data.manufacturingDate)
        setbatteryDeploymentDate(result.data.dateOfDeployment)
        setbatteryNumber(result.data.batteryNumber)
        setbatteryCapacity(result.data.batteryCapacity)
        setbatteryCycleCount(result.data.batteryCycleCount)
        setbatteryChemistry(result.data.batteryChemistry)
        setcRate(result.data.cRate)
        setnoOfS(result.data.noOfS)
        setnoOfP(result.data.noOfP)
        setcellModelNumber(result.data.cellModelNumber)
        setbatteryManufacturer(result.data.batteryManufacturer)
        setalias(result.data.alias)
        setoutOfOrder(result.data.outOfOrder)
        setShowSpinner(false)
      })
      .catch((error) => console.log("error", error));
  };
  const showBmsList = (second) => {
    setShowBmsListSelector(false)
    getBMSList(second.value)
  }
  const showData = (event) => {
    setBMSID(event.value)
    getDeviceData(event.value);
    setShowSpinner(true)

  };
  const onSubmit = (event) => {
    let apiHeaders = new Headers();
    apiHeaders.append('Content-Type', 'application/json')
    apiHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    requestOptions = {
      method: "PATCH",
      headers: apiHeaders,
      body: JSON.stringify({
        packData: {
          iotId: IOTID,
          manufacturingDate: batteryManufacturingDate,
          dateOfDeployment: batteryDeploymentDate,
          batteryNumber: batteryNumber,
          batteryCapacity: batteryCapacity,
          batteryCycleCount: batteryCycleCount,
          batteryChemistry: batteryChemistry,
          cRate: cRate,
          noOfS: noOfS,
          noOfP: noOfP,
          cellModelNumber: cellModelNumber,
          batteryManufacturer: batteryManufacturer,
          alias: alias,
          outOfOrder: outOfOrder
        },
        batteryPackId: BMSID,
      }),
    };
    fetch(API_URL + "/bms/bms_devices", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert(result.message)
      })
      .catch((error) => console.log("error", error));
  };
  const deleteDevice = () => {
    let apiHeaders = new Headers();
    apiHeaders.append("Content-Type", "application/json");
    apiHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    requestOptions = {
      method: "DELETE",
      headers: apiHeaders,
      body: JSON.stringify({
        batteryPackId: BMSID,
      }),
    };
    fetch(API_URL + "/bms/bms_devices", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert(result.message)
      })
      .catch((error) => console.log("error", error));
  };
  if (subUserList) {
    let subUserListOptions = []
    subUserList.users.map((val, i) => (
      subUserListOptions[i] = {
        value: val.id,
        label: val.userProfile.name
      }
    ))
    let bmsListOptions = []
    if (showBmsListSelector)
      if (deviceList)
        deviceList.map((val, i) => (
          bmsListOptions[i] = {
            value: val.bmsId,
            label: val.alias ? val.alias : val.bmsId
          }
        ))
    return (
      <div className="main-margin">
        <Card
          style={{
            padding: "5px 50px",
            border: "solid",
            margin: "10px",
            borderColor: "#32C85A",
          }}
        >
          <h5 style={{ textAlign: "center" }}>Manage BMS Device</h5>
          <Row>
            <Col md="4" lg="4" sm="4" xs="12" style={{ padding: "10px" }}><strong className="adt-blue-color">Select Sub Operator: </strong><br />
              <ReactSelect
                onChange={showBmsList}
                options={subUserListOptions}
              >
              </ReactSelect></Col>
            <Col md="2" lg="2" sm="2" xs="12"></Col>
            <Col md="4" lg="4" sm="4" xs="12" style={{ padding: "10px" }}><strong className="adt-blue-color">Select BMS ID: </strong><br />
              <ReactSelect
                onChange={showData}
                options={bmsListOptions}
              >
              </ReactSelect></Col>
            <Col md="2" lg="2" sm="2" xs="12"></Col>
          </Row>
          <Row>
            <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
              <strong className="adt-blue-color">Set Out Of Order</strong>

              <input className="adt-input" style={{ height: "20px", width: "20px", position: 'absolute', border: "solid #0faef2", marginLeft: "5px" }} type="checkbox" id="outOfOrder" name="outOfOrder" value={outOfOrder} onChange={() => setoutOfOrder(!outOfOrder)} checked={outOfOrder ? true : false}></input>
            </Col>
            <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
              <strong className="adt-blue-color">Enter Battery pack ID</strong><br />

              <input className="adt-input"
                type="text"
                placeholder="Battery Pack ID"
                value={BPID}
                onChange={(event) => setBPID(event.target.value)}
                disabled
              />
            </Col><Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
              <strong className="adt-blue-color">Enter IoT ID</strong><br />

              <input className="adt-input"
                type="text"
                placeholder="IoT ID"
                value={IOTID}
                onChange={(event) => setIOTID(event.target.value)}
              />
            </Col><Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
              <strong className="adt-blue-color">Enter Battery Capacity (Ah)</strong><br />

              <input className="adt-input"
                type="number"
                placeholder="Battery Capacity"
                value={batteryCapacity}
                onChange={(event) =>
                  setbatteryCapacity(event.target.value)
                }
              />
            </Col><Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
              <strong className="adt-blue-color">Enter Battery Number</strong><br />

              <input className="adt-input"
                type="text"
                placeholder="Battery Number"
                value={batteryNumber}
                onChange={(event) =>
                  setbatteryNumber(event.target.value)
                }
              />
            </Col><Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
              <strong className="adt-blue-color">Enter Battery Cycle Count</strong><br />

              <input className="adt-input"
                type="number"
                placeholder="Battery Cycle Count"
                value={batteryCycleCount}
                onChange={(event) =>
                  setbatteryCycleCount(event.target.value)
                }
              />
            </Col><Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
              <strong className="adt-blue-color">Enter Battery Chemistry</strong><br />

              <input className="adt-input"
                type="text"
                placeholder="Battery Chemistry"
                value={batteryChemistry}
                onChange={(event) =>
                  setbatteryChemistry(event.target.value)
                }
              />
            </Col>
            <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
              <strong className="adt-blue-color">Enter C-Rate</strong><br />

              <input className="adt-input"
                type="number"
                placeholder="C-Rate"
                value={cRate}
                onChange={(event) => setcRate(event.target.value)}
              />
            </Col><Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
              <strong className="adt-blue-color">Enter Battery Manufacturing Date</strong><br />

              <input className="adt-input"
                type="date"
                placeholder="Battery Manufacturing Date"
                value={batteryManufacturingDate}
                onChange={(event) =>
                  setbatteryManufacturingDate(event.target.value)
                }
              />
            </Col><Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
              <strong className="adt-blue-color">Enter Battery Deployment Date</strong><br />

              <input className="adt-input"
                type="date"
                placeholder="Battery Deployment Date"
                value={batteryDeploymentDate}
                onChange={(event) =>
                  setbatteryDeploymentDate(event.target.value)
                }
              />
            </Col><Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
              <strong className="adt-blue-color">Enter Number of S</strong><br />

              <input className="adt-input"
                type="number"
                placeholder="Number of S"
                value={noOfS}
                onChange={(event) => setnoOfS(event.target.value)}
              />
            </Col><Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
              <strong className="adt-blue-color">Enter Number of P</strong><br />

              <input className="adt-input"
                type="number"
                placeholder="Number of P"
                value={noOfP}
                onChange={(event) => setnoOfP(event.target.value)}
              />
            </Col><Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
              <strong className="adt-blue-color">Enter Cell Model Number</strong>(optional)<br />

              <input className="adt-input"
                type="text"
                placeholder="Cell Model Number"
                value={cellModelNumber}
                onChange={(event) =>
                  setcellModelNumber(event.target.value)
                }
              />
            </Col><Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
              <strong className="adt-blue-color">Enter Alias for Battery Pack</strong>(optional)<br />

              <input className="adt-input"
                type="text"
                placeholder="Alias for Battery Pack"
                value={alias}
                onChange={(event) => setalias(event.target.value)}
              />
            </Col><Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
              <strong className="adt-blue-color">Enter Name of Battery Manufacturer</strong>(optional)<br />

              <input className="adt-input"
                type="text"
                placeholder="Battery Manufacturer"
                value={batteryManufacturer}
                onChange={(event) =>
                  setbatteryManufacturer(event.target.value)
                }
              />
            </Col>
          </Row>
          <Row><Col md="6" lg="6" sm="6" xs="12"><Button
            onClick={onSubmit}
            style={{
              color: "#f4f3ef",
              backgroundColor: "#009846",
              width: "10rem"
            }}
          >
            Save Changes
          </Button>
          </Col>
            <Col md="6" lg="6" sm="6" xs="12">
              <Button
                onClick={BMSID ? deleteDevice : <></>}
                style={{
                  color: "#f4f3ef",
                  backgroundColor: "crimson",
                  width: "10rem"
                }}
              >
                Delete Device
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    )
  }

  else return (<div className="main-margin" style={{ textAlign: "center" }}>
    <Spinner color="red">
      Loading...
    </Spinner>
  </div>)

}
export default ManageDevice;