import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import { Redirect, Route, Router, Switch } from "react-router-dom";

import AdminLayout from "layouts/Admin.js";
// import ErrorPage from "./components/Error/Error";
import Login from "components/Auth/Login";
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import ModeSelectionPage from "components/ModeSelectionPage/ModeSelectionPage";
import CANHome from "components/CAN/CANHome";
import Inventory from "components/Inventory/Inventory";
import AddMacAddress from "components/Inventory/BMSEntry/AddMacAddress";
import Test from "views/Test";
import BLEView from "components/BLE/BLEView";
import EVIoT from "components/EVIoT/EVIoT";
import test from "components/IoT-Parent/test";
import Error from "components/Error/Error";

/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
    {window.localStorage.getItem("jio_zypp") === "true"?"":<Route path="/admin" render={(props) => <AdminLayout {...props} />} />}
      <Route exact path="/" component={Login} />
      <Route path="/mode-selection-page" component={ModeSelectionPage} />
      {window.localStorage.getItem("jio_zypp") === "true"?"":<><Route path="/can-home" component={CANHome} />
      <Route path="/inventory" component={Inventory} />
      <Route path="/ble" component={BLEView} />
      <Route path="/testing" component={test} />
      {/* <Route path="/testing2" component={test2} /> */}
      <Route path="/error-page" component={Error} />
      <Route path="/add-mac-address" component={AddMacAddress} />
      </>}
      <Route path="/eviot" component={EVIoT} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
