import { Card, Col, Row, Button, Spinner } from "reactstrap";
import React, { Component } from "react";

import { API_URL } from "variables/general";
import ReactSelect from "react-select";

class MapSubUserBMS extends Component {
  constructor() {
    super();
    this.state = { id: null, bmsId: null };
  }
  componentDidMount() {
    this.getSubUser();
    this.getBMSlist();
  }
  getBMSlist = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(API_URL + "/bms/battery_packs", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          devData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };
  getSubUser = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(API_URL + "/bms/manage_bms_subuser", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          getData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };
  mapSubUserBMS = () => {
    if (this.state.bmsId && this.state.id) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + window.localStorage.getItem("token")
      );
      var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: JSON.stringify({ batteryPackId: this.state.bmsId, id: this.state.id }),
      };

      fetch(API_URL + "/bms/manage_bms_subuser", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let statusResult = JSON.parse(result);
          if (statusResult.status === true) alert(statusResult.message);
          else alert("Something went wrong");
          this.setState({
            mapData: result,
          });
        })
        .catch((error) => console.log("error", error));
    }
    else alert('Select Sub User and BMS Id')
  };
  getSubUserId = (event) => {
    this.setState({
      id: event.value,
    });
  };
  getDeviceId = (event) => {
    this.setState({
      bmsId: event.value,
    });
  };
  render() {
    if (this.state.getData && this.state.devData) {
      let getDataItem = JSON.parse(this.state.getData).data.users;
      let devDataItem = JSON.parse(this.state.devData).data.data;
      let subUserListOptions = []
      getDataItem.map((val, i) => (
        subUserListOptions[i] = {
          value: val.id,
          label: val.userProfile.name
        }
      ))
      let bmsListOptions = []
      devDataItem.map((val, i) => (
        bmsListOptions[i] = {
          value: val.bmsId,
          label:  val.alias?val.bmsId + '('+val.alias+')' :val.bmsId
        }
      ))
      return (
        <Card
          style={{
            padding: "5px 50px",
            border: "solid",
            margin: "10px",
            borderColor: "#32C85A",
          }}
        >
          <h5 style={{ textAlign: 'center' }}>Map Sub Operator to BMS Device</h5>
          <Row>
            <Col md="4" lg="4" sm="4" xs="12">
              <strong className="adt-blue-color">Select Sub User: </strong><br />
              <ReactSelect
                onChange={this.getSubUserId}
                options={subUserListOptions}
              ></ReactSelect>
            </Col>
            <Col md="4" lg="4" sm="4" xs="12">
              <strong className="adt-blue-color">Select BMS ID: </strong><br />
              <ReactSelect
                onChange={this.getDeviceId}
                options={bmsListOptions}
              ></ReactSelect>
            </Col>
            <Col md="4" lg="4" sm="4" xs="12" style={{ textAlign: 'center' }}>
              <Button
                style={{
                  color: "#f4f3ef",
                  backgroundColor: "#009846",
                  width: "10rem"
                }}
                onClick={
                  this.mapSubUserBMS
                }
              >
                Assign BMS to User
              </Button>
            </Col>
          </Row>
        </Card>
      );
    } else return (<div className="main-margin" style={{ textAlign: "center" }}>
      <Spinner color="red">
        Loading...
      </Spinner>
    </div>)
  }
}
export default MapSubUserBMS;
