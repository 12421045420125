import React, { useEffect, useState } from 'react'
import Select from "react-select";
import {Row, Col} from 'reactstrap';
import { API_URL } from 'variables/general';
function BalancerView(props) {
    const balancerData = props.InventoryData.balancerCard;
    const [reportData, setReportData] = useState({
        "analogFrontendTest": {
            "cellVoltagesTest": {
                "cell1": 1,
                "cell2": 1,
                "cell3": 1,
                "cell4": 1,
                "cell5": 1,
                "cell6": 0,
                "cell7": 1,
                "cell8": 0,
                "cell9": 1,
                "cell10": 1,
                "cell11": 1,
                "cell12": 1,
                "cell13": 1,
                "cell14": 1,
                "cell15": 0,
                "cell16": 1,
                "cell17": 1,
                "cell18": 1,
                "cell19": 1,
                "cell20": 1,
                "cell21": 1,
                "cell22": 1,
                "cell23": 1,
                "cell24": 1
            },
            "ntcValuesTest": {
                "temp1": 1,
                "temp2": 1,
                "temp3": 1,
                "temp4": 0,
                "temp5": 1,
                "temp6": 1,
                "temp7": 1,
                "temp8": 1
            },
            "cellBalancingTest": {
                "cell1": 1,
                "cell2": 1,
                "cell3": 1,
                "cell4": 0,
                "cell5": 1,
                "cell6": 1,
                "cell7": 1,
                "cell8": 1,
                "cell9": 1,
                "cell10": 1,
                "cell11": 1,
                "cell12": 1,
                "cell13": 1,
                "cell14": 0,
                "cell15": 1,
                "cell16": 1,
                "cell17": 1,
                "cell18": 1,
                "cell19": 1,
                "cell20": 1,
                "cell21": 1,
                "cell22": 1,
                "cell23": 1,
                "cell24": 1
            }
        },
        "did": "test01",
        "gpioConnectorPinTest": {
            "ignitionPinTest": 1,
            "reservePinTest": 1,
            "gsmOnOffPinTest": 1,
            "out1PinTest": 0,
            "buzzLedPinTest": 1,
            "out2PinTest": 1,
            "out3PinTest": 1
        },
        "pduConnectorPinTest": {
            "latRstPinTest": 1,
            "scdAlertPinTest": 1,
            "pchgEnPinTest": 0,
            "dsgEnPinTest": 1,
            "chgEnPinTest": 0,
            "ntc100KPinTest": 1,
            "drvVadcPinTest": 1,
            "csnAdcPinTest": 1
        },
        "perpherialTest": {
            "perpherialTest1": 1,
            "perpherialTest2": 1,
            "perpherialTest3": 1,
            "perpherialTest4": 0,
            "perpherialTest5": 0,
            "perpherialTest6": 1,
            "perpherialTest7": 1
        },
        "powerRailTest": {
            "powerRail1": 1,
            "powerRail2": 1,
            "powerRail3": 0,
            "powerRail4": 1,
            "powerRail5": 0,
            "powerRail6": 1,
            "powerRail7": 1
        },
        "tms": "24-1-2024:15:30"
    });
    const [batteryPack, setBatteryPack] = useState("");
    const [reportUpdateTime, setReportUpdateTime] = useState("");
    const [showBatteryPack, setShowBatteryPack] = useState(false);
    let balancerDataOptions = [];
    const getReport = (val) => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          "Bearer " + window.localStorage.getItem("token")
        );
        let requestOptions = {
          method: "GET",
          headers: myHeaders,
        };
        fetch(
          API_URL + "/testjig/test_report?did=" + val,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            setReportData(result.data)
          })
          .catch((error) => console.log("error", error));
      };
    const handleSearchValue = (val) => {
        balancerData.forEach(element => {
            if(val.value === element.blId){
                setBatteryPack(element.bpId)
                setShowBatteryPack(true)
            }
        });
        getReport(val.value);
    }
    for (let i = 0; i < balancerData.length; i++) {
        balancerDataOptions[i] = {
            value: balancerData[i].blId,
            label: balancerData[i].blId,
        }
    }
    return (
        <div>
            <Row>
                <Col md='4'>
                    <strong>Select Balancer Card</strong>
                <Select
                options={balancerDataOptions}
                onChange={handleSearchValue}
            />
                </Col>
                <Col>{showBatteryPack?<span>Battery Pack: <strong>{batteryPack}</strong></span>:''}</Col>
            </Row>
           {reportData? <div className='test-report-container'>
            <Row>
                <Col>
                <h5>Card ID: {reportData.did}</h5>
                </Col>
                <Col>
                <strong>Last Updated: {reportData.tms}</strong>
                </Col>
            </Row>
          
          <Row className="test-report-main-row">
            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>Power Rail Test</h6>
                <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">
                  <strong>+5V0#</strong><br />
                <strong>+5V0</strong><br />
                <strong>+VCAN</strong><br />
                <strong>+3.0V</strong><br />
                <strong>3P3 (ISL TOP)</strong><br />
                <strong>3P3 (ISL BOTTOM)</strong><br />
                <strong>+5V_MCU</strong>
                  </Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.powerRailTest.powerRail1?'#37fa71':'#fa3737'}}>{reportData.powerRailTest.powerRail1 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.powerRailTest.powerRail2?'#37fa71':'#fa3737'}}>{reportData.powerRailTest.powerRail2 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.powerRailTest.powerRail3?'#37fa71':'#fa3737'}}>{reportData.powerRailTest.powerRail3 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.powerRailTest.powerRail4?'#37fa71':'#fa3737'}}>{reportData.powerRailTest.powerRail4 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.powerRailTest.powerRail5?'#37fa71':'#fa3737'}}>{reportData.powerRailTest.powerRail5 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.powerRailTest.powerRail6?'#37fa71':'#fa3737'}}>{reportData.powerRailTest.powerRail6 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.powerRailTest.powerRail7?'#37fa71':'#fa3737'}}>{reportData.powerRailTest.powerRail7 ? 'passed' : 'failed'}</span>
                  </Col>
                </Row>

            

            </Col>
            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>Peripheral Test</h6>
                <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">
                  <strong>EEPROM (I2C comm.)</strong><br />
                <strong>BLE (UART comm.)</strong><br />
                <strong>ISL (SPI comm.)</strong><br />
                <strong>ADC (I2C comm.)</strong><br />
                <strong>CAN</strong><br />
                <strong>Balancing NTC</strong><br />
                <strong>RTC in sleep mode</strong>
                  </Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.perpherialTest.perpherialTest1?'#37fa71':'#fa3737'}}>{reportData.perpherialTest.perpherialTest1 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.perpherialTest.perpherialTest2?'#37fa71':'#fa3737'}}>{reportData.perpherialTest.perpherialTest2 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.perpherialTest.perpherialTest3?'#37fa71':'#fa3737'}}>{reportData.perpherialTest.perpherialTest3 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.perpherialTest.perpherialTest4?'#37fa71':'#fa3737'}}>{reportData.perpherialTest.perpherialTest4 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.perpherialTest.perpherialTest5?'#37fa71':'#fa3737'}}>{reportData.perpherialTest.perpherialTest5 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.perpherialTest.perpherialTest6?'#37fa71':'#fa3737'}}>{reportData.perpherialTest.perpherialTest6 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.perpherialTest.perpherialTest7?'#37fa71':'#fa3737'}}>{reportData.perpherialTest.perpherialTest7 ? 'passed' : 'failed'}</span>
                  </Col>
                </Row>
             

            </Col>

            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>PDU Connector Pin Test</h6>
              <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">
                  <strong>latRstPinTest</strong><br />
                <strong>scdAlertPinTest</strong><br />
                <strong>pchgEnPinTest</strong><br />
                <strong>dsgEnPinTest</strong><br />
                <strong>chgEnPinTest</strong><br />
                <strong>ntc100KPinTest</strong><br />
                <strong>drvVadcPinTest</strong><br />
                <strong>csnAdcPinTest</strong>
</Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.pduConnectorPinTest.latRstPinTest?'#37fa71':'#fa3737'}}>{reportData.pduConnectorPinTest.latRstPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.pduConnectorPinTest.scdAlertPinTest?'#37fa71':'#fa3737'}}>{reportData.pduConnectorPinTest.scdAlertPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.pduConnectorPinTest.pchgEnPinTest?'#37fa71':'#fa3737'}}>{reportData.pduConnectorPinTest.pchgEnPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.pduConnectorPinTest.dsgEnPinTest?'#37fa71':'#fa3737'}}>{reportData.pduConnectorPinTest.dsgEnPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.pduConnectorPinTest.chgEnPinTest?'#37fa71':'#fa3737'}}>{reportData.pduConnectorPinTest.chgEnPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.pduConnectorPinTest.ntc100KPinTest?'#37fa71':'#fa3737'}}>{reportData.pduConnectorPinTest.ntc100KPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.pduConnectorPinTest.drvVadcPinTest?'#37fa71':'#fa3737'}}>{reportData.pduConnectorPinTest.drvVadcPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.pduConnectorPinTest.csnAdcPinTest?'#37fa71':'#fa3737'}}>{reportData.pduConnectorPinTest.csnAdcPinTest ? 'passed' : 'failed'}</span>
</Col>
                </Row>
           

            </Col>
            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>GPIO Connector Pin Test</h6>
                <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">
                  <strong>ignitionPinTest</strong><br />
                <strong>reservePinTest</strong><br />
                <strong>gsmOnOffPinTest</strong><br />
                <strong>out1PinTest</strong><br />
                <strong>buzzLedPinTest</strong><br />
                <strong>out2PinTest</strong><br />
                <strong>out3PinTest</strong>
</Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.gpioConnectorPinTest.ignitionPinTest ?'#37fa71':'#fa3737'}}>{reportData.gpioConnectorPinTest.ignitionPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.gpioConnectorPinTest.reservePinTest ?'#37fa71':'#fa3737'}}>{reportData.gpioConnectorPinTest.reservePinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.gpioConnectorPinTest.gsmOnOffPinTest ?'#37fa71':'#fa3737'}}>{reportData.gpioConnectorPinTest.gsmOnOffPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.gpioConnectorPinTest.out1PinTest ?'#37fa71':'#fa3737'}}>{reportData.gpioConnectorPinTest.out1PinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.gpioConnectorPinTest.buzzLedPinTest ?'#37fa71':'#fa3737'}}>{reportData.gpioConnectorPinTest.buzzLedPinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.gpioConnectorPinTest.out2PinTest ?'#37fa71':'#fa3737'}}>{reportData.gpioConnectorPinTest.out2PinTest ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.gpioConnectorPinTest.out3PinTest ?'#37fa71':'#fa3737'}}>{reportData.gpioConnectorPinTest.out3PinTest ? 'passed' : 'failed'}</span>
</Col>
                </Row>
                

            </Col>



            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>Cell Voltage Test</h6>
                <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">
                  <strong>Cell 1</strong><br />
                <strong>Cell 2</strong><br />
                <strong>Cell 3</strong><br />
                <strong>Cell 4</strong><br />
                <strong>Cell 5</strong><br />
                <strong>Cell 6</strong><br />
                <strong>Cell 7</strong><br />
                <strong>Cell 8</strong>
                  </Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell1?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell1 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell2?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell2 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell3?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell3 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell4?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell4 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell5?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell5 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell6?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell6 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell7?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell7 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell8?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell8 ? 'passed' : 'failed'}</span>

                  </Col>
                </Row>
               

            </Col>
            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>Cell Voltage Test</h6>
                <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">
                  <strong>Cell 9</strong><br />
                <strong>Cell 10</strong><br />
                <strong>Cell 11</strong><br />
                <strong>Cell 12</strong><br />
                <strong>Cell 13</strong><br />
                <strong>Cell 14</strong><br />
                <strong>Cell 15</strong><br />
                <strong>Cell 16</strong>
                  </Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell9?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell9 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell10?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell10 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell11?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell11 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell12?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell12 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell13?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell13 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell14?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell14 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell15?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell15 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell16?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell16 ? 'passed' : 'failed'}</span>
                  </Col>
                </Row>
              

            </Col>
            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>Cell Voltage Test</h6>
                <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">
                  <strong>Cell 17</strong><br />
                <strong>Cell 18</strong><br />
                <strong>Cell 19</strong><br />
                <strong>Cell 20</strong><br />
                <strong>Cell 21</strong><br />
                <strong>Cell 22</strong><br />
                <strong>Cell 23</strong><br />
                <strong>Cell 24</strong>
                  </Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell17?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell17 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell18?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell18 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell19?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell19 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell20?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell20 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell21?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell21 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell22?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell22 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell23?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell23 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellVoltagesTest.cell24?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellVoltagesTest.cell24 ? 'passed' : 'failed'}</span>
</Col>
                </Row>

               

            </Col>
            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>NTC Value Test</h6>
               <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">
                  <strong>Temp 1</strong><br />
                <strong>Temp 2</strong><br />
                <strong>Temp 3</strong><br />
                <strong>Temp 4</strong><br />
                <strong>Temp 5</strong><br />
                <strong>Temp 6</strong><br />
                <strong>Temp 7</strong><br />
                <strong>Temp 8</strong>
</Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.analogFrontendTest.ntcValuesTest.temp1?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.ntcValuesTest.temp1 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.ntcValuesTest.temp2?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.ntcValuesTest.temp2 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.ntcValuesTest.temp3?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.ntcValuesTest.temp3 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.ntcValuesTest.temp4?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.ntcValuesTest.temp4 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.ntcValuesTest.temp5?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.ntcValuesTest.temp5 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.ntcValuesTest.temp6?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.ntcValuesTest.temp6 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.ntcValuesTest.temp7?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.ntcValuesTest.temp7 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.ntcValuesTest.temp8?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.ntcValuesTest.temp8 ? 'passed' : 'failed'}</span>
</Col>
                </Row>
                

            </Col>
            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>Cell Balancing Test</h6>
              <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">


                  <strong>Cell 1</strong><br />
                <strong>Cell 2</strong><br />
                <strong>Cell 3</strong><br />
                <strong>Cell 4</strong><br />
                <strong>Cell 5</strong><br />
                <strong>Cell 6</strong><br />
                <strong>Cell 7</strong><br />
                <strong>Cell 8</strong>
                 
</Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell1?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell1 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell2?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell2 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell3?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell3 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell4?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell4 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell5?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell5 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell6?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell6 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell7?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell7 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell8?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell8 ? 'passed' : 'failed'}</span>
</Col>
                </Row>
                

            </Col>
            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>Cell Balancing Test</h6>
               <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col"><strong>Cell 9</strong><br />
                <strong>Cell 10</strong><br />
                <strong>Cell 11</strong><br />
                <strong>Cell 12</strong><br />
                <strong>Cell 13</strong><br />
                <strong>Cell 14</strong><br />
                <strong>Cell 15</strong><br />
                <strong>Cell 16</strong>
</Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col"><span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell9?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell9 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell10?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell10 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell11?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell11 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell12?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell12 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell13?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell13 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell14?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell14 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell15?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell15 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell16?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell16 ? 'passed' : 'failed'}</span>
</Col>
                </Row>
                

            </Col>
            <Col md='3' lg='3' xl='3' sm='6' xs='12'>

              
                <h6>Cell Balancing Test</h6>
               <Row className="test-report-row">
                  <Col md='8' lg='8' xl='8' sm='8' xs='8' className="test-report-col">
                  <strong>Cell 17</strong><br />
                <strong>Cell 18</strong><br />
                <strong>Cell 19</strong><br />
                <strong>Cell 20</strong><br />
                <strong>Cell 21</strong><br />
                <strong>Cell 22</strong><br />
                <strong>Cell 23</strong><br />
                <strong>Cell 24</strong>
</Col>
                  <Col md='4' lg='4' xl='4' sm='4' xs='4' className="test-report-col">
                  <span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell17?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell17 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell18?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell18 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell19?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell19 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell20?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell20 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell21?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell21 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell22?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell22 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell23?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell23 ? 'passed' : 'failed'}</span><br />
<span style={{backgroundColor:reportData.analogFrontendTest.cellBalancingTest.cell24?'#37fa71':'#fa3737'}}>{reportData.analogFrontendTest.cellBalancingTest.cell24 ? 'passed' : 'failed'}</span>
</Col>
                </Row>
                

            </Col>
           
          </Row>
      </div>:''}
        </div>
    )
}

export default BalancerView