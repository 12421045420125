import React from "react";
import { Bar } from "react-chartjs-2";
import { Card, Row, Col, Badge, Spinner } from "reactstrap";

function BatteryCellFor24SPlus(props) {
  if (props) {
    let cellVoltageFor24SPlus=props.cellVoltageFor24SPlus;
    let MaxCellVolt = props.MaxCellVolt;
    let MinCellVolt = props.MinCellVolt;
    let NumberOfCell = props.BMS_NoOfCellAvailable;
    let cellValue = [];
    let cellLabel = [];
    let cellColor = [];
    let cellVoltageData = cellVoltageFor24SPlus
    for (let i = 0; i < cellVoltageData.length; i++) {
      cellValue[i] = cellVoltageData[i];
    }
    for (let i = 0; i < NumberOfCell; i++) {
      cellLabel[i] = i + 1;
    }
    for (let i = 0; i < cellVoltageData.length; i++) {
      if (cellVoltageData[i] === MaxCellVolt) cellColor[i] = "#b8b209";
      else if (cellVoltageData[i] === MinCellVolt) cellColor[i] = "#078da8";
      else cellColor[i] = "rgba(10, 255, 10, 1)";
    }

    const batteryCellsChart = {
      data: () => {
        return {
          labels: cellLabel,
          datasets: [
            {
              label: "Cell Voltage (mV)",
              data: cellValue,
              backgroundColor: cellColor,
              borderColor: [],
              borderWidth: 0,
            },
          ],
        };
      },

      options: {
        legend: {
          display: false,
          position: "top",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                    max: 5000,
                    min: 0,
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
    return (
      <div>
        <div>
          <Card>
            <div style={{ margin: "15px" }}>
              <div className="card-head">Cell Voltages</div>
              {NumberOfCell ? (
                <>
                  <Row>
                    <Col>
                      <div>
                        <Badge
                          style={{
                            backgroundColor: "#078da8",
                            color: "white",

                            margin: "10px",
                          }}
                        >
                          Min: {MinCellVolt} mV
                        </Badge>
                        <Badge
                          style={{
                            backgroundColor: "#b8b209",
                            color: "white",

                            margin: "10px",
                          }}
                        >
                          Max: {MaxCellVolt} mV
                        </Badge>
                        <Badge
                          style={{
                            backgroundColor: "rgba(100, 100, 100, 1)",
                            color: "white",

                            margin: "10px",
                          }}
                        >
                          Diff: {MaxCellVolt - MinCellVolt} mV
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Bar
                        data={batteryCellsChart.data}
                        options={batteryCellsChart.options}
                      />
                      <Row>
                        <Col md="3"></Col>
                        <Col md="3" className="card-head">
                          Cell Number
                        </Col>
                        {/* <Col md="3">
                      {" "}
                      <img src={Arrow} />
                    </Col> */}
                        <Col md="3"></Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              ) : (
                <div>
                  <Spinner>Loading...</Spinner>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Spinner>Loading...</Spinner>
      </div>
    );
  }
}

export default BatteryCellFor24SPlus;
