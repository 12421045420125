
import { Link, Redirect } from "react-router-dom";
import React, { useState, useContext } from "react";
import Card from "reactstrap/lib/Card";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Select from "react-select";
import Warning from "assets/img/subuser.png";
import Online from "assets/img/online.png"
import Offline from "assets/img/offline.png"
import Alarm from "assets/img/alarm.png"
import Location from "assets/img/location.png"
import OutOfOrder from "assets/img/outOfOrder.png"
import { UncontrolledTooltip } from "reactstrap";
import { subUserDetailsContext, userDetailsContext } from "layouts/Admin";
function SubUsers() {
  const getSubUsers = useContext(subUserDetailsContext)
  const userData = useContext(userDetailsContext)
  const [showSearchedItem, setShowSearchedItem] = useState(false)
  const [showSubUserData, setShowSubUserData] = useState(false)
  const [searchedItem, setSearchedItem] = useState()
  const [subUserId, setSubUserId] = useState()
  const getSubUserId = (event) => {
    setSubUserId(event)
    setShowSubUserData(true)
  };
  const handleSearchValue = (val) => {
    if (val.value === 'All') {
      setSearchedItem(null)
      setShowSearchedItem(false)
    }
    else {
      setSearchedItem(val.value)
      setShowSearchedItem(true)
    }
  }
  const styler = {
    margin: "15px",
    padding: "15px",
    textAlign: "center",
    color: "#6da2f7",
  };
  if (window.localStorage.getItem("role") === "sub_owner") {
    return (
      <Redirect
        to={{
          pathname: "/admin/bmsdevices",
          state: null,
        }}
      ></Redirect>
    );
  } else {
    if (getSubUsers && userData) {
      let subUserList = [];
      for (let i = 0; i < getSubUsers.length; i++) {
        subUserList[i + 1] = {
          value: getSubUsers[i].id,
          label: getSubUsers[i].userProfile.name,
        };
        subUserList[0] = {
          value: "All",
          label: "All Sub Operators"
        }
      }
      if (showSubUserData) {
        return (
          <Redirect
            to={{
              pathname: "/admin/bmsdevices",
              state: subUserId,
            }}
          ></Redirect>
        );
      } else {
        return (
          <div className="main-margin">
            <Row>
              <Col md="4" lg="4" sm="12" xs="12" xl="4">
                <Select
                  options={subUserList}
                  onChange={handleSearchValue}
                />
              </Col>
            </Row>
            <UncontrolledTooltip placement="top" target="Online">Online</UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="Offline">Offline</UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="OutOfOrder">OutOfOrder</UncontrolledTooltip>
            <Row>
              {showSearchedItem ? getSubUsers.map((val) => (
                searchedItem === val.id ?
                  <Col md="4" lg="4" sm="12" xs="12" xl="4">
                    <Card style={styler}>
                      <Row
                        onClick={val.total ? () => getSubUserId(val.id) : ''}
                        style={{ cursor: val.total ? "pointer" : '' }}
                      >
                        <Col md="4" lg="4" sm="12" xs="12" xl="4">
                          <img
                            src={Warning}
                            alt="test"
                          ></img>
                        </Col>
                        <Col md="8" lg="8" sm="12" xs="12" xl="8">
                          <h6>{val.userProfile.name}</h6>
                          <p> {val.userProfile.email}</p>
                          <p>Total: {val.total ? val.total : 0}</p>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>
                          <img src={Online} alt='online' id='Online'></img>
                          <div>{val.online ? val.online : 0}</div>
                        </Col>
                        <Col>
                          <img src={Offline} alt='offline' id='Offline'></img>
                          <div>{val.offline ? val.offline : 0}</div>
                        </Col>
                        <Col>
                          <img src={OutOfOrder} alt='OutOfOrder' id='OutOfOrder'></img>
                          <div>{val.outOfOrder ? val.outOfOrder : 0}</div>
                        </Col>
                        {val.total ? <>
                          <UncontrolledTooltip placement="top" target="Location">Location</UncontrolledTooltip>
                          <UncontrolledTooltip placement="top" target="Notification">Notification</UncontrolledTooltip>
                         <Col>
                          <Link to={{ pathname: '/admin/mappro', state: val.id }}><img src={Location} alt='location' id='Location'></img></Link>
                        </Col>
                          <Col>
                            <Link
                              to={{
                                pathname: "/admin/notifications",
                                state: val.id,
                              }}
                            >
                              <img src={Alarm} alt='alarm' id='Notification'></img><c
                                style={{
                                  position: "absolute",
                                  top: "-6px",
                                }}
                              >
                                {val.unreadNotifications ? <strong
                                  style={{
                                    backgroundColor: 'red',
                                    color: "white",
                                    padding: "1px",
                                    border: 'red solid 1px',
                                    borderRadius: '5px',
                                    fontSize: '8px',
                                    margin: "0 2px"
                                  }}
                                >
                                  {val.unreadNotifications}
                                </strong> : ''}
                              </c>
                            </Link>
                          </Col></> : ''}
                      </Row>
                    </Card>
                  </Col> : ''
              )) : getSubUsers.map((val) => (
                <Col md="4" lg="4" sm="12" xs="12" xl="4">
                  <Card style={styler}>
                    <Row
                      onClick={val.total ? () => getSubUserId(val.id) : ''}
                      style={{ cursor: val.total ? "pointer" : '' }}
                    >
                      <Col md="4" lg="4" sm="12" xs="12" xl="4">
                        <img
                          src={Warning}
                          alt="test"
                          style={{ minWidth: "50px", maxWidth: "75px" }}
                        ></img>
                      </Col>
                      <Col md="8" lg="8" sm="12" xs="12" xl="8">
                        <h6>{val.userProfile.name}</h6>
                        <p> {val.userProfile.email}</p>
                        <p>Total: {val.total ? val.total : 0}</p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <img src={Online} alt='online' id='Online'></img>
                        <div>{val.online ? val.online : 0}</div>
                      </Col>
                      <Col>
                        <img src={Offline} alt='offline' id='Offline'></img>
                        <div>{val.offline ? val.offline : 0}</div>
                      </Col>
                      <Col>
                        <img src={OutOfOrder} alt='OutOfOrder' id='OutOfOrder'></img>
                        <div>{val.outOfOrder ? val.outOfOrder : 0}</div>
                      </Col>
                      {val.total ?
                       <>
                       <UncontrolledTooltip placement="top" target="Location">Location</UncontrolledTooltip>
                       <UncontrolledTooltip placement="top" target="Notification">Notification</UncontrolledTooltip>
                        <Col>
                          <Link to={{ pathname: '/admin/mappro', state: { subUserId: val.id } }}><img src={Location} alt='location' id='Location'></img></Link></Col>
                        <Col>
                          <Link
                            to={{
                              pathname: "/admin/notifications",
                              state: val.id,
                            }}
                          >
                            <img src={Alarm} alt='alarm' id='Notification'></img><c
                              style={{
                                position: "absolute",
                                top: "-6px",
                              }}
                            >
                              {val.unreadNotifications ? <strong
                                style={{
                                  backgroundColor: 'red',
                                  color: "white",
                                  padding: "1px",
                                  border: 'red solid 1px',
                                  borderRadius: '5px',
                                  fontSize: '8px',
                                  margin: "0 2px"
                                }}
                              >
                                {val.unreadNotifications}
                              </strong> : ''}
                            </c>
                          </Link>
                        </Col></> : ''}
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        );
      }
    } else {
      return (
        <div className="content">
          <div className="loadingContainer">
            <div className="ball1"></div>
            <div className="ball2"></div>
            <div className="ball3"></div>
            <div className="ball4"></div>
          </div>
        </div>
      );
    }
  }
}

export default SubUsers;