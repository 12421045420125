import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  NavItem,
  NavLink,
  Nav,
  Button,
} from "reactstrap";
import { API_URL } from "variables/general";

function EditConfiguration(props) {
  const [iconPills, setIconPills] = useState("1");
  useEffect(() => {
    if (props.showConfigData) showData();
  }, [props.showConfigData]);
  const byteArrayToInt4 = (byteArray) => {
    let out =
      (byteArray[3] << 24) |
      (byteArray[2] << 16) |
      (byteArray[1] << 8) |
      byteArray[0];
    return out;
  };
  const byteArrayToInt2 = (byteArray) => {
    let out = (byteArray[1] << 8) | byteArray[0];
    return out;
  };
  let configurationArray = props.configurationArray;

  let startIndex = 0;
  let NumberOfCells = configurationArray[startIndex++];
  let NumberOfTemp = configurationArray[startIndex++];
  let TypeOfCell = configurationArray[startIndex++];
  let CellManufacturer = configurationArray[startIndex++];
  let CAN_Status = configurationArray[startIndex++];
  let BLE_Status = configurationArray[startIndex++];
  let IOT_Status = configurationArray[startIndex++];
  let IMU_Status = configurationArray[startIndex++];
  let BarGraph_Status = configurationArray[startIndex++];
  let DischargingRate = configurationArray[startIndex++];
  let MemorySize = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let DesignCapacity = byteArrayToInt4([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let CycleCapacity = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let FullChargeVol = byteArrayToInt4([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let ChargeEndVol = byteArrayToInt4([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let CycleCount = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let ChgOverTemp = configurationArray[startIndex++];
  let ChgOTRelease = configurationArray[startIndex++];
  let ChgUnderTemp = configurationArray[startIndex++];
  let ChgUTRelease = configurationArray[startIndex++];
  let LoadOverTemp = configurationArray[startIndex++];
  let LoadOTRelease = configurationArray[startIndex++];
  let LoadLowTemp = configurationArray[startIndex++];
  let LoadUTRelease = configurationArray[startIndex++];
  let DisOverTemp = configurationArray[startIndex++];
  let DsgOTRelease = configurationArray[startIndex++];
  let DisLowTemp = configurationArray[startIndex++];
  let DsgUTRelease = configurationArray[startIndex++];
  let PackOverVoltage = byteArrayToInt4([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]); //Voltages are in milliVolt
  let PackOVRelease = byteArrayToInt4([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let PackUnderVoltage = byteArrayToInt4([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let PackUVRelease = byteArrayToInt4([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let CellFullChrVoltage = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let CellNominalVoltage = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let CellOverVoltage = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let CellOVRelease = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let CellUnderVoltage = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let CellUVRelease = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let CellUnBalProtec = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]); //Cell Unbalance Protection
  let OverChargeCurrent = byteArrayToInt4([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]); //Current are in milliAmp
  let OverDisCurrent = byteArrayToInt4([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let BalanceStartVoltage = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let SenseResistor = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let BatteryConfig = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let NtcConfig = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let PackNum = byteArrayToInt4([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let fet_ctrl_time_set = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let led_disp_time_set = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let VoltageCap80 = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let VoltageCap60 = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let VoltageCap40 = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let VoltageCap20 = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let HardCellOverVoltage = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let HardCellUnderVoltage = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let HardPackOverVoltage = byteArrayToInt4([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let HardPackUnderVoltage = byteArrayToInt4([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let HardChgOverCurrent = byteArrayToInt4([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let HardDsgOverCurrent = byteArrayToInt4([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let HardTime = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]); //All below time in milliSec
  let SCReleaseTime = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]); //Short Circuit Release time milliSec
  let ChgUTDelay = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let ChgOTDelay = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let DsgUTDelay = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let DsgOTDelay = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let PackUVDelay = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let PackOVDelay = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let CellUVDelay = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let CellOVDelay = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let ChgOCDelay = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let ChgOCRDelay = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let DsgOCDelay = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let DsgOCRDelay = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let GPS_TIME = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let GPS_VOL = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let PreChargeTime = byteArrayToInt2([
    configurationArray[startIndex++],
    configurationArray[startIndex++],
  ]);
  let BalanceWindow = configurationArray[startIndex++]; //max 255 milliVolt
  let ReserveCapacityS1 = configurationArray[startIndex++];
  let ReserveCapacityS2 = configurationArray[startIndex++];
  let DataEnd = configurationArray[startIndex++];
  const [editNumberOfCells, seteditNumberOfCells] = useState(NumberOfCells);
  const [editNumberOfTemp, seteditNumberOfTemp] = useState(NumberOfTemp);
  const [editTypeOfCell, seteditTypeOfCell] = useState(TypeOfCell);
  const [editCellManufacturer, seteditCellManufacturer] =
    useState(CellManufacturer);
  const [editCAN_Status, seteditCAN_Status] = useState(CAN_Status);
  const [editBLE_Status, seteditBLE_Status] = useState(BLE_Status);
  const [editIOT_Status, seteditIOT_Status] = useState(IOT_Status);
  const [editIMU_Status, seteditIMU_Status] = useState(IMU_Status);
  const [editBarGraph_Status, seteditBarGraph_Status] =
    useState(BarGraph_Status);
  const [editDischargingRate, seteditDischargingRate] =
    useState(DischargingRate);
  const [editMemorySize, seteditMemorySize] = useState(MemorySize);
  const [editDesignCapacity, seteditDesignCapacity] = useState(DesignCapacity);
  const [editCycleCapacity, seteditCycleCapacity] = useState(CycleCapacity);
  const [editFullChargeVol, seteditFullChargeVol] = useState(FullChargeVol);
  const [editChargeEndVol, seteditChargeEndVol] = useState(ChargeEndVol);
  const [editCycleCount, seteditCycleCount] = useState(CycleCount);
  const [editChgOverTemp, seteditChgOverTemp] = useState(ChgOverTemp);
  const [editChgOTRelease, seteditChgOTRelease] = useState(ChgOTRelease);
  const [editChgUnderTemp, seteditChgUnderTemp] = useState(ChgUnderTemp);
  const [editChgUTRelease, seteditChgUTRelease] = useState(ChgUTRelease);
  const [editLoadOverTemp, seteditLoadOverTemp] = useState(LoadOverTemp);
  const [editLoadOTRelease, seteditLoadOTRelease] = useState(LoadOTRelease);
  const [editLoadLowTemp, seteditLoadLowTemp] = useState(LoadLowTemp);
  const [editLoadUTRelease, seteditLoadUTRelease] = useState(LoadUTRelease);
  const [editDisOverTemp, seteditDisOverTemp] = useState(DisOverTemp);
  const [editDsgOTRelease, seteditDsgOTRelease] = useState(DsgOTRelease);
  const [editDisLowTemp, seteditDisLowTemp] = useState(DisLowTemp);
  const [editDsgUTRelease, seteditDsgUTRelease] = useState(DsgUTRelease);
  const [editPackOverVoltage, seteditPackOverVoltage] =
    useState(PackOverVoltage);
  const [editPackOVRelease, seteditPackOVRelease] = useState(PackOVRelease);
  const [editPackUnderVoltage, seteditPackUnderVoltage] =
    useState(PackUnderVoltage);
  const [editPackUVRelease, seteditPackUVRelease] = useState(PackUVRelease);
  const [editCellFullChrVoltage, seteditCellFullChrVoltage] =
    useState(CellFullChrVoltage);
  const [editCellNominalVoltage, seteditCellNominalVoltage] =
    useState(CellNominalVoltage);
  const [editCellOverVoltage, seteditCellOverVoltage] =
    useState(CellOverVoltage);
  const [editCellOVRelease, seteditCellOVRelease] = useState(CellOVRelease);
  const [editCellUnderVoltage, seteditCellUnderVoltage] =
    useState(CellUnderVoltage);
  const [editCellUVRelease, seteditCellUVRelease] = useState(CellUVRelease);
  const [editCellUnBalProtec, seteditCellUnBalProtec] =
    useState(CellUnBalProtec);
  const [editOverChargeCurrent, seteditOverChargeCurrent] =
    useState(OverChargeCurrent);
  const [editOverDisCurrent, seteditOverDisCurrent] = useState(OverDisCurrent);
  const [editBalanceStartVoltage, seteditBalanceStartVoltage] =
    useState(BalanceStartVoltage);
  const [editSenseResistor, seteditSenseResistor] = useState(SenseResistor);
  const [editBatteryConfig, seteditBatteryConfig] = useState(BatteryConfig);
  const [editNtcConfig, seteditNtcConfig] = useState(NtcConfig);
  const [editPackNum, seteditPackNum] = useState(PackNum);
  const [editfet_ctrl_time_set, seteditfet_ctrl_time_set] =
    useState(fet_ctrl_time_set);
  const [editled_disp_time_set, seteditled_disp_time_set] =
    useState(led_disp_time_set);
  const [editVoltageCap80, seteditVoltageCap80] = useState(VoltageCap80);
  const [editVoltageCap60, seteditVoltageCap60] = useState(VoltageCap60);
  const [editVoltageCap40, seteditVoltageCap40] = useState(VoltageCap40);
  const [editVoltageCap20, seteditVoltageCap20] = useState(VoltageCap20);
  const [editHardCellOverVoltage, seteditHardCellOverVoltage] =
    useState(HardCellOverVoltage);
  const [editHardCellUnderVoltage, seteditHardCellUnderVoltage] =
    useState(HardCellUnderVoltage);
  const [editHardPackOverVoltage, seteditHardPackOverVoltage] =
    useState(HardPackOverVoltage);
  const [editHardPackUnderVoltage, seteditHardPackUnderVoltage] =
    useState(HardPackUnderVoltage);
  const [editHardChgOverCurrent, seteditHardChgOverCurrent] =
    useState(HardChgOverCurrent);
  const [editHardDsgOverCurrent, seteditHardDsgOverCurrent] =
    useState(HardDsgOverCurrent);
  const [editHardTime, seteditHardTime] = useState(HardTime);
  const [editSCReleaseTime, seteditSCReleaseTime] = useState(SCReleaseTime);
  const [editChgUTDelay, seteditChgUTDelay] = useState(ChgUTDelay);
  const [editChgOTDelay, seteditChgOTDelay] = useState(ChgOTDelay);
  const [editDsgUTDelay, seteditDsgUTDelay] = useState(DsgUTDelay);
  const [editDsgOTDelay, seteditDsgOTDelay] = useState(DsgOTDelay);
  const [editPackUVDelay, seteditPackUVDelay] = useState(PackUVDelay);
  const [editPackOVDelay, seteditPackOVDelay] = useState(PackOVDelay);
  const [editCellUVDelay, seteditCellUVDelay] = useState(CellUVDelay);
  const [editCellOVDelay, seteditCellOVDelay] = useState(CellOVDelay);
  const [editChgOCDelay, seteditChgOCDelay] = useState(ChgOCDelay);
  const [editChgOCRDelay, seteditChgOCRDelay] = useState(ChgOCRDelay);
  const [editDsgOCDelay, seteditDsgOCDelay] = useState(DsgOCDelay);
  const [editDsgOCRDelay, seteditDsgOCRDelay] = useState(DsgOCRDelay);
  const [editGPS_TIME, seteditGPS_TIME] = useState(GPS_TIME);
  const [editGPS_VOL, seteditGPS_VOL] = useState(GPS_VOL);
  const [editPreChargeTime, seteditPreChargeTime] = useState(PreChargeTime);
  const [editBalanceWindow, seteditBalanceWindow] = useState(BalanceWindow);
  const [editReserveCapacityS1, seteditReserveCapacityS1] =
    useState(ReserveCapacityS1);
  const [editReserveCapacityS2, seteditReserveCapacityS2] =
    useState(ReserveCapacityS2);
  const [editDataEnd, seteditDataEnd] = useState(DataEnd);
  const integerTo4ByteArray = (integer) => {
    let out = [];

    out[3] = (integer >> 24) & 0xff;
    out[2] = (integer >> 16) & 0xff;
    out[1] = (integer >> 8) & 0xff;
    out[0] = integer & 0xff;

    return out;
  };
  const integerTo2ByteArray = (integer) => {
    let out = [];
    out[1] = (integer >> 8) & 0xff;
    out[0] = integer & 0xff;

    return out;
  };
  // const getConfigurationSettings = (subOwnerId) => {
  //   console.log('settings')
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append(
  //     "Authorization",
  //     "Bearer " + window.localStorage.getItem("token")
  //   );
  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: JSON.stringify({ model: 'SN14S50' }),
  //   };
  //   fetch(API_URL + "/bms/app_get_profile", requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       seteditCellFullChrVoltage(result.data.cellSettingsDataModel.cellFullChargeVoltage)
  //       seteditCellNominalVoltage(result.data.cellSettingsDataModel.cellNominalVoltage)
  //       seteditCellOverVoltage(result.data.cellSettingsDataModel.cellOverVoltageLimit)
  //       seteditCellOVDelay(result.data.cellSettingsDataModel.cellOverVoltageDelay)
  //       seteditCellOVRelease(result.data.cellSettingsDataModel.cellOverVoltageRelease)
  //       seteditCellUnBalProtec(result.data.cellSettingsDataModel.cellUnbalanceProtection)
  //       seteditCellUnderVoltage(result.data.cellSettingsDataModel.cellUnderVoltage)
  //       seteditCellUVDelay(result.data.cellSettingsDataModel.cellUnderVoltageDelay)
  //       seteditCellUVRelease(result.data.cellSettingsDataModel.cellUnderVoltageRelease)
  //       // seteditCell(result.data.cellSettingsDataModel.diffMinMax)
  //       seteditHardCellOverVoltage(result.data.cellSettingsDataModel.hardCellOverVoltage)
  //       seteditHardCellUnderVoltage(result.data.cellSettingsDataModel.hardCellUnderVoltage)
  //       seteditBalanceStartVoltage(result.data.cellSettingsDataModel.startBalancingVoltage)
  //     })
  //     .catch((error) => console.log("error", error));
  // };
  const showData = () => {
    seteditNumberOfCells(NumberOfCells);
    seteditNumberOfTemp(NumberOfTemp);
    seteditTypeOfCell(TypeOfCell);
    seteditCellManufacturer(CellManufacturer);
    seteditCAN_Status(CAN_Status);
    seteditBLE_Status(BLE_Status);
    seteditIOT_Status(IOT_Status);
    seteditIMU_Status(IMU_Status);
    seteditBarGraph_Status(BarGraph_Status);
    seteditDischargingRate(DischargingRate);
    seteditMemorySize(MemorySize);
    seteditDesignCapacity(DesignCapacity);
    seteditCycleCapacity(CycleCapacity);
    seteditFullChargeVol(FullChargeVol);
    seteditChargeEndVol(ChargeEndVol);
    seteditCycleCount(CycleCount);
    seteditChgOverTemp(ChgOverTemp);
    seteditChgOTRelease(ChgOTRelease);
    seteditChgUnderTemp(ChgUnderTemp);
    seteditChgUTRelease(ChgUTRelease);
    seteditLoadOverTemp(LoadOverTemp);
    seteditLoadOTRelease(LoadOTRelease);
    seteditLoadLowTemp(LoadLowTemp);
    seteditLoadUTRelease(LoadUTRelease);
    seteditDisOverTemp(DisOverTemp);
    seteditDsgOTRelease(DsgOTRelease);
    seteditDisLowTemp(DisLowTemp);
    seteditDsgUTRelease(DsgUTRelease);
    seteditPackOverVoltage(PackOverVoltage);
    seteditPackOVRelease(PackOVRelease);
    seteditPackUnderVoltage(PackUnderVoltage);
    seteditPackUVRelease(PackUVRelease);
    seteditCellFullChrVoltage(CellFullChrVoltage);
    seteditCellNominalVoltage(CellNominalVoltage);
    seteditCellOverVoltage(CellOverVoltage);
    seteditCellOVRelease(CellOVRelease);
    seteditCellUnderVoltage(CellUnderVoltage);
    seteditCellUVRelease(CellUVRelease);
    seteditCellUnBalProtec(CellUnBalProtec);
    seteditOverChargeCurrent(OverChargeCurrent);
    seteditOverDisCurrent(OverDisCurrent);
    seteditBalanceStartVoltage(BalanceStartVoltage);
    seteditSenseResistor(SenseResistor);
    seteditBatteryConfig(BatteryConfig);
    seteditNtcConfig(NtcConfig);
    seteditPackNum(PackNum);
    seteditfet_ctrl_time_set(fet_ctrl_time_set);
    seteditled_disp_time_set(led_disp_time_set);
    seteditVoltageCap80(VoltageCap80);
    seteditVoltageCap60(VoltageCap60);
    seteditVoltageCap40(VoltageCap40);
    seteditVoltageCap20(VoltageCap20);
    seteditHardCellOverVoltage(HardCellOverVoltage);
    seteditHardCellUnderVoltage(HardCellUnderVoltage);
    seteditHardPackOverVoltage(HardPackOverVoltage);
    seteditHardPackUnderVoltage(HardPackUnderVoltage);
    seteditHardChgOverCurrent(HardChgOverCurrent);
    seteditHardDsgOverCurrent(HardDsgOverCurrent);
    seteditHardTime(HardTime);
    seteditSCReleaseTime(SCReleaseTime);
    seteditChgUTDelay(ChgUTDelay);
    seteditChgOTDelay(ChgOTDelay);
    seteditDsgUTDelay(DsgUTDelay);
    seteditDsgOTDelay(DsgOTDelay);
    seteditPackUVDelay(PackUVDelay);
    seteditPackOVDelay(PackOVDelay);
    seteditCellUVDelay(CellUVDelay);
    seteditCellOVDelay(CellOVDelay);
    seteditChgOCDelay(ChgOCDelay);
    seteditChgOCRDelay(ChgOCRDelay);
    seteditDsgOCDelay(DsgOCDelay);
    seteditDsgOCRDelay(DsgOCRDelay);
    seteditGPS_TIME(GPS_TIME);
    seteditGPS_VOL(GPS_VOL);
    seteditPreChargeTime(PreChargeTime);
    seteditBalanceWindow(BalanceWindow);
    seteditReserveCapacityS1(ReserveCapacityS1);
    seteditReserveCapacityS2(ReserveCapacityS2);
    seteditDataEnd(DataEnd);
  };

  let editConfigArray = [];
  let editConfigIndex = 0;
  const sendConfiguration = (second) => {
    // uint8_t
    editConfigArray[editConfigIndex++] = editNumberOfCells;
    // uint8_t
    editConfigArray[editConfigIndex++] = editNumberOfTemp;
    // uint8_t
    editConfigArray[editConfigIndex++] = editTypeOfCell;
    // uint8_t
    editConfigArray[editConfigIndex++] = editCellManufacturer;
    // uint8_t
    editConfigArray[editConfigIndex++] = editCAN_Status;
    // uint8_t
    editConfigArray[editConfigIndex++] = editBLE_Status;
    // uint8_t
    editConfigArray[editConfigIndex++] = editIOT_Status;
    // uint8_t
    editConfigArray[editConfigIndex++] = editIMU_Status;
    // uint8_t
    editConfigArray[editConfigIndex++] = editBarGraph_Status;
    // uint8_t
    editConfigArray[editConfigIndex++] = editDischargingRate;
    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editMemorySize)[0]; //Kilobyte
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editMemorySize)[1]; //Kilobyte
    // uint32_t
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editDesignCapacity)[0];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editDesignCapacity)[1];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editDesignCapacity)[2];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editDesignCapacity)[3];

    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editCycleCapacity)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editCycleCapacity)[1];

    // uint32_t

    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editFullChargeVol)[0];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editFullChargeVol)[1];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editFullChargeVol)[2];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editFullChargeVol)[3];

    // uint32_t

    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editChargeEndVol)[0];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editChargeEndVol)[1];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editChargeEndVol)[2];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editChargeEndVol)[3];

    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editCycleCount)[0];
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editCycleCount)[1];
    // int8_t
    editConfigArray[editConfigIndex++] = editChgOverTemp;
    // int8_t
    editConfigArray[editConfigIndex++] = editChgOTRelease;
    // int8_t
    editConfigArray[editConfigIndex++] = editChgUnderTemp;
    // int8_t
    editConfigArray[editConfigIndex++] = editChgUTRelease;
    // int8_t
    editConfigArray[editConfigIndex++] = editLoadOverTemp;
    // int8_t
    editConfigArray[editConfigIndex++] = editLoadOTRelease;
    // int8_t
    editConfigArray[editConfigIndex++] = editLoadLowTemp;
    // int8_t
    editConfigArray[editConfigIndex++] = editLoadUTRelease;
    // int8_t
    editConfigArray[editConfigIndex++] = editDisOverTemp;
    // int8_t
    editConfigArray[editConfigIndex++] = editDsgOTRelease;
    // int8_t
    editConfigArray[editConfigIndex++] = editDisLowTemp;
    // int8_t
    editConfigArray[editConfigIndex++] = editDsgUTRelease;
    // uint32_t

    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editPackOverVoltage)[0];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editPackOverVoltage)[1];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editPackOverVoltage)[2];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editPackOverVoltage)[3];
    // uint32_t

    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editPackOVRelease)[0];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editPackOVRelease)[1];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editPackOVRelease)[2];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editPackOVRelease)[3];
    // uint32_t

    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editPackUnderVoltage)[0];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editPackUnderVoltage)[1];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editPackUnderVoltage)[2];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editPackUnderVoltage)[3];
    // uint32_t

    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editPackUVRelease)[0];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editPackUVRelease)[1];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editPackUVRelease)[2];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editPackUVRelease)[3];

    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(
      editCellFullChrVoltage
    )[0];
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(
      editCellFullChrVoltage
    )[1];
    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(
      editCellNominalVoltage
    )[0];
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(
      editCellNominalVoltage
    )[1];
    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editCellOverVoltage)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editCellOverVoltage)[1];

    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editCellOVRelease)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editCellOVRelease)[1];

    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editCellUnderVoltage)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editCellUnderVoltage)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editCellUVRelease)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editCellUVRelease)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editCellUnBalProtec)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editCellUnBalProtec)[1]; //Cell Unbalance Protection
    // uint32_t

    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editOverChargeCurrent
    )[0];
    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editOverChargeCurrent
    )[1];
    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editOverChargeCurrent
    )[2];
    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editOverChargeCurrent
    )[3];
    //Current are in milliAmp
    // uint32_t

    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editOverDisCurrent)[0];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editOverDisCurrent)[1];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editOverDisCurrent)[2];
    editConfigArray[editConfigIndex++] =
      integerTo4ByteArray(editOverDisCurrent)[3];

    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(
      editBalanceStartVoltage
    )[0];
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(
      editBalanceStartVoltage
    )[1];
    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editSenseResistor)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editSenseResistor)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editBatteryConfig)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editBatteryConfig)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editNtcConfig)[0];
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editNtcConfig)[1];
    // uint32_t

    editConfigArray[editConfigIndex++] = integerTo4ByteArray(editPackNum)[0];
    editConfigArray[editConfigIndex++] = integerTo4ByteArray(editPackNum)[1];
    editConfigArray[editConfigIndex++] = integerTo4ByteArray(editPackNum)[2];
    editConfigArray[editConfigIndex++] = integerTo4ByteArray(editPackNum)[3];
    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(
      editfet_ctrl_time_set
    )[0];
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(
      editfet_ctrl_time_set
    )[1];

    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(
      editled_disp_time_set
    )[0];
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(
      editled_disp_time_set
    )[1];
    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editVoltageCap80)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editVoltageCap80)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editVoltageCap60)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editVoltageCap60)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editVoltageCap40)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editVoltageCap40)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editVoltageCap20)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editVoltageCap20)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(
      editHardCellOverVoltage
    )[0];
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(
      editHardCellOverVoltage
    )[1];
    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(
      editHardCellUnderVoltage
    )[0];
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(
      editHardCellUnderVoltage
    )[1];
    // uint32_t

    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editHardPackOverVoltage
    )[0];
    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editHardPackOverVoltage
    )[1];
    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editHardPackOverVoltage
    )[2];
    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editHardPackOverVoltage
    )[3];

    // uint32_t

    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editHardPackUnderVoltage
    )[0];
    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editHardPackUnderVoltage
    )[1];
    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editHardPackUnderVoltage
    )[2];
    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editHardPackUnderVoltage
    )[3];

    // uint32_t

    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editHardChgOverCurrent
    )[0];
    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editHardChgOverCurrent
    )[1];
    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editHardChgOverCurrent
    )[2];
    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editHardChgOverCurrent
    )[3];

    // uint32_t

    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editHardDsgOverCurrent
    )[0];
    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editHardDsgOverCurrent
    )[1];
    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editHardDsgOverCurrent
    )[2];
    editConfigArray[editConfigIndex++] = integerTo4ByteArray(
      editHardDsgOverCurrent
    )[3];

    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editHardTime)[0];
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editHardTime)[1]; //All below time in milliSec
    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editSCReleaseTime)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editSCReleaseTime)[1]; //Short Circuit Release time milliSec
    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editChgUTDelay)[0];
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editChgUTDelay)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editChgOTDelay)[0];
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editChgOTDelay)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editDsgUTDelay)[0];
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editDsgUTDelay)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editDsgOTDelay)[0];
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editDsgOTDelay)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editPackUVDelay)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editPackUVDelay)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editPackOVDelay)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editPackOVDelay)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editCellUVDelay)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editCellUVDelay)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editCellOVDelay)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editCellOVDelay)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editChgOCDelay)[0];
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editChgOCDelay)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editChgOCRDelay)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editChgOCRDelay)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editDsgOCDelay)[0];
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editDsgOCDelay)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editDsgOCRDelay)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editDsgOCRDelay)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editGPS_TIME)[0];
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editGPS_TIME)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editGPS_VOL)[0];
    editConfigArray[editConfigIndex++] = integerTo2ByteArray(editGPS_VOL)[1];
    // uint16_t
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editPreChargeTime)[0];
    editConfigArray[editConfigIndex++] =
      integerTo2ByteArray(editPreChargeTime)[1];
    // uint8_t
    editConfigArray[editConfigIndex++] = editBalanceWindow; //max 255 milliVolt
    // uint8_t
    editConfigArray[editConfigIndex++] = editReserveCapacityS1;
    // uint8_t
    editConfigArray[editConfigIndex++] = editReserveCapacityS2;
    // uint8_t
    editConfigArray[editConfigIndex++] = editDataEnd;
    props.sendConfig(editConfigArray);
  };
  const configurationDetails = {
    cell: [
      {
        name: "Balance Start Voltage",
        value: editBalanceStartVoltage,
        limit: "0-5000",
        unit: "mV",
        id: "BalanceStartVoltage",
      },
      {
        name: "Balance Window",
        value: editBalanceWindow,
        limit: "0-255",
        unit: "mV",
        id: "BalanceWindow",
      },
      {
        name: "Cell Full Charge Voltage",
        value: editCellFullChrVoltage,
        limit: "0-5000",
        unit: "mV",
        id: "CellFullChrVoltage",
      },
      {
        name: "Cell Nominal Voltage",
        value: editCellNominalVoltage,
        limit: "0-5000",
        unit: "mV",
        id: "CellNominalVoltage",
      },
      {
        name: "Cell Under Voltage",
        value: editCellUnderVoltage,
        limit: "0-5000",
        unit: "mV",
        id: "CellUnderVoltage",
      },
      {
        name: "Cell Over Voltage",
        value: editCellOverVoltage,
        limit: "0-5000",
        unit: "mV",
        id: "CellOverVoltage",
      },
      {
        name: "Cell Over Voltage Release",
        value: editCellOVRelease,
        limit: "0-5000",
        unit: "mV",
        id: "CellOVRelease",
      },
      {
        name: "Cell Over Voltage Delay",
        value: editCellOVDelay / 10,
        limit: "0-65534",
        unit: "s",
        id: "CellOVDelay",
      },
      {
        name: "Hard Cell Over Voltage",
        value: editHardCellOverVoltage,
        limit: "0-5000",
        unit: "mV",
        id: "HardCellOverVoltage",
      },
      {
        name: "Cell Under Voltage Release",
        value: editCellUVRelease,
        limit: "0-5000",
        unit: "mV",
        id: "CellUVRelease",
      },
      {
        name: "Cell Under Voltage Delay",
        value: editCellUVDelay / 10,
        limit: "0-65535",
        unit: "s",
        id: "CellUVDelay",
      },
      {
        name: "Hard Cell Under Voltage",
        value: editHardCellUnderVoltage,
        limit: "0-5000",
        unit: "mV",
        id: "HardCellUnderVoltage",
      },
    ],
    pack: [
      {
        name: "CAN Baudrate",
        value: editPackNum,
        limit: "125-1000",
        unit: "kbps",
        id: "Baudrate",
      },
      {
        name: "Number Of Temp",
        value: editNumberOfTemp,
        limit: "0-255",
        unit: "",
        id: "NumberOfTemp",
      },
      {
        name: "Type Of Cell",
        value: editTypeOfCell,
        limit: "0-20",
        unit: "",
        id: "TypeOfCell",
      },
      {
        name: "Design Capacity",
        value: editDesignCapacity,
        limit: "0-655355",
        unit: "mAh",
        id: "DesignCapacity",
      },
      {
        name: "Cycle Capacity",
        value: editCycleCapacity,
        limit: "0-655355",
        unit: "",
        id: "CycleCapacity",
      },
      {
        name: "Full Charge Voltage",
        value: editFullChargeVol,
        limit: "0-655355",
        unit: "mV",
        id: "FullChargeVol",
      },
      {
        name: "Charge End Voltage",
        value: editChargeEndVol,
        limit: "0-655355",
        unit: "mV",
        id: "ChargeEndVol",
      },
      {
        name: "Discharging Rate",
        value: editDischargingRate,
        limit: "0-50",
        unit: "C",
        id: "DischargingRate",
      },
      {
        name: "Pack Over Voltage",
        value: editPackOverVoltage,
        limit: "0-655355",
        unit: "mV",
        id: "PackOverVoltage",
      },
      {
        name: "Pack Over Voltage Release",
        value: editPackOVRelease,
        limit: "0-655355",
        unit: "mV",
        id: "PackOVRelease",
      },
      {
        name: "Pack Over Voltage Delay",
        value: editPackOVDelay / 10,
        limit: "0-65535",
        unit: "s",
        id: "PackOVDelay",
      },
      {
        name: "Hard Pack Over Voltage",
        value: editHardPackOverVoltage,
        limit: "0-655355",
        unit: "mV",
        id: "HardPackOverVoltage",
      },
      {
        name: "Pack Under Voltage",
        value: editPackUnderVoltage,
        limit: "0-655355",
        unit: "mV",
        id: "PackUnderVoltage",
      },
      {
        name: "Pack Under Voltage Release",
        value: editPackUVRelease,
        limit: "0-655355",
        unit: "mV",
        id: "PackUVRelease",
      },
      {
        name: "Pack Under Voltage Delay",
        value: editPackUVDelay / 10,
        limit: "0-65535",
        unit: "s",
        id: "PackUVDelay",
      },
      {
        name: "Hard Pack Under Voltage",
        value: editHardPackUnderVoltage,
        limit: "0-655355",
        unit: "mV",
        id: "HardPackUnderVoltage",
      },
    ],
    temp: [
      {
        name: "Charge Over Temperature",
        value: editChgOverTemp,
        limit: "-40-120",
        unit: "°C",
        id: "ChgOverTemp",
      },
      {
        name: "Charge Over Temperature Release",
        value: editChgOTRelease,
        limit: "-40-120",
        unit: "°C",
        id: "ChgOTRelease",
      },
      {
        name: "Charge Over Temperature Delay",
        value: editChgOTDelay / 10,
        limit: "0-65535",
        unit: "s",
        id: "ChgOTDelay",
      },
      {
        name: "Charge Under Temperature",
        value: editChgUnderTemp,
        limit: "-40-120",
        unit: "°C",
        id: "ChgLowTemp",
      },
      {
        name: "Charge Under Temperature Release",
        value: editChgUTRelease,
        limit: "-40-120",
        unit: "°C",
        id: "ChgUTRelease",
      },
      {
        name: "Charge Under Temperature Delay",
        value: editChgUTDelay / 10,
        limit: "0-65535",
        unit: "s",
        id: "ChgUTDelay",
      },
      {
        name: "Discharge Over Temp",
        value: editDisOverTemp,
        limit: "-40-120",
        unit: "°C",
        id: "DisOverTemp",
      },
      {
        name: "Discharge Over Temperature Release",
        value: editDsgOTRelease,
        limit: "-40-120",
        unit: "°C",
        id: "DsgOTRelease",
      },
      {
        name: "Discharge Over Temperature Delay",
        value: editDsgOTDelay / 10,
        limit: "0-65535",
        unit: "s",
        id: "DsgOTDelay",
      },
      {
        name: "Discharge Low Temp",
        value: editDisLowTemp,
        limit: "-40-120",
        unit: "°C",
        id: "DisLowTemp",
      },
      {
        name: "Discharge Under Temperature Release",
        value: editDsgUTRelease,
        limit: "-40-120",
        unit: "°C",
        id: "DsgUTRelease",
      },
      {
        name: "Discharge Under Temperature Delay",
        value: editDsgUTDelay / 10,
        limit: "0-65535",
        unit: "s",
        id: "DsgUTDelay",
      },
    ],
    pdu: [
      {
        name: "Over Charge Current",
        value: editOverChargeCurrent,
        limit: "0 - 655355",
        unit: "mA",
        id: "OverChargeCurrent",
      },
      {
        name: "Charge Over Current Delay",
        value: editChgOCDelay / 10,
        limit: "0-65535",
        unit: "s",
        id: "ChgOCDelay",
      },
      {
        name: "Charge Over Current R Delay",
        value: editChgOCRDelay / 10,
        limit: "0-65535",
        unit: "s",
        id: "ChgOCRDelay",
      },
      {
        name: "Hard Charge Over Current",
        value: editHardChgOverCurrent,
        limit: "0 - 655355",
        unit: "mA",
        id: "HardChgOverCurrent",
      },
      {
        name: "Over Discharge Current",
        value: editOverDisCurrent,
        limit: "0 - 655355",
        unit: "mA",
        id: "OverDisCurrent",
      },
      {
        name: "Discharge Over Current Delay",
        value: editDsgOCDelay / 10,
        limit: "0-65535",
        unit: "s",
        id: "DsgOCDelay",
      },
      {
        name: "Discharge Over Current R Delay",
        value: editDsgOCRDelay / 10,
        limit: "0-65535",
        unit: "s",
        id: "DsgOCRDelay",
      },
      {
        name: "Hard Discharge Over Current",
        value: editHardDsgOverCurrent,
        limit: "0 - 655355",
        unit: "mA",
        id: "HardDsgOverCurrent",
      },
      {
        name: "Reserve Capacity S1",
        value: editReserveCapacityS1,
        limit: "0 - 100",
        unit: "%",
        id: "ReserveCapacityS1",
      },
      {
        name: "Reserve Capacity S2",
        value: editReserveCapacityS2,
        limit: "0 - 100",
        unit: "%",
        id: "ReserveCapacityS2",
      },
      {
        name: "S C Release Time",
        value: editSCReleaseTime,
        limit: "0 - 65535",
        unit: "s",
        id: "SCReleaseTime",
      },
      {
        name: "Hard Time",
        value: editHardTime,
        limit: "0 - 65535",
        unit: "s",
        id: "HardTime",
      },
    ],
    other: [
      {
        name: "Number Of Cells",
        value: editNumberOfCells,
        limit: "0 - 96",
        unit: "",
        id: "NumberOfCells",
      },
      {
        name: "Cell Manufacturer",
        value: editCellManufacturer,
        limit: " - ",
        unit: "",
        id: "CellManufacturer",
      },
      {
        name: "Can Status",
        value: editCAN_Status,
        limit: " - ",
        unit: "",
        id: "CAN_Status",
      },
      {
        name: "BLE Status",
        value: editBLE_Status,
        limit: " - ",
        unit: "",
        id: "BLE_Status",
      },

      {
        name: "IOT Status",
        value: editIOT_Status,
        limit: " - ",
        unit: "",
        id: "IOT_Status",
      },

      {
        name: "IMU Status",
        value: editIMU_Status,
        limit: " - ",
        unit: "",
        id: "IMU_Status",
      },

      {
        name: "Bar Graph Status",
        value: editBarGraph_Status,
        limit: " - ",
        unit: "",
        id: "BarGraph_Status",
      },

      {
        name: "Memory Size",
        value: editMemorySize,
        limit: "0-1024",
        unit: "kB",
        id: "MemorySize",
      },

      {
        name: "Design Capacity",
        value: editDesignCapacity,
        limit: " - ",
        unit: "Ah",
        id: "DesignCapacity",
      },

      {
        name: "Cycle Count",
        value: editCycleCount,
        limit: " - ",
        unit: "",
        id: "CycleCount",
      },

      {
        name: "Load Over Temp",
        value: editLoadOverTemp,
        limit: "-40-120",
        unit: "°C",
        id: "LoadOverTemp",
      },

      {
        name: "Load Over Temp Release",
        value: editLoadOTRelease,
        limit: "-40-120",
        unit: "°C",
        id: "LoadOTRelease",
      },

      {
        name: "Load Low Temp",
        value: editLoadLowTemp,
        limit: "-40-120",
        unit: "°C",
        id: "LoadLowTemp",
      },

      {
        name: "Load Under Temp Release",
        value: editLoadUTRelease,
        limit: "-40-120",
        unit: "°C",
        id: "LoadUTRelease",
      },

      {
        name: "Sense Resistor",
        value: editSenseResistor,
        limit: " - ",
        unit: "",
        id: "SenseResistor",
      },

      {
        name: "Battery Config",
        value: editBatteryConfig,
        limit: " - ",
        unit: "",
        id: "BatteryConfig",
      },

      {
        name: "NTC Config",
        value: editNtcConfig,
        limit: " - ",
        unit: "",
        id: "NtcConfig",
      },

      {
        name: "FET Ctrl Time Set",
        value: editfet_ctrl_time_set,
        limit: "0-65535",
        unit: "s",
        id: "fet_ctrl_time_set",
      },

      {
        name: "LED Disp Time Set",
        value: editled_disp_time_set,
        limit: "0-65535",
        unit: "s",
        id: "led_disp_time_set",
      },

      {
        name: "Voltage Cap 80",
        value: editVoltageCap80,
        limit: "0-655355",
        unit: "mV",
        id: "VoltageCap80",
      },

      {
        name: "Voltage Cap 60",
        value: editVoltageCap60,
        limit: "0-655355",
        unit: "mV",
        id: "VoltageCap60",
      },

      {
        name: "Voltage Cap 40",
        value: editVoltageCap40,
        limit: "0-655355",
        unit: "mV",
        id: "VoltageCap40",
      },

      {
        name: "Voltage Cap 20",
        value: editVoltageCap20,
        limit: "0-655355",
        unit: "mV",
        id: "VoltageCap20",
      },

      {
        name: "GPS Time",
        value: editGPS_TIME,
        limit: "0 - 65535",
        unit: "s",
        id: "GPS_TIME",
      },

      {
        name: "GPS Voltage",
        value: editGPS_VOL,
        limit: "0-655355",
        unit: "mV",
        id: "GPS_VOL",
      },

      {
        name: "Precharge Time",
        value: editPreChargeTime,
        limit: "0 - 65535",
        unit: "s",
        id: "PreChargeTime",
      },
    ],
  };
  const editedConfig = (value, id) => {
    if (id === "NumberOfCells") seteditNumberOfCells(value);
    else if (id === "NumberOfTemp") seteditNumberOfTemp(value);
    else if (id === "TypeOfCell") seteditTypeOfCell(value);
    else if (id === "CellManufacturer") seteditCellManufacturer(value);
    else if (id === "CAN_Status") seteditCAN_Status(value);
    else if (id === "BLE_Status") seteditBLE_Status(value);
    else if (id === "IOT_Status") seteditIOT_Status(value);
    else if (id === "IMU_Status") seteditIMU_Status(value);
    else if (id === "BarGraph_Status") seteditBarGraph_Status(value);
    else if (id === "DischargingRate") seteditDischargingRate(value);
    else if (id === "MemorySize") seteditMemorySize(value);
    else if (id === "DesignCapacity") seteditDesignCapacity(value);
    else if (id === "CycleCapacity") seteditCycleCapacity(value);
    else if (id === "FullChargeVol") seteditFullChargeVol(value);
    else if (id === "ChargeEndVol") seteditChargeEndVol(value);
    else if (id === "CycleCount") seteditCycleCount(value);
    else if (id === "ChgOverTemp") seteditChgOverTemp(value);
    else if (id === "ChgOTRelease") seteditChgOTRelease(value);
    else if (id === "ChgUnderTemp") seteditChgUnderTemp(value);
    else if (id === "ChgUTRelease") seteditChgUTRelease(value);
    else if (id === "LoadOverTemp") seteditLoadOverTemp(value);
    else if (id === "LoadOTRelease") seteditLoadOTRelease(value);
    else if (id === "LoadLowTemp") seteditLoadLowTemp(value);
    else if (id === "LoadUTRelease") seteditLoadUTRelease(value);
    else if (id === "DisOverTemp") seteditDisOverTemp(value);
    else if (id === "DsgOTRelease") seteditDsgOTRelease(value);
    else if (id === "DisLowTemp") seteditDisLowTemp(value);
    else if (id === "DsgUTRelease") seteditDsgUTRelease(value);
    else if (id === "PackOverVoltage") seteditPackOverVoltage(value);
    else if (id === "PackOVRelease") seteditPackOVRelease(value);
    else if (id === "PackUnderVoltage") seteditPackUnderVoltage(value);
    else if (id === "PackUVRelease") seteditPackUVRelease(value);
    else if (id === "CellFullChrVoltage") seteditCellFullChrVoltage(value);
    else if (id === "CellNominalVoltage") seteditCellNominalVoltage(value);
    else if (id === "CellOverVoltage") seteditCellOverVoltage(value);
    else if (id === "CellOVRelease") seteditCellOVRelease(value);
    else if (id === "CellUnderVoltage") seteditCellUnderVoltage(value);
    else if (id === "CellUVRelease") seteditCellUVRelease(value);
    else if (id === "CellUnBalProtec") seteditCellUnBalProtec(value);
    else if (id === "OverChargeCurrent") seteditOverChargeCurrent(value);
    else if (id === "OverDisCurrent") seteditOverDisCurrent(value);
    else if (id === "BalanceStartVoltage") seteditBalanceStartVoltage(value);
    else if (id === "SenseResistor") seteditSenseResistor(value);
    else if (id === "BatteryConfig") seteditBatteryConfig(value);
    else if (id === "NtcConfig") seteditNtcConfig(value);
    else if (id === "PackNum") seteditPackNum(value);
    else if (id === "fet_ctrl_time_set") seteditfet_ctrl_time_set(value);
    else if (id === "led_disp_time_set") seteditled_disp_time_set(value);
    else if (id === "VoltageCap80") seteditVoltageCap80(value);
    else if (id === "VoltageCap60") seteditVoltageCap60(value);
    else if (id === "VoltageCap40") seteditVoltageCap40(value);
    else if (id === "VoltageCap20") seteditVoltageCap20(value);
    else if (id === "HardCellOverVoltage") seteditHardCellOverVoltage(value);
    else if (id === "HardCellUnderVoltage") seteditHardCellUnderVoltage(value);
    else if (id === "HardPackOverVoltage") seteditHardPackOverVoltage(value);
    else if (id === "HardPackUnderVoltage") seteditHardPackUnderVoltage(value);
    else if (id === "HardChgOverCurrent") seteditHardChgOverCurrent(value);
    else if (id === "HardDsgOverCurrent") seteditHardDsgOverCurrent(value);
    else if (id === "HardTime") seteditHardTime(value);
    else if (id === "SCReleaseTime") seteditSCReleaseTime(value);
    else if (id === "ChgUTDelay") seteditChgUTDelay(value * 10);
    else if (id === "ChgOTDelay") seteditChgOTDelay(value * 10);
    else if (id === "DsgUTDelay") seteditDsgUTDelay(value * 10);
    else if (id === "DsgOTDelay") seteditDsgOTDelay(value * 10);
    else if (id === "PackUVDelay") seteditPackUVDelay(value * 10);
    else if (id === "PackOVDelay") seteditPackOVDelay(value * 10);
    else if (id === "CellUVDelay") seteditCellUVDelay(value * 10);
    else if (id === "CellOVDelay") seteditCellOVDelay(value * 10);
    else if (id === "ChgOCDelay") seteditChgOCDelay(value * 10);
    else if (id === "ChgOCRDelay") seteditChgOCRDelay(value * 10);
    else if (id === "DsgOCDelay") seteditDsgOCDelay(value * 10);
    else if (id === "DsgOCRDelay") seteditDsgOCRDelay(value * 10);
    else if (id === "GPS_TIME") seteditGPS_TIME(value);
    else if (id === "GPS_VOL") seteditGPS_VOL(value);
    else if (id === "PreChargeTime") seteditPreChargeTime(value);
    else if (id === "BalanceWindow") seteditBalanceWindow(value);
    else if (id === "ReserveCapacityS1") seteditReserveCapacityS1(value);
    else if (id === "ReserveCapacityS2") seteditReserveCapacityS2(value);
    else if (id === "DataEnd") seteditDataEnd(value);
  };
  return (
    <div>
      <div>
        <UncontrolledTooltip target="BalanceStartVoltage">
          Balance Start after this max cell voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="BalanceWindow">
          Balance window min max cell
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CellFullChrVoltage ">
          Cell full charge Voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CellNominalVoltage">
          Cell Nominal Voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CellUnderVoltage">
          Cell Under Voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CellOverVoltage">
          Cell Over Voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CellOVRelease">
          Cell Over Voltage Release
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CellOVDelay">
          Cell Over Voltage delay
        </UncontrolledTooltip>
        <UncontrolledTooltip target="HardCellOverVoltage">
          Instant Cut Over voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CellUnderVoltage">
          Cell Under Voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CellUVRelease">
          Cell Under Voltage Release
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CellUVDelay">
          Cell Under Voltage delay
        </UncontrolledTooltip>
        <UncontrolledTooltip target="HardCellUnderVoltage">
          Instant Cut Under voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="Baudrate">
          CAN Communication Baudrate
        </UncontrolledTooltip>
        <UncontrolledTooltip target="NumberOfTemp">
          Number of temp Used
        </UncontrolledTooltip>
        <UncontrolledTooltip target="TypeOfCell">
          Type of cell chemistry (Details Below)
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DesignCapacity">
          Pack Capacity (mAh)
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CycleCapacity">
          Max charge discharge cycle
        </UncontrolledTooltip>
        <UncontrolledTooltip target="FullChargeVol">
          Pack Full Charge Voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ChargeEndVol">
          Charge End Voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DischargingRate">
          Max Discharge Rate
        </UncontrolledTooltip>
        <UncontrolledTooltip target="PackOverVoltage">
          Pack Over voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="PackOVRelease">
          Pack over voltage Release
        </UncontrolledTooltip>
        <UncontrolledTooltip target="PackOVDelay">
          Pack Over voltage Delay
        </UncontrolledTooltip>
        <UncontrolledTooltip target="HardPackOverVoltage">
          Instant Cut Pack Over voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="PackUnderVoltage">
          Pack Under voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="PackUVRelease">
          Pack Under voltage Release
        </UncontrolledTooltip>
        <UncontrolledTooltip target="PackUVDelay">
          Pack Under voltage Delay
        </UncontrolledTooltip>
        <UncontrolledTooltip target="HardPackUnderVoltage">
          Instant Cut Pack Under voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ChgOverTemp">
          Cell Over Temperature Limit at the time of charging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ChgOTRelease">
          Cell Over Temperature Release Limit at the time of charging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ChgOTDelay">
          Cell Over Temperature Limit delay at the time of charging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ChgLowTemp">
          Cell Under Temperature Limit at the time of charging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ChgUTRelease">
          Cell Under Temperature Release Limit at the time of charging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ChgUTDelay ">
          Cell Under Temperature Limit delay at the time of charging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DisOverTemp">
          Cell Over Temperature Limit at the time of Discharging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DsgOTRelease">
          Cell Over Temperature Release Limit at the time of Discharging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DsgOTDelay">
          Cell Over Temperature Limit delay at the time of Discharging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DisLowTemp">
          Cell Under Temperature Limit at the time of Discharging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DsgUTRelease">
          Cell Under Temperature Release Limit at the time of Discharging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DsgUTDelay">
          Cell Under Temperature Limit delay at the time of Discharging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="OverChargeCurrent ">
          Over Charging Current
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ChgOCDelay">
          Over Charging Current delay
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ChgOCRDelay">
          Over Charging Release Time if current is below release Value
        </UncontrolledTooltip>
        <UncontrolledTooltip target="HardChgOverCurrent">
          Instant current CutOff Limit at the time of Charging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="OverDisCurrent">
          Over DisCharging Current
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DsgOCDelay">
          Over DisCharging Current delay
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DsgOCRDelay">
          Over DisCharging Release Time if current is below release Value
        </UncontrolledTooltip>
        <UncontrolledTooltip target="HardDsgOverCurrent">
          Instant current CutOff Limit at the time of DisCharging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ReserveCapacityS1">
          Reserve 1 Enable Value
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ReserveCapacityS2">
          Reserve 2 Enable Value
        </UncontrolledTooltip>
        <UncontrolledTooltip target="SCReleaseTime">
          Not Use
        </UncontrolledTooltip>
        <UncontrolledTooltip target="HardTime">Not Use</UncontrolledTooltip>

        <UncontrolledTooltip target="NumberOfCells">----</UncontrolledTooltip>
        <UncontrolledTooltip target="CellManufacturer">
          ----
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CAN_Status">----</UncontrolledTooltip>
        <UncontrolledTooltip target="BLE_Status">----</UncontrolledTooltip>
        <UncontrolledTooltip target="IOT_Status">----</UncontrolledTooltip>
        <UncontrolledTooltip target="IMU_Status">----</UncontrolledTooltip>
        <UncontrolledTooltip target="BarGraph_Status">----</UncontrolledTooltip>
        <UncontrolledTooltip target="MemorySize">----</UncontrolledTooltip>
        <UncontrolledTooltip target="DesignCapacity">----</UncontrolledTooltip>
        <UncontrolledTooltip target="CycleCount">----</UncontrolledTooltip>
        <UncontrolledTooltip target="LoadOverTemp">----</UncontrolledTooltip>
        <UncontrolledTooltip target="LoadOTRelease">----</UncontrolledTooltip>
        <UncontrolledTooltip target="LoadLowTemp">----</UncontrolledTooltip>
        <UncontrolledTooltip target="LoadUTRelease">----</UncontrolledTooltip>
        <UncontrolledTooltip target="SenseResistor">----</UncontrolledTooltip>
        <UncontrolledTooltip target="BatteryConfig">----</UncontrolledTooltip>
        <UncontrolledTooltip target="NtcConfig">----</UncontrolledTooltip>
        <UncontrolledTooltip target="fet_ctrl_time_set">
          ----
        </UncontrolledTooltip>
        <UncontrolledTooltip target="led_disp_time_set">
          ----
        </UncontrolledTooltip>
        <UncontrolledTooltip target="VoltageCap80">----</UncontrolledTooltip>
        <UncontrolledTooltip target="VoltageCap60">----</UncontrolledTooltip>
        <UncontrolledTooltip target="VoltageCap40">----</UncontrolledTooltip>
        <UncontrolledTooltip target="VoltageCap20">----</UncontrolledTooltip>
        <UncontrolledTooltip target="GPS_TIME">----</UncontrolledTooltip>
        <UncontrolledTooltip target="GPS_VOL">----</UncontrolledTooltip>
        <UncontrolledTooltip target="PreChargeTime">----</UncontrolledTooltip>
      </div>
      {/* <Button onClick={props.fetchConfiguration} disabled={props.fetchConfigDisabled}>Fetch Configuration</Button> */}
      {/* <Button onClick={showData} disabled={props.fetchConfigDisabled}>
        Get Config Data
      </Button> */}
      {/* <Button onClick={getConfigurationSettings}>Get Configuration From Web</Button> */}
      <Button onClick={sendConfiguration} disabled={props.sendConfigDisabled}>
        Save Configuration
      </Button>
      <Nav className="justify-content-center" role="" tabs>
        <NavItem>
          <NavLink
            className={iconPills === "1" ? "active" : ""}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              setIconPills("1");
            }}
          >
            <strong>Cell Settings</strong>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={iconPills === "2" ? "active" : ""}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              setIconPills("2");
            }}
          >
            <strong>Pack Settings</strong>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={iconPills === "3" ? "active" : ""}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              setIconPills("3");
            }}
          >
            <strong>Temperature Settings</strong>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={iconPills === "4" ? "active" : ""}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              setIconPills("4");
            }}
          >
            <strong>PDU card Settings</strong>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={iconPills === "5" ? "active" : ""}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              setIconPills("5");
            }}
          >
            <strong>Other Settings</strong>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={"iconPills" + iconPills}>
        <TabPane tabId="iconPills1">
          <Row>
            {configurationDetails.cell.map((val, i) => (
              <Col md="4" lg="4" xl="4" xs="12" sm="12">
                <Card
                  style={{
                    margin: "5px",
                    padding: "5px",
                    backgroundColor: "#e3fcee",
                  }}
                >
                  <strong className="adt-blue-color">{val.name}</strong>
                  <div style={{ margin: " 1rem" }} className=" adt-blue-color">
                    <strong>
                      <input
                        className="adt-input"
                        type="number"
                        value={val.value}
                        onChange={(e) => editedConfig(e.target.value, val.id)}
                      ></input>{" "}
                      {val.unit}
                    </strong>
                    <d style={{ fontSize: "10px" }}>({val.limit})</d>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id={val.id}
                    ></i>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </TabPane>
      </TabContent>
      <TabContent activeTab={"iconPills" + iconPills}>
        <TabPane tabId="iconPills2">
          <Row>
            {configurationDetails.pack.map((val, i) => (
              <Col md="4" lg="4" xl="4" xs="12" sm="12">
                <Card
                  style={{
                    margin: "5px",
                    padding: "5px",
                    backgroundColor: "#e3fcee",
                  }}
                >
                  <strong className="adt-blue-color">{val.name}</strong>
                  <div style={{ margin: " 1rem" }} className="adt-blue-color">
                    <strong>
                      <input
                        className="adt-input"
                        type="number"
                        value={val.value}
                        onChange={(e) => editedConfig(e.target.value, val.id)}
                      ></input>{" "}
                      {val.unit}
                    </strong>
                    <d style={{ fontSize: "10px" }}>({val.limit})</d>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id={val.id}
                    ></i>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </TabPane>
      </TabContent>
      <TabContent activeTab={"iconPills" + iconPills}>
        <TabPane tabId="iconPills3">
          <Row>
            {configurationDetails.temp.map((val, i) => (
              <Col md="4" lg="4" xl="4" xs="12" sm="12">
                <Card
                  style={{
                    margin: "5px",
                    padding: "5px",
                    backgroundColor: "#e3fcee",
                  }}
                >
                  <strong className="adt-blue-color">{val.name}</strong>

                  <div style={{ margin: " 1rem" }} className="adt-blue-color">
                    <strong>
                      <input
                        className="adt-input"
                        type="number"
                        value={val.value}
                        onChange={(e) => editedConfig(e.target.value, val.id)}
                      ></input>{" "}
                      {val.unit}
                    </strong>
                    <d style={{ fontSize: "10px" }}>({val.limit})</d>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id={val.id}
                    ></i>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </TabPane>
      </TabContent>
      <TabContent activeTab={"iconPills" + iconPills}>
        <TabPane tabId="iconPills4">
          <Row>
            {configurationDetails.pdu.map((val, i) => (
              <Col md="4" lg="4" xl="4" xs="12" sm="12">
                <Card
                  style={{
                    margin: "5px",
                    padding: "5px",
                    backgroundColor: "#e3fcee",
                  }}
                >
                  <strong className="adt-blue-color">{val.name}</strong>

                  <div style={{ margin: " 1rem" }} className="adt-blue-color">
                    <strong>
                      <input
                        className="adt-input"
                        type="number"
                        value={val.value}
                        onChange={(e) => editedConfig(e.target.value, val.id)}
                      ></input>{" "}
                      {val.unit}
                    </strong>
                    <d style={{ fontSize: "10px" }}>({val.limit})</d>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id={val.id}
                    ></i>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </TabPane>
      </TabContent>
      <TabContent activeTab={"iconPills" + iconPills}>
        <TabPane tabId="iconPills5">
          <Row>
            {configurationDetails.other.map((val, i) => (
              <Col md="4" lg="4" xl="4" xs="12" sm="12">
                <Card
                  style={{
                    margin: "5px",
                    padding: "5px",
                    backgroundColor: "#e3fcee",
                  }}
                >
                  <strong className="adt-blue-color">{val.name}</strong>

                  <div style={{ margin: " 1rem" }} className="adt-blue-color">
                    <strong>
                      <input
                        className="adt-input"
                        type="number"
                        value={val.value}
                        onChange={(e) => editedConfig(e.target.value, val.id)}
                      ></input>{" "}
                      {val.unit}
                    </strong>
                    <d style={{ fontSize: "10px" }}>({val.limit})</d>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id={val.id}
                    ></i>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default EditConfiguration;
