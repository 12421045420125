import "components/MapPro.js/Map.css";

import {
  Circle,
  GoogleMap,
  InfoBox,
  LoadScript,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import { Row, Col } from "reactstrap";

import { API_URL } from "variables/general";
import { AWS_URL } from "variables/general";
import React from "react";
import { Redirect } from "react-router";
import ReactSelect from "react-select";

class SingleMapPro extends React.Component {
  constructor(props) {
    super();
    this.state = {
      showDashboard: false,
      did: "",
      selectedSubUser: '',
      // ifSubUserClicked: props.location.state.subUserId !== null ? props.location.state.subUserId : ''
    };
  }

  componentDidMount() {
    if (this.props.location.state)
      if (Object.keys(this.props.location.state).includes("subUserId"))
        this.setPropSubUser()
    this.handleMapCoordinates();
    window.localStorage.getItem('role') === 'sub_owner' ?
      this.getBmsList()
      :
      this.getSubUserList()
    // this.handleMapCoordinates = setInterval(this.handleMapCoordinates, 60000);
  }
  // stopInterval() {
  //   clearInterval(this.handleMapCoordinates);
  // }
  // componentWillUnmount() {
  //   this.stopInterval();
  // }
  getSubUserList = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(API_URL + "/bms/manage_bms_subuser", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          getSubUsers: result.data.users,
          spinner: false
        });
      })
      .catch((error) => console.log("error", error));
  };
  setPropSubUser = () => {
    this.setState({
      selectedSubUser: this.props.location.state.subUserId
    })
    this.getBmsList(this.props.location.state.subUserId)
  }
  handleSubUserSearchValue = (val) => {
    if (val.value === 'All') {
      this.setState({
        selectedSubUser: 'All',
      })
    }
    else {
      this.setState({
        selectedSubUser: val.value,
      })
      this.getBmsList(val.value)
    }
  }
  handleBmsSearchValue = (val) => {
    val.value === 'All' ? this.setState({
      searchedBmsId: 'All',
    }) : this.setState({
      searchedBmsId: val.value,
    })
  }

  getPackData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(API_URL + "/bms/battery_packs", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          packData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };
  getLocationData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(API_URL + "/bms/bms_location", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          loactionData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };
  onClick = (id) => {
    this.setState({ showDashboard: true, did: id });
  };
  getBmsList = (val) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    val
      ? (requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ subUser: val }),
      })
      : (requestOptions = {
        method: "GET",
        headers: myHeaders,
      });
    fetch(API_URL + "/bms/bms_sub_user", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({ bmsList: result.data, spinner: false });
      })
      .catch((error) => console.log("error", error));
  };

  render() {
    let deviceData = this.state.deviceData;
    if (this.state.showDashboard) {
      return (
        <Redirect
          to={{ pathname: "/admin/dashboard", state: { id: this.state.did } }}
        />
      );
    } else {
      if (this.state.spinner) {
        return <div className="content">
          <div className="loadingContainer">
            <div className="ball1"></div>
            <div className="ball2"></div>
            <div className="ball3"></div>
            <div className="ball4"></div>
          </div>
        </div>
      }
      else {
        if (deviceData) {
          let apiData = JSON.parse(deviceData).data.data;
          let options = { closeBoxURL: "", enableEventPropagation: true };
          let containerStyle = {
            width: "75vw",
            height: "75vh",
          };
          let center = {
            lat: 28.5,
            lng: 77,
          };
          let subUserList = [];
          if (this.state.getSubUsers)
            for (let i = 0; i < this.state.getSubUsers.length; i++) {
              subUserList[i + 1] = {
                value: this.state.getSubUsers[i].id,
                label: this.state.getSubUsers[i].userProfile.name,
              };
              subUserList[0] = {
                value: "All",
                label: "All Sub Operators"
              }
            }
          let bmsList = [];
          if (this.state.bmsList)
            for (let i = 0; i < this.state.bmsList.length; i++) {
              bmsList[i + 1] = {
                value: this.state.bmsList[i].bmsId,
                label: this.state.bmsList[i].alias ? this.state.bmsList[i].alias : this.state.bmsList[i].bmsId,
              };
              bmsList[0] = { value: "All", label: "All Devices" }
            }
          let SubuserWiseGroup = []
          let bmsDeviceData
          for (let i = 0; i < apiData.length; i++) {
            if (this.state.searchedBmsId === 'All') {
              bmsDeviceData = null
            }
            else {
              if (apiData[i].packData.bmsId === this.state.searchedBmsId) {
                bmsDeviceData = apiData[i]
              }
            }
            if (this.state.selectedSubUser === 'All') {
              window.location.reload()
            }
            else {
              if (apiData[i].packData.subOwner === this.state.selectedSubUser) {
                SubuserWiseGroup.push(apiData[i])
              }
            }
          }
          return (
            <div className="main-margin" style={{ background: 'white' }}>
              <Row>
                {window.localStorage.getItem('role') === 'sub_owner' ? '' : <Col md="3" lg="3" sm="12" xs="12" xl="3">
                  <ReactSelect
                    defaultValue={this.state.defaultSubUser ? this.state.defaultSubUser : ''}
                    options={subUserList}
                    onChange={this.handleSubUserSearchValue}
                  />
                </Col>}
                <Col md="3" lg="3" sm="12" xs="12" xl="3"><ReactSelect
                  options={bmsList}
                  onChange={this.handleBmsSearchValue}
                /></Col>
                <Col></Col>
                <Col></Col>
              </Row>
              <div style={{ marginTop: "5px", position: "fixed" }}>
                <LoadScript googleMapsApiKey="AIzaSyDUob5ItT5zZ_lWzJVkW9_Rz9oTq-B9M74">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={5}
                  >
                    <>
                      {window.localStorage.getItem('role') === 'sub_owner' ?
                        <MarkerClusterer options={options}>
                          {(clusterer) =>
                            bmsDeviceData ?
                              <>
                                <Marker
                                  key={bmsDeviceData.location.did}
                                  className="tooltipOne"
                                  title={`BMS ID: ${bmsDeviceData.location.did}`}
                                  position={{
                                    lat: bmsDeviceData.location.gpsLatCoordinate,
                                    lng: bmsDeviceData.location.gpsLongCoordinate,
                                  }}
                                  clusterer={clusterer}
                                  clickable="true"
                                  onClick={() => this.onClick(bmsDeviceData.location.did)}
                                ></Marker>
                              </>
                              : apiData.map((val) =>
                                val.location.gpsLatCoordinate &&
                                  val.location.gpsLongCoordinate ? (
                                  <>
                                    <Marker
                                      key={val.location.did}
                                      className="tooltipOne"
                                      title={`ID: ${val.location.did}\nSoC: ${val.bmsValues.soC} %\nVoltage: ${val.bmsValues.packVoltage / 1000} V`}
                                      position={{
                                        lat: val.location.gpsLatCoordinate,
                                        lng: val.location.gpsLongCoordinate,
                                      }}
                                      clusterer={clusterer}
                                      clickable="true"
                                      onClick={() => this.onClick(val.location.did)}
                                    ></Marker>
                                  </>
                                ) : (
                                  <></>
                                )
                              )}
                        </MarkerClusterer> :
                        <MarkerClusterer options={options}>
                          {(clusterer) =>
                            bmsDeviceData ?
                              <>
                                <Marker
                                  key={bmsDeviceData.location.did}
                                  className="tooltipOne"
                                  title={`BMS ID: ${bmsDeviceData.location.did}`}
                                  position={{
                                    lat: bmsDeviceData.location.gpsLatCoordinate,
                                    lng: bmsDeviceData.location.gpsLongCoordinate,
                                  }}
                                  clusterer={clusterer}
                                  clickable="true"
                                  onClick={() => this.onClick(bmsDeviceData.location.did)}
                                ></Marker>
                              </>
                              :
                              this.state.bmsList ?
                                SubuserWiseGroup.map((val) =>
                                  val.location.gpsLatCoordinate &&
                                    val.location.gpsLongCoordinate ? (
                                    <>
                                      <Marker
                                        key={val.location.did}
                                        className="tooltipOne"
                                        title={`ID: ${val.location.did}\nSoC: ${val.bmsValues.soC} %\nVoltage: ${val.bmsValues.packVoltage / 1000} V`}
                                        position={{
                                          lat: val.location.gpsLatCoordinate,
                                          lng: val.location.gpsLongCoordinate,
                                        }}
                                        clusterer={clusterer}
                                        clickable="true"
                                        onClick={() => this.onClick(val.location.did)}
                                      ></Marker>
                                    </>
                                  ) : (
                                    <></>
                                  )
                                ) :
                                apiData.map((val) =>
                                  val.location.gpsLatCoordinate &&
                                    val.location.gpsLongCoordinate ? (
                                    <>
                                      <Marker
                                        key={val.location.did}
                                        className="tooltipOne"
                                        title={`ID: ${val.location.did}\nSoC: ${val.bmsValues.soC} %\nVoltage: ${val.bmsValues.packVoltage / 1000} V`}
                                        position={{
                                          lat: val.location.gpsLatCoordinate,
                                          lng: val.location.gpsLongCoordinate,
                                        }}
                                        clusterer={clusterer}
                                        clickable="true"
                                        onClick={() => this.onClick(val.location.did)}
                                      ></Marker>
                                    </>
                                  ) : (
                                    <></>
                                  )
                                )
                          }
                        </MarkerClusterer>}
                    </>
                  </GoogleMap>
                </LoadScript></div>
            </div>
          );
        } else {
          return (
            <div className="content">
              <div className="loadingContainer">
                <div className="ball1"></div>
                <div className="ball2"></div>
                <div className="ball3"></div>
                <div className="ball4"></div>
              </div>
            </div>
          );
        }
      }
    }
  }
}
export default SingleMapPro;
