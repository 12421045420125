
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { API_URL } from "variables/general";
import Direction from '../../assets/img/icons8-flag-30.png'
import "../MapPro.js/Map.css"
import {
  Circle,
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import { Button, Col, Row, Table } from "reactstrap";
let polylineArray = []
let tripFinalCoordinates = []
let tripFinalDistance = []
function TripMap(props) {
  useEffect(() => {
    if (evTracker) {
      getLocationData()
    }
    else {
      getBmsValueData()
    }
  }, [])
  const did = props.id
  let date = new Date()
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  const evTracker = props.evTracker
  const [sTms, setSTms] = useState(`${year}-${month}-${day}`);
  const [showData, setShowData] = useState(true);
  const [key, setKey] = useState(false);
  const [keyShow, setKeyShow] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [tripId, setTripId] = useState(0);
  const [distanceSum, setDistanceSum] = useState([]);
  const [totalDistance, setTotalDistance] = useState(0);
  const [distancePredictor, setDistancePredictor] = useState(0);
  const [bmsValues, setBmsValues] = useState();
  const [locationValues, setLocationValues] = useState();
  function getBmsValueData() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({
        // did: did,
        batteryPackId: did,
        tms: sTms + 'T00:00:00Z',
        // limit: 1000,
      }),
    };
    fetch(API_URL + "/bms/v2/bms_values", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.data.bmsValues.length > 0) {
          setBmsValues(result.data.bmsValues)
          getLocationData(result.data.bmsValues)
          setShowData(true)
        }
        else setShowData(false)
      }).catch((error) => console.log("error", error));
  }

  // Function 2: Returns a promise
  function getLocationData(bmsValues) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({
        // did: did,
        did: did,
        // limit:1000
        tms_gt: `${sTms}T00:00:00Z`,
        tms_lt: `${sTms}T23:59:59Z`,
      }),
    };

    fetch(API_URL + "/bms/bms_location", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log('This is location data', result.data.bmsLocation)
        if (evTracker) {
          plotTrip([], result.data.bmsLocation)
        }
        else {
          let locationValues = result.data.bmsLocation
          setLocationValues(result.data.bmsLocation)
          interpolate(locationValues, bmsValues)
        }
      })
      .catch((error) => console.log("error", error));
  }
  // State to hold redistributed data
  const [redistributedData, setRedistributedData] = useState([]);
  function interpolate(locationValues, bmsValues) {
    const locationSet = locationValues
    const socSet = bmsValues
    // Interpolate SOC values
    const interpolatedSOC = interpolateSOC(locationSet, socSet);
    // Update state with redistributed data
    setRedistributedData(interpolatedSOC);
    console.log('interpolatedSOC', interpolatedSOC)
    plotTrip(interpolatedSOC, locationValues, bmsValues)
  }
  // Function to interpolate SOC values
  const interpolateSOC = (locations, socData) => {
    // Convert time strings to timestamps
    const locationTimes = locations.map(entry => new Date(entry.tms).getTime());
    const socTimes = socData.map(entry => new Date(entry.tms).getTime());
    // Extract SOC values
    const socValues = socData.map(entry => entry.soC);
    // Interpolate SOC values for each location time
    return locationTimes.map(locationTime => {
      const index = findNearestIndex(socTimes, locationTime);
      // If location time is outside the range of SOC times, return null
      if (index === -1 || index === socTimes.length - 1) return null;
      // Perform linear interpolation
      const x0 = socTimes[index];
      const x1 = socTimes[index + 1];
      const y0 = socValues[index];
      const y1 = socValues[index + 1];
      const interpolatedValue = y0 + ((locationTime - x0) / (x1 - x0)) * (y1 - y0);
      return interpolatedValue;
    });
  };
  // Function to find the index of the nearest value in an array
  const findNearestIndex = (array, value) => {
    return array.findIndex((element, index, array) => {
      const nextElement = array[index + 1] || Infinity;
      return element <= value && value < nextElement;
    });
  };
  let containerStyle = {
    width: !evTracker ? "50vw" : "60vw",
    height: "70vh",
    float: "right"
  };
  let center = {
    lat: 28.536288,
    lng: 77.421571,
  };
  function distance(lat1, lat2, lon1, lon2) {
    const earthRadius = 6371e3; // Earth's radius in meters
    const φ1 = lat1 * Math.PI / 180; // Convert latitude 1 to radians
    const φ2 = lat2 * Math.PI / 180; // Convert latitude 2 to radians
    const Δφ = (lat2 - lat1) * Math.PI / 180; // Difference in latitude
    const Δλ = (lon2 - lon1) * Math.PI / 180; // Difference in longitude

    // Haversine formula
    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadius * c; // Distance in meters
    return distance;
  }
  function isoToEpoch(isoTimestamp) {
    return Date.parse(isoTimestamp);
  }
  function plotTrip(redistributedData, locationValues, bmsValues) {
    console.log('redistributedData,locationValues', redistributedData, locationValues)
    locationValues.sort((a, b) => new Date(a.tms) - new Date(b.tms));
    // console.log('array', locationValues)
    let locationData = locationValues
    let tripCoordinatesArray = []
    let tripCoordinates = []
    let count = 0
    let revcount = 0
    let tripStart = false
    let tripArray = []
    let id = 0
    locationData.sort((a, b) => new Date(a.tms) - new Date(b.tms));
    for (let index = 0; index < locationData.length; index++) {
      let timeDuration
      if (index < locationData.length - 1)
        timeDuration = new Date(locationData[index + 1].tms) - new Date(locationData[index].tms)
      else timeDuration = 0
      if (timeDuration < 300000) {
        if ((locationData[index].gpsSpeed > 5) && (count == 8)) {
          tripCoordinates.push({
            'id': ++id,
            'gpsSpeed': locationData[index].gpsSpeed,
            'lat': parseFloat(locationData[index].gpsLatCoordinate),
            'lng': parseFloat(locationData[index].gpsLongCoordinate),
            'tms': locationData[index].tms,
            'soc': redistributedData[index],
          })
          revcount = 0
          tripStart = true
        }
        else if ((locationData[index].gpsSpeed > 5) && (count < 8)) {
          count++
        }
        else if ((locationData[index].gpsSpeed < 5) && (revcount < 8) && tripStart) {
          revcount++
          tripCoordinates.push({
            'id': ++id,
            'gpsSpeed': locationData[index].gpsSpeed,
            'lat': parseFloat(locationData[index].gpsLatCoordinate),
            'lng': parseFloat(locationData[index].gpsLongCoordinate),
            'tms': locationData[index].tms,
            'soc': redistributedData[index],
          })
        }
        else if ((locationData[index].gpsSpeed < 5) && (revcount == 8) && tripStart) {
          //trip end
          count = 0
          tripStart = false
          tripArray.push(tripCoordinates)
          tripCoordinates = []
        }
        else if ((locationData[index].gpsSpeed < 5) && (count <= 8) && !tripStart) {
          //trip end
          count = 0
        }
      }
      else {
        if (tripStart)
        //end trip
        {
          count = 0
          tripStart = false
          tripArray.push(tripCoordinates)
          tripCoordinates = []
        }
      }
      if (index == (locationData.length - 1) && tripStart) {
        count = 0
        tripStart = false
        tripArray.push(tripCoordinates)
        tripCoordinates = []
      }
    }
    let biasTimeArray = []
    let biasTimeSum = 0
    let biasDistance = 0
    let distanceArray = []
    let biasTimeArraySets = []
    let totalDistance = 0
    let maxDistanceTravelled = 0
    let maxDistanceTravelledId = 0
    tripArray.forEach((element, id) => {
      let distance = getTripDistance(element)
      if (maxDistanceTravelled < distance) {
        maxDistanceTravelled = distance
        maxDistanceTravelledId = id
      }
      if (distance > 0) {
        distanceArray.push(distance)
        totalDistance = totalDistance + distance
        // let time = new Date(element[element.length - 1].tms) - new Date(element[0].tms)
        // time = time / 1000 //in sec
        // let speed = distance / time // km/sec
        // for (let index = 1; index < element.length; index++) {
        //   if (new Date(element[index].tms) - new Date(element[index - 1].tms) > 10000) {
        //     let diff = new Date(element[index].tms) - new Date(element[index - 1].tms)
        //     biasTimeArray.push(element[index])
        //     biasTimeSum = biasTimeSum + diff
        //   }
        // }
        // biasTimeSum = biasTimeSum / 10000
        // // let x = biasTimeSum - biasTimeArray.length
        // biasDistance = biasTimeSum * speed
        // distanceArray.push(biasDistance + distance)
        // totalDistance = totalDistance+biasDistance+distance
        // biasTimeArraySets.push(biasTimeArray)
        // biasTimeArray = []
      }
    });
    setDistanceSum(distanceArray)
    setTotalDistance(totalDistance)
    tripFinalCoordinates = tripArray
    let DifferenceSoc 
    let distancePredictor
    if(tripArray.length>0){
      DifferenceSoc = tripFinalCoordinates[maxDistanceTravelledId][0].soc - tripFinalCoordinates[maxDistanceTravelledId][tripFinalCoordinates[maxDistanceTravelledId].length - 1].soc
     distancePredictor = 100 * (maxDistanceTravelled / DifferenceSoc)
    setDistancePredictor(distancePredictor)  
  }
  else {
    setShowData(false)
  }
    setShowTable(true)
    getTripId(0)
  }
  function locationClicked() {
    setKey(false)
    setShowTable(false)
    getBmsValueData()
  }
  function getStartTime(val) {
    setSTms(val.target.value)
  }
  const polylineOptions = {
    strokeColor: "#32c85a",
    strokeOpacity: 1,
    strokeWeight: 5,
    fillColor: "#32c85a",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 3000,
    zIndex: 1,
  };
  const getTripId = (second) => {
    polylineArray = tripFinalCoordinates[second]
    setTripId(second)
    setKey(true)
    setKeyShow(!keyShow)
  }
  function getTripDistance(array) {
    let distanceTravelled = 0
    for (let index = 1; index < array.length; index++) {
      distanceTravelled = distanceTravelled + distance(array[index - 1].lat, array[index].lat, array[index - 1].lng, array[index].lng)
    }
    distanceTravelled = distanceTravelled / 1000
    return distanceTravelled
  }
  return <div>
    <Row>
      <Col md='2'>
        <div id='trip-sidebar'>
          <strong>Trip Mode:</strong>&ensp; <label class="switch">
            <input type="checkbox" checked={props.showTripPage} onChange={() => props.setShowTripPage(!props.showTripPage)}></input>
            <span class="slider round"></span>
          </label>
          <strong>Filter by Date:</strong>
          <br />
          <input type="date" onChange={getStartTime}></input><Button color="success" onClick={locationClicked}>Set</Button>
          <br />
          {showTable ? <div>
            <Table bordered
              hover
              dark
            >
              <thead>
                <tr>
                  <th>
                    #
                  </th>
                  <th>
                    Distance Travelled
                  </th>
                  <th>
                    Start Time
                  </th>
                  <th>
                    End Time
                  </th>
                  {!evTracker ? <><th>
                    Start SOC
                  </th>
                    <th>
                      End SOC
                    </th>
                  </> : ''}
                </tr>
              </thead>
              <tbody>
                {tripFinalCoordinates.map((val, i) => val.length > 0 ? <tr onClick={() => getTripId(i)} key={i} className={tripId === i ? "table-success" : "table-info"}>
                  <th scope="row">
                    {i + 1}
                  </th>
                  <td>
                    {Math.round(distanceSum[i] *1.05* 100) / 100} km
                  </td>
                  <td>
                    {val[0].tms.slice(11, 19)}
                    &ensp;
                    {val[0].tms.slice(8, 10)}/
                    {val[0].tms.slice(5, 7)}/
                    {val[0].tms.slice(0, 4)}
                  </td>
                  <td>
                    {val[val.length - 1].tms.slice(11, 19)}
                    &ensp;
                    {val[val.length - 1].tms.slice(8, 10)}/
                    {val[val.length - 1].tms.slice(5, 7)}/
                    {val[val.length - 1].tms.slice(0, 4)}
                  </td>{
                    !evTracker ? <>
                      <td>{Math.round(val[0].soc * 100) / 100}%</td>
                      <td>{val[val.length - 1].soc !== null ? Math.round(val[val.length - 1].soc * 100) / 100 : val[val.length - 2].soc !== null ? Math.round(val[val.length - 2].soc * 100) / 100 : 'No Data'}%</td></> : ''
                  }
                </tr> : '')}
              </tbody>
            </Table>
            <div>
              <span>Total Distance Travelled:</span>&ensp;<strong>{Math.round(totalDistance * 1.05 * 100) / 100} km</strong></div>
            <div>
              <span>Predicted Full Charge Range:</span>&ensp;<strong>{Math.round(distancePredictor * 1.05 * 100) / 100} km</strong></div>
            <div>
            </div>
          </div> : showData ? 'Waiting for trip data' : 'No Data'}
        </div>
      </Col>
      <Col>
        {key ?
          polylineArray ?
            <div style={{ marginTop: "5px" }}>
              <LoadScript googleMapsApiKey="AIzaSyDUob5ItT5zZ_lWzJVkW9_Rz9oTq-B9M74">
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={polylineArray[polylineArray.length - 1]}
                  zoom={13}
                >
                  <Marker
                    position={polylineArray[0]}
                  ></Marker>
                  <Marker
                    position={polylineArray[polylineArray.length - 1]}
                  ></Marker>
                  {polylineArray.map((val, index) =>
                    !evTracker ? <Marker
                      icon={Direction}
                      key={val.id}
                      position={{ lat: val.lat, lng: val.lng }}
                      title={`SOC: ${val.soc ? JSON.stringify(Math.round(val.soc * 100) / 100) + '%' : 'no data'}\nTime: ${val.tms.slice(11, 19)}`}
                    ></Marker> : ''
                  )}
                  <Polyline
                    path={polylineArray}
                    options={polylineOptions}
                  />
                </GoogleMap>
              </LoadScript>
            </div>
            : <strong>No Trip Found</strong> : ""}
      </Col>
    </Row>
  </div>
}

export default TripMap;
