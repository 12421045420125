import React from "react";
import PackList from "./PackList";
import BatteryPackDetails from "./BatterypackDetails";
import { useState, useEffect } from "react";
import { API_URL } from "variables/general";

function InventoryView() {
    const [InventoryData, setInventoryData] = useState();
    useEffect(() => getInventory(), []);
    const getInventory = (val) => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          "Bearer " + window.localStorage.getItem("token")
        );
        let requestOptions = {
          method: "GET",
          headers: myHeaders,
        };
        fetch(API_URL + "/bms/inventory_data", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            setInventoryData(result.data);
          })
          .catch((error) => console.log("error", error));
      };
  const [batteryPackId, setBatteryPackId] = useState();
  return (
    <div>
      {batteryPackId ? (
        <BatteryPackDetails batteryPackId={batteryPackId} setBatteryPackId={setBatteryPackId} InventoryData={InventoryData}/>
      ) : (
        InventoryData?<PackList setBatteryPackId={setBatteryPackId} InventoryData={InventoryData}/>:'loading...'
      )}
    </div>
  );
}
export default InventoryView;