import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import ReactSelect from "react-select";
import axios from "axios";
import { API_URL } from "variables/general";
function EditInventory() {
  let todayDate = new Date();
  const [cSelectedBp, setCSelectedBp] = useState([]);
  const [cSelectedBl, setCSelectedBl] = useState([]);
  const [cSelectedPd, setCSelectedPd] = useState([]);
  const [blCustomFields, setBlCustomFields] = useState("");
  const [bpCustomFields, setBpCustomFields] = useState("");
  const [pdCustomFields, setPdCustomFields] = useState("");
  const [batteryPackId, setBatteryPackId] = useState("");
  const [batteryPackAlias, setBatteryPackAlias] = useState("");
  const [batteryPackUpdateDate, setBatteryPackUpdateDate] = useState(
    todayDate.getFullYear() +
      "-" +
      todayDate.getMonth() +
      "-" +
      todayDate.getDate()
  );
  const [batteryPackDescription, setBatteryPackDescription] = useState("");
  const [batteryPackImage, setBatteryPackImage] = useState("NA");
  const [balancerCardId, setBalancerCardId] = useState("");
  const [balancerCardLastUpdated, setBalancerCardLastUpdated] = useState(
    todayDate.getFullYear() +
      "-" +
      todayDate.getMonth() +
      "-" +
      todayDate.getDate()
  );
  const [balancerCardDescription, setBalancerCardDescription] = useState("");
  const [powerCardId, setPowerCardId] = useState("");
  const [powerCardLastUpdated, setPowerCardLastUpdated] = useState(
    todayDate.getFullYear() +
      "-" +
      todayDate.getMonth() +
      "-" +
      todayDate.getDate()
  );
  const [powerCardDescription, setPowerCardDescription] = useState("");
  const [reason, setReason] = useState("");
  const [comment, setComment] = useState("");
  const [assignedBalancer, setAssignedBalancer] = useState(false);
  const [assignedPower, setAssignedPower] = useState(false);
  const [modal, setModal] = useState(false);
  const [iconPills, setIconPills] = React.useState(window.localStorage.getItem("role") === "sub_owner" ?"1":'2');
  const [inventoryList, setInventoryList] = useState("");

  useEffect(() => {
    getInventory("1", "query");
  }, []);

  const onCheckboxBtnClickBp = (selected) => {
    const index = cSelectedBp.indexOf(selected);
    if (index < 0) {
      cSelectedBp.push(selected);
    } else {
      cSelectedBp.splice(index, 1);
    }
    setCSelectedBp([...cSelectedBp]);
  };
  const onCheckboxBtnClickBl = (selected) => {
    const index = cSelectedBl.indexOf(selected);
    if (index < 0) {
      cSelectedBl.push(selected);
    } else {
      cSelectedBl.splice(index, 1);
    }
    setCSelectedBl([...cSelectedBl]);
  };
  const onCheckboxBtnClickPd = (selected) => {
    const index = cSelectedPd.indexOf(selected);
    if (index < 0) {
      cSelectedPd.push(selected);
    } else {
      cSelectedPd.splice(index, 1);
    }
    setCSelectedPd([...cSelectedPd]);
  };

  const getInventory = (val, params, id) => {
    let invType;
    if (val === "0") {
      invType = id;
    } else if (val === "1") {
      invType = "bp";
    } else if (val === "2") {
      invType = "bl";
    } else if (val === "3") {
      invType = "pd";
    }
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      API_URL + "/bms/inventory_data?" + params + "=" + invType,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        let resulted = result.data;
        if (resulted.ids) setInventoryList(result.data);
        else {
          if (resulted.invType === "bp") {
            setBatteryPackAlias(
              resulted.data.batteryPackAlias
                ? resulted.data.batteryPackAlias
                : ""
            );
            setCSelectedBp(resulted.data.status ? resulted.data.status : "");
            setBatteryPackUpdateDate(
              resulted.data.lastUpdated ? resulted.data.lastUpdated : ""
            );
            setBatteryPackDescription(
              resulted.data.description ? resulted.data.description : ""
            );
            setBpCustomFields(resulted.customField);
          } else if (resulted.invType === "bl") {
            setBalancerCardLastUpdated(
              resulted.data.lastUpdated ? resulted.data.lastUpdated : ""
            );
            setBalancerCardDescription(
              resulted.data.description ? resulted.data.description : ""
            );
            setCSelectedBl(resulted.data.status ? resulted.data.status : "");
            if (resulted.bpId === "unassigned") setAssignedBalancer(false);
            else setAssignedBalancer(true);
          } else if (resulted.invType === "pd") {
            setPowerCardLastUpdated(
              resulted.data.lastUpdated ? resulted.data.lastUpdated : ""
            );
            setPowerCardDescription(
              resulted.data.description ? resulted.data.description : ""
            );
            setCSelectedPd(resulted.data.status ? resulted.data.status : "");
            if (resulted.bpId === "unassigned") setAssignedPower(false);
            else setAssignedPower(true);
          }
        }
      })
      .catch((error) => console.log("error", error));
  };

  const toggle = () => setModal(!modal);
  const getBatteryPackAlias = (e) => {
    e.preventDefault();
    setBatteryPackAlias(e.target.value);
  };
  const getComment = (e) => {
    e.preventDefault();
    setComment(e.target.value);
  };
  const getReason = (e) => {
    setReason(e.value);
  };
  const getBatteryPackId = (e) => {
    setBatteryPackId(e.value);
    getInventory("0", "bp_id", e.value);
  };
  const getBalancerCardId = (e) => {
    setBalancerCardId(e.value);
    getInventory("0", "bl_id", e.value);
  };
  const getPowerCardId = (e) => {
    setPowerCardId(e.value);
    getInventory("0", "pd_id", e.value);
  };
  const getBatteryPackDescription = (e) => {
    e.preventDefault();
    setBatteryPackDescription(e.target.value);
  };
  const getBalancerCardDescription = (e) => {
    e.preventDefault();
    setBalancerCardDescription(e.target.value);
  };
  const getPowerCardDescription = (e) => {
    e.preventDefault();
    setPowerCardDescription(e.target.value);
  };
  const getBatteryPackImage = (e) => {
    e.preventDefault();
    setBatteryPackImage(e.target.files[0]);
  };
  const getBatteryPackUpdateDate = (e) => {
    e.preventDefault();
    setBatteryPackUpdateDate(e.target.value);
  };
  const getBalancerCardUpdateDate = (e) => {
    e.preventDefault();
    setBalancerCardLastUpdated(e.target.value);
  };
  const getPowerCardUpdateDate = (e) => {
    e.preventDefault();
    setPowerCardLastUpdated(e.target.value);
  };
  const reasonList = [
    { value: "Balancer Card Faulted", label: "Balancer Card Faulted" },
    { value: "Power Card Faulted", label: "Power Card Faulted" },
  ];
  const submit = async (e) => {
    const formData = new FormData();
    if (iconPills === "1") {
      let data = {
        batteryPackAlias: batteryPackAlias,
        description: batteryPackDescription,
        status: cSelectedBp,
        lastUpdated: batteryPackUpdateDate,
        changes: {
          comment: comment,
          reason: reason,
          lastUpdated:
            todayDate.getFullYear() +
            "-" +
            todayDate.getMonth() +
            "-" +
            todayDate.getDate(),
        },
      };
      formData.append("invType", "bp");
      formData.append("bpId", batteryPackId);
      formData.append("imageBp", batteryPackImage);
      formData.append("data", JSON.stringify(data));
      formData.append("customField", JSON.stringify(bpCustomFields));
    } else if (iconPills === "2") {
      let data = {
        description: balancerCardDescription,
        status: cSelectedBl,
        lastUpdated: balancerCardLastUpdated,
        changes: {
          comment: comment,
          reason: reason,
          lastUpdated:
            todayDate.getFullYear() +
            "-" +
            todayDate.getMonth() +
            "-" +
            todayDate.getDate(),
        },
      };
      formData.append("invType", "bl");
      if (!assignedBalancer) formData.append("bpId", "unassigned");
      formData.append("blId", balancerCardId);
      formData.append("data", JSON.stringify(data));
      // formData.append("customField", JSON.stringify(blCustomFields));
    } else if (iconPills === "3") {
      let data = {
        description: powerCardDescription,
        status: cSelectedPd,
        lastUpdated: powerCardLastUpdated,
        changes: {
          comment: comment,
          reason: reason,
          lastUpdated:
            todayDate.getFullYear() +
            "-" +
            todayDate.getMonth() +
            "-" +
            todayDate.getDate(),
        },
      };
      formData.append("invType", "pd");
      if (!assignedPower) formData.append("bpId", "unassigned");
      formData.append("pdId", powerCardId);
      formData.append("data", JSON.stringify(data));
      // formData.append("customField", JSON.stringify(pdCustomFields));
    }
    try {
      await axios.patch(API_URL + "/bms/inventory_data", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + window.localStorage.getItem("token"),
        },
      });
      alert("Added Successfully!");
    } catch (error) {
      alert("Error");
      console.log(error);
    }
  };
  const handleFormChangeBp = (val, event) => {
    let values = bpCustomFields;
    values = { ...values, [val]: event.target.value };
    setBpCustomFields(values);
  };
  const handleFormChangeBl = (val, event) => {
    let values = blCustomFields;
    values = { ...values, [val]: event.target.value };
    setBlCustomFields(values);
  };
  const handleFormChangePd = (val, event) => {
    let values = pdCustomFields;
    values = { ...values, [val]: event.target.value };
    setPdCustomFields(values);
  };
  const deleteInventory = (val, type) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    if (type === "bp")
      requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: JSON.stringify({
          invType: type,
          bpId: val,
        }),
      };
    else if (type === "bl")
      requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: JSON.stringify({
          invType: type,
          blId: val,
        }),
      };
    else if (type === "pd")
      requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: JSON.stringify({
          invType: type,
          pdId: val,
        }),
      };
    fetch(API_URL + "/bms/inventory_data", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) alert("Deleted Successfully!");
        else alert("Something went Wrong");
      })
      .catch((error) => {
        alert("Error");
        console.log("error", error);
      });
  };

  const bpStatusList = [
    { value: "Operational", label: "Operational" },
    { value: "Not Operational", label: "Not Operational" },
    { value: "Replaced", label: "Replaced" },
    { value: "Faulty", label: "Faulty" },
    { value: "In warranty", label: "In warranty" },
    { value: "Out Of warranty", label: "Out Of warranty" },
    { value: "Repaired", label: "Repaired" },
  ];
  const blStatusList = [
    { value: "Operational", label: "Operational" },
    { value: "Replaced", label: "Replaced" },
    { value: "Faulty", label: "Faulty" },
    { value: "In warranty", label: "In warranty" },
    { value: "Out Of warranty", label: "Out Of warranty" },
    { value: "Installed", label: "Installed" },
    { value: "Not Installed", label: "Not Installed" },
  ];

  if (inventoryList) {
    let BatteryPackList = [];
    let BalancerCardList = [];
    let PowerCardList = [];
    if (inventoryList.invType === "bp")
      for (let i = 0; i < inventoryList.ids.length; i++) {
        BatteryPackList[i] = {
          value: inventoryList.ids[i],
          label: inventoryList.ids[i],
        };
      }
    else if (inventoryList.invType === "bl")
      for (let i = 0; i < inventoryList.ids.length; i++) {
        BalancerCardList[i] = {
          value: inventoryList.ids[i],
          label: inventoryList.ids[i],
        };
      }
    else if (inventoryList.invType === "pd")
      for (let i = 0; i < inventoryList.ids.length; i++) {
        PowerCardList[i] = {
          value: inventoryList.ids[i],
          label: inventoryList.ids[i],
        };
      }
    return (
      <Card style={{ padding: "1rem 5rem" }}>
        <h5 style={{ textAlign: "center" }}>Edit Inventory</h5>
        <Nav className="justify-content-center" role="tablist" tabs>
        {window.localStorage.getItem("role") === "true" ?<NavItem>
            <NavLink
              className={iconPills === "1" ? "active" : ""}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setIconPills("1");
                getInventory("1", "query");
              }}
            >
              
              <strong>Battery Pack</strong>
            </NavLink>
          </NavItem>:''}
          <NavItem>
            <NavLink
              className={iconPills === "2" ? "active" : ""}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setIconPills("2");
                getInventory("2", "query");
              }}
            >
              
              <strong>Balancer Card</strong>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={iconPills === "3" ? "active" : ""}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setIconPills("3");
                getInventory("3", "query");
              }}
            >
              
              <strong>Power Card</strong>
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={iconPills === "4" ? "active" : ""}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setIconPills("4");
              }}
            >
              
              <strong>IoT Card</strong>
            </NavLink>
          </NavItem> */}
        </Nav>
        <TabContent className="text-center" activeTab={"iconPills" + iconPills}>
        {window.localStorage.getItem("role") === "sub_owner" ?<TabPane tabId="iconPills1">
            <>
              <Row>
                <Col md="2" lg="2" xl="2" sm="2" xs="12"></Col>
                <Col md="8" lg="8" xl="8" sm="8" xs="12">
                  <Form>
                    <FormGroup row>
                      <Label for="bpId" sm={4}>
                        <strong>Battery Pack Id</strong>
                      </Label>
                      <Col sm={8}>
                        <ReactSelect
                          options={BatteryPackList}
                          onChange={getBatteryPackId}
                        />
                      </Col>
                    </FormGroup>
                    {batteryPackId ? (
                      <>
                        <FormGroup row>
                          <Label for="alias" sm={4}>
                            <strong>Alias</strong>
                          </Label>
                          <Col sm={8}>
                            <Input
                              id="alias"
                              placeholder="Alias"
                              type="text"
                              value={batteryPackAlias}
                              onChange={getBatteryPackAlias}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="desc" sm={4}>
                            <strong>Description</strong>
                          </Label>
                          <Col sm={8}>
                            <Input
                              id="desc"
                              placeholder="Description"
                              value={batteryPackDescription}
                              type="textarea"
                              onChange={getBatteryPackDescription}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="bpimg" sm={4}>
                            <strong>Battery Pack Image</strong>
                          </Label>
                          <Col sm={8}>
                            <Input
                              id="bpimg"
                              placeholder="Battery Pack Image"
                              type="file"
                              onChange={getBatteryPackImage}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup>
                          <Label for="bpId">
                            <strong>Select Status</strong>
                          </Label>
                          <Col>
                            <ButtonGroup>
                              {bpStatusList.map((val, i) => (
                                <Button
                                  color="warning"
                                  style={{ margin: "5px" }}
                                  size="sm"
                                  outline
                                  onClick={() =>
                                    onCheckboxBtnClickBp(val.value)
                                  }
                                  active={cSelectedBp.includes(val.value)}
                                >
                                  {val.value}
                                </Button>
                              ))}
                            </ButtonGroup>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="date" sm={4}>
                            <strong>Update Date</strong>
                          </Label>
                          <Col sm={8}>
                            <Input
                              id="date"
                              placeholder="Update Date"
                              type="date"
                              value={batteryPackUpdateDate}
                              onChange={getBatteryPackUpdateDate}
                            />
                          </Col>
                        </FormGroup>
                        {bpCustomFields
                          ? Object.keys(bpCustomFields).map((val, index) => (
                              <FormGroup row>
                                <Label for={index} sm={4}>
                                  <strong>{val}</strong>
                                </Label>
                                <Col sm={8}>
                                  <Input
                                    type="text"
                                    id={index}
                                    value={bpCustomFields[val]}
                                    onChange={(event) =>
                                      handleFormChangeBp(val, event)
                                    }
                                  ></Input>
                                </Col>
                              </FormGroup>
                            ))
                          : ""}
                      </>
                    ) : (
                      ""
                    )}
                  </Form>
                  {batteryPackId ? (
                    <>
                      <Button
                        onClick={() => {
                          toggle();
                          setComment("");
                          setReason("");
                        }}
                        style={{ width: "10rem" }}
                        color="success"
                      >
                        Update
                      </Button>
                      <i
                        class="fas fa-trash fa-lg"
                        onClick={() => deleteInventory(batteryPackId, "bp")}
                        style={{
                          paddingTop: "15px",
                          cursor: "pointer",
                          float: "right",
                        }}
                      ></i>
                    </>
                  ) : (
                    ""
                  )}
                  <Modal isOpen={modal} toggle={toggle} centered>
                    <ModalBody>
                      <FormGroup row>
                        <Label for="reason" sm={4}>
                          <strong>Select Reason</strong>
                        </Label>
                        <Col sm={8}>
                          <ReactSelect
                            onChange={getReason}
                            options={reasonList}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="comment" sm={4}>
                          <strong>Comment</strong>
                        </Label>
                        <Col sm={8}>
                          <Input
                            id="comment"
                            placeholder="Add Comment"
                            type="textarea"
                            value={comment}
                            onChange={getComment}
                          />
                        </Col>
                      </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={submit}>
                        Submit
                      </Button>
                    </ModalFooter>
                  </Modal>
                </Col>
                <Col md="2" lg="2" xl="2" sm="2" xs="12"></Col>
              </Row>
            </>
          </TabPane>:''}
          <TabPane tabId="iconPills2">
            <>
              <Row>
                <Col md="2" lg="2" xl="2" sm="2" xs="12"></Col>
                <Col md="8" lg="8" xl="8" sm="8" xs="12">
                  <Form>
                    <FormGroup row>
                      <Label for="blId" sm={4}>
                        <strong>Balancer Card Id</strong>
                      </Label>
                      <Col sm={8}>
                        <ReactSelect
                          options={BalancerCardList}
                          onChange={getBalancerCardId}
                        />
                      </Col>
                    </FormGroup>
                    {balancerCardId ? (
                      <>
                        <FormGroup row>
                          <Label for="desc" sm={4}>
                            <strong>Description</strong>
                          </Label>
                          <Col sm={8}>
                            <Input
                              id="desc"
                              placeholder="Description"
                              type="textarea"
                              value={balancerCardDescription}
                              onChange={getBalancerCardDescription}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup>
                          <Label for="bpId">
                            <strong>Select Status</strong>
                          </Label>
                          <Col>
                            <ButtonGroup>
                              {blStatusList.map((val, i) => (
                                <Button
                                  color="warning"
                                  style={{ margin: "5px" }}
                                  size="sm"
                                  outline
                                  onClick={() =>
                                    onCheckboxBtnClickBl(val.value)
                                  }
                                  active={cSelectedBl.includes(val.value)}
                                >
                                  {val.value}
                                </Button>
                              ))}
                            </ButtonGroup>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="date" sm={4}>
                            <strong>Update Date</strong>
                          </Label>
                          <Col sm={8}>
                            <Input
                              id="date"
                              placeholder="Update Date"
                              type="date"
                              onChange={getBalancerCardUpdateDate}
                              value={balancerCardLastUpdated}
                            />
                          </Col>
                        </FormGroup>
                        {window.localStorage.getItem("role") === "sub_owner" ?<FormGroup row>
                          <Label for="unassigned" sm={4}>
                            <strong>Assigned to Battery Pack</strong>
                          </Label>
                          <Col sm={8}>
                            <input
                              type="checkbox"
                              checked={assignedBalancer}
                              onChange={() =>
                                setAssignedBalancer(!assignedBalancer)
                              }
                            ></input>
                          </Col>
                        </FormGroup>:''}
                        {blCustomFields
                          ? Object.keys(blCustomFields).map((val, index) => (
                              <FormGroup row>
                                <Label for={index} sm={4}>
                                  <strong>{val}</strong>
                                </Label>
                                <Col sm={8}>
                                  <Input
                                    type="text"
                                    id={index}
                                    value={blCustomFields[val]}
                                    onChange={(event) =>
                                      handleFormChangeBl(val, event)
                                    }
                                  ></Input>
                                </Col>
                              </FormGroup>
                            ))
                          : ""}
                      </>
                    ) : (
                      ""
                    )}
                  </Form>
                  {balancerCardId ? (
                    <>
                      <Button
                        onClick={() => {
                          toggle();
                          setComment("");
                          setReason("");
                        }}
                        style={{ width: "10rem" }}
                        color="success"
                      >
                        Submit
                      </Button>
                      {window.localStorage.getItem("role") === "sub_owner" ? (
                        ""
                      ) : (
                        <i
                          class="fas fa-trash fa-lg"
                          onClick={() => deleteInventory(balancerCardId, "bl")}
                          style={{
                            paddingTop: "15px",
                            cursor: "pointer",
                            float: "right",
                          }}
                        ></i>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md="2" lg="2" xl="2" sm="2" xs="12"></Col>
              </Row>
            </>
          </TabPane>
          <TabPane tabId="iconPills3">
            <>
              <Row>
                <Col md="2" lg="2" xl="2" sm="2" xs="12"></Col>
                <Col md="8" lg="8" xl="8" sm="8" xs="12">
                  <Form>
                    <FormGroup row>
                      <Label for="pdId" sm={4}>
                        <strong>Power Card Id</strong>
                      </Label>
                      <Col sm={8}>
                        <ReactSelect
                          options={PowerCardList}
                          onChange={getPowerCardId}
                        />
                      </Col>
                    </FormGroup>
                    {powerCardId ? (
                      <>
                        <FormGroup row>
                          <Label for="desc" sm={4}>
                            <strong>Description</strong>
                          </Label>
                          <Col sm={8}>
                            <Input
                              id="desc"
                              placeholder="Description"
                              type="textarea"
                              value={powerCardDescription}
                              onChange={getPowerCardDescription}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup>
                          <Label for="bpId">
                            <strong>Select Status</strong>
                          </Label>
                          <Col>
                            <ButtonGroup>
                              {blStatusList.map((val, i) => (
                                <Button
                                  color="warning"
                                  style={{ margin: "5px" }}
                                  size="sm"
                                  outline
                                  onClick={() =>
                                    onCheckboxBtnClickPd(val.value)
                                  }
                                  active={cSelectedPd.includes(val.value)}
                                >
                                  {val.value}
                                </Button>
                              ))}
                            </ButtonGroup>
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label for="date" sm={4}>
                            <strong>Update Date</strong>
                          </Label>
                          <Col sm={8}>
                            <Input
                              id="date"
                              placeholder="Update Date"
                              type="date"
                              onChange={getPowerCardUpdateDate}
                              value={powerCardLastUpdated}
                            />
                          </Col>
                        </FormGroup>
                        {window.localStorage.getItem("role") === "sub_owner" ? <FormGroup row>
                          <Label for="unassigned" sm={4}>
                            <strong>Assigned to Battery Pack</strong>
                          </Label>
                          <Col sm={8}>
                            <input
                              type="checkbox"
                              checked={assignedPower}
                              onChange={() => setAssignedPower(!assignedPower)}
                            ></input>
                          </Col>
                        </FormGroup>:''}
                        {pdCustomFields
                          ? Object.keys(pdCustomFields).map((val, index) => (
                              <FormGroup row>
                                <Label for={index} sm={4}>
                                  <strong>{val}</strong>
                                </Label>
                                <Col sm={8}>
                                  <Input
                                    type="text"
                                    id={index}
                                    value={pdCustomFields[val]}
                                    onChange={(event) =>
                                      handleFormChangePd(val, event)
                                    }
                                  ></Input>
                                </Col>
                              </FormGroup>
                            ))
                          : ""}
                      </>
                    ) : (
                      ""
                    )}
                  </Form>
                  {powerCardId ? (
                    <>
                      <Button
                        onClick={() => {
                          toggle();
                          setComment("");
                          setReason("");
                        }}
                        style={{ width: "10rem" }}
                        color="success"
                      >
                        Submit
                      </Button>
                      {window.localStorage.getItem("role") === "sub_owner" ? (
                        ""
                      ) : (
                        <i
                          class="fas fa-trash fa-lg"
                          onClick={() => deleteInventory(powerCardId, "pd")}
                          style={{
                            paddingTop: "15px",
                            cursor: "pointer",
                            float: "right",
                          }}
                        ></i>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md="2" lg="2" xl="2" sm="2" xs="12"></Col>
              </Row>
            </>
          </TabPane>
        </TabContent>
      </Card>
    );
  } else return <div>Loading....</div>;
}
export default EditInventory;
