import React, {useState, useEffect} from 'react'
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { API_URL } from 'variables/general';
import BalancerView from './BalancerView';
import PowerView from './PowerView';
function ViewCards() {
    const [iconPills, setIconPills] = useState("1");
    const [InventoryData, setInventoryData] = useState();
    const [balancerList, setBalancerList] = useState();
    const [powerList, setPowerList] = useState();
    useEffect(() => getInventory(), []);
    const getInventory = (val) => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          "Bearer " + window.localStorage.getItem("token")
        );
        let requestOptions = {
          method: "GET",
          headers: myHeaders,
        };
        fetch(API_URL + "/bms/inventory_data", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            setInventoryData(result.data);
          })
          .catch((error) => console.log("error", error));
      };
      if(InventoryData){
        return (
    <>
     <div className="section section-tabs">
        <Card style={{ marginRight: "10px" }}>
          <Nav className="justify-content-center" role="tablist" tabs>
            <NavItem>
              <NavLink
                className={iconPills === "1" ? "active" : ""}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIconPills("1");
                }}
              >
                
                <strong>Balancer Cards</strong>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconPills === "2" ? "active" : ""}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIconPills("2");
                }}
              >
                
                <strong>Power Cards</strong>
              </NavLink>
            </NavItem>
            </Nav>
            <TabContent
            className="text-center"
            activeTab={"iconPills" + iconPills}
          >
            <TabPane tabId="iconPills1"><BalancerView InventoryData={InventoryData}/>
                </TabPane>
                
                </TabContent>
            <TabContent
            className="text-center"
            activeTab={"iconPills" + iconPills}
          >
            <TabPane tabId="iconPills2"><PowerView InventoryData={InventoryData} />
                </TabPane>
                
                </TabContent>
            </Card>
            </div>
    </>
    
  )}
  else return <div>Loading...</div>
}

export default ViewCards