import React from "react";
import { Bar } from "react-chartjs-2";
import { Spinner, Card, Badge, Row, Col } from "reactstrap";

function TemperatureFor24SPlus(props) {
  if (props) {

    let tempValues = [];
    let tempLabels = [];
    let tempColor = [];
    let cellTempData = props.cellTempFor24SPlus;
    let extrTempData = props.extrTempFor24SPlus;
    let NumOfTemp = props.BMS_NoOfTempAvailable;
    let MaxCellTemp = Math.max(...cellTempData);
    let MinCellTemp = Math.min(...cellTempData);
    let BMS_NoOfExterTempAvailable = props.BMS_NoOfExterTempAvailable
    for (let i = 0; i < NumOfTemp; i++) {
      tempValues.push(cellTempData[i]);
      tempLabels.push("CT" + (i + 1));
      if (cellTempData[i] === MaxCellTemp) tempColor[i] = "#b8b209";
      else if (cellTempData[i] === MinCellTemp) tempColor[i] = "#078da8";
      else tempColor[i] = "rgba(10, 255, 10, 1)";
    }
    for (let i = 0; i < BMS_NoOfExterTempAvailable; i++) {
      tempValues.push(extrTempData[i]);
      tempLabels.push("ExT" + (i + 1));
      tempColor.push("rgba(10, 255, 255, 1)");
    }

    const TempChart = {
      data: () => {
        return {
          labels: tempLabels,
          datasets: [
            {
              label: "Temperature (°C)",
              data: tempValues,
              backgroundColor: tempColor,
              borderColor: [],
              borderWidth: 0,
            },
          ],
        };
      },

      options: {
        legend: {
          display: false,
          position: "top",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
    return (
      <div>
        <Card style={{ padding: "5px", textAlign: "center" }}>
          <div className="card-head">Temperature</div>
          {cellTempData ? (
            <>
            <Row>
              <Col>
              <Badge
                style={{
                  backgroundColor: "#078da8",
                  color: "white",

                  margin: "0 10px",
                }}
              >
                Min Cell Temp: {MinCellTemp} °C
              </Badge>
              </Col>
              <Col>
              <Badge
                style={{
                  backgroundColor: "#b8b209",
                  color: "white",
                  margin: "0 10px",
                }}
              >
                Max Cell Temp: {MaxCellTemp} °C
              </Badge>
              </Col>
            </Row>
              <Bar data={TempChart.data} options={TempChart.options} />
            </>
          ) : (
            <div>
              <Spinner>Loading...</Spinner>
            </div>
          )}
        </Card>
      </div>
    );
  } else {
    return (
      <div>
        <Spinner>Loading...</Spinner>
      </div>
    );
  }
}

export default TemperatureFor24SPlus;
