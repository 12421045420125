import React, { useState, useEffect, useContext } from 'react'
import {
    UncontrolledTooltip,
    Col,
    Row,
    Tab,
    Tabs,
    NavItem,
    NavLink,
    Card,
    Nav,
    TabContent,
    TabPane,
} from "reactstrap";
import { Line } from "react-chartjs-2";
import { API_URL } from 'variables/general';
import { bmsIdContext } from "views/Dashboard";
function ChargerGraphs() {
    useEffect(() => {
        fetchChargerValues();
    }, []);
    const [iconPills, setIconPills] = useState("1");
    const [chargerValueData, setChargerValueData] = useState("");
    const [chargerValues, setChargerValues] = useState([]);
    const [clickLoader, setClickLoader] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(0);
    const [noOfSamples, setNoOfSamples] = useState(20);
    const [loader, setLoader] = useState(false);
    const [time, setTime] = useState('');
    const IotId = useContext(bmsIdContext);
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    if (month < 10)
        month = '0' + month
    let day = date.getDate()
    if (day < 10)
        day = '0' + day
    const [sTms, setSTms] = useState(`${year}-${month}-${day}`);
    const buttonBasics = {
        borderWidth: 0,
        borderRadius: "20px",
        backgroundColor: "#B6BDBB",
        color: "white",
        padding: "5px 15px 5px 15px",
        margin: "5px",
        fontWeight: "bold",
    };
    const fetchChargerValues = () => {
        setLoader(true)
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Bearer " + window.localStorage.getItem("token")
        );
        let requestOptions;
        requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                batteryPackId: IotId,
                tms: `${sTms}T00:00:00Z`,
                tms_lt: `${sTms}T23:59:59Z`,
            }),
        };
        fetch(API_URL + "/bms/charger_data", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                let bmsChargerValuesArray = result.data.bmsChargerValues
                setLoader(false)
                if (bmsChargerValuesArray.length > 0) {
                    bmsChargerValuesArray.sort((a, b) => new Date(a.tms) - new Date(b.tms));
                    setChargerValues(bmsChargerValuesArray)
                    setStartIndex(bmsChargerValuesArray.length - 1 - noOfSamples)
                    setEndIndex(bmsChargerValuesArray.length - 1)
                    settingDisplayValues(bmsChargerValuesArray, bmsChargerValuesArray.length - 1 - noOfSamples, bmsChargerValuesArray.length - 1)
                }
            })
            .catch((error) => console.log("error", error));
    };
    const settingDisplayValues = (chargerValueData, startIndex, endIndex) => {
        let subArray = chargerValueData.slice(startIndex, endIndex)
        setChargerValueData(subArray);
    }
    const submitDate = (event) => {
        fetchChargerValues()
    }
    const getDateTime = (event) => {
        setSTms(event)
    };
    const getTime = (event) => {
        let searchTime = sTms.split("T")[0] + 'T' + event + ':00Z'
        let timestampObj = chargerValues.findIndex(obj => {
            let objTimestamp = new Date(obj.tms).getTime();
            return objTimestamp >= new Date(searchTime).getTime();
        });
        let foundTimeIndex = timestampObj
        let updatedStartIndex = foundTimeIndex
        let updatedEndIndex = foundTimeIndex + noOfSamples
        if (updatedEndIndex > chargerValues.length)
            updatedEndIndex = chargerValues.length
        setStartIndex(updatedStartIndex)
        setEndIndex(updatedEndIndex)
        let subArray = chargerValues.slice(updatedStartIndex, updatedEndIndex)
        setChargerValueData(subArray);
    };
    const previousClicked = (val) => {
        let updatedStartIndex = startIndex - noOfSamples
        let updatedEndIndex = endIndex - noOfSamples
        if (updatedStartIndex < 0) {
            updatedStartIndex = 0
            updatedEndIndex = noOfSamples
            setStartIndex(updatedStartIndex)
            setEndIndex(updatedEndIndex)
            let subArray = chargerValues.slice(updatedStartIndex, updatedEndIndex)
            setChargerValueData(subArray);
        }
        else {
            setStartIndex(updatedStartIndex)
            setEndIndex(updatedEndIndex)
            let subArray = chargerValues.slice(updatedStartIndex, updatedEndIndex)
            setChargerValueData(subArray);
        }
    };
    const nextClicked = (val) => {
        let updatedStartIndex = startIndex + noOfSamples
        let updatedEndIndex = endIndex + noOfSamples
        if (updatedEndIndex > chargerValues.length) {
        }
        else {
            setStartIndex(updatedStartIndex)
            setEndIndex(updatedEndIndex)
            let subArray = chargerValues.slice(updatedStartIndex, updatedEndIndex)
            setChargerValueData(subArray);
        }
    };
    const changeNoOfSample = (val) => {
        let updatedStartIndex = endIndex - noOfSamples
        let updatedEndIndex = endIndex
        if (updatedStartIndex < 0) {
            updatedStartIndex = 0
            setStartIndex(updatedStartIndex)
            let subArray = chargerValues.slice(updatedStartIndex, updatedEndIndex)
            setChargerValueData(subArray);
        }
        else {
            setStartIndex(updatedStartIndex)
            setEndIndex(updatedEndIndex)
            let subArray = chargerValues.slice(updatedStartIndex, updatedEndIndex)
            setChargerValueData(subArray);
        }
    };


    // Charger data manipulation---------------------->
    let chargerTms = [];
    let ChargerVoltageData = [];
    let chargerCurrentData = [];
    let chargerTemperatureData = [[]];
    let timeLabel = [];
    let prevDate;
    if (!loader) {
        if (chargerValueData) {
            // console.log('<---------------------------chargerValueData------------------------>',chargerValueData)
            for (let index = 0; index < chargerValueData.length; index++) {
                chargerTms[index] = chargerValueData[index].tms.slice(8, 10) +
                    "/" +
                    chargerValueData[index].tms.slice(5, 7) +
                    " " +
                    chargerValueData[index].tms.slice(11, 16);
                ChargerVoltageData[index] = chargerValueData[index].chargerVoltage / 1000
                chargerCurrentData[index] = chargerValueData[index].chargerCurrent / 1000
                chargerTemperatureData[index] = JSON.parse(chargerValueData[index].chargerTemp)
            }
            if (chargerValueData.length > 1) {
                prevDate = chargerValueData[1].tms.slice(0, 10);
                timeLabel[0] =
                    chargerValueData[0].tms.slice(8, 10) +
                    "/" +
                    chargerValueData[0].tms.slice(5, 7) +
                    " " +
                    chargerValueData[0].tms.slice(11, 16);
                for (let i = 1; i < chargerValueData.length; i++) {
                    if (prevDate === chargerValueData[i].tms.slice(0, 10))
                        timeLabel[i] = chargerValueData[i].tms.slice(11, 16);
                    else {
                        timeLabel[i] =
                            chargerValueData[i].tms.slice(8, 10) +
                            "/" +
                            chargerValueData[i].tms.slice(5, 7) +
                            " " +
                            chargerValueData[i].tms.slice(11, 16);
                        prevDate = chargerValueData[i].tms.slice(0, 10);
                    }
                }
            }

            let chargerTemp_temp_max_len = 0;
            for (let te_l_len = 0; te_l_len < chargerTemperatureData.length; te_l_len++) {
                if (chargerTemperatureData[te_l_len].length > chargerTemp_temp_max_len) {
                    chargerTemp_temp_max_len = chargerTemperatureData[te_l_len].length;
                }
            }
            let chargerTempDataSets = [];
            let chargerTempNewArray = Array(chargerTemp_temp_max_len)
                .fill(0)
                .map((row) => new Array(chargerTemperatureData.length).fill(0));

            for (let k = 0; k < chargerTemperatureData.length; k++) {
                for (let j = 0; j < chargerTemperatureData[k].length; j++) {
                    chargerTempNewArray[j][k] = chargerTemperatureData[k][j];
                }
            }
            for (let x = 0; x < chargerTempNewArray.length; x++) {
                let colorArray = [
                    "#08E8DE",
                    "#004225",
                    "#8B008B",
                    "#03C03C",
                    "#C04000",
                    "#191970",
                    "#77DD77",
                ];
                chargerTempDataSets[x] = {
                    label: "Charger Temp " + (x + 1) + " (°C)",
                    data: chargerTempNewArray[x],
                    fill: false,
                    borderColor: colorArray[x],
                    backgroundColor: "transparent",
                    pointBorderColor: colorArray[x],
                    pointRadius: 1,
                    pointHoverRadius: 10,
                    pointBorderWidth: 0,
                };
            }
            const chargerTempChart = {
                data: {
                    labels: chargerTms,
                    datasets: chargerTempDataSets,
                },
                options: {
                    tooltips: {
                        yAxis: "center",
                    },
                    elements: {
                        line: {
                            tension: 0.3,
                        },
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    // max: yAxisTempVariableMax,
                                    // min: yAxisTempVariableMin,
                                },
                            },
                        ],
                    },
                    legend: {
                        display: true,
                    },
                },
            };

            const ChargerVoltageChart = {
                data: {
                    labels: timeLabel,
                    datasets: [
                        {
                            label: "Charger Voltage",
                            data: ChargerVoltageData,
                            fill: false,
                            borderColor: "#0ff",
                            backgroundColor: "transparent",
                            pointBorderColor: "#0ff",
                            pointRadius: 1,
                            pointHoverRadius: 10,
                            pointBorderWidth: 0,
                        },
                    ],
                },
                options: {
                    elements: {
                        line: {
                            tension: 0.3,
                        },
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    // beginAtZero: false,
                                    // max: 100,
                                    // min: 0,
                                },
                            },
                        ],
                    },
                    legend: {
                        display: true,
                        position: "top",
                    },
                },
            };
            const ChargerCurrentChart = {
                data: {
                    labels: timeLabel,
                    datasets: [
                        {
                            label: "Charger Current",
                            data: chargerCurrentData,
                            fill: false,
                            borderColor: "#0ff",
                            backgroundColor: "transparent",
                            pointBorderColor: "#0ff",
                            pointRadius: 1,
                            pointHoverRadius: 10,
                            pointBorderWidth: 0,
                        },
                    ],
                },
                options: {
                    elements: {
                        line: {
                            tension: 0.3,
                        },
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    // beginAtZero: false,
                                    // max: 100,
                                    // min: 0,
                                },
                            },
                        ],
                    },
                    legend: {
                        display: true,
                        position: "top",
                    },
                },
            };
            return (
                <div>
                    <div>
                        No Of Samples:
                        <b style={{ margin: "5px" }}>
                            <input
                                onChange={(e) => {
                                    if (e.target.value > 500) alert('Max limit 500')
                                    else
                                        setNoOfSamples(parseInt(e.target.value))
                                }}
                                value={noOfSamples}
                                placeholder="Max 500"
                                type="number"
                            >
                            </input>
                        </b>
                        <button
                            onClick={changeNoOfSample}
                            style={{ marginRight: "50px" }}
                        >
                            Set
                        </button>
                        &ensp;
                        <input
                            type="date"
                            onChange={(event) => getDateTime(event.target.value)}
                        ></input>&ensp;
                        <button
                            onClick={submitDate}
                        >
                            Submit
                        </button>&ensp;
                        <input
                            type="time"
                            onChange={(event) => setTime(event.target.value)}
                        ></input>&ensp;
                        <button
                            onClick={() => getTime(time)}
                        >
                            Submit
                        </button>

                    </div>
                    <Nav className="justify-content-center" role="tablist" tabs>
                        <NavItem>
                            <NavLink
                                className={iconPills === "1" ? "active" : ""}
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIconPills("1");
                                }}
                            >
                                Charger Voltage
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={iconPills === "2" ? "active" : ""}
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIconPills("2");
                                }}
                            >
                                Charger Current
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={iconPills === "3" ? "active" : ""}
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIconPills("3");
                                }}
                            >
                                Charger Temperature
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent className="text-center" activeTab={"iconPills" + iconPills}>
                        <TabPane tabId="iconPills1">
                            <strong>Charger Voltage</strong>

                            {ChargerVoltageData.length > 0 ? <> <Line data={ChargerVoltageChart.data} options={ChargerVoltageChart.options} />

                                <button
                                    style={buttonBasics}
                                    onClick={() => previousClicked(chargerValueData[0].tms)}
                                >
                                    <i class="fas fa-chevron-left"></i>
                                </button>
                                {clickLoader ? (
                                    <strong style={{ margin: "0 15px", cursor: "pointer" }}>
                                        Loading...
                                    </strong>
                                ) : (<strong
                                    style={{ margin: "0 15px", cursor: "pointer" }}

                                >
                                    Sample Time (s)
                                </strong>)}
                                <button
                                    style={buttonBasics}
                                    onClick={() =>
                                        nextClicked(
                                            chargerValueData[chargerValueData.length - 1].tms
                                        )
                                    }
                                >
                                    <i class="fas fa-chevron-right"></i>
                                </button> </> : <>
                                <br />
                                <div>No data Yet</div>
                            </>}
                        </TabPane>
                        <TabPane tabId="iconPills2">
                            <strong>Charger Current</strong>
                            {chargerCurrentData.length > 0 ? <><Line data={ChargerCurrentChart.data} options={ChargerCurrentChart.options} />

                                <button
                                    style={buttonBasics}
                                    onClick={() => previousClicked(chargerValueData[0].tms)}
                                >
                                    <i class="fas fa-chevron-left"></i>
                                </button>
                                {clickLoader ? (
                                    <strong style={{ margin: "0 15px", cursor: "pointer" }}>
                                        Loading...
                                    </strong>
                                ) : (<strong
                                    style={{ margin: "0 15px", cursor: "pointer" }}

                                >
                                    Sample Time (s)
                                </strong>)}
                                <button
                                    style={buttonBasics}
                                    onClick={() =>
                                        nextClicked(
                                            chargerValueData[chargerValueData.length - 1].tms
                                        )
                                    }
                                >
                                    <i class="fas fa-chevron-right"></i>
                                </button> </> : <>
                                <br />
                                <div>No data Yet</div>
                            </>}
                        </TabPane>
                        <TabPane tabId="iconPills3">
                            <strong>Charger Temperature</strong>
                            {chargerTemperatureData[0].length > 0 ? <> <Line data={chargerTempChart.data} options={chargerTempChart.options} />

                                <button
                                    style={buttonBasics}
                                    onClick={() => previousClicked(chargerValueData[0].tms)}
                                >
                                    <i class="fas fa-chevron-left"></i>
                                </button>
                                {clickLoader ? (
                                    <strong style={{ margin: "0 15px", cursor: "pointer" }}>
                                        Loading...
                                    </strong>
                                ) : (<strong
                                    style={{ margin: "0 15px", cursor: "pointer" }}

                                >
                                    Sample Time (s)
                                </strong>)}
                                <button
                                    style={buttonBasics}
                                    onClick={() =>
                                        nextClicked(
                                            chargerValueData[chargerValueData.length - 1].tms
                                        )
                                    }
                                >
                                    <i class="fas fa-chevron-right"></i>
                                </button> </> : <>
                                <br />
                                <div>No data Yet</div>
                            </>}
                        </TabPane>
                    </TabContent>
                </div>
            )
        }
    }
    else return <div>loading...</div>
}

export default ChargerGraphs