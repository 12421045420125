import React, { useContext, useEffect, useState } from "react";

import { API_URL } from "variables/general";
import { bmsIdContext, recordsContext, locationDataContext } from "../../views/Dashboard";

import { CSVLink } from "react-csv";
function BMSLogs() {
    const id = useContext(bmsIdContext);
    const records = useContext(recordsContext);
    const [valueData, setValueData] = useState('');
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(0);
    const [noOfSamples, setNoOfSamples] = useState(20);
    const [bmsValues, setBmsValues] = useState([]);
    const [loader, setLoader] = useState(false);
    const [bmsDataFileName, setBmsDataFileName] = useState('');
    const [newData, setNewData] = useState('');
    const [time, setTime] = useState('');
    const [showDate, setShowDate] = useState('');
    useEffect(() => {
        fetchBmsValues()
    }, [])
    let date = records[records.length - 1]
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    if (month < 10)
        month = '0' + month
    let day = date.getDate()
    if (day < 10)
        day = '0' + day
    const [sTms, setSTms] = useState(`${year}-${month}-${day}T00:00:00Z`);
    const fetchBmsValues = () => {
        setLoader(true)
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Bearer " + window.localStorage.getItem("token")
        );
        let requestOptions;
        requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                batteryPackId: id,
                tms: sTms
            }),
            redirect: "follow",
        };
        fetch(API_URL + "/bms/v2/bms_values", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                let bmsValuesArray = result.data.bmsValues
                let newBmsValueArray = JSON.parse(JSON.stringify(bmsValuesArray))
                setLoader(false)
                if (bmsValuesArray.length > 0) {
                    download_data_logs(newBmsValueArray)
                    bmsValuesArray.sort((a, b) => new Date(a.tms) - new Date(b.tms));
                    setBmsValues(bmsValuesArray)
                    let startIn
                    let endIn
                    if (bmsValuesArray.length - 1 - noOfSamples > 0) {
                        startIn = bmsValuesArray.length - 1 - noOfSamples
                        endIn = bmsValuesArray.length - 1
                    }
                    else {
                        endIn = 1
                        startIn = 0
                    }
                    setStartIndex(startIn)
                    setEndIndex(endIn)
                    settingDisplayValues(bmsValuesArray, startIn, endIn)
                }
                else alert('No Data Found')
            })
            .catch((error) => console.log("error", error));
    };
    const settingDisplayValues = (bmsValues, startIndex, endIndex) => {
        let subArray = bmsValues.slice(startIndex, endIndex)
        setValueData(subArray);
    }
    const submitDate = (event) => {
        // console.log('event', sTms)
        if (window.localStorage.getItem("role") === 'sub_owner') {
            let checkDate = new Date(sTms)
            let currentDate = new Date()
            if ((currentDate.getTime() - checkDate.getTime()) < 259200000) {
                fetchBmsValues()
            }
            else
                alert('Select a date within 3 days from now. To view more data upgrade to Premium.')
        }
        else fetchBmsValues()

    }
    const getDateTime = (event) => {
        let dateArray = []
        records.forEach(element => {
            let year = element.getFullYear()
            let month = element.getMonth() + 1
            if (month < 10)
                month = '0' + month
            let day = element.getDate()
            if (day < 10)
                day = '0' + day
            dateArray.push(`${year}-${month}-${day}`)
        });
        if (dateArray.includes(event)) {
            setSTms(event + 'T00:00:00Z')
        }
        else alert('No Data for this date')
    };
    const getTime = (event) => {
        let searchTime = sTms.split("T")[0] + 'T' + event + ':00Z'
        let timestampObj = bmsValues.findIndex(obj => {
            let objTimestamp = new Date(obj.tms).getTime();
            return objTimestamp >= new Date(searchTime).getTime();
        });
        let foundTimeIndex = timestampObj
        let updatedStartIndex = foundTimeIndex
        let updatedEndIndex = foundTimeIndex + noOfSamples
        if (updatedEndIndex > bmsValues.length)
            updatedEndIndex = bmsValues.length
        setStartIndex(updatedStartIndex)
        setEndIndex(updatedEndIndex)
        let subArray = bmsValues.slice(updatedStartIndex, updatedEndIndex)
        setValueData(subArray);
    };
    const previousClicked = (val) => {
        let updatedStartIndex = startIndex - noOfSamples
        let updatedEndIndex = endIndex - noOfSamples
        if (updatedStartIndex < 0) {
            updatedStartIndex = 0
            updatedEndIndex = noOfSamples
            setStartIndex(updatedStartIndex)
            setEndIndex(updatedEndIndex)
            let subArray = bmsValues.slice(updatedStartIndex, updatedEndIndex)
            setValueData(subArray);
        }
        else {
            setStartIndex(updatedStartIndex)
            setEndIndex(updatedEndIndex)
            let subArray = bmsValues.slice(updatedStartIndex, updatedEndIndex)
            setValueData(subArray);
        }
    };
    const nextClicked = (val) => {
        let updatedStartIndex = startIndex + noOfSamples
        let updatedEndIndex = endIndex + noOfSamples
        if (updatedEndIndex > bmsValues.length) {
        }
        else {
            setStartIndex(updatedStartIndex)
            setEndIndex(updatedEndIndex)
            let subArray = bmsValues.slice(updatedStartIndex, updatedEndIndex)
            setValueData(subArray);
        }
    };
    const changeNoOfSample = (val) => {
        let updatedStartIndex = endIndex - noOfSamples
        let updatedEndIndex = endIndex
        if (updatedStartIndex < 0) {
            updatedStartIndex = 0
            setStartIndex(updatedStartIndex)
            let subArray = bmsValues.slice(updatedStartIndex, updatedEndIndex)
            setValueData(subArray);
        }
        else {
            setStartIndex(updatedStartIndex)
            setEndIndex(updatedEndIndex)
            let subArray = bmsValues.slice(updatedStartIndex, updatedEndIndex)
            setValueData(subArray);
        }
    };
    const download_data_logs = (dataItem) => {
        let updated_array_item_download = [];
        dataItem.forEach((element) => {
            let ind_item;
            ind_item = element;
            let cell_temp = JSON.parse(element.cellTemp);
            for (let i = 0; i < cell_temp.length; i++) {
                ind_item["Cell Temp " + (i + 1)] = cell_temp[i];
            }
            delete ind_item["cellTemp"];
            let cell_voltage = JSON.parse(element.cellVoltage);
            let maxCellVoltage = cell_voltage[0];
            let minCellVoltage = cell_voltage[0];
            let averageCellVoltage;
            let sumOfCellVoltages = 0;
            for (let i = 0; i < cell_voltage.length; i++) {
                sumOfCellVoltages = sumOfCellVoltages + cell_voltage[i];
                ind_item["Cell Voltage " + (i + 1)] = cell_voltage[i];
                if (maxCellVoltage < cell_voltage[i]) {
                    maxCellVoltage = cell_voltage[i];
                }
                if (minCellVoltage > cell_voltage[i]) {
                    minCellVoltage = cell_voltage[i];
                }
            }
            ind_item["maxCellVoltage"] = maxCellVoltage;
            ind_item["minCellVoltage"] = minCellVoltage;
            averageCellVoltage = sumOfCellVoltages / cell_voltage.length;
            ind_item["averageCellVoltage"] = averageCellVoltage;
            let cellVoltageDifference = maxCellVoltage - minCellVoltage;
            ind_item["cellVoltageDifference"] = cellVoltageDifference;
            delete ind_item["cellVoltage"];
            let ic_temp = JSON.parse(element.icTemp);
            for (let i = 0; i < ic_temp.length; i++) {
                ind_item["IC Temp " + (i + 1)] = ic_temp[i];
            }
            delete ind_item["icTemp"];
            let pcb_temp = JSON.parse(element.pcbTemp);
            for (let i = 0; i < pcb_temp.length; i++) {
                ind_item["pcb temp " + (i + 1)] = pcb_temp[i];
            }
            delete ind_item["pcbTemp"];
            let heatsink = JSON.parse(element.heatsink);
            for (let i = 0; i < heatsink.length; i++) {
                ind_item["Temp Cell " + (i + 1)] = heatsink[i];
            }
            delete ind_item["heatsink"];
            updated_array_item_download.push(ind_item);
        });
        for (let k = 0; k < updated_array_item_download.length; k++) {
            updated_array_item_download[k]["lastFault"] = FaultArray[updated_array_item_download[k]["lastFault"]]
        }
        // console.log(updated_array_item_download);
        setNewData(updated_array_item_download);
        setBmsDataFileName(id + "_BMS-Data_" + sTms);
    };
    const FaultArray = ["No Fault",
        "Over Pack Voltage Fault",
        "Over Pack Voltage",
        "Under Pack Voltage Fault",
        "Over OCD1 Current",
        "Over OCD2 Current",
        "Over Charge Current",
        "Over Discharge Current",
        "Short Circuit",
        "Cell Open Wire Fault",
        "Voltage Reference Fault",
        "Voltage Regulator Fault",
        "Temperature Multiplexer Error",
        "Under Temperature Fault",
        "Load Over Temperature",
        "Oscillator Fault",
        "Watchdog Timeout Fault",
        "Under Cell Voltage Fault",
        "Over Cell Voltage Fault",
        "Open Wire Fault",
        "Over Temperature Fault",
        "Cell Unbalance Pro",
        "SCD Latch High",
        "SPI Communication Fault",
        "I2C Communication Fault",
        "MCU Reset Fault",
        "Data CRC Error Fault",
        "Data Ready Fault",
        "Charger Authentication Failed",
        "Thermal Runaway Due To Temperature",
        "Thermal Runaway Due To Voltage",
        "MOSFET Drive Voltage Fault",
        "Reserve Condition Reached",
        "Battery Empty Reached",
        "Battery Full Charged Reached"]
    const tableHeaderStyle = {
        position: "sticky",
        top: 0,
        backgroundColor: "#30428c",
        color: "white",
        zIndex: 1,
        padding: "5px",
    };

    const tableRowStyle = {
        fontWeight: "700",
        padding: "5px",
        borderBottom: "2px solid #c1d6c6",
    };
    if (!loader) {
        if (valueData) {
            console.log('valuedata', valueData)
            let valueArray = [];
            for (let i = 0; i < valueData.length; i++) {
                valueArray[i] =
                    valueData[
                    valueData.length - 1 - i
                    ];
            }
            const buttonBasics = {
                borderWidth: 0,
                borderRadius: "20px",
                backgroundColor: "#B6BDBB",
                color: "white",
                padding: "5px 15px 5px 15px",
                margin: "5px 50px",
                fontWeight: "bold",
            };
            return (
                <div>
                    <div>
                        No Of Samples:
                        <b style={{ margin: "5px" }}>
                            <input
                                onChange={(e) => {
                                    if (e.target.value > 500) alert('Max limit 500')
                                    else
                                        setNoOfSamples(parseInt(e.target.value))
                                }}
                                value={noOfSamples}
                                placeholder="Max 500"
                                type="number"
                            >
                            </input>
                        </b>
                        <button
                            onClick={changeNoOfSample}
                            style={{ marginRight: "50px" }}
                        >
                            Set
                        </button>
                        &ensp;
                        <input
                            type="date"
                            value={showDate}
                            onChange={(event) => { getDateTime(event.target.value); setShowDate(event.target.value) }}
                        ></input>&ensp;
                        <button
                            onClick={submitDate}
                        >
                            Submit
                        </button>&ensp;
                        <input
                            type="time"
                            value={time}
                            onChange={(event) => setTime(event.target.value)}
                        ></input>&ensp;
                        <button
                            onClick={() => getTime(time)}
                        >
                            Submit
                        </button>&ensp;
                        <button>
                            <CSVLink data={newData} filename={bmsDataFileName}>
                                Download CSV
                            </CSVLink>
                        </button>

                    </div>
                    <div>
                        <button
                            style={buttonBasics}
                            onClick={() =>
                                previousClicked(valueData[0].tms)
                            }
                        >
                            <i class="fas fa-chevron-left"></i>
                        </button>
                        <a>BMS Data Logs</a>
                        <button
                            style={buttonBasics}
                            onClick={() =>
                                nextClicked(
                                    valueData[
                                        valueData.length - 1
                                    ].tms
                                )
                            }
                        >
                            <i class="fas fa-chevron-right"></i>
                        </button>
                    </div>
                    <table
                        style={{
                            overflow: "scroll",
                            display: "block",
                            height: "95vh",
                        }}
                    >
                        <thead style={tableHeaderStyle}>
                            <tr>
                                <th style={tableHeaderStyle}>S. No.</th>
                                <th style={tableHeaderStyle}>Time</th>
                                <th style={tableHeaderStyle}>Pack Voltage (V)</th>
                                <th style={tableHeaderStyle}>Pack Current (A)</th>
                                <th style={tableHeaderStyle}>Average Pack Current (A)</th>
                                <th style={tableHeaderStyle}>Max Pack Current (A)</th>
                                <th style={tableHeaderStyle}>Pack Power (kW)</th>
                                <th style={tableHeaderStyle}>SoC (%)</th>
                                <th style={tableHeaderStyle}>SoP (%)</th>
                                <th style={tableHeaderStyle}>SoH (%)</th>
                                <th style={tableHeaderStyle}>Remaining Capacity (mAh)</th>
                                <th style={tableHeaderStyle}>Charging</th>
                                <th style={tableHeaderStyle}>Discharging</th>
                                <th style={tableHeaderStyle}>Cycle Count</th>
                                {JSON.parse(valueArray[0].cellTemp).map((v, i) => (
                                    <th style={tableHeaderStyle}>Cell Temp ({i + 1}) (°C)</th>
                                ))}
                                {JSON.parse(valueArray[0].pcbTemp).map((v, i) => (
                                    <th style={tableHeaderStyle}>PCB Temp ({i + 1}) (°C)</th>
                                ))}
                                {JSON.parse(valueArray[0].icTemp).map((v, i) => (
                                    <th style={tableHeaderStyle}>IC Temp ({i + 1}) (°C)</th>
                                ))}
                                {JSON.parse(valueArray[0].cellVoltage).map((v, i) => (
                                    <th style={tableHeaderStyle}>
                                        Cell Voltage ({i + 1}) (mV)
                                    </th>
                                ))}
                                <th style={tableHeaderStyle}>Max Cell Voltage (mV)</th>
                                <th style={tableHeaderStyle}>Min Cell Voltage (mV)</th>
                                <th style={tableHeaderStyle}>Cell Voltage Difference (mV)</th>
                                <th style={tableHeaderStyle}>Energy In (W)</th>
                                <th style={tableHeaderStyle}>Energy Out (W)</th>
                                <th style={tableHeaderStyle}>BMS Power Mode</th>
                                <th style={tableHeaderStyle}>Pack Current State</th>
                                <th style={tableHeaderStyle}>Last Fault</th>
                                <th style={tableHeaderStyle}>Alarm</th>
                                <th style={tableHeaderStyle}>BMS Data Event</th>
                            </tr>
                        </thead>
                        {valueArray.map((val, i) => (
                            <tbody>
                                <tr style={{ color: val.bmsDataEvent === 'Fault' ? 'orangered' : '#30428c' }}>
                                    <td className="text-center" style={tableRowStyle}>
                                        {i + 1}.
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.tms ? (
                                            <>
                                                {val.tms.slice(11, 19)}&ensp;
                                                {val.tms.slice(8, 10)}/{val.tms.slice(5, 7)}/
                                                {val.tms.slice(0, 4)}
                                            </>
                                        ) : null}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.packVoltage / 1000}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.packCurrent / 1000}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.avgPackCurrent / 1000}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.maxPackCurrent / 1000}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.packPower / 1000}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.soC}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.soP}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.soH}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.remainingCapacity}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.chargeFet === 1 ? "true" : "false"}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.dischargeFet === 1 ? "true" : "false"}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.cycleCount}
                                    </td>

                                    {JSON.parse(val.cellTemp).map((v) => (
                                        <td className="text-center" style={tableRowStyle}>
                                            {v}
                                        </td>
                                    ))}
                                    {JSON.parse(val.pcbTemp).map((v) => (
                                        <td className="text-center" style={tableRowStyle}>
                                            {v}
                                        </td>
                                    ))}
                                    {JSON.parse(val.icTemp).map((v) => (
                                        <td className="text-center" style={tableRowStyle}>
                                            {v}
                                        </td>
                                    ))}
                                    {JSON.parse(val.cellVoltage).map((v) => (
                                        <td className="text-center" style={tableRowStyle}>
                                            {v}
                                        </td>
                                    ))}
                                    <td className="text-center" style={tableRowStyle}>
                                        {Math.max(...JSON.parse(val.cellVoltage))}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {Math.min(...JSON.parse(val.cellVoltage))}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {Math.max(...JSON.parse(val.cellVoltage)) - Math.min(...JSON.parse(val.cellVoltage))}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.energyIn}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.energyOut}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.bmsPowerMode}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.packCurrentState}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.lastFault < FaultArray.length ? FaultArray[val.lastFault] : 'Unknown'}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.lastAlarm === 1 ? "Geo Fence Breach" : "No Alarm"}
                                    </td>
                                    <td className="text-center" style={tableRowStyle}>
                                        {val.bmsDataEvent}
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            )
        }
        else return <div>
            No Data Found</div>
    }
    else return <div>
        Loading...</div>

}

export default BMSLogs