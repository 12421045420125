import React, { useContext, useState } from "react";
import {
  AWS_REGION,
  AWS_IOT_ENDPOINT,
  AWS_CUSTOM_AUTH_USERNAME,
  AWS_CUSTOM_AUTH_AUTHORIZER_NAME,
  AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD,
  AWS_CUSTOM_AUTH_PASSWORD,
} from "../../views/MQTTSettings";
import Select from "react-select";
import { subUserDetailsContext } from "../../layouts/Admin";
import { API_URL } from "variables/general";
import { Row, Col, Container } from "reactstrap";
import { Button } from "react-bootstrap";
const iotsdk = require("aws-iot-device-sdk-v2");
const iot = iotsdk.iot;
const mqtt = iotsdk.mqtt;
function FOTAIoT() {
  const FotaTypeArray = [
    { value: 0, label: "IoT" },
    { value: 1, label: "BMS" },
  ];
  const BaudRateArray = [
    { value: 0, label: "No Baud Rate" },
    { value: 1, label: "125 kbps" },
    { value: 2, label: "250 kbps" },
    { value: 3, label: "500 kbps" },
    { value: 4, label: "1 Mbps" },
  ];
  const trackerCommandArray = [
    { value: "A", label: "Reset" },
    { value: "B", label: "Details" },
    // { value: 'C', label: "Start Logging" },
    // { value: 'D', label: "Stop Logging" },
    // { value: 'E', label: "Get AP Logs" },
    { value: "F", label: "Module Info" },
    { value: "G", label: "Sim Info" },
    { value: "H", label: "Get CAN BaudRate" },
  ];
  const bmsCommandArray = [
    { value: "A", label: "MCU_Reset_enum" },
    { value: "B", label: "FuelGuageReset_enum" },
    { value: "C", label: "ISL78600Reset_enum" },
    { value: "D", label: "BMSGoToSleepMode_enum" },
    { value: "E", label: "BMSWakeUpMode_enum" },
    { value: "F", label: "I2C_EN_AEI_enum" },
    { value: "G", label: "I2C_DS_AEI_enum" },
    { value: "H", label: "SPI_EN_AEI_enum" },
    { value: "I", label: "SPI_DS_AEI_enum" },
    { value: "K", label: "UART_EN_AEI_enum" },
    { value: "L", label: "UART_DS_AEI_enum" },
    { value: "M", label: "LED_DS_AEI_enum" },
    { value: "O", label: "LED_EN_AEI_enum" },
    { value: "P", label: "CAN_EN_AEI_enum" },
    { value: "Q", label: "CAN_DS_AEI_enum" },
    { value: "R", label: "BAL_EN_AEI_enum" },
    { value: "S", label: "BAL_DS_AEI_enum" },
    { value: "T", label: "BAL_EN_AEI_OV_enum" },
    { value: "U", label: "BAL_DS_AEI_OV_enum" },
    { value: "V", label: "DailyEEpromDataErase_enum" },
    { value: "W", label: "EEprom_Complete_Erase_enum" },
    { value: "X", label: "ReadFaultArrayData_enum" },
    { value: "Z", label: "BQ34Z100_Ready_enum" },
    { value: "a", label: "BQ34Z100_DataReset_enum" },
    { value: "b", label: "BQ34Z100DataWriteFlag_enum" },
    { value: "c", label: "UartSendData_enum" },
    { value: "d", label: "UartDebugData_enum" },
    { value: "e", label: "PreChargeEn_enum" },
    { value: "f", label: "PreChargeDs_enum" },
    { value: "g", label: "BMS_S_ConfigComm" },
    { value: "h", label: "BMSCurrentCal" },
    { value: "i", label: "BMSCurrentCalZero" },
    { value: "j", label: "BMSPreChargTime" },
    { value: "k", label: "BMSmAhWrite" },
    { value: "l", label: "WriteDefaultValues" },
    { value: "m", label: "ReqBMSConfCAN" },
    { value: "o", label: "Erase5MinAllData" },
    { value: "p", label: "BootSwap" },
    { value: "q", label: "DefaultCurrentFac" },
    { value: "r", label: "FactoryBMS_AllSetting" },
    { value: "s", label: "StartCalibirationMode" },
    { value: "t", label: "PDU_TempLimitConfig" },
    { value: "u", label: "BMS_dvdtdtdtConfig" }
  ]
  const getSubUsers = useContext(subUserDetailsContext);
  const [deviceList, setDeviceList] = useState();
  const [bmsId, setBmsId] = useState();
  const [fotaType, setFotaType] = useState();
  const [fileName, setFileName] = useState();
  const [downloadTime, setDownloadTime] = useState();
  const [installTime, setInstallTime] = useState();
  const [fotaStatus, setFotaStatus] = useState();
  const [fileDownloadStatus, setFileDownloadStatus] = useState();
  const [fileDownloadPercentage, setFileDownloadPercentage] = useState();
  const [showDetails, setShowDetails] = useState();
  const [bmsCommand, setBmsCommand] = useState()
  const [iotCommand, setIotCommand] = useState()
  const [iotBaudRate, setIotBaudRate] = useState()
  const getBMSList = (subOwnerId) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ subUser: subOwnerId }),
    };
    fetch(API_URL + "/bms/bms_sub_user", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDeviceList(result.data);
      })
      .catch((error) => console.log("error", error));
  };
  let connection = null;
  async function connect_websocket() {
    return new Promise((resolve, reject) => {
      let config = iot.AwsIotMqttConnectionConfigBuilder.new_default_builder()
        .with_clean_session(true)
        .with_client_id(new Date())
        .with_endpoint(AWS_IOT_ENDPOINT)
        .with_custom_authorizer(
          AWS_CUSTOM_AUTH_USERNAME,
          AWS_CUSTOM_AUTH_AUTHORIZER_NAME,
          AWS_CUSTOM_AUTH_PASSWORD,
          AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD
        )
        .with_keep_alive_seconds(30)
        .build();

      console.log("Connecting custom authorizer...");
      const client = new mqtt.MqttClient();

      connection = client.new_connection(config);
      connection.on("connect", (session_present) => {
        console.log("Connected", session_present);
        resolve(connection);
      });
      connection.on("interrupt", (error) => {
        console.log(`Connection interrupted: error=${error}`);
      });
      connection.on("resume", (return_code, session_present) => {
        console.log(
          `Resumed: rc: ${return_code} existing session: ${session_present}`
        );
      });
      connection.on("disconnect", () => {
        console.log("Disconnected");
      });
      connection.on("error", (error) => {
        reject(error);
      });
      connection.connect();
    });
  }
  async function main(val) {
    connect_websocket()
      .then((connection) => {
        connection.subscribe(
          `adtiot/bms1.0/pub/bt/${val}`,
          mqtt.QoS.AtLeastOnce,
          (topic, payload, dup, qos, retain) => {
            const decoder = new TextDecoder("utf8");
            let message = decoder.decode(new Uint8Array(payload));
            getMqttData(message);
            console.log("message", message);
          }
        );
      }
      )
      .catch((reason) => {
        console.log(`Error while connecting: ${reason}`);
      });
  }
  async function getMqttData(message) {
    await message;
    message = JSON.parse(message);
    // console.log("parsed message", message);
    if (message.evc === "500") {
      setFotaStatus(message.ext.fileDownloadValues.status);
    } else if (message.evc === "505") {
      if (message.ext.fileDownloadStatus) {
        setFileDownloadStatus(message.ext.fileDownloadStatus.status);
        setFileDownloadPercentage(message.ext.fileDownloadStatus.percentage);
      }
    } else if (message.evc === "508") {
    } else if (message.evc === "503") {
      if (message.ext.trackerDetails) {
        setShowDetails(<div>
          <div>Firmware Version : {message.ext.trackerDetails.firmwareVersion}</div>
          <div>Hardware Version : {message.ext.trackerDetails.hardwareVersion}</div>
        </div>)
      }
      else if (message.ext.moduleInfo) {
        setShowDetails(<div>
          <div>IMEI : {message.ext.moduleInfo.imei}</div>
          <div>Model : {message.ext.moduleInfo.model}</div>
          <div>Serial number : {message.ext.moduleInfo.sn}</div>
          <div>Product Id : {message.ext.moduleInfo.product}</div>
          <div>Chip temperature : {message.ext.moduleInfo.chipTemp}</div>
        </div>)
      }
      else if (message.ext.simInfo) {
        setShowDetails(<div>
          <div>IMSI : {message.ext.simInfo.imsi}</div>
          <div>ICCID : {message.ext.simInfo.iccid}</div>
          <div>Operator : {message.ext.simInfo.operator}</div>
          <div>APN : {message.ext.simInfo.apn}</div>
          <div>MCC : {message.ext.simInfo.mcc}</div>
          <div>MNC : {message.ext.simInfo.mnc}</div>
          <div>RSSI : {message.ext.simInfo.rssi}</div>
        </div>)
      }
      else if (message.ext.mcuBaudrate) {
        setShowDetails(<div>
          <div>Baud Rate : {BaudRateArray[message.ext.mcuBaudrate.baudrateEnum].label}</div>
        </div>)
      }
    } else console.log("do nothing");
  }

  const publishData = (val, evc) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    if (evc === "503")
      requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          did: bmsId,
          evc: "503",
          payload: {
            command: val,
          },
        }),
      };
    if (evc === "508")
      requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          did: bmsId,
          evc: "508",
          payload: {
            baudrate: val,
          },
        }),
      };
    if (evc === "506")
      requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          did: bmsId,
          evc: "506",
          payload: {
            bmsCommand: val,
          },
        }),
      };
    fetch(API_URL + "/bms/publish_data", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.message === "Msg Published") {
          alert("Publish command sent");
        } else {
          alert("Something went wrong.");
        }
      })
      .catch((error) => console.log("error", error));
  };
  const publishDataFota = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        did: bmsId,
        evc: "500",
        fileName: fileName,
        payload: {
          fileType: fotaType,
          retrieveDateTime: downloadTime,
          installDateTime: installTime,
          retryInterval: 60,
          retries: 3,
        },
      }),
    };
    fetch(API_URL + "/bms/publish_data", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.message === "Msg Published") {
          alert("Publish command sent");
        } else {
          alert("Something went wrong.");
        }
      })
      .catch((error) => console.log("error", error));
  };
  const handleSearchValue = (val) => {
    getBMSList(val.value);
  };
  const getBaudRate = (val) => {
    setIotBaudRate(val.value);
  };
  const sendIotBaudRate = () => {
    publishData(iotBaudRate, "508");
  };
  const getTrackerCommand = (val) => {
    setIotCommand(val.value);
  };
  const sendIotCommand = () => {
    publishData(iotCommand, "503");
  };
  const getBMSCommand = (val) => {
    setBmsCommand(val.value);
  };
  const sendBmsCommand = () => {
    publishData(bmsCommand, "506");
  };

  if (getSubUsers) {
    let subUserListOptions = [];
    getSubUsers.map(
      (val, i) =>
      (subUserListOptions[i] = {
        value: val.id,
        label: val.userProfile.name,
      })
    );
    let bmsListOptions = [];
    if (deviceList)
      deviceList.map(
        (val, i) =>
        (bmsListOptions[i] = {
          value: val.bmsId,
          label: val.online? val.bmsId + " ("+ val.alias + ")" + '[Online]':val.bmsId + " ("+ val.alias + ")" + '[Offline]',
        })
      );
    const getDownloadDateTime = (second) => {
      second = second + ":00";
      setDownloadTime(second);
    };
    const getInstallDateTime = (second) => {
      second = second + ":00";
      setInstallTime(second);
    };
    const getBMSId = (second) => {
      setBmsId(second.value);
      main(second.value);
    };
    const getFileName = (second) => {
      // second = second.toUpperCase();
      setFileName(second);
    };
    const getFotaType = (second) => {
      setFotaType(second.value);
    };
    return (
      <div>
        <Container>
          <h3>IoT Control Center</h3>
          <Row>
            <Col>
              <strong>Select Sub User</strong>
              <Select
                options={subUserListOptions}
                onChange={handleSearchValue}
              ></Select>
            </Col>
            <Col>
              <strong>Select device</strong>
              <Select onChange={getBMSId} options={bmsListOptions}></Select>
            </Col>
          </Row>
          {bmsId ? (
            <>
              <Row>
                <Col>
                  <strong>Send Command for IoT</strong>
                  <Select
                    onChange={getTrackerCommand}
                    options={trackerCommandArray}
                  ></Select>
                </Col>
                <Col>
                  <Button onClick={sendIotCommand}>Set</Button>
                </Col>
                <Col>
                  {showDetails ? showDetails : ''}
                </Col>
              </Row>
              <Row>
                <Col>
                  <strong>Send Command for BMS</strong>
                  <Select
                    onChange={getBMSCommand}
                    options={bmsCommandArray}
                  ></Select></Col>
                <Col>
                  <Button onClick={sendBmsCommand}>Set</Button>
                </Col>
                <Col>
                  <strong>Set IoT Baud Rate</strong>
                  <Select
                    onChange={getBaudRate}
                    options={BaudRateArray}
                  ></Select>
                </Col>
                <Col>
                  <Button onClick={sendIotBaudRate}>Set</Button>
                </Col>
              </Row>
              <hr /><h5>Fimrware Update</h5>
              <Row>
                <Col>
                  <strong>Type Fota File Name</strong>
                  <br />
                  <input
                    type="text"
                    onChange={(e) => getFileName(e.target.value)}
                  ></input>
                </Col>
                <Col>
                  <strong>Select Fota Type</strong>
                  <Select
                    options={FotaTypeArray}
                    onChange={getFotaType}
                  ></Select>
                </Col>
              </Row>
              <Row>
                <Col>
                  <strong>Set File Download Time</strong>
                  <br />
                  <input
                    type="datetime-local"
                    onChange={(e) => getDownloadDateTime(e.target.value)}
                  ></input>
                </Col>
                <Col>
                  <strong>Set File Install Time</strong>
                  <br />
                  <input
                    type="datetime-local"
                    onChange={(e) => getInstallDateTime(e.target.value)}
                  ></input>
                </Col>
              </Row>
              <Button onClick={publishDataFota}>Send</Button>
              <Row>
                {fotaStatus ? <Col>Fota Status: {fotaStatus}</Col> : ""}
                {fileDownloadStatus ? (
                  <Col>Status: {fileDownloadStatus}</Col>
                ) : (
                  ""
                )}
                {fileDownloadPercentage ? (
                  <Col>Installing Percentage: {fileDownloadPercentage}</Col>
                ) : (
                  ""
                )}
              </Row>
            </>
          ) : (
            ""
          )}
        </Container>
      </div>
    );
  }
}

export default FOTAIoT;
