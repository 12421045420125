import { Route, Switch } from "react-router-dom";

import AddDevice from "components/Settings/AddDevice";
import AddSettings from "components/Settings/AddSettings";
import Alarm from "components/Alarm/Alarm";
import Analytics from "views/Analytics";
import BatteryCells from "views/BatteryCells";
import CreateSubUser from "components/Settings/CreateSubUser";
import Dashboard from "views/Dashboard";
import DataLog from "views/DataLog";
import EditSettings from "components/Settings/EditSettings";
import Header from "components/Navbars/Header.js";
import Logout from "components/Auth/Logout";
import ManageDevice from "components/Settings/ManageDevice";
import ManageSubUser from "components/Settings/ManageSubUser";
import MapSubUserBMS from "components/Settings/MapSubUserBMS";
import React, { createContext } from "react";
import Sidebar from "components/Sidebar/Sidebar.js";
import SubUsers from "views/SubUsers";
import {adminRoutes, ownerRoutes} from "routes.js";
import subOwnerRoutes from "subOwnerRoutes.js";
import BmsDevices from "components/BmsDevices/BmsDevices";
import TopBar from "components/Navbars/TopBar";
import { API_URL } from "variables/general";
import BmsDevicesTab from "components/BmsDevices/BmsDevicesTab";
import ParallelTab from "components/Parallel/ParallelTab";

var ps;
const userDetailsContext = createContext();
const subUserDetailsContext = createContext();
const notificationCountContext = createContext();
class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
    };
    this.mainPanel = React.createRef();
  }
  componentDidMount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(this.mainPanel.current);
    //   document.body.classList.toggle("perfect-scrollbar-on");
    // }
    this.getUserDetails();
    if (window.localStorage.getItem("role") === "sub_owner")
      this.getNotificationCount();
    else this.getSubUserDetails();
  }
  // componentWillUnmount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps.destroy();
  //     document.body.classList.toggle("perfect-scrollbar-on");
  //   }
  // }
  // componentDidUpdate(e) {
  //   if (e.history.action === "PUSH") {
  //     ps.destroy();
  //     this.mainPanel.current.scrollTop = 0;
  //     document.scrollingElement.scrollTop = 0;
  //   }
  // }
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  getUserDetails = (second) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(API_URL + "/bms/userdetails", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          userDetails: result.data,
        });
      })
      .catch((error) => console.log("error", error));
  };
  getSubUserDetails = (second) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(API_URL + "/bms/manage_bms_subuser", requestOptions)
      .then((response) => {
       return response.json()
      })
      .then((result) => {
        this.setState({
          subUserDetails: result.data.users,
        });
      })
      .catch((error) => console.log("error", error));
  };
  getNotificationCount = (second) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(API_URL + "/bms/notifications_count", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({ notificationCount: result.data.data });
      })
      .catch((error) => console.log("error", error));
  };
  
  render() {
    if (window.localStorage.getItem("token") == null)
      window.location.href = "/login";
    else {
      let routes
      switch(window.localStorage.getItem("role")) 
      {
        case "sub_owner":{
        routes = subOwnerRoutes
      }
      break;
        case "owner":{
        routes = ownerRoutes
      }
      break;
        case "admin":{
        routes = adminRoutes
      }
      break;
      default:routes = subOwnerRoutes
      break;
      }
      return (
        <div className="wrapper">
          <Sidebar
            {...this.props}
            routes={routes}
            bgColor={this.state.backgroundColor}
            activeColor={this.state.activeColor}
          />
          <userDetailsContext.Provider value={this.state.userDetails}>
            <subUserDetailsContext.Provider value={this.state.subUserDetails}>
              <notificationCountContext.Provider
                value={this.state.notificationCount}
              >
                <div className="main-panel" ref={this.mainPanel}>
                  <Header {...this.props} />
                  <TopBar />
                  <Switch>
                    {window.localStorage.getItem("role") === "sub_owner"
                      ? subOwnerRoutes.map((prop, key) => {
                          return (
                            <Route
                              path={prop.layout + prop.path}
                              component={prop.component}
                              key={key}
                            />
                          );
                        })
                      : adminRoutes.map((prop, key) => {
                          return (
                            <Route
                              path={prop.layout + prop.path}
                              component={prop.component}
                              key={key}
                            />
                          );
                        })}
                    <Route
                      path="/admin/batterycells"
                      component={BatteryCells}
                    />
                    <Route path="/admin/datalogs" component={DataLog} />
                    <Route path="/admin/analytics" component={Analytics} />
                    <Route path="/admin/dashboard" component={Dashboard} />
                    <Route path="/admin/evtrackerhome" component={ParallelTab} />
                    <Route path="/admin/alarm" component={Alarm} />
                    <Route
                      path="/admin/create-sub-user"
                      component={CreateSubUser}
                    />
                    <Route
                      path="/admin/manage-sub-user"
                      component={ManageSubUser}
                    />
                    <Route
                      path="/admin/map-sub-user-bms"
                      component={MapSubUserBMS}
                    />
                    <Route path="/admin/add-device" component={AddDevice} />
                    <Route
                      path="/admin/manage-device"
                      component={ManageDevice}
                    />
                    <Route path="/admin/bmsdevices" component={BmsDevicesTab} />
                    <Route path="/admin/sub-users" component={SubUsers} />
                    <Route
                      path="/admin/edit-settings"
                      component={EditSettings}
                    />
                    <Route path="/admin/add-settings" component={AddSettings} />
                    <Route path="/admin/logout" component={Logout} />
                    {/* <Route path="*" component={ErrorPage} /> */}
                  </Switch>
                  {/* <Footer fluid /> */}
                </div>
              </notificationCountContext.Provider>
            </subUserDetailsContext.Provider>
          </userDetailsContext.Provider>
        </div>
      );
    }
  }
}

export default Admin;
export { userDetailsContext, subUserDetailsContext, notificationCountContext };
