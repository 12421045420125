import { subUserDetailsContext } from "layouts/Admin";
import React from "react";
import { useState, useContext } from "react";
import Select from "react-select";
import { API_URL } from "variables/general";
import { Row, Col, Card } from "reactstrap";
import axios from "axios";

function UploadBinFile() {
  const [binFileData, setBinFileData] = useState();
  const [fileName, setFileName] = useState();
  const [ownerId, setOwnerId] = useState();
  const [fileList, setFileList] = useState();
  const [FTPFile, setFTPFile] = useState();
  const [FTPPath, setFTPPath] = useState();
  const [fotaFileName, setFotaFileName] = useState();
  // const []
  const getSubUsers = useContext(subUserDetailsContext);
  const apiFOTAFile = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify({
        fileData: {
          fileName: fileName,
          data: binFileData,
          ownerId: ownerId,
        },
      }),
    };
    fetch(API_URL + "/bms/fota_file", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert(result.message);
      })
      .catch((error) => console.log("error", error));
  };
  const getFOTAFileList = (val) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(API_URL + "/bms/fota_file?owner_id=" + ownerId, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setFileList(result.data);
      })
      .catch((error) => console.log("error", error));
  };
  const deleteFotaFile = (val) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: JSON.stringify({
        fileName: fotaFileName
      })
    };
    fetch(API_URL + "/bms/fota_file", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert(result.message)
      })
      .catch((error) => console.log("error", error));
  };
  const getBinFile = (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
        let FileArray = new Uint8Array(event.target.result);
        let fileString;
        for (let i = 0; i < FileArray.length; i++) {
          if (i === 0) {
            fileString = FileArray[0];
          } else fileString = fileString + " " + FileArray[i];
        }
        setBinFileData(fileString);
      };
      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsArrayBuffer(file);
    });
  };
  const handleSearchValue = (val) => {
    setOwnerId(val.value);
  };
  const getFotaFile = (val) => {
    setFotaFileName(val.value);
  };
  const uploadToFTP = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('ftpFile', FTPFile);
    formData.append('ftpPath', FTPPath);
    try {
      await axios.post(API_URL + '/bms/upload_file', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      alert('File uploaded successfully!');
    } catch (error) {
      alert('Error!');
      console.log(error);
    }
  }
  const getFTPFile = (val) => {
    setFTPFile(val)
  }
  const getFTPPath = (val) => {
    setFTPPath(val)
  }
  let subUserList = [];
  if (getSubUsers) {
    for (let i = 0; i < getSubUsers.length; i++) {
      subUserList[i] = {
        value: getSubUsers[i].id,
        label: getSubUsers[i].userProfile.name,
      };
    }
    let fileListArray = [];
    if (fileList) for (let i = 0; i < fileList.length; i++) {
      fileListArray[i] = {
        value: fileList[i],
        label: fileList[i],
      };
    }
    return (
      <div>
        <h3>Upload Bin file</h3>
        <Row>
          <Col md='4' lg='4' xl='3' sm='6' xs='12'>
            <strong>Select Sub Owner:  </strong>
            <Select options={subUserList} onChange={handleSearchValue} />
          </Col>

        </Row>
        <hr />
        <Row>
          <Col md='4' lg='4' xl='3' sm='6' xs='12'>
            <input type="file" onChange={getBinFile}></input>
          </Col>
          <Col md='4' lg='4' xl='3' sm='6' xs='12'>
            <button onClick={apiFOTAFile}>Save</button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md='4' lg='4' xl='3' sm='6' xs='12'>
            <button onClick={getFOTAFileList}>Get Added Files</button>
          </Col>
          <Col md='4' lg='4' xl='3' sm='6' xs='12'>
            <Select options={fileListArray} onChange={getFotaFile} />
          </Col>
          <Col md='4' lg='4' xl='3' sm='6' xs='12'>
            <button onClick={deleteFotaFile}>Delete File</button>
          </Col>
        </Row>
        <br />
        <h3>Upload File to FTP</h3>
        <Row>
          <Col>
            <strong>Select File:</strong>&ensp;
            <input type="file" onChange={(e) => getFTPFile(e.target.files[0])}></input>
          </Col>
          <Col>
            <strong>Give Path:</strong>&ensp;
            <input type="text" onChange={(e) => getFTPPath(e.target.value)}></input>
          </Col>
        </Row>
        <button onClick={uploadToFTP}>Send</button>
      </div>
    );
  } else return <div>Loading...</div>;
}

export default UploadBinFile;
