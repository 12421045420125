import React from "react";

import { API_URL } from "variables/general";
import { Button, Row, Col, Spinner, Card } from "reactstrap";
import ReactSelect from "react-select";

class AddDevice extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.getSubUser();
  }

  onSubmit = (event) => {
    if (!this.state.id) alert("Select Sub Operator");
    else if (!this.state.BMSID) alert("Missing BMS ID");
    else if (!this.state.BPID) alert("Missing Battery Pack ID");
    else if (!this.state.IOTID) alert("Missing BMS ID");
    else if (!this.state.dateOfDeployment)
      alert("Missing Deployment Date of Battery");
    else if (!this.state.manufacturingDate)
      alert("Missing Manufacturing Date of Battery");
    else if (!this.state.batteryCapacity) alert("Missing Battery Capacity");
    else if (!this.state.batteryNumber) alert("Missing Battery Number");
    else if (!this.state.batteryCycleCount)
      alert("Missing Battery Cycle Count");
    else if (!this.state.batteryChemistry) alert("Missing Battery Chemistry");
    else if (!this.state.cRate) alert("Missing c-Rate");
    else if (!this.state.noOfS) alert("Missing No of S");
    else if (!this.state.noOfP) alert("Missing No of P");
    else {
      let apiHeaders = new Headers();
      apiHeaders.append("Content-Type", "application/json");
      apiHeaders.append(
        "Authorization",
        "Bearer " + window.localStorage.getItem("token")
      );
      let requestOptions;

      requestOptions = {
        method: "POST",
        headers: apiHeaders,
        body: JSON.stringify({
          packData: {
            subOwner: this.state.id,
            bmsId: this.state.BMSID,
            batteryPackId: this.state.BPID,
            iotId: this.state.IOTID,
            manufacturingDate: this.state.manufacturingDate,
            dateOfDeployment: this.state.dateOfDeployment,
            batteryNumber: this.state.batteryNumber,
            batteryCapacity: this.state.batteryCapacity,
            batteryCycleCount: this.state.batteryCycleCount,
            batteryChemistry: this.state.batteryChemistry,
            cRate: this.state.cRate,
            noOfS: this.state.noOfS,
            noOfP: this.state.noOfP,
            cellModelNumber: this.state.cellModelNumber,
            batteryManufacturer: this.state.batteryManufacturer,
            alias: this.state.alias,
          },
          geoFencing: {
            enable: 0,
          },
          // location: {},
        }),
      };
      fetch(API_URL + "/bms/bms_devices", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let statusResult = JSON.parse(result);
          if (statusResult.status === true) alert(statusResult.message);
          else alert("Something went wrong");
          this.setState({
            resultData: result,
          });
        })
        .catch((error) => console.log("error", error));
    }
  };

  getBatteryCapacity = (event) => {
    this.setState({ batteryCapacity: event });
  };
  getCellModelNumber = (event) => {
    this.setState({ cellModelNumbe: event });
  };
  getBatteryNumber = (event) => {
    this.setState({ batteryNumber: event });
  };
  getBatteryCycleCount = (event) => {
    this.setState({ batteryCycleCount: event });
  };
  getBatteryChemistry = (event) => {
    this.setState({ batteryChemistry: event });
  };
  getCRate = (event) => {
    this.setState({ cRate: event });
  };
  getBatteryDeploymentDate = (event) => {
    this.setState({ dateOfDeployment: event });
  };
  getBatteryManufacturer = (event) => {
    this.setState({ batteryManufacturer: event });
  };
  getBatteryManufacturingDate = (event) => {
    this.setState({ manufacturingDate: event });
    console.log(event);
  };
  getNoOfS = (event) => {
    this.setState({ noOfS: event });
  };
  getNoOfP = (event) => {
    this.setState({ noOfP: event });
  };
  getBMSID = (event) => {
    this.setState({ BMSID: event });
  };
  getBPID = (event) => {
    this.setState({ BPID: event });
  };
  getIOTID = (event) => {
    this.setState({ IOTID: event });
  };
  getAlias = (event) => {
    this.setState({ alias: event });
  };
  getSubUserId = (event) => {
    this.setState({
      id: event.value,
    });
  };
  getSubUser = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(API_URL + "/bms/manage_bms_subuser", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          getData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };
  render() {
    if (this.state.getData) {
      let getDataItem = JSON.parse(this.state.getData).data.users;
      let subUserListOptions = []
      getDataItem.map((val, i) => (
        subUserListOptions[i] = {
          value: val.id,
          label: val.userProfile.name
        }
      ))
      return (
        <div className="main-margin">
          <Card
            style={{
              padding: "5px 50px",
              border: "solid",
              margin: "10px",
              borderColor: "#32C85A",
            }}
          >
            <h5 style={{ textAlign: "center" }}>Add New BMS Device</h5>
            <Row>
              <Col md="4" lg="4" sm="4" xs="12" style={{ padding: "10px" }}>
                <strong className="adt-blue-color">Select Sub Operator</strong>
                <br />
                <ReactSelect
                  onChange={this.getSubUserId}
                  options={subUserListOptions}
                ></ReactSelect>
              </Col>
              <Col md="2" lg="2" sm="2" xs="12"></Col>
              <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
                <strong className="adt-blue-color">Enter BMS ID</strong>
                <br />
                <input className="adt-input"
                  type="text"
                  placeholder="BMS ID"
                  value={this.state.BMSID}
                  onChange={(event) => this.getBMSID(event.target.value)}
                />
              </Col>
              <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
                <strong className="adt-blue-color">Enter Battery pack ID</strong>
                <br />
                <input className="adt-input"
                  type="text"
                  placeholder="Battery Pack ID"
                  value={this.state.BPID}
                  onChange={(event) => this.getBPID(event.target.value)}
                />
              </Col>
              <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
                <strong className="adt-blue-color">Enter IoT ID</strong>
                <br />
                <input className="adt-input"
                  type="text"
                  placeholder="IoT ID"
                  value={this.state.IOTID}
                  onChange={(event) => this.getIOTID(event.target.value)}
                />
              </Col>
              <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
                <strong className="adt-blue-color">Enter Battery Capacity (Ah)</strong>
                <br />
                <input className="adt-input"
                  type="number"
                  placeholder="Battery Capacity"
                  value={this.state.batteryCapacity}
                  onChange={(event) =>
                    this.getBatteryCapacity(event.target.value)
                  }
                />
              </Col>
              <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
                <strong className="adt-blue-color">Enter Battery Number</strong>
                <br />
                <input className="adt-input"
                  type="text"
                  placeholder="Battery Number"
                  value={this.state.batteryNumber}
                  onChange={(event) =>
                    this.getBatteryNumber(event.target.value)
                  }
                />
              </Col>
              <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
                <strong className="adt-blue-color">Enter Battery Cycle Count</strong>
                <br />
                <input className="adt-input"
                  type="number"
                  placeholder="Battery Cycle Count"
                  value={this.state.batteryCycleCount}
                  onChange={(event) =>
                    this.getBatteryCycleCount(event.target.value)
                  }
                />
              </Col>
              <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
                <strong className="adt-blue-color">Enter Battery Chemistry</strong>
                <br />
                <input className="adt-input"
                  type="text"
                  placeholder="Battery Chemistry"
                  value={this.state.batteryChemistry}
                  onChange={(event) =>
                    this.getBatteryChemistry(event.target.value)
                  }
                />
              </Col>
              <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
                <strong className="adt-blue-color">Enter C-Rate</strong>
                <br />
                <input className="adt-input"
                  type="number"
                  placeholder="C-Rate"
                  value={this.state.cRate}
                  onChange={(event) => this.getCRate(event.target.value)}
                />
              </Col>
              <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
                <strong className="adt-blue-color">Enter Battery Manufacturing Date</strong>
                <br />
                <input className="adt-input"
                  type="date"
                  placeholder="Battery Manufacturing Date"
                  value={this.state.batteryManufacturingDate}
                  onChange={(event) =>
                    this.getBatteryManufacturingDate(event.target.value)
                  }
                />
              </Col>
              <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
                <strong className="adt-blue-color">Enter Battery Deployment Date</strong>
                <br />
                <input className="adt-input"
                  type="date"
                  placeholder="Battery Deployment Date"
                  value={this.state.batteryDeploymentDate}
                  onChange={(event) =>
                    this.getBatteryDeploymentDate(event.target.value)
                  }
                />
              </Col>
              <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
                <strong className="adt-blue-color">Enter Number of S</strong>
                <br />
                <input className="adt-input"
                  type="number"
                  placeholder="Number of S"
                  value={this.state.noOfS}
                  onChange={(event) => this.getNoOfS(event.target.value)}
                />
              </Col>
              <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
                <strong className="adt-blue-color">Enter Number of P</strong>
                <br />
                <input className="adt-input"
                  type="number"
                  placeholder="Number of P"
                  value={this.state.noOfP}
                  onChange={(event) => this.getNoOfP(event.target.value)}
                />
              </Col>
              <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
                <strong className="adt-blue-color">Enter Cell Model Number</strong>(optional)
                <br />
                <input className="adt-input"
                  type="text"
                  placeholder="Cell Model Number"
                  value={this.state.cellModelNumber}
                  onChange={(event) =>
                    this.getCellModelNumber(event.target.value)
                  }
                />
              </Col>
              <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
                <strong className="adt-blue-color">Enter Alias for Battery Pack</strong>(optional)
                <br />
                <input className="adt-input"
                  type="text"
                  placeholder="Alias for Battery Pack"
                  value={this.state.alias}
                  onChange={(event) => this.getAlias(event.target.value)}
                />
              </Col>
              <Col md="6" lg="6" sm="6" xs="12" style={{ padding: "10px" }}>
                <strong className="adt-blue-color">Enter Name of Battery Manufacturer</strong>(optional)
                <br />
                <input className="adt-input"
                  type="text"
                  placeholder="Battery Manufacturer"
                  value={this.state.batteryManufacturer}
                  onChange={(event) =>
                    this.getBatteryManufacturer(event.target.value)
                  }
                />
              </Col>
            </Row>
            <Row><Col><Button
              onClick={this.onSubmit}
              style={{
                color: "#f4f3ef",
                backgroundColor: "#009846",
                width: "10rem"
              }}
            >
              Save new Device
            </Button>
            </Col></Row>
          </Card>
        </div>
      );
    }
    else return (<div className="main-margin" style={{ textAlign: "center" }}>
      <Spinner >
        Loading...
      </Spinner>
    </div>)
  }
}

export default AddDevice;
