import { Badge, Button, Card, Col, Container, Row } from "reactstrap";
import React, { Component } from "react";

import { API_URL } from "variables/general";

class CreateSubUser extends Component {
  constructor() {
    super();
    this.state = {
      password: null,
      disabled: true,
      age: null,
      email: null,
      mobileNumber: null,
      fullName: null,
      userName: null,
      edit: "edit",
      tried: null,
      company: null,
      checkMessage: "",
    };
  }

  createSubUserFunction = () => {
    if (this.state.tried) {
      if (this.state.userName === null) alert("Please Enter Unique Username");
      else if (this.state.fullName === null) alert("Missing Full Name");
      else if (this.state.company === null) alert("Missing Company");
      else if (this.state.mobileNumber === null)
        alert("Please provide Mobile number");
      else if (this.state.email === null) alert("Please provide Email");
      else if (this.state.password === null) alert("Missing Password");
      else {
        const enteredPassword = this.validatePassword(this.state.password)
        if (enteredPassword) {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append(
            "Authorization",
            "Bearer " + window.localStorage.getItem("token")
          );
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
              password: this.state.password,
              userProfile: {
                email: this.state.email.toLowerCase(),
                mobile: this.state.mobileNumber,
                name: this.state.fullName,
                company: this.state.company,
              },
              id: this.state.userName.toLowerCase(),
              edit:this.state.edit
            }),
          };

          fetch(API_URL + "/bms/sub_user_creation", requestOptions)
            .then((response) => response.text())
            .then((result) => {
              let statusResult = JSON.parse(result);
              if (statusResult.status === true) {
                alert(statusResult.message);
                window.location.reload();
              } else alert("Something went wrong");
              this.setState({
                userData: result,
              });
            })
            .catch((error) => console.log("error", error));
        }
        else alert("Unsecured Password! Password must contain Uppercase, Number, Special Character and 8 character length.")
      }
    } else
      alert("Please check for availability first, then continue to submit");
  };
  validatePassword = (password) => {
    // Regular expression to check the criteria
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    // Test the password against the regex
    if (regex.test(password)) {
      return true;
    } else {
      return false;
    }
  }
  userNameCheck = () => {
    if (this.state.userName && this.state.email) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + window.localStorage.getItem("token")
      );
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          id: this.state.userName.toLowerCase(),
          email: this.state.email.toLowerCase(),
        }),
      };
      fetch(API_URL + "/bms/verify_user_id", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status)
            this.setState({
              devData: result,
              tried: true,
              disabled: false,
            });
          else alert(result.message);
        })
        .catch((error) => console.log("error", error));
    } else alert("Please Enter Username and Email to check for availability");
  };
  getName = (event) => {
    this.setState({
      fullName: event,
    });
  };
  getAge = (event) => {
    this.setState({
      age: event,
    });
  };
  getEmail = (event) => {
    this.setState({
      email: event,
    });
  };
  getMobileNumber = (event) => {
    this.setState({
      mobileNumber: event,
    });
  };
  getPassword = (event) => {
    this.setState({
      password: event,
    });
  };
  getUserName = (event) => {
    this.setState({
      userName: event,
    });
  };
  getCompany = (event) => {
    this.setState({
      company: event,
    });
  };
  getEdit = (event) => {
    console.log('getedit',event.target.value)
    this.setState({edit:event.target.value});
  };
  getCompanyLogo = (event) => {
    // console.log(event)
    // if (event.includes('.png' || '.jpg' || '.webp' || '.svg' || '.pdf' || '.jpeg'))
    //   console.log(true)
    // else
    //   console.log('Unsupported File Type')
    // this.setState({
    //   company: event,
    // });
  };

  render() {
    const buttonStyle = {
      borderRadius: "15px",
      borderWidth: "0",
      color: "white",
      backgroundColor: "orange",
    };
    const inputStyle = {
      padding: "10px",
    };
    let showCheck;
    if (this.state.tried) {
      showCheck = this.state.devData.message;
    }
    return (
      <div>
        <Card
          style={{
            padding: "5px 25px",
            margin: "5px 25px",
            border: "solid",
            borderColor: "#32C85A",
          }}
        >
          <h5 style={{ textAlign: "center" }}>Add Sub User</h5>
          <Row>
            <Col style={inputStyle} md="4" lg="4" xl='3' sm="4" xs="12">
              <div>
                <strong className="adt-blue-color">Username</strong>{" "}
              </div>
              <input className="adt-input"
                type="text"
                placeholder="Username"
                value={this.state.userName}
                onChange={(event) => this.getUserName(event.target.value)}
              />
            </Col>
            <Col style={inputStyle} md="4" lg="4" xl='3' sm="4" xs="12">
              <div>
                <strong className="adt-blue-color">Email</strong>
              </div>
              <input className="adt-input"
                type="email"
                placeholder="Email"
                value={this.state.email}
                onChange={(event) => this.getEmail(event.target.value)}
              />
            </Col>
            <Col style={inputStyle} md="4" lg="4" xl='3' sm="4" xs="12">
              <button onClick={this.userNameCheck} style={buttonStyle}>
                Check availability
              </button>
              <div>{showCheck}</div>
            </Col>
          </Row>
          <Row>
            <Col style={inputStyle} md="4" lg="4" xl='3' sm="4" xs="12">
              <div>
                <strong className="adt-blue-color">Full Name</strong>
              </div>
              <input className="adt-input"
                type="text"
                placeholder="Full Name"
                value={this.state.fullName}
                onChange={(event) => this.getName(event.target.value)}
                disabled={this.state.disabled ? "disabled" : ""}
              />
            </Col>
            <Col style={inputStyle} md="4" lg="4" xl='3' sm="4" xs="12">
              <div>
                <strong className="adt-blue-color">Company</strong>
              </div>
              <input className="adt-input"
                type="text"
                placeholder="Company"
                value={this.state.company}
                onChange={(event) => this.getCompany(event.target.value)}
                disabled={this.state.disabled ? "disabled" : ""}
              />
            </Col>
            <Col style={inputStyle} md="4" lg="4" xl='3' sm="4" xs="12"></Col>
          </Row>
          <Row>
            <Col style={inputStyle} md="4" lg="4" xl='3' sm="4" xs="12">
              <div>
                <strong className="adt-blue-color">Mobile Number</strong>
              </div>
              <input className="adt-input"
                type="number"
                placeholder="Mobile Number"
                value={this.state.mobileNumber}
                onChange={(event) => this.getMobileNumber(event.target.value)}
                disabled={this.state.disabled ? "disabled" : ""}
              />
            </Col>
            
            <Col style={inputStyle} md="4" lg="4" xl='3' sm="4" xs="12">
              <div>
                <strong className="adt-blue-color">Password</strong>
              </div>
              <input className="adt-input"
                type="password"
                placeholder="Password"
                value={this.state.password}
                onChange={(event) => this.getPassword(event.target.value)}
                disabled={this.state.disabled ? "disabled" : ""}
              />
            </Col>
            <Col style={inputStyle} md="4" lg="4" xl='3' sm="4" xs="12">
              <div>
                <strong className="adt-blue-color">Edit</strong>
              </div>
              <select onChange={this.getEdit}>
                <option selected value="edit">Edit</option>
                <option value="no">Non - Edit</option>
              </select>
            </Col>
          </Row>
          {/* <Row>
            <Col style={inputStyle} md="4" lg="4" xl='3' sm="4" xs="12">
              <strong className="adt-blue-color">Company Logo</strong>(optional)
              <br />
              <input
                type="file"
                accept="image/*"
                value={this.state.companyLogo}
                onChange={(event) =>
                  this.getCompanyLogo(event.target.files[0])
                }
              />
            </Col>
          </Row> */}
          <Row>
            <Col style={inputStyle} md="4" lg="4" xl='3' sm="4" xs="12">
              <Button
                onClick={this.createSubUserFunction}
                disabled={this.state.disabled ? "disabled" : ""}
                style={{
                  color: "#f4f3ef",
                  backgroundColor: "#009846",
                  width: "10rem"
                }}
              >
                Add User
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}
export default CreateSubUser;
