import Logo from "assets/img/logo-white-unbg.png";
import { Nav } from "reactstrap";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import React from "react";

// javascript plugin used to create scrollbars on windows

/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.sidebar = React.createRef();
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  componentDidMount() {
    // this.testFunction();
    // this.testFunction = setInterval(this.testFunction, 3000);
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  // stopInterval() {
  //   clearInterval(this.testFunction);
  // }

  componentWillUnmount() {
    // this.stopInterval();
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo">
          <div className="logo-img">
            <img src={Logo} alt=""></img>
          </div>
        </div>
        <div className="sidebar-wrapper" ref={this.sidebar}>
          <Nav>
            {window.localStorage.getItem("role") === "super_user" ? (
              <div>
                <li>
                  <NavLink
                    to="/admin/edit-settings"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className="nc-icon nc-bullet-list-67" />
                    <p>Edit Settings</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/add-settings"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className="nc-icon nc-bullet-list-67" />
                    <p>Add Settings</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/fota-iot"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className="nc-icon nc-bullet-list-67" />
                    <p>FOTA IoT</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/logout"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className="nc-icon nc-bullet-list-67" />
                    <p>Logout</p>
                  </NavLink>
                </li>
              </div>
            ) : (
              this.props.routes.map((prop, key) => {
                return (
                  <li
                    className={
                      this.activeRoute(prop.path) +
                      (prop.pro ? " active-pro" : "")
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"
                      activeClassName="active"
                    >
                      <i className={prop.icon} />
                      <p>{prop.name}</p>
                    </NavLink>
                  </li>
                );
              })
            )}
          </Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
