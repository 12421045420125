// NOTE: If a setting is marked as optional you can leave it as the default text to skip using it.

const AWS_REGION = "ap-south-1";
const AWS_IOT_ENDPOINT = "a2q76fsclylwif-ats.iot.ap-south-1.amazonaws.com";
const AWS_CUSTOM_AUTH_USERNAME = "the_suave_developer"
const AWS_CUSTOM_AUTH_AUTHORIZER_NAME = "CustomIoTAuth" // Optional if using a custom domain with a custom authorizer
const AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD = "rajatyadav"
const AWS_CUSTOM_AUTH_PASSWORD = ""
export {
    AWS_REGION,
    AWS_IOT_ENDPOINT,
    AWS_CUSTOM_AUTH_USERNAME,
    AWS_CUSTOM_AUTH_AUTHORIZER_NAME,
    AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD,
    AWS_CUSTOM_AUTH_PASSWORD
}