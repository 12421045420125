
const API_URL = "https://cpo.aeidth.com"
// const API_URL = "http://192.168.1.100:8000"
// 

const WebSocketClientAddress = [
  "wss://cpo.aeidth.com:8003/bms_app/BMSADT2021001/test",
];
const serverUrl = "https://cpo.aeidth.com"
const AWS_URL = ["https://zcrate9n4a.execute-api.ap-south-1.amazonaws.com/v1/"];

export { WebSocketClientAddress, API_URL, AWS_URL , serverUrl};