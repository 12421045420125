import React, { useEffect, useState } from 'react';
import "components/MapPro.js/Map.css";
import { Redirect } from "react-router";
import ReactSelect from "react-select";
import { API_URL } from "variables/general";
import { Row, Col } from "reactstrap";
import {
  Circle,
  GoogleMap,
  InfoBox,
  LoadScript,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
function MainMap() {
  const [locationCoordinates, setLocationCoordinates] = useState('')
  const [packData, setPackData] = useState('')
  useEffect(() => {
    handleMapCoordinates()
    // getPackData()
  }, [])
  const handleMapCoordinates = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(API_URL + "/bms/location_latest_data", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLocationCoordinates(result.data.data)
      })
      .catch((error) => console.log("error", error));
  };
  const getPackData = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(API_URL + "/bms/battery_packs", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPackData(result.data)
      })
      .catch((error) => console.log("error", error));
  };
  let options = { closeBoxURL: "", enableEventPropagation: true };
  let containerStyle = {
    width: "75vw",
    height: "75vh",
  };
  let center = {
    lat: 28.5,
    lng: 77,
  };
    return (
      <div style={{ marginTop: "5px", position: "fixed" }}>
        <LoadScript googleMapsApiKey="AIzaSyDUob5ItT5zZ_lWzJVkW9_Rz9oTq-B9M74">
        {locationCoordinates?<GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={5}
          >
            <MarkerClusterer options={options}>
              {(clusterer) =>
               locationCoordinates.map((val,i)=>
                <Marker
                key={val.did}
                className="tooltipOne"
                title={`BMS ID: ${val.did}`}
                position={{
                  lat: parseFloat(val.gpsLatCoordinate),
                  lng: parseFloat(val.gpsLongCoordinate),
                }}
                clusterer={clusterer}
                clickable="true"
              // onClick={() => this.onClick(bmsDeviceData.location.did)}
              ></Marker>
               ) 
               }
            </MarkerClusterer>
          </GoogleMap>:'Loading ... '}
        </LoadScript>
      </div>
    )
}

export default MainMap