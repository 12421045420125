import React from "react";

function Logout() {
  const logoutFunction = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };
  return (
    <div className="main-margin">
      <button onClick={logoutFunction}>Logout</button>
    </div>
  );
}
export default Logout;
