import React ,{useEffect,useState, useContext}from "react";
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import ChargerData from "./ChargerData";
import ChargerGraphs from "./ChargerGraphs";
function ChargerTab() {
    const [iconPills, setIconPills] = useState("1");
    return (
        <>
          <div className="section section-tabs">
              <Nav className="justify-content-center" role="tablist" tabs>
                <NavItem>
                  <NavLink
                    className={iconPills === "1" ? "active" : ""}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setIconPills("1");
                    }}
                  >
                    <i class="fa fa-home" aria-hidden="true"></i>&ensp;{" "}
                    <strong>Home</strong>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={iconPills === "2" ? "active" : ""}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setIconPills("2");
                    }}
                  >
                    <i class="fa fa-home" aria-hidden="true"></i>&ensp;
                    <strong>Analytics</strong>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent
                className="text-center"
                activeTab={"iconPills" + iconPills}
              >
                <TabPane tabId="iconPills1">
                  <ChargerData />
                </TabPane>
                <TabPane tabId="iconPills2">
                    <ChargerGraphs />
                </TabPane>
              </TabContent>
          </div>
        </>
      );
}

export default ChargerTab