import React from "react";
import { Bar } from "react-chartjs-2";
import { Card, Row, Col, Badge, Spinner } from "reactstrap";

function BatteryCells(props) {
  if (props) {
    let Cell1 = props.Cell1;
    let Cell2 = props.Cell2;
    let Cell3 = props.Cell3;
    let cell4 = props.cell4;
    let Cell5 = props.Cell5;
    let Cell6 = props.Cell6;
    let Cell7 = props.Cell7;
    let cell8 = props.cell8;
    let Cell9 = props.Cell9;
    let Cell10 = props.Cell10;
    let Cell11 = props.Cell11;
    let cell12 = props.cell12;
    let Cell13 = props.Cell13;
    let Cell14 = props.Cell14;
    let Cell15 = props.Cell15;
    let cell16 = props.cell16;
    let Cell17 = props.Cell17;
    let Cell18 = props.Cell18;
    let Cell19 = props.Cell19;
    let cell20 = props.cell20;
    let Cell21 = props.Cell21;
    let Cell22 = props.Cell22;
    let Cell23 = props.Cell23;
    let Cell24 = props.Cell24;
    let MaxCellVolt = props.MaxCellVolt;
    let MinCellVolt = props.MinCellVolt;
    let NumberOfCell = props.NumberOfCell;

    let cellValue = [];
    let cellLabel = [];
    let cellColor = [];
    let cellVoltageData = [
      Cell1,
      Cell2,
      Cell3,
      cell4,
      Cell5,
      Cell6,
      Cell7,
      cell8,
      Cell9,
      Cell10,
      Cell11,
      cell12,
      Cell13,
      Cell14,
      Cell15,
      cell16,
      Cell17,
      Cell18,
      Cell19,
      cell20,
      Cell21,
      Cell22,
      Cell23,
      Cell24,
    ];

    for (let i = 0; i < cellVoltageData.length; i++) {
      cellValue[i] = cellVoltageData[i];
    }
    for (let i = 0; i < NumberOfCell; i++) {
      cellLabel[i] = i + 1;
    }
    for (let i = 0; i < cellVoltageData.length; i++) {
      if (cellVoltageData[i] === MaxCellVolt) cellColor[i] = "#b8b209";
      else if (cellVoltageData[i] === MinCellVolt) cellColor[i] = "#078da8";
      else cellColor[i] = "rgba(10, 255, 10, 1)";
    }

    const batteryCellsChart = {
      data: () => {
        return {
          labels: cellLabel,
          datasets: [
            {
              label: "Cell Voltage (mV)",
              data: cellValue,
              backgroundColor: cellColor,
              borderColor: [],
              borderWidth: 0,
            },
          ],
        };
      },

      options: {
        legend: {
          display: false,
          position: "top",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                    max: 5000,
                    min: 0,
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
    return (
      <div>
        <div>
          <Card>
            <div style={{ margin: "15px" }}>
              <div className="card-head">Cell Voltages</div>
              {NumberOfCell ? (
                <>
                  <Row>
                    <Col>
                      <div>
                        <Badge
                          style={{
                            backgroundColor: "#078da8",
                            color: "white",

                            margin: "10px",
                          }}
                        >
                          Min: {MinCellVolt} mV
                        </Badge>
                        <Badge
                          style={{
                            backgroundColor: "#b8b209",
                            color: "white",

                            margin: "10px",
                          }}
                        >
                          Max: {MaxCellVolt} mV
                        </Badge>
                        <Badge
                          style={{
                            backgroundColor: "rgba(100, 100, 100, 1)",
                            color: "white",

                            margin: "10px",
                          }}
                        >
                          Diff: {MaxCellVolt - MinCellVolt} mV
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Bar
                        data={batteryCellsChart.data}
                        options={batteryCellsChart.options}
                      />
                      <Row>
                        <Col md="3"></Col>
                        <Col md="3" className="card-head">
                          Cell Number
                        </Col>
                        {/* <Col md="3">
                      {" "}
                      <img src={Arrow} />
                    </Col> */}
                        <Col md="3"></Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              ) : (
                <div>
                  <Spinner>Loading...</Spinner>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Spinner>Loading...</Spinner>
      </div>
    );
  }
}

export default BatteryCells;
