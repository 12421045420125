
import MapPro from "components/MapPro.js/MapPro";

import Notifications from "views/Notifications.js";

import ProGeoFence from "components/Settings/ProGeoFencing";
import SubUsers from "views/SubUsers";
import ReadCSVFile from "components/ReadCSVFile/ReadCSVFile";
import MainMap from "components/MapPro.js/MainMap";
import Test from "views/Test";

const subOwnerRoutes = [
    {
        path: "/sub-users",
        name: "Dashboard",
        icon: "nc-icon nc-bullet-list-67",
        component: SubUsers,
        layout: "/admin",
    },
    {
        path: "/mappro",
        name: "Map",
        icon: "nc-icon nc-pin-3",
        component: MainMap,
        layout: "/admin",
    },
    {
        path: "/pro-geo-fence",
        name: "Geo Fence Config",
        icon: "nc-icon nc-settings-gear-65",
        component: ProGeoFence,
        layout: "/admin",
    },
    {
        path: "/notifications",
        name: "Notifications",
        icon: "nc-icon nc-bell-55",
        component: Notifications,
        layout: "/admin",
    },
    // {
    //     path: "/test",
    //     name: "Test",
    //     icon: "nc-icon nc-single-02",
    //     component: Test,
    //     layout: "/admin",
    // },
    {
        path: "/read-csv-file",
        name: "Get Data From CSV file",
        icon: "nc-icon nc-cloud-upload-94",
        component: ReadCSVFile,
        layout: "/admin",
    },
];
export default subOwnerRoutes