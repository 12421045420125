import ReactSelect from 'react-select';
import React, { useState, useEffect } from 'react'
import { Row, Col, Card, UncontrolledPopover,PopoverHeader,PopoverBody } from 'reactstrap'
import ParallelTab from 'components/Parallel/ParallelTab';
import { API_URL } from 'variables/general';
import { Link, Redirect } from "react-router-dom";
function EVDevices(props) {
  let packData = [];
  useEffect(() => {
    getIoTList()
  }, [])
  const [packTableS, setPackTableS] = useState("");
  const [alias, setAlias] = useState("");
  const [idForAlias, setIdForAlias] = useState("");
  const [ioTList, setIoTList] = useState()
  const [showUi, setShowUi] = useState(false);
  const [packDataComplete, setPackDataComplete] = useState("");
  const subuserId = props.location.state
  const tableHeaderStyle = {
    position: "sticky",
    top: 0,
    backgroundColor: "#6da2f7",
    color: "#000",
    padding: "5px",
  };

  const tableRowStyle = {
    color: "#30428c",
    fontWeight: "700",
    padding: "5px",
    borderBottom: "2px solid #c1d6c6",
  };
  const enumForIotBootReason = [
    "ApplicationReset",
    "FirmwareUpdate",
    "LocalReset",
    "PowerUp",
    "RemoteReset",
    "ScheduledReset",
    "Triggered",
    "Unknown",
    "Watchdog",
  ];
  let iotBootReason;
  const getIotBootReason = (second) => {
    iotBootReason = enumForIotBootReason[second];
  };
  const editAlias = (val) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify({
        batteryPackId: idForAlias,
        packData: {
          alias: alias,
        },
      }),
    };
    fetch(API_URL + "/bms/bms_devices", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // console.log("dataaaa", result)
        let status = JSON.parse(result).status;
        if (status === true) alert("Update Successful");
        // window.location.reload();
      })
      .catch((error) => console.log("error", error));
  };
  function packDataRecv(data) {
    var temp_arr = [];
    for (let i = 0; i < data.length; i++) {
      temp_arr[i] = { did: data[i].did, packData: data[i] };
    }
    packData = temp_arr;
    
    let bmsList = [];
    for (let i = 0; i < packData.length; i++) {
      bmsList[i + 1] = {
        value: packData[i].did,
        label: packData[i].packData.alias
          ? packData[i].packData.alias
          : packData[i].did,
      };
      bmsList[0] = { value: "All", label: "All Devices" };
    }
    setIoTList(bmsList)
    ///////for online row on top ============================================>
    // let updatedArray = [];
    // for (let i = 0; i < packData.length; i++) {
    //   if (packData[i].packData.online) {
    //     updatedArray.unshift(packData[i]);
    //   } else updatedArray.push(packData[i]);
    // }
    // packData = updatedArray;
    /////////////////////searched id////////////////////////

    setPackTableS(
      <table
        style={{
          overflow: "scroll",
          display: "block",
          height: "70vh",
        }}
      >
        <thead style={{ textAlign: "center" }}>
          <th style={tableHeaderStyle}>Alias</th>
          <th style={tableHeaderStyle}>Device ID</th>
          <th style={tableHeaderStyle}>Manufacturing Date</th>
          <th style={tableHeaderStyle}>IoT Boot</th>
          <th style={tableHeaderStyle}>IoT Firmware Version</th>
          <th style={tableHeaderStyle}>IoT Hardware Version</th>
          <th style={tableHeaderStyle}>IoT Boot Up Reason</th>
        </thead>
        <tbody>
          {packData.map((val, i) => (
            <tr style={{ textAlign: "center" }}>
              <td className="text-center" style={tableRowStyle}>
                <div>
                  <UncontrolledPopover
                    target="PopoverForAlias"
                    trigger="legacy"
                    placement="bottom"
                  >
                    <PopoverHeader>Edit Alias</PopoverHeader>
                    <PopoverBody>
                      <input
                        onChange={(event) => setAlias(event.target.value)}
                        placeholder="New Alias"
                      ></input>
                      <button onClick={() => editAlias(val.packData.did)}>
                        Submit
                      </button>
                    </PopoverBody>
                  </UncontrolledPopover>
                  <button
                    id="PopoverForAlias"
                    style={{
                      color: "#17a2b8",
                      backgroundColor: "white",
                      borderWidth: 0,
                      float: "right",
                      fontWeight: 600,
                    }}
                    onClick={() => setIdForAlias(val.packData.did)}
                  >
                    {val.packData.alias}&ensp;
                    <i class="fas fa-pen"></i>
                  </button>
                </div>
              </td>
              <td className="text-center" style={tableRowStyle}>
                <Link
                  to={{
                    pathname: "/admin/evtrackerhome",
                    state: {
                      id: val.packData.did,
                      alias : val.packData.alias
                    },
                  }}
                >
                  {val.packData.did}
                </Link>
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.manufacturingDate}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {"-"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
    setShowUi(true);
    getBootData();
  }
  const getBootData = (packData) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    let qParam = ''
    if (props.location.state)
      requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ subOwner: props.location.state, boot: 'iot' }),
      }
    else {
      requestOptions = {
        method: "GET",
        headers: myHeaders,
      }
      qParam = '?boot=iot'
    }
    fetch(API_URL + "/bms/boot" + qParam, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        bootRecv(result.data.data);
      })
      .catch((error) => console.log("error", error));
  };
  function bootRecv(data) {
    for (let i = 0; i < packData.length; i++) {
      for (let j = 0; j < data.length; j++) {
        if (packData[i].packData.did === data[j].did) {
          packData[i]["bootData"] = data[j];
          break;
        }
        if (j === data.length - 1) packData[i]["bootData"] = {};
      }
    }
    setPackDataComplete(packData)
    // console.log('bootrcd', packData)
    setPackTableS(
      <table
        style={{
          overflow: "scroll",
          display: "block",
          height: "70vh",
        }}
      >
        <thead style={{ textAlign: "center" }}>
        <th style={tableHeaderStyle}>Alias</th>
          <th style={tableHeaderStyle}>Device ID</th>
          <th style={tableHeaderStyle}>Manufacturing Date</th>
          <th style={tableHeaderStyle}>IoT Boot</th>
          <th style={tableHeaderStyle}>IoT Firmware Version</th>
          <th style={tableHeaderStyle}>IoT Hardware Version</th>
          <th style={tableHeaderStyle}>IoT Boot Up Reason</th>
        </thead>
        <tbody>
          {packData.map((val, i) => (
            <tr style={{ textAlign: "center" }}>
              <td className="text-center" style={tableRowStyle}>
                <div>
                  <UncontrolledPopover
                    target="PopoverForAlias"
                    trigger="legacy"
                    placement="bottom"
                  >
                    <PopoverHeader>Edit Alias</PopoverHeader>
                    <PopoverBody>
                      <input
                        onChange={(event) => setAlias(event.target.value)}
                        placeholder="New Alias"
                      ></input>
                      <button onClick={() => editAlias(val.did)}>
                        Submit
                      </button>
                    </PopoverBody>
                  </UncontrolledPopover>
                  <button
                    id="PopoverForAlias"
                    style={{
                      color: "#17a2b8",
                      backgroundColor: "white",
                      borderWidth: 0,
                      float: "right",
                      fontWeight: 600,
                    }}
                    onClick={() => setIdForAlias(val.did)}
                  >
                    {val.packData.alias}&ensp;
                    <i class="fas fa-pen"></i>
                  </button>
                </div>
              </td>
              <td className="text-center" style={tableRowStyle}>
                <Link
                  to={{
                    pathname: "/admin/evtrackerhome",
                    state: {
                      id: val.packData.did,
                      alias : val.packData.alias
                    },
                  }}
                >
                  {val.packData.did}
                </Link>
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.manufacturingDate?
                <div>
                    {val.packData.manufacturingDate.slice(8, 10)}/
                    {val.packData.manufacturingDate.slice(5, 7)}/
                    {val.packData.manufacturingDate.slice(0, 4)}
                    </div>:'-'}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.tms ? (
                  <div>
                    {val.bootData.tms.slice(11, 19)}&ensp;
                    {val.bootData.tms.slice(8, 10)}/
                    {val.bootData.tms.slice(5, 7)}/
                    {val.bootData.tms.slice(0, 4)}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.firmwareVersion
                  ? val.bootData.firmwareVersion
                  : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.hardwareVersion
                  ? val.bootData.hardwareVersion
                  : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {getIotBootReason(val.bootData.bootUpReason)}
                {iotBootReason ? iotBootReason : "-"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
  const getIoTList = ()=>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    // fetch(API_URL + "/iot/devices?sub_owner="+subuserId, requestOptions)
let text
    if(subuserId){
      text =API_URL + "/iot/devices?sub_owner="+subuserId 
    }
    else{
      text= API_URL + "/iot/devices"
    }
    fetch(text, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        packDataRecv(result.data)
      })
      .catch((error) => console.log("error", error));
  }
  const getSearchedItem = (val) => {
    let searchedArray = []
    for (let i = 0; i < packDataComplete.length; i++) {
      if (val.value === packDataComplete[i].did) {
        searchedArray = packDataComplete[i]
      }
    }
    if (val.value === "All") {
      setPackTableS(
        <table
          style={{
            overflow: "scroll",
            display: "block",
            height: "70vh",
          }}
        >
          <thead style={{ textAlign: "center" }}>
          <th style={tableHeaderStyle}>Alias</th>
          <th style={tableHeaderStyle}>Device ID</th>
          <th style={tableHeaderStyle}>Manufacturing Date</th>
          <th style={tableHeaderStyle}>IoT Boot</th>
          <th style={tableHeaderStyle}>IoT Firmware Version</th>
          <th style={tableHeaderStyle}>IoT Hardware Version</th>
          <th style={tableHeaderStyle}>IoT Boot Up Reason</th>
          </thead>
          <tbody>
            {packDataComplete.map((val, i) => (
              <tr style={{ textAlign: "center" }}>
              <td className="text-center" style={tableRowStyle}>
                <div>
                  <UncontrolledPopover
                    target="PopoverForAlias"
                    trigger="legacy"
                    placement="bottom"
                  >
                    <PopoverHeader>Edit Alias</PopoverHeader>
                    <PopoverBody>
                      <input
                        onChange={(event) => setAlias(event.target.value)}
                        placeholder="New Alias"
                      ></input>
                      <button onClick={() => editAlias(val.did)}>
                        Submit
                      </button>
                    </PopoverBody>
                  </UncontrolledPopover>
                  <button
                    id="PopoverForAlias"
                    style={{
                      color: "#17a2b8",
                      backgroundColor: "white",
                      borderWidth: 0,
                      float: "right",
                      fontWeight: 600,
                    }}
                    onClick={() => setIdForAlias(val.did)}
                  >
                    {val.packData.alias}&ensp;
                    <i class="fas fa-pen"></i>
                  </button>
                </div>
              </td>
              <td className="text-center" style={tableRowStyle}>
                <Link
                  to={{
                    pathname: "/admin/evtrackerhome",
                    state: {
                      id: val.packData.did,
                      alias : val.packData.alias
                    },
                  }}
                >
                  {val.packData.did}
                </Link>
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.packData.manufacturingDate?
                <div>
                    {val.packData.manufacturingDate.slice(8, 10)}/
                    {val.packData.manufacturingDate.slice(5, 7)}/
                    {val.packData.manufacturingDate.slice(0, 4)}
                    </div>:'-'}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.tms ? (
                  <div>
                    {val.bootData.tms.slice(11, 19)}&ensp;
                    {val.bootData.tms.slice(8, 10)}/
                    {val.bootData.tms.slice(5, 7)}/
                    {val.bootData.tms.slice(0, 4)}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.firmwareVersion
                  ? val.bootData.firmwareVersion
                  : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {val.bootData.hardwareVersion
                  ? val.bootData.hardwareVersion
                  : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {getIotBootReason(val.bootData.bootUpReason)}
                {iotBootReason ? iotBootReason : "-"}
              </td>
            </tr>
            ))}
          </tbody>
        </table>
      );
    } else {
      setPackTableS(
        <table
          style={{
            overflow: "scroll",
            display: "block",
            height: "70vh",
          }}
        >
          <thead style={{ textAlign: "center" }}>
          <th style={tableHeaderStyle}>Alias</th>
          <th style={tableHeaderStyle}>Device ID</th>
          <th style={tableHeaderStyle}>Manufacturing Date</th>
          <th style={tableHeaderStyle}>IoT Boot</th>
          <th style={tableHeaderStyle}>IoT Firmware Version</th>
          <th style={tableHeaderStyle}>IoT Hardware Version</th>
          <th style={tableHeaderStyle}>IoT Boot Up Reason</th>
          </thead>
          <tbody>
          <tr style={{ textAlign: "center" }}>
              <td className="text-center" style={tableRowStyle}>
                <div>
                  <UncontrolledPopover
                    target="PopoverForAlias"
                    trigger="legacy"
                    placement="bottom"
                  >
                    <PopoverHeader>Edit Alias</PopoverHeader>
                    <PopoverBody>
                      <input
                        onChange={(event) => setAlias(event.target.value)}
                        placeholder="New Alias"
                      ></input>
                      <button onClick={() => editAlias(searchedArray.did)}>
                        Submit
                      </button>
                    </PopoverBody>
                  </UncontrolledPopover>
                  <button
                    id="PopoverForAlias"
                    style={{
                      color: "#17a2b8",
                      backgroundColor: "white",
                      borderWidth: 0,
                      float: "right",
                      fontWeight: 600,
                    }}
                    onClick={() => setIdForAlias(searchedArray.did)}
                  >
                    {searchedArray.packData.alias}&ensp;
                    <i class="fas fa-pen"></i>
                  </button>
                </div>
              </td>
              <td className="text-center" style={tableRowStyle}>
                <Link
                  to={{
                    pathname: "/admin/evtrackerhome",
                    state: {
                      id: searchedArray.packData.did,
                      alias : searchedArray.packData.alias
                    },
                  }}
                >
                  {searchedArray.packData.did}
                </Link>
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.packData.manufacturingDate?
                <div>
                    {searchedArray.packData.manufacturingDate.slice(8, 10)}/
                    {searchedArray.packData.manufacturingDate.slice(5, 7)}/
                    {searchedArray.packData.manufacturingDate.slice(0, 4)}
                    </div>:'-'}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bootData.tms ? (
                  <div>
                    {searchedArray.bootData.tms.slice(11, 19)}&ensp;
                    {searchedArray.bootData.tms.slice(8, 10)}/
                    {searchedArray.bootData.tms.slice(5, 7)}/
                    {searchedArray.bootData.tms.slice(0, 4)}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bootData.firmwareVersion
                  ? searchedArray.bootData.firmwareVersion
                  : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {searchedArray.bootData.hardwareVersion
                  ? searchedArray.bootData.hardwareVersion
                  : "-"}
              </td>
              <td className="text-center" style={tableRowStyle}>
                {getIotBootReason(searchedArray.bootData.bootUpReason)}
                {iotBootReason ? iotBootReason : "-"}
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
  };
  if (showUi) {
    return (
      <div>
        <Card style={{ padding: "15px", margin: "10px" }}>
          <Row style={{ padding: "0 15px 15px 15px", marginBottom: "10px" }}>
            <Col md="4" lg="4" sm="12" xs="12" xl="4">
              <ReactSelect options={ioTList} onChange={getSearchedItem} />
            </Col>
          </Row>
          {packTableS}
        </Card>
      </div>
    )
  }
else return <div>Loading...</div>
}
export default EVDevices