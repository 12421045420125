import "../../components/MapPro.js/Map.css";

import {
  Circle,
  GoogleMap,
  InfoBox,
  LoadScript,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import { Button, Col, Row, Tooltip } from "reactstrap";

import { API_URL } from "variables/general";
import React, { useEffect, useState, useRef } from "react";
import { useContext } from "react";

import MarkerB from "assets/img/icons8-map-pin-50.png";
import MarkerA from "assets/img/icons8-map-pin-50 (1).png";
let dataArray = [];
let mapCenterOnDrag;
let map_center_dragged;
let dragFlag = false;
function SingleMapPro(props) {
  const mapRef = useRef(null);
  const locationData = props.locationData
  const locationDataM = props.locationDataM
  const [locationTime, setLocationTime] = useState("");
  const [position, setPosition] = useState();
  const id = props.id
  const apiKey = "AIzaSyDUob5ItT5zZ_lWzJVkW9_Rz9oTq-B9M74"

  function handleLoad(map) {
    mapRef.current = map;
  }
  function handleCenter() {
    if (!mapRef.current) return;
    dragFlag = true;
    const newPos = mapRef.current.getCenter().toJSON();
    setPosition(newPos);
  }

  const getLocationTime = (val) => {
    setLocationTime(val);
  };
  const sendLocationTime = () => {
    // props.setLocationDataLoaded(true);
    // props.setLocationDataLoadedFirst(true);
    props.getLocationData(id,locationTime);
  };
  const containerStyle = {
    width: "70vw",
    height: "70vh",
  };
  if (locationData) {
  if (locationData.bmsLocation.length>0) {
    let polyLinePath = [];
    let positionArray;


    let positionUsed;
    let tms;
    let startPoint = {
      lat: parseFloat(locationData.bmsLocation[0].gpsLatCoordinate),
      lng: parseFloat(locationData.bmsLocation[0].gpsLongCoordinate),
    };
    let startPointTime;
    if (locationData.bmsLocation[0].tms)
      startPointTime = locationData.bmsLocation[0].tms;
    else startPointTime = locationData.bmsLocation[1].tms;

    let endPointTime =
      locationData.bmsLocation[locationData.bmsLocation.length - 1].tms;

    // for (let i = 0; i < locationData.bmsLocation.length;) {
    //   if (
    //     locationData.bmsLocation[i].gpsLatCoordinate &&
    //     locationData.bmsLocation[i].gpsLongCoordinate
    //   ) {
    //     startPoint = {
    //       lat: parseFloat(locationData.bmsLocation[i].gpsLatCoordinate),
    //       lng: parseFloat(locationData.bmsLocation[i].gpsLongCoordinate),
    //     };
    //     startPointTime = locationData.bmsLocation[i].tms;
    //     endPointTime =
    //       locationData.bmsLocation[locationData.bmsLocation.length - 1].tms;
    //   } else i++;
    // }

    for (let i = 0; i < locationData.bmsLocation.length; i++)
      if (
        locationData.bmsLocation[i].gpsLatCoordinate &&
        locationData.bmsLocation[i].gpsLongCoordinate
      ) {
        polyLinePath.push({
          lat: parseFloat(locationData.bmsLocation[i].gpsLatCoordinate),
          lng: parseFloat(locationData.bmsLocation[i].gpsLongCoordinate),
        });
      }
    for (let i = locationData.bmsLocation.length - 1; i > 0; i--)
      if (
        locationData.bmsLocation[i].gpsLatCoordinate &&
        locationData.bmsLocation[i].gpsLongCoordinate
      ) {
        positionUsed = {
          lat: parseFloat(locationData.bmsLocation[i].gpsLatCoordinate),
          lng: parseFloat(locationData.bmsLocation[i].gpsLongCoordinate),
        };
        tms = locationData.bmsLocation[i].tms;
        i = 0;
        mapCenterOnDrag = {
          lat: parseFloat(locationData.bmsLocation[i].gpsLatCoordinate),
          lng: parseFloat(locationData.bmsLocation[i].gpsLongCoordinate),
        };
      }
    if (locationDataM) {
      console.log('locationDataM',locationDataM)
      if (locationDataM.ext[0].gpsValues.gpsFixStatus)
        if (locationDataM.ext[0].gpsValues.gpsFixStatus === 1)
          dataArray.push({
            lat: parseFloat(locationDataM.ext[0].gpsValues.gpsLatCoordinate),
            lng: parseFloat(locationDataM.ext[0].gpsValues.gpsLongCoordinate),
          });
    }
    const polylineOptions = {
      strokeColor: "#32c85a",
      strokeOpacity: 1,
      strokeWeight: 5,
      fillColor: "#32c85a",
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: 3000,
      zIndex: 1,
    };
    console.log('locationDataM',locationDataM)
    if (tms) {
      positionArray = polyLinePath.concat(dataArray);
      return (
        <div>
          <Row>
            <Col xs="12" sm="12" md="4" lg="3" xl="3">
              <input
                type="datetime-local"
                onChange={(event) => getLocationTime(event.target.value)}
              ></input>
              <br />
             
                <Button onClick={sendLocationTime}>Submit</Button>
              
              <br />
              <div>
                {startPointTime ? (
                  <>
                    <strong>Starting Point:</strong>&ensp;
                    <span>
                      {startPointTime.slice(11, 19)}&ensp;
                      {startPointTime.slice(8, 10)}/{startPointTime.slice(5, 7)}
                      /{startPointTime.slice(0, 4)}
                    </span>
                    &ensp;
                    <img alt="startPoint" src={MarkerA}></img>
                  </>
                ) : (
                  ""
                )}
                <br />
                {endPointTime ? (
                  <>
                    <strong>End Point:</strong>&ensp;
                    {locationDataM.tms?
                    locationDataM.ext[0].gpsValues.gpsFixStatus?
                    
                    <span>
                      {locationDataM.tms.slice(11, 19)}&ensp;
                      {locationDataM.tms.slice(8, 10)}/{locationDataM.tms.slice(5, 7)}/
                      {locationDataM.tms.slice(0, 4)}
                    </span>
                    :<span>
                    {endPointTime.slice(11, 19)}&ensp;
                    {endPointTime.slice(8, 10)}/{endPointTime.slice(5, 7)}/
                    {endPointTime.slice(0, 4)}
                  </span>
                    :<span>
                      {endPointTime.slice(11, 19)}&ensp;
                      {endPointTime.slice(8, 10)}/{endPointTime.slice(5, 7)}/
                      {endPointTime.slice(0, 4)}
                    </span>}
                    &ensp;
                    <img alt="endPoint" src={MarkerB}></img>
                  </>
                ) : (
                  ""
                )}
              </div>
              <br />
              {dragFlag ? (
                <Button
                  onClick={() => {
                    dragFlag = false;
                    setPosition(
                      locationDataM
                        ? locationDataM.ext[0].gpsValues.gpsFixStatus === 1
                          ? {
                              lat: parseFloat(
                                locationDataM.ext[0].gpsValues.gpsLatCoordinate
                              ),
                              lng: parseFloat(
                                locationDataM.ext[0].gpsValues.gpsLongCoordinate
                              ),
                            }
                          : positionUsed
                        : positionUsed
                    );
                  }}
                  style={{
                    borderWidth: "0px",
                    borderRadius: "10px",
                    color: "white",
                    backgroundColor: "orange",
                    fontWeight: "bolder",
                    fontSize: "12px",
                  }}
                >
                  Recenter
                </Button>
              ) : (
                ""
              )}
            </Col>
            <Col xs="12" sm="12" md="8" lg="9" xl="9">
              <div>
                <LoadScript googleMapsApiKey={apiKey}>
                  <GoogleMap
                    onLoad={handleLoad}
                    mapContainerStyle={containerStyle}
                    center={
                      dragFlag
                        ? position
                        : locationDataM
                        ? locationDataM.ext[0].gpsValues.gpsFixStatus === 1
                          ? {
                              lat: parseFloat(
                                locationDataM.ext[0].gpsValues.gpsLatCoordinate
                              ),
                              lng: parseFloat(
                                locationDataM.ext[0].gpsValues.gpsLongCoordinate
                              ),
                            }
                          : positionUsed
                        : positionUsed
                    }
                    onDragEnd={handleCenter}
                    zoom={12}
                  >
                    <>
                      <Marker
                        position={
                          locationDataM
                            ? locationDataM.ext[0].gpsValues.gpsFixStatus === 1
                              ? {
                                  lat: parseFloat(
                                    locationDataM.ext[0].gpsValues
                                      .gpsLatCoordinate
                                  ),
                                  lng: parseFloat(
                                    locationDataM.ext[0].gpsValues
                                      .gpsLongCoordinate
                                  ),
                                }
                              : positionUsed
                            : positionUsed
                        }
                        icon={{
                          url: MarkerB,
                        }}
                      ></Marker>
                      <Marker
                        position={startPoint}
                        icon={{
                          url: MarkerA,
                        }}
                      ></Marker>
                      <Polyline
                        path={positionArray}
                        options={polylineOptions}
                        visible="true"
                      />
                    </>
                  </GoogleMap>
                </LoadScript>
              </div>
            </Col>
          </Row>
        </div>
      );
    } else return <h4>No Location Data Yet</h4>;
  } else {
    return <div>No GPS Data Yet</div>
  } }else {
    return (
      <div className="content">
        <div className="loadingContainer">
          <div className="ball1"></div>
          <div className="ball2"></div>
          <div className="ball3"></div>
          <div className="ball4"></div>
        </div>
      </div>
    );
  }
}

export default SingleMapPro;
