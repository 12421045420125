import React, { useState } from "react";
import { Row, Col, Card, Badge, Container } from "reactstrap";
import BatteryPackStock from "assets/img/batterypack.png";
import { API_URL } from "variables/general";
import { useEffect } from "react";
import Select from "react-select";
import { serverUrl } from "variables/general";
function PackList(props) {
  const [searchedItem, setSearchedItem] = useState();
  const [showSearchedItem, setShowSearchedItem] = useState(false);
  const handleSearchValue = (val) => {
    if (val.value === "All") {
      setSearchedItem(null);
      setShowSearchedItem(false);
    } else {
      setSearchedItem(val.value);
      setShowSearchedItem(true);
    }
  };
  const batteryPackList = props.InventoryData.packs;
  let unassignedBal = 0
  for (let index = 0; index < props.InventoryData.balancerCard.length; index++) {
    if (props.InventoryData.balancerCard[index].bpId === "unassigned") {
      unassignedBal = unassignedBal + 1
    }

  }
  let unassignedPower = 0
  for (let index = 0; index < props.InventoryData.powerCard.length; index++) {
    if (props.InventoryData.powerCard[index].bpId === "unassigned") {
      unassignedPower = unassignedPower + 1
    }

  }
  if (batteryPackList) {
    let batteryPackListOptions = [];
    for (let i = 0; i < batteryPackList.length; i++) {
      batteryPackListOptions[i + 1] = {
        value: batteryPackList[i].bpId,
        label: batteryPackList[i].data.batteryPackAlias
          ? batteryPackList[i].data.batteryPackAlias
          : batteryPackList[i].bpId,
      };
      batteryPackListOptions[0] = {
        value: "All",
        label: "All Battery Packs",
      };
    }
    return (
      <div style={{ padding: "2rem" }}>
        <Row >
          <Col md="3" lg="3" xl="3" sm="12" xs="12">
            <strong>Search Battery Packs:</strong>
            <Select
              options={batteryPackListOptions}
              onChange={handleSearchValue}
            />
          </Col>
          <Col
            md="3" lg="3" xl="3" sm="12" xs="12"
          ></Col>
          <Col
            md="3" lg="3" xl="3" sm="12" xs="12"
          >
            <Card>
              <Container>
                <h6>Total</h6>
                <strong>Battery Packs: {props.InventoryData.packs.length}</strong><br />
                <strong>Balancer Cards: {props.InventoryData.balancerCard.length}</strong><br />
                <strong>Power Cards: {props.InventoryData.powerCard.length}</strong>
              </Container>
            </Card>
          </Col>
          <Col
            md="3" lg="3" xl="3" sm="12" xs="12"
          >
            <Card>
              <Container>
                <h6>Unassigned</h6>
                <strong>Balancer Cards: {unassignedBal}</strong>
                <br />
                <strong>Power Cards: {unassignedPower}</strong>
              </Container>
            </Card>
          </Col>
        </Row>
        <Row>
          {showSearchedItem
            ? batteryPackList.map((val, i) =>
              searchedItem === val.bpId ? (
                <Col md="4" lg="4" xl="4" sm="12" xs="12">
                  <Card
                    style={{ padding: "5px" }}
                    onClick={() => props.setBatteryPackId(val.bpId)}
                  >
                    <Row>
                      <Col md="5" lg="5" xl="5" sm="12" xs="12">
                        <div style={{ padding: "1rem", marginTop: "1rem" }}>
                          <img
                            alt="batterypack"
                            src={
                              val.data.url
                                ? serverUrl + val.data.url
                                : BatteryPackStock
                            }
                            style={{ maxWidth: "10rem", maxHeight: "10rem" }}
                          ></img>
                        </div>
                      </Col>
                      <Col md="7" lg="7" xl="7" sm="12" xs="12">
                        <div style={{ paddingTop: "1rem" }}>
                          <h6>{val.data.batteryPackAlias}</h6>
                          <span>
                            <strong>Pack ID: </strong>
                            {val.bpId}
                          </span>
                          <br />
                          <span>
                            <strong>Status: </strong>
                            {val.data.status.map((value, i) => (
                              <Badge
                                color="info"
                                pill
                                style={{ padding: "10px", margin: "2px" }}
                              >
                                {value}
                              </Badge>
                            ))}
                          </span>
                          <br />
                          <span>
                            <strong>Last Updated: </strong>
                            {val.data.lastUpdated}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ) : (
                ""
              )
            )
            : batteryPackList.map((val, i) => (
              <Col md="4" lg="4" xl="4" sm="12" xs="12">
                <Card
                  style={{ padding: "5px" }}
                  onClick={() => props.setBatteryPackId(val.bpId)}
                >
                  <Row>
                    <Col md="5" lg="5" xl="5" sm="12" xs="12">
                      <div style={{ padding: "1rem", marginTop: "1rem" }}>
                        <img
                          alt="batterypack"
                          src={
                            val.data.url
                              ? serverUrl + val.data.url
                              : BatteryPackStock
                          }
                          style={{ maxWidth: "10rem", maxHeight: "10rem" }}
                        ></img>
                      </div>
                    </Col>
                    <Col md="7" lg="7" xl="7" sm="12" xs="12">
                      <div style={{ paddingTop: "1rem" }}>
                        <h6>{val.data.batteryPackAlias}</h6>
                        <span>
                          <strong>Pack ID: </strong>
                          {val.bpId}
                        </span>
                        <br />
                        <span>
                          <strong>Status: </strong>
                          {val.data.status.map((value, i) => (
                            <Badge
                              color="info"
                              pill
                              style={{ padding: "10px", margin: "2px" }}
                            >
                              {value}
                            </Badge>
                          ))}
                        </span>
                        <br />
                        <span>
                          <strong>Last Updated: </strong>
                          {val.data.lastUpdated}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
        </Row>
      </div>
    );
  } else return <div>loading...</div>;
}

export default PackList;
