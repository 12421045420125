import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router';
import { Row, Col, Card } from 'reactstrap';
import { Button, ButtonGroup } from 'reactstrap';
import ReactSelect from 'react-select';
import {
    AWS_REGION,
    AWS_IOT_ENDPOINT,
    AWS_CUSTOM_AUTH_USERNAME,
    AWS_CUSTOM_AUTH_AUTHORIZER_NAME,
    AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD,
    AWS_CUSTOM_AUTH_PASSWORD,
} from "../../views/MQTTSettings";
import Map from './Map';
import { API_URL } from 'variables/general';
import Error from 'components/Error/Error';
import JioLogo from './Jio-Logo.jpg'
const iotsdk = require("aws-iot-device-sdk-v2");
const iot = iotsdk.iot;
const mqtt = iotsdk.mqtt;
let connection = null;
let iotListOptions = []
function EVIoT() {
    useEffect(() => {
        getIoTList()
    }, [])
    // const IotId = 'AET865577075041625'
    // const [showError, setShowError] = useState(false);
    const [navigateBack, setNavigateBack] = useState(false);
    const [batteryVoltage, setBatteryVoltage] = useState(0);
    const [IgnitionStatus, setIgnitionStatus] = useState(0);
    const [lastUpdated, setLastUpdated] = useState('-');
    const [ImmobilizationStatus, setImmobilizationStatus] = useState(0);
    const [ioaData, setIoaData] = useState('');
    const [GPSSatellite, setGPSSatellite] = useState('-');
    const [IotId, setIotId] = useState('');
    const [packData, setPackData] = useState('');
    const [locationData, setLocationData] = useState('');
    const [locationDataM, setLocationDataM] = useState('');
    const [mqttConnectionStatus, setMqttConnectionStatus] = useState('Not Connected');

    function navigateToModeSelectionPage() {
        setNavigateBack(true);
    }
    async function connect_websocket() {
        return new Promise((resolve, reject) => {
            let config = iot.AwsIotMqttConnectionConfigBuilder.new_default_builder()
                .with_clean_session(true)
                .with_client_id(new Date())
                .with_endpoint(AWS_IOT_ENDPOINT)
                .with_custom_authorizer(
                    AWS_CUSTOM_AUTH_USERNAME,
                    AWS_CUSTOM_AUTH_AUTHORIZER_NAME,
                    AWS_CUSTOM_AUTH_PASSWORD,
                    AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD
                )
                .with_keep_alive_seconds(30)
                .build();

            // console.log("Connecting custom authorizer...");
            setMqttConnectionStatus("Connecting...");
            const client = new mqtt.MqttClient();

            connection = client.new_connection(config);
            connection.on("connect", (session_present) => {
                setMqttConnectionStatus("Connected");
                // console.log("Connected", session_present);
                resolve(connection);
            });
            connection.on("interrupt", (error) => {
                setMqttConnectionStatus("Connection Interrupted");
                // console.log(`Connection interrupted: error=${error}`);
            });
            connection.on("resume", (return_code, session_present) => {
                setMqttConnectionStatus("Connection Resumed");
                // console.log(
                //   `Resumed: rc: ${return_code} existing session: ${session_present}`
                // );
            });
            connection.on("disconnect", () => {
                setMqttConnectionStatus("Disconnected");
                //   main();
                // console.log("Disconnected");
            });
            connection.on("error", (error) => {
                setMqttConnectionStatus("Error Occured");
                reject(error);
            });
            connection.connect();
        });
    }
    async function subscribeMqtt(val) {
        connect_websocket()
            .then((connection) => {
                connection.subscribe(
                    `adtiot/bms1.0/pub/bt/${IotId}`,
                    mqtt.QoS.AtLeastOnce,
                    (topic, payload, dup, qos, retain) => {
                        const decoder = new TextDecoder("utf8");
                        let message = decoder.decode(new Uint8Array(payload));
                        // console.log("message", message);
                        let parsed = JSON.parse(message);
                        console.log("parsed", parsed);
                        if (parsed.evc === "472") {
                            // console.log("main packet", parsed);
                            setIoaData(parsed);
                            setBatteryVoltage(parsed.ext[0].ioaValues.intBattV / 1000)

                            setLastUpdated(parsed.ext[0].tms.slice(11, 19)
                                + ' ' +
                                parsed.ext[0].tms.slice(8, 10) + '/' +
                                parsed.ext[0].tms.slice(5, 7) + '/' +
                                parsed.ext[0].tms.slice(0, 4))
                            setIgnitionStatus(parsed.ext[0].ioaValues.out2)
                            setImmobilizationStatus(parsed.ext[0].ioaValues.out3)
                        }
                        else if (parsed.evc === "477") {
                            // console.log("main packet", parsed);
                            setLocationDataM(parsed);
                            //                 setBatteryVoltage(parsed.ext[0].ioaValues.intBattV / 1000)
                            // setIgnitionStatus(parsed.ext[0].ioaValues.out2)
                            // setImmobilizationStatus(parsed.ext[0].ioaValues.out3)
                        }
                    }
                );

                // .then((publish) => {
                //     return connection.publish('publish.topic', "Hello World!", publish.qos);
                // });
            })
            .catch((reason) => {
                console.log(`Error while connecting: ${reason}`);
            });
    }
    async function publish(connection, topic, message) {
        try {
            const encoder = new TextEncoder();
            const payload = encoder.encode(message);
            const result = await connection.publish(topic, payload, mqtt.QoS.AtLeastOnce);
            console.log("Published:", message);
            return result;
        } catch (error) {
            console.error("Error while publishing:", error);
        }
    }
    async function publishMqtt(val) {
        connect_websocket()
            .then((connection) => {
                // Usage of the publish method
                publish(connection, `adtiot/bms1.0/sub/bt/${IotId}`, `{
                    "did": "${IotId}",
                    "tms": "2022-10-21T16:05:46+0530",
                    "evc": "475",
                    "dvt": "smart_battery",
                    "ext": [
                        {
                            "tms": "2022-10-21T16:05:46+0530",
                            "did": "${IotId}",
                            "configIOAValues": {
                                "out1": 0,
                                "out2": ${IgnitionStatus},
                                "out3":${ImmobilizationStatus}
                            }
                        }
                    ]
                }`)
                    // publish(connection, `${selectedMacAddress}_cmd`, '*#31011997AEIDTHBMS\r\n')
                    .then((publishResult) => {
                        console.log('Publish result:', publishResult);
                    })
                    .catch((error) => {
                        console.error('Error while publishing:', error);
                    });
            })
            .catch((reason) => {
                console.log(`Error while connecting: ${reason}`);
            });
    }
    function getIoaData(val) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Bearer " + window.localStorage.getItem("token")
        );
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: JSON.stringify({
                batteryPackId: val
            })
        };
        fetch(API_URL + "/bms/bms_ioa", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setIoaData(result.data.bmsIoa[result.data.bmsIoa.length - 1]);

                setLastUpdated(result.data.bmsIoa[result.data.bmsIoa.length - 1].tms.slice(11, 19)
                    + ' ' +
                    result.data.bmsIoa[result.data.bmsIoa.length - 1].tms.slice(8, 10) + '/' +
                    result.data.bmsIoa[result.data.bmsIoa.length - 1].tms.slice(5, 7) + '/' +
                    result.data.bmsIoa[result.data.bmsIoa.length - 1].tms.slice(0, 4));
                setBatteryVoltage(result.data.bmsIoa[result.data.bmsIoa.length - 1].intbattv / 1000)
                setIgnitionStatus(result.data.bmsIoa[result.data.bmsIoa.length - 1].out2)
                setImmobilizationStatus(result.data.bmsIoa[result.data.bmsIoa.length - 1].out3)
            })
            .catch((error) => {
                console.log("error", error)
            });
    }
    function getLocationData(val, time) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Bearer " + window.localStorage.getItem("token")
        );
        var requestOptions
        if (val && time) {
            requestOptions = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
                body: JSON.stringify({
                    did: val,
                    tms:time
                })
            }
        }
        else {
            requestOptions = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
                body: JSON.stringify({
                    did: val
                })
            }
        };
        fetch(API_URL + "/bms/bms_location", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setLocationData(result.data);
                setGPSSatellite(result.data.bmsLocation[result.data.bmsLocation.length - 1].gpsNoOfSatellites)
            })
            .catch((error) => {
                console.log("error", error)
            });
    }
    function getIoTList() {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Bearer " + window.localStorage.getItem("token")
        );
        let requestOptions;
        requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({ subOwner: 'Jio' }),
        }
        fetch(API_URL + "/bms/battery_packs", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setPackData(result.data)
                for (let index = 0; index < result.data.data.length; index++) {
                    iotListOptions[index] = {
                        value: result.data.data[index].iotId,
                        label: result.data.data[index].iotId
                    }
                }
            })
            .catch((error) => { console.error(error) }
                // setShowError(true)
            );
    }
    function getIgnitionStatus(val) {
        setIgnitionStatus(val)
    }
    function getImmobilizationStatus(val) {
        setImmobilizationStatus(val)
    }
    function getIot(val) {
        setIotId(val.value)
        getIoaData(val.value)
        getLocationData(val.value)
    }
    let statusColor
    switch (mqttConnectionStatus) {
        case 'Not Connected':
            statusColor = '#d70f0f'
            break;
        case 'Connected':
            statusColor = '#09c309'
            break;
        default:
            statusColor = '#a39805'
            break;
    }
    if (navigateBack) { return <Redirect to="/mode-selection-page"></Redirect> }
    else {
        if (packData)
            return (
                <div style={{ padding: "15px", backgroundColor: '#f4f3ef' }}>
                    <span
                        style={{ paddingLeft: "1rem", cursor: "pointer" }}
                        onClick={navigateToModeSelectionPage}
                        id="switchCommMode1"
                    >
                        <i class="fas fa-arrow-left"></i>&ensp;Mode Selection Page
                    </span>
                    <h3 style={{ textTransform: 'uppercase', fontFamily: 'ariel', fontWeight: '900', fontSize: '48px' }}>Testing Dashboard</h3>
                    <Row>
                        <Col md='4' lg='4' xs='12' sm='12'>
                            <strong>Select Device:</strong>
                            <ReactSelect options={iotListOptions} onChange={getIot}></ReactSelect>
                            <Button onClick={subscribeMqtt}>Connect</Button>
                        </Col>
                        <Col md='2' lg='2' xs='12' sm='12' style={{ textAlign: 'center' }}>
                            <strong> MQTT Status:</strong><br />
                            <h4 style={{ color: statusColor, fontWeight: '700', textTransform: 'uppercase' }}>{mqttConnectionStatus}</h4>

                        </Col>

                        {ioaData ? <Col md='6' lg='6' xs='12' sm='12'>
                            <Card style={{ textAlign: "center" }}>
                                <Row>
                                    <Col md='6' lg='6' xs='12' sm='12'>
                                        <div style={{ padding: '15px' }}>
                                            <div style={{ margin: '15px 0' }}>
                                                <strong> Last Updated:</strong> {lastUpdated}
                                            </div>
                                            <div style={{ margin: '15px 0' }}>
                                                <strong> Battery Voltage:</strong> {batteryVoltage} V
                                            </div>
                                            <div style={{ margin: '15px 0' }}>
                                                <strong> No of GPS satellite:</strong> {GPSSatellite}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md='6' lg='6' xs='12' sm='12'>
                                        <Row style={{ padding: "15px" }}>
                                            <Col md='6' lg='6' xs='12' sm='12'>
                                                <strong>Ignition Status:</strong>
                                                <br />
                                                <ButtonGroup>
                                                    <Button
                                                        color="primary"
                                                        outline
                                                        onClick={() =>
                                                            getIgnitionStatus(1)}
                                                        active={IgnitionStatus === 1}
                                                    >
                                                        On
                                                    </Button>
                                                    <Button
                                                        color="primary"
                                                        outline
                                                        onClick={() =>
                                                            getIgnitionStatus(0)}
                                                        active={IgnitionStatus === 0}
                                                    >
                                                        Off
                                                    </Button>
                                                </ButtonGroup>
                                            </Col>
                                            <Col md='6' lg='6' xs='12' sm='12'>
                                                <strong>Immobilization Status:</strong>
                                                <br />
                                                <ButtonGroup>
                                                    <Button
                                                        color="primary"
                                                        outline
                                                        onClick={() =>
                                                            getImmobilizationStatus(1)}
                                                        active={ImmobilizationStatus === 1}
                                                    >
                                                        On
                                                    </Button>
                                                    <Button
                                                        color="primary"
                                                        outline
                                                        onClick={() =>
                                                            getImmobilizationStatus(0)}
                                                        active={ImmobilizationStatus === 0}
                                                    >
                                                        Off
                                                    </Button>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                        <Button onClick={publishMqtt}>Update</Button>
                                    </Col>


                                </Row>

                            </Card>
                        </Col> : ''}


                    </Row>

                    {IotId ? <Map id={IotId} locationData={locationData} locationDataM={locationDataM} getLocationData={getLocationData}/> : ''}
                </div>
            )
        else return <div>loading...</div>
    }
}

export default EVIoT