import React, { useEffect, useState, useContext } from 'react'
import { Pie } from 'react-chartjs-2'
import { Card, Col, Row } from 'reactstrap'
import { API_URL } from 'variables/general'
import { bmsIdContext } from 'views/Dashboard'
import Nodatagraph from './Nodatagraph.png'
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
function DailyAnalysis() {
    useEffect(() => {
        // getAnalysisData()
        getDailyAnalysisRecords()
    }, [])
    // const date = ['2024-05-27T00:00:00Z']
    const id = useContext(bmsIdContext)
    const [analysisData, setAnalysisData] = useState()
    const [analysisDateArray, setAnalysisDateArray] = useState([])
    const [selectedDate, setSelectedDate] = useState()
    function getDailyAnalysisRecords() {
        let status = 1
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Bearer " + window.localStorage.getItem('token')
        );
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(API_URL + "/bms/v2/analysis?status=" + status + "&batteryPackId=" + id, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                let dateArray = []
                result.data.ltAnalysisRecord.forEach(element => {
                    dateArray.push(new Date(element.tms))
                });
                dateArray.sort((a, b) => a - b)
                setSelectedDate(dateArray[dateArray.length - 1])
                let year = dateArray[dateArray.length - 1].getFullYear()
                let month = (dateArray[dateArray.length - 1].getMonth() + 1)
                let date = dateArray[dateArray.length - 1].getDate()
                if (date < 10) {
                    date = '0' + date
                }
                if (month < 10) {
                    month = '0' + month
                }
                getAnalysisData(`${year}-${month}-${date}T00:00:00Z`)
                setAnalysisDateArray(dateArray)
            })
            .catch((error) => console.log("error", error));
    }
    function getAnalysisData(date) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Bearer " + window.localStorage.getItem('token')
        );
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(API_URL + "/bms/v2/analysis?date=" + date + "&batteryPackId=" + id, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setAnalysisData(result.data.analysisValues)
            })
            .catch((error) => console.log("error", error));
    }
    const FaultArray = [
        "No Fault",
        "Over Pack Voltage Fault",
        "Over Pack Voltage",
        "Under Pack Voltage Fault",
        "Over OCD1 Current",
        "Over OCD2 Current",
        "Over Charge Current",
        "Over Discharge Current",
        "Short Circuit",
        "Cell Open Wire Fault",
        "Voltage Reference Fault",
        "Voltage Regulator Fault",
        "Temperature Multiplexer Error",
        "Under Temperature Fault",
        "Load Over Temperature",
        "Oscillator Fault",
        "Watchdog Timeout Fault",
        "Under Cell Voltage Fault",
        "Over Cell Voltage Fault",
        "Open Wire Fault",
        "Over Temperature Fault",
        "Cell Unbalance Pro",
        "SCD Latch High",
        "SPI Communication Fault",
        "I2C Communication Fault",
        "MCU Reset Fault",
        "Data CRC Error Fault",
        "Data Ready Fault",
        "Charger Authentication Failed",
        "Thermal Runaway Due To Temperature",
        "Thermal Runaway Due To Voltage",
        "MOSFET Drive Voltage Fault",
        "Reserve Condition Reached",
        "Battery Empty Reached",
        "Battery Full Charged Reached"
    ]
    const backgroundColor = [
        '#FF5733', //(Red Orange)
        '#33FF57', //(Green)
        '#3357FF', //(Blue)
        '#FF33A8', //(Pink)
        '#33FFF5', //(Cyan)
        '#F5FF33', //(Yellow)
        '#8D33FF', //(Purple)
        '#FF8333', //(Orange)
        '#33FF8D', //(Light Green)
        '#337FFF', //(Light Blue)
        '#FF338D', //(Magenta)
        '#33F5FF', //(Aqua)
        '#FFF533', //(Light Yellow)
        '#BD33FF', //(Violet)
        '#FFBD33', //(Peach)
        '#33FFBD', //(Mint)
        '#33B8FF', //(Sky Blue)
        '#FF33BD', //(Hot Pink)
        '#33FF8D', //(Lime)
        '#FFD433', //(Gold)
        '#3366FF', //(Royal Blue)
        '#FF3366', //(Rose)
        '#33FFF5', //(Turquoise)
        '#F5FF33', //(Lemon)
        '#A833FF', //(Indigo)
        '#FF8D33', //(Coral)
        '#33FF83', //(Spring Green)
        '#33A8FF', //(Azure)
        '#FF33A8', //(Fuchsia)
        '#33F5BD', //(Sea Green)
        '#FFF533', //(Sunflower)
        '#6B33FF', //(Purple Blue)
        '#FF6B33', //(Tangerine)
        '#33FF6B', //(Electric Green)
        '#33B8A8', //(Teal)
        '#FF83A8', //(Light Pink)
    ]
    const getCustomDate = (date) => {
        let year = date.getFullYear()
        let month = (date.getMonth() + 1)
        let day = date.getDate()
        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }
        return `${year}-${month}-${day}T00:00:00Z`
    }
    if (analysisData && analysisDateArray) {
        if (analysisData.length > 0 && analysisDateArray.length > 0) {
            // console.log(analysisDateArray, 'analysisDateArray')
            // console.log(selectedDate, 'Selecteddate')
            const labelObj = Object.keys(analysisData[0].analysis.bmsfaultCounts)
            let updatedLabel = []
            labelObj.forEach(element => {
                updatedLabel.push(FaultArray[parseInt(element)])
            });
            const data = Object.values(analysisData[0].analysis.bmsfaultCounts)
            let totalFaultCount = 0
            data.forEach(val => {
                totalFaultCount += val
            })
            const faultChart = {
                data: (canvas) => {
                    return {
                        labels: updatedLabel,
                        datasets: [
                            {
                                label: "Faults",
                                pointRadius: 0,
                                pointHoverRadius: 0,
                                backgroundColor: backgroundColor,
                                //   borderWidth: 1,
                                //   borderColor:'yellow',
                                data: data,
                            },
                        ],
                    };
                },
                options: {
                    legend: {
                        // display: false,
                    },
                    pieceLabel: {
                        render: "percentage",
                        fontColor: ["white"],
                        precision: 2,
                    },
                    tooltips: {
                        // enabled: false,
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    display: false,
                                },
                                gridLines: {
                                    drawBorder: false,
                                    zeroLineColor: "transparent",
                                    color: "rgba(255,255,255,0.00)",
                                },
                            },
                        ],
                        xAxes: [
                            {
                                barPercentage: 1,
                                gridLines: {
                                    // drawBorder: false,
                                    color: "rgba(255,255,255,0.0)",
                                    zeroLineColor: "transparent",
                                },
                                ticks: {
                                    display: false,
                                },
                            },
                        ],
                    },
                },
            };

            return (
                <div>
                    <Row>
                        <Col md='3'>
                            <strong>Current Date:</strong>
                            <div style={{ padding: '0 1rem' }}>
                                <Flatpickr
                                    value={selectedDate}
                                    onChange={date => {
                                        setSelectedDate(date[0])
                                        getAnalysisData(getCustomDate(date[0]))
                                    }
                                    }
                                    options={{
                                        enable: analysisDateArray
                                    }}
                                    className="form-control"
                                    placeholder={selectedDate.getDate() + '-' + (selectedDate.getMonth() + 1) + '-' + selectedDate.getFullYear()}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" sm="12" md="3">
                            <Card className="hero-cards">
                                <div className="card-head"> Peak Current Charging </div>
                                <div className="card-foot">
                                    {analysisData[0].analysis.peakCurrentCharging / 1000} A
                                </div>
                            </Card>
                        </Col>
                        <Col xs="12" sm="12" md="3">
                            <Card className="hero-cards">
                                <div className="card-head"> Peak Current Discharging </div>
                                <div className="card-foot">
                                    {analysisData[0].analysis.peakCurrentDischarging / 1000} A
                                </div>
                            </Card>
                        </Col>
                        <Col xs="12" sm="12" md="3">
                            <Card className="hero-cards">
                                <div className="card-head"> Peak Current Regen </div>
                                <div className="card-foot">
                                    {analysisData[0].analysis.peakRegen / 1000} A
                                </div>
                            </Card>
                        </Col>
                        <Col xs="12" sm="12" md="3">
                            <Card className="hero-cards">
                                <div className="card-head"> Lowest Voltage Reached</div>
                                <div className="card-foot">
                                    {analysisData[0].analysis.lowestVoltage / 1000} V
                                </div>
                            </Card>
                        </Col>
                        <Col xs="12" sm="12" md="3">
                            <Card className="hero-cards">
                                <div className="card-head"> Peak Power Charging</div>
                                <div className="card-foot">
                                    {analysisData[0].analysis.peakPowerCharging > 1000 ? `${analysisData[0].analysis.peakPowerCharging / 1000} kW` : `${analysisData[0].analysis.peakPowerCharging} W`}
                                </div>
                            </Card>
                        </Col>
                        <Col xs="12" sm="12" md="3">
                            <Card className="hero-cards">
                                <div className="card-head"> Peak Power Discharging</div>
                                <div className="card-foot">

                                    {analysisData[0].analysis.peakPowerDischarging > 1000 ? `${analysisData[0].analysis.peakPowerDischarging / 1000} kW` : `${analysisData[0].analysis.peakPowerDischarging} W`}
                                </div>
                            </Card>
                        </Col>
                        <Col xs="12" sm="12" md="3">
                            <Card className="hero-cards">
                                <div className="card-head"> Max Cell Temperature</div>
                                <div className="card-foot">
                                    {analysisData[0].analysis.maxCellTemp} °C
                                </div>
                            </Card>
                        </Col>
                        <Col xs="12" sm="12" md="3">
                            <Card className="hero-cards">
                                <div className="card-head"> Max PDU Temperature</div>
                                <div className="card-foot">
                                    {analysisData[0].analysis.maxPduTemp} °C
                                </div>
                            </Card>
                        </Col>
                        <Col xs="12" sm="12" md="3">
                            <Card className="hero-cards">
                                <div className="card-head">Distance Travelled</div>
                                <div className="card-foot">
                                    {analysisData[0].analysis.distanceTravelled} km
                                </div>
                            </Card>
                        </Col>
                        <Col xs="12" sm="12" md="3">
                            <Card className="hero-cards">
                                <div className="card-head">Efficiency</div>
                                <div className="card-foot">
                                    {analysisData[0].analysis.efficiencyPercentage} %
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md='6'>
                            <Card style={{ padding: "1rem" }} className="hero-cards">
                                <h6>Fault Chart</h6>
                                {totalFaultCount > 0 ? <Pie data={faultChart.data} options={faultChart.options} /> : <img src={Nodatagraph}></img>}
                                <strong>Total: {totalFaultCount}</strong>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
        }
        else return <div>No data Found</div>
    }
    else return <div>loading..</div>
}

export default DailyAnalysis