import React, { useState, useEffect } from 'react';
import { API_URL } from 'variables/general';
const LocationSOCRedistribution = () => {
useEffect(() => {
    getBmsValueData()
    getLocationData()

}, [])
const [bmsValues, setBmsValues] = useState('');
const [locationValues, setLocationValues] = useState('');
    function getBmsValueData() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          "Bearer " + window.localStorage.getItem("token")
        );
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body: JSON.stringify({
            // did: did,
            batteryPackId: 'ABT865577074929713',
            tms: '2024-04-30T00:00:00Z',
            
          }),
        };
    
        fetch(API_URL + "/bms/v2/bms_values", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            setBmsValues(result.data.bmsValues)
          })}
      function getLocationData() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          "Bearer " + window.localStorage.getItem("token")
        );
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body: JSON.stringify({
            // did: did,
            batteryPackId: 'ABT865577074929713',
            tms_gt: `2024-04-30T00:00:00Z`,
        tms_lt: `2024-04-30T23:59:59Z`,
          }),
        };
    
        fetch(API_URL + "/bms/bms_location", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            // console.log('This is location data', result.data.bmsLocation)
            setLocationValues(result.data.bmsLocation)})}



  // Sample location set and SOC set
  const locationSet = locationValues
  const socSet =  bmsValues
  // State to hold redistributed data
  const [redistributedData, setRedistributedData] = useState([]);
  function interpolate() {
    // Interpolate SOC values
    const interpolatedSOC = interpolateSOC(locationSet, socSet);
    // Update state with redistributed data
    setRedistributedData(interpolatedSOC);
  }
  // Function to interpolate SOC values
  const interpolateSOC = (locations, socData) => {
    // Convert time strings to timestamps
    const locationTimes = locations.map(entry => new Date(entry.tms).getTime());
    const socTimes = socData.map(entry => new Date(entry.tms).getTime());
    // Extract SOC values
    const socValues = socData.map(entry => entry.soC);
    // Interpolate SOC values for each location time
    return locationTimes.map(locationTime => {
      const index = findNearestIndex(socTimes, locationTime);
      // If location time is outside the range of SOC times, return null
      if (index === -1 || index === socTimes.length - 1) return null;
      // Perform linear interpolation
      const x0 = socTimes[index];
      const x1 = socTimes[index + 1];
      const y0 = socValues[index];
      const y1 = socValues[index + 1];
      const interpolatedValue = y0 + ((locationTime - x0) / (x1 - x0)) * (y1 - y0);
      return interpolatedValue;
    });
  };
  // Function to find the index of the nearest value in an array
  const findNearestIndex = (array, value) => {
    return array.findIndex((element, index, array) => {
      const nextElement = array[index + 1] || Infinity;
      return element <= value && value < nextElement;
    });
  };
  // Render the redistributed data
  return (
    <div>
        <button onClick={interpolate}>click</button>
      <h2>Redistributed SOC Data</h2>
      <table>
        <thead>
          <tr>
            <th>Latitude</th>
            <th>Longitude</th>
            <th>Time</th>
            <th>SOC</th>
          </tr>
        </thead>
        <tbody>
          {locationSet?locationSet.map((entry, index) => (
            <tr key={index}>
              <td>{entry.gpsLatCoordinate}</td>
              <td>{entry.gpsLongCoordinate}</td>
              <td>{entry.tms}</td>
              <td>{redistributedData[index]}</td>
            </tr>
          )):''}
        </tbody>
      </table>
    </div>
  );
};
export default LocationSOCRedistribution;