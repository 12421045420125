import React, { useEffect, useState } from 'react'
import {
    AWS_REGION,
    AWS_IOT_ENDPOINT,
    AWS_CUSTOM_AUTH_USERNAME,
    AWS_CUSTOM_AUTH_AUTHORIZER_NAME,
    AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD,
    AWS_CUSTOM_AUTH_PASSWORD,
} from "../../views/MQTTSettings";
import BatteryCells from 'views/BatteryCells';
import Temperature from 'components/Temperature/Temperature';
import { Card, Col, Row, Nav, NavItem, NavLink, TabContent, TabPane, Button } from 'reactstrap';
import ReactSelect from 'react-select';
import { API_URL } from 'variables/general';
import { Redirect } from "react-router";
import "./BLEView.css"
const iotsdk = require("aws-iot-device-sdk-v2");
const iot = iotsdk.iot;
const mqtt = iotsdk.mqtt;
let connection = null;
function BLEView() {
    const [navigateBacktoModeSelectionPage, setNavigateBacktoModeSelectionPage] =
        useState(false);
    const [iconPills, setIconPills] = React.useState("1");
    useEffect(() => { getMacAddressList() }, [])
    // let mqttTopic = props.macAddress
    // let mqttTopic = '48:23:35:13:98:C7'
    let cellVoltageArray
    // const [mqttTopic, setMqttTopic] = useState("")
    const [macAddressList, setMacAddressList] = useState("")
    const [selectedMacAddress, setSelectedMacAddress] = useState("")
    const [cellVoltageState, setCellVoltageState] = useState("")
    const [fuelDataObj, setFuelDataObj] = useState("")
    const [tempObj, setTempObj] = useState("")
    const [pinObj, setPinObj] = useState("")
    const [lastFault, setLastFault] = useState("")
    const [bmsDateTime, setBmsDateTime] = useState("")
    const [cellSetting, setCellSetting] = useState("")
    const [packSetting, setPackSetting] = useState("")
    const [tempSetting, setTempSetting] = useState("")
    const [pduSetting, setPduSetting] = useState("")
    const [warningSetting, setWarningSetting] = useState("")
    const [ManufacturingData, setManufacturingData] = useState("")
    const [mqttConnectionStatus, setMqttConnectionStatus] =
        useState("Not Connected");
    function getMacAddressList() {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Bearer " + window.localStorage.getItem("token")
        );
        let requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(API_URL + "/bms/inventory", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setMacAddressList(result.data.macAllowed);
                console.log("macalloed", result.data.macAllowed);
            })
            .catch((error) => console.log("error", error));
    }
    async function connect_websocket() {
        return new Promise((resolve, reject) => {
            let config = iot.AwsIotMqttConnectionConfigBuilder.new_default_builder()
                .with_clean_session(true)
                .with_client_id(new Date())
                .with_endpoint(AWS_IOT_ENDPOINT)
                .with_custom_authorizer(
                    AWS_CUSTOM_AUTH_USERNAME,
                    AWS_CUSTOM_AUTH_AUTHORIZER_NAME,
                    AWS_CUSTOM_AUTH_PASSWORD,
                    AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD
                )
                .with_keep_alive_seconds(30)
                .build();

            // console.log("Connecting custom authorizer...");
            setMqttConnectionStatus("Connecting...");
            const client = new mqtt.MqttClient();

            connection = client.new_connection(config);
            connection.on("connect", (session_present) => {
                setMqttConnectionStatus("Connected");
                // console.log("Connected", session_present);
                resolve(connection);
            });
            connection.on("interrupt", (error) => {
                setMqttConnectionStatus("Connection Interrupted");
                // console.log(`Connection interrupted: error=${error}`);
            });
            connection.on("resume", (return_code, session_present) => {
                setMqttConnectionStatus("Connection Resumed");
                // console.log(
                //   `Resumed: rc: ${return_code} existing session: ${session_present}`
                // );
            });
            connection.on("disconnect", () => {
                setMqttConnectionStatus("Disconnected");
                main();
                // console.log("Disconnected");
            });
            connection.on("error", (error) => {
                setMqttConnectionStatus("Error Occured");
                reject(error);
            });
            connection.connect();
        });
    }
    async function main(val) {
        connect_websocket()
            .then((connection) => {
                // console.log('topic:',`BLE/${val}`)
                connection.subscribe(
                    `BLE/${val}`,
                    // `48:23:35:13:98:C7_cmd`,
                    mqtt.QoS.AtLeastOnce,
                    (topic, payload, dup, qos, retain) => {
                        const decoder = new TextDecoder("utf8");
                        let message = decoder.decode(new Uint8Array(payload));
                        parseMessages(message)
                        console.log("message", message);
                    }
                );

                // .then((publish) => {
                //     return connection.publish('publish.topic', "Hello World!", publish.qos);
                // });
            })
            .catch((reason) => {
                console.log(`Error while connecting: ${reason}`);
            });
    }

    async function publish(connection, topic, message) {
        try {
            const encoder = new TextEncoder();
            const payload = encoder.encode(message);
            const result = await connection.publish(topic, payload, mqtt.QoS.AtLeastOnce);
            console.log("Published:", message);
            return result;
        } catch (error) {
            console.error("Error while publishing:", error);
        }
    }
    async function publishMqtt(val) {
        connect_websocket()
            .then((connection) => {
                // Usage of the publish method
                publish(connection, `BLE/${selectedMacAddress}_cmd`, '*#31011997AEIDTHBMS\r\n')
                    .then((publishResult) => {
                        // console.log('Publish topic:', `BLE/${selectedMacAddress}_cmd`);
                        console.log('Publish result:', publishResult);
                    })
                    .catch((error) => {
                        console.error('Error while publishing:', error);
                    });
            })
            .catch((reason) => {
                console.log(`Error while connecting: ${reason}`);
            });
    }
    function parseMessages(message) {
        let messageArray = message.split(',')
        switch (messageArray[0]) {
            case '*#1':
                cellData(messageArray)
                break;
            case '*#2':
                fuelData(messageArray)
                break;
            case '*#3':
                pinData(messageArray)
                break;
            case '*#4':
                tempData(messageArray)
                break;
            case '*#5':
                bmsTimeData(messageArray)
                break;
            case '*#6':
                faultData(messageArray)
                break;
            case '*#7':
                // balancingData(messageArray)
                break;
            case '*#8':
                cellOverVoltageData(messageArray)
                break;
            case '*#9':
                cellUnderVoltageData(messageArray)
                break;
            case '*#10':
                // openWireData(messageArray)
                break;
            case '*#11':
                // openWireData(messageArray)
                break;
            case '*#12':
                // openWireData(messageArray)
                break;
            case '*#13':
                // parallelData(messageArray)
                break;
            case '*#14':
                // warningData(messageArray)
                break;
            case '*#15':
                newTempData(messageArray)
                break;
            case '*$0':
                cellSettingData(messageArray)
                break;
            case '*$1':
                packSettingData(messageArray)
                break;
            case '*$4':
                tempSettingData(messageArray)
                break;
            case '*$5':
                pduSettingData(messageArray)
                break;
            case '*$8':
                warningSettingData(messageArray)
                break;
            case '*$9':
                // thermalRunawayData(messageArray)
                break;
            case '*$10':
                manufacturingData(messageArray)
                break;
            case '*$17':
                newManufacturingData(messageArray)
                break;

            default:
                break;
        }
    }

    function cellData(messageArray) {
        cellVoltageArray = []
        for (let index = 5; index < (messageArray.length - 1); index++) {
            if (messageArray[index] > 0)
                cellVoltageArray.push(messageArray[index])
        }
        setCellVoltageState(cellVoltageArray)
        // writeToCSV(cellVoltageArray)
        // console.log("<--------------cell array---------->", cellVoltageArray)
    }

    let cellUnderVoltageArray = []
    function cellUnderVoltageData(messageArray) {
        for (let index = 3; index < messageArray.length - 1; index++) {
            if (messageArray[index] === (0 || 1))
                cellUnderVoltageArray.push(messageArray[index])
        }
    }
    let cellOverVoltageArray = []
    function cellOverVoltageData(messageArray) {
        for (let index = 3; index < messageArray.length - 1; index++) {
            if (messageArray[index] === (0 || 1))
                cellOverVoltageArray.push(messageArray[index])
        }
    }
    let packDischargeCurrent
    let packChargeCurrent
    let ADC1
    let packVoltage
    let ADC2
    let current
    let soc
    let capacity
    let floatmAh
    let packCapacity
    let soh
    let CCmAh
    let cycleCountmAh
    let DCF
    let CCF
    let CC
    let BRC
    let mAh
    let BCT
    let LDO
    let DV
    function fuelData(messageArray) {
        let inc = 0

        packDischargeCurrent = messageArray[++inc]
        packChargeCurrent = messageArray[++inc]
        ADC1 = messageArray[++inc]
        packVoltage = messageArray[++inc]
        ADC2 = messageArray[++inc]
        current = messageArray[++inc]
        soc = messageArray[++inc]
        capacity = messageArray[++inc]
        floatmAh = messageArray[++inc]
        packCapacity = messageArray[++inc]
        soh = messageArray[++inc]
        CCmAh = messageArray[++inc]
        cycleCountmAh = messageArray[++inc]
        DCF = messageArray[++inc]
        CCF = messageArray[++inc]
        CC = messageArray[++inc]
        BRC = messageArray[++inc]
        mAh = messageArray[++inc]
        BCT = messageArray[++inc]
        LDO = messageArray[++inc]
        DV = messageArray[++inc]
        setFuelDataObj({
            "packDischargeCurrent": packDischargeCurrent,
            "packChargeCurrent": packChargeCurrent,
            "ADC1": ADC1,
            "packVoltage": packVoltage,
            "ADC2": ADC2,
            "current": current,
            "soc": soc,
            "capacity": capacity,
            "floatmAh": floatmAh,
            "packCapacity": packCapacity,
            "soh": soh,
            "CCmAh": CCmAh,
            "cycleCountmAh": cycleCountmAh,
            "DCF": DCF,
            "CCF": CCF,
            "CC": CC,
            "BRC": BRC,
            "mAh": mAh,
            "BCT": BCT,
            "LDO": LDO,
            "DV": DV
        })
    }
    let Charge_FET
    let DisCharge_FET
    let PreCharge_FET
    let V5_Supply
    let SCD_PIN
    let BMSPowerMode
    let BatteryPackState
    let SOCIndPresent
    let PIN_INGITION_State


    const bmsPowerModeArray = [
        "Active Mode",
        "Sleep Mode",
        "Deep Sleep Mode",
        "Sleep Wake Up Sleep",
    ];
    const packCurrentStateArray = [
        "None",
        "Charging",
        "Discharging",
        "Normal",
        "Ideal",
        "Current Sleep",
        "Regen"
    ];
    function pinData(messageArray) {
        let inc = 0
        Charge_FET = messageArray[++inc]
        DisCharge_FET = messageArray[++inc]
        PreCharge_FET = messageArray[++inc]
        V5_Supply = messageArray[++inc]
        SCD_PIN = messageArray[++inc]
        BMSPowerMode = bmsPowerModeArray[messageArray[++inc]]
        BatteryPackState = packCurrentStateArray[messageArray[++inc]]
        SOCIndPresent = messageArray[++inc]
        PIN_INGITION_State = messageArray[++inc]

        setPinObj({
            "Charge_FET": Charge_FET,
            "DisCharge_FET": DisCharge_FET,
            "PreCharge_FET": PreCharge_FET,
            "V5_Supply": V5_Supply,
            "SCD_PIN": SCD_PIN,
            "BMSPowerMode": BMSPowerMode,
            "BatteryPackState": BatteryPackState,
            "SOCIndPresent": SOCIndPresent,
            "PIN_INGITION_State": PIN_INGITION_State,
        })

    }
    let icTempArray = []
    let cellTempArray = []
    let pduTempArray = []
    function tempData(messageArray) {
        for (let index = 1; index < 3; index++) {
            if (messageArray[index] > 0)
                icTempArray[index - 1] = messageArray[index]
        }
        for (let index = 3; index < 11; index++) {
            if (messageArray[index] > 0)
                cellTempArray[index - 3] = messageArray[index]
        }
        pduTempArray[0] = messageArray[messageArray.length - 2]
        setTempObj({
            "icTempArray": icTempArray,
            "cellTempArray": cellTempArray,
            "pduTempArray": pduTempArray,
        })
    }
    let newIcTempArray = []
    let newCellTempArray = []
    let newExtTempArray = []
    let newIcTempNumber
    let newCellTempNumber
    let newExtTempNumber

    function newTempData(messageArray) {
        newIcTempNumber = messageArray[2]
        newCellTempNumber = messageArray[3]
        newExtTempNumber = messageArray[4]
        for (let index = 5; index < (newIcTempNumber + 5); index++) {
            newIcTempArray[index - 5] = messageArray[index]
        }
        for (let index = (newIcTempNumber + 5); index < (newIcTempNumber + 5 + newCellTempNumber); index++) {
            newCellTempArray[index - (newIcTempNumber + 5)] = messageArray[index]
        }
        for (let index = (newIcTempNumber + 5 + newCellTempNumber); index < (newIcTempNumber + 5 + newCellTempNumber + newExtTempNumber); index++) {
            newExtTempArray[index - (newIcTempNumber + 5 + newCellTempNumber)] = messageArray[index]
        }
    }
    const FaultArray = [
        "No Fault",
        "Over Pack Voltage Fault",
        "Over Pack Voltage",
        "Under Pack Voltage Fault",
        "Over OCD1 Current",
        "Over OCD2 Current",
        "Over Charge Current",
        "Over Discharge Current",
        "Short Circuit",
        "Cell Open Wire Fault",
        "Voltage Reference Fault",
        "Voltage Regulator Fault",
        "Temperature Multiplexer Error",
        "Under Temperature Fault",
        "Load Over Temperature",
        "Oscillator Fault",
        "Watchdog Timeout Fault",
        "Under Cell Voltage Fault",
        "Over Cell Voltage Fault",
        "Open Wire Fault",
        "Over Temperature Fault",
        "Cell Unbalance Pro",
        "SCD Latch High",
        "SPI Communication Fault",
        "I2C Communication Fault",
        "MCU Reset Fault",
        "Data CRC Error Fault",
        "Data Ready Fault",
        "Charger Authentication Failed",
        "Thermal Runaway Due To Temperature",
        "Thermal Runaway Due To Voltage",
        "MOSFET Drive Voltage Fault",
        "Reserve Condition Reached",
        "Battery Empty Reached",
        "Battery Full Charged Reached",
    ];

    function bmsTimeData(messageArray) {
       setBmsDateTime(messageArray[4] + "-" + messageArray[5] + "-" + messageArray[6] + " "+messageArray[1] + ":" + messageArray[2] + ":" + messageArray[3])
    }
    function faultData(messageArray) {
        setLastFault(FaultArray[messageArray[1]])
    }
    function packSettingData(messageArray) {
        let start_index = 0
        setPackSetting({
            "baud_rate": messageArray[start_index + 1],
            "pack_numbe_of_tempratures": messageArray[start_index + 2],
            "pack_type_of_cell": messageArray[start_index + 3],
            "initial_total_capacity": messageArray[start_index + 4],
            "pack_cycle_capacity": messageArray[start_index + 5],
            "pack_full_charge_voltage": messageArray[start_index + 6],
            "pack_charge_end_voltage": messageArray[start_index + 7],
            "pack_discharge_rate": messageArray[start_index + 8],
            "pack_over_voltage": messageArray[start_index + 9],
            "pack_over_voltage_release": messageArray[start_index + 10],
            "pack_over_voltage_delay": messageArray[start_index + 11],
            "pack_under_voltage": messageArray[start_index + 12],
            "pack_under_voltage_release": messageArray[start_index + 13],
            "pack_under_voltage_delay": messageArray[start_index + 14],
            "pack_hard_over_voltage": messageArray[start_index + 15],
            "pack_hard_under_voltage": messageArray[start_index + 16],
        })
    }
    function tempSettingData(messageArray) {
        let start_index = 0
        setTempSetting({
            "charge_over_temp": messageArray[start_index + 1],
            "charge_over_temp_release": messageArray[start_index + 2],
            "charge_over_temp_delay": messageArray[start_index + 3],
            "charge_under_temp": messageArray[start_index + 4],
            "charge_under_temp_release": messageArray[start_index + 5],
            "charge_under_temp_delay": messageArray[start_index + 6],
            "discharge_over_temp": messageArray[start_index + 7],
            "discharge_over_temp_release": messageArray[start_index + 8],
            "discharge_over_temp_delay": messageArray[start_index + 9],
            "discharge_under_temp": messageArray[start_index + 10],
            "discharge_under_temp_release": messageArray[start_index + 11],
            "discharge_under_temp_delay": messageArray[start_index + 12],
        })
    }
    function pduSettingData(messageArray) {
        let start_index = 0
        setPduSetting({
            "over_charge_current": messageArray[start_index + 1],
            "charge_over_current_delay": messageArray[start_index + 2],
            "charge_over_current_release_delay": messageArray[start_index + 3],
            "over_discharge_current": messageArray[start_index + 4],
            "discharge_over_current_delay": messageArray[start_index + 5],
            "discharge_over_current_release_delay": messageArray[start_index + 6],
            "hard_charge_current": messageArray[start_index + 7],
            "hard_discharge_current": messageArray[start_index + 8],
            "reserve_capacity_1": messageArray[start_index + 9],
            "reserve_capacity_2": messageArray[start_index + 10],
            "scr_realease_time": messageArray[start_index + 11],
            "hard_time_delay": messageArray[start_index + 12],
        })
    }
    function warningSettingData(messageArray) {
        let start_index = 1
        setWarningSetting({
            "cellOverVoltageWarningAlarm": messageArray[start_index++],
            "cellUnderVoltageWarningAlarm": messageArray[start_index++],
            "cellChargingOverTempWarningAlarm": messageArray[start_index++],
            "cellChargingUnderTempWarningAlarm": messageArray[start_index++],
            "cellDischargingOverTempWarningAlarm": messageArray[start_index++],
            "cellDischargingUnderTempWarningAlarm": messageArray[start_index++],
            // "cellUnBalanceWarningAlarm": messageArray[start_index++],
            "packOverVoltageWarningAlarm": messageArray[start_index++],
            "packUnderVoltageWarningAlarm": messageArray[start_index++],
            "chargingOverCurrentWarningAlarm": messageArray[start_index++],
            "dischargingOverCurrentWarningAlarm": messageArray[start_index++],
            "socWarningAlarm": messageArray[start_index++],
            "sohWarningAlarm": messageArray[start_index++],
            "pduOverTempWarningAlarm": messageArray[start_index++],
            "shortCircuitWarningAlarm": messageArray[start_index++],
            // "opneWireWarningAlarm": messageArray[start_index++],
        })

    }
    function manufacturingData(messageArray) {
        let start_index = 0
        setManufacturingData({
            "manufacturing_date": messageArray[++start_index],
            "warranty_lapse_date": messageArray[++start_index],
            "last_firmware_date": messageArray[++start_index],
            "model_no": messageArray[++start_index],
            "board_revesion": messageArray[++start_index],
            "firmware_revision": messageArray[++start_index],
            "no_of_slaves": messageArray[++start_index],
            "tv_battery_serial_no": messageArray[++start_index],
        })

    }
    function newManufacturingData(messageArray) {
        let start_index = 0
        setManufacturingData({
            "manufacturing_date":messageArray[start_index++],
            "warranty_lapse_date":messageArray[start_index++],
            "last_firmware_date":messageArray[start_index++],
            "model_no":messageArray[start_index++],
            "board_revesion":messageArray[start_index++],
            "firmware_revision":messageArray[start_index++],
            "no_of_slaves":messageArray[start_index++],
            "tv_battery_serial_no":messageArray[start_index++] +"-"+ messageArray[start_index++],
            "device_id":messageArray[start_index++],
        })

    }
    function cellSettingData(messageArray) {
        let start_index = 0
        let start_balancing_voltage = messageArray[start_index + 1]
        let diff_min_max = messageArray[start_index + 2]
        let cell_full_charge_voltage = messageArray[start_index + 3]
        let cell_nominal_voltage = messageArray[start_index + 4]
        let cell_over_voltage_limit = messageArray[start_index + 5]
        let cell_over_voltage_release = messageArray[start_index + 6]
        let cell_over_voltage_delay = messageArray[start_index + 7]
        let cell_under_Voltage = messageArray[start_index + 8]
        let cell_under_voltage_release = messageArray[start_index + 9]
        let cell_under_voltage_delay = messageArray[start_index + 10]
        let hard_cell_over_voltage = messageArray[start_index + 11]
        let hard_cell_under_voltage = messageArray[start_index + 12]
        let cell_unbalanceProtection = messageArray[start_index + 13]
        setCellSetting({
            "start_balancing_voltage": start_balancing_voltage,
            "diff_min_max": diff_min_max,
            "cell_full_charge_voltage": cell_full_charge_voltage,
            "cell_nominal_voltage": cell_nominal_voltage,
            "cell_over_voltage_limit": cell_over_voltage_limit,
            "cell_over_voltage_release": cell_over_voltage_release,
            "cell_over_voltage_delay": cell_over_voltage_delay,
            "cell_under_Voltage": cell_under_Voltage,
            "cell_under_voltage_release": cell_under_voltage_release,
            "cell_under_voltage_delay": cell_under_voltage_delay,
            "hard_cell_over_voltage": hard_cell_over_voltage,
            "hard_cell_under_voltage": hard_cell_under_voltage,
            "cell_unbalanceProtection": cell_unbalanceProtection,
        })
    }
    function getMacAddress(val) {
        setSelectedMacAddress(val.value)
        main(val.value)
    }

    // const [csvData, setCsvData] = useState('');

    // // Function to handle writing data to the CSV string
    // const writeToCSV = (data) => {
    //     // Append new data to the existing CSV data
    //     setCsvData(prevCsvData => prevCsvData + data + '\n');
    // };
    // // Function to trigger the download of the CSV file
    // const downloadCSV = () => {
    //     const blob = new Blob([csvData], { type: 'text/csv' });
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = 'data.csv';
    //     document.body.appendChild(a);
    //     a.click();
    //     window.URL.revokeObjectURL(url);
    //     document.body.removeChild(a);
    // };


    let macAddressListOptions = []
    if (macAddressList) macAddressList.forEach((element, index) => {
        macAddressListOptions[index] = {
            value: element,
            label: element
        }
    });
    if (navigateBacktoModeSelectionPage)
        return <Redirect to="/mode-selection-page"></Redirect>
    else return (
        <div> <div style={{ paddingTop: "1rem" }}>
            <span
                onClick={() => setNavigateBacktoModeSelectionPage(true)}
                style={{ paddingLeft: "1rem", cursor: "pointer", textTransform: 'none' }}
            >
                <i className="fas fa-arrow-left"></i>&ensp;Mode Selection Page
            </span>
        </div>
            <h3>View BLE App Data</h3>
            <div className='main-container'>
                <Row>
                    <Col md="3" lg="3" sm='6' xs="12"><ReactSelect options={macAddressListOptions} onChange={getMacAddress}></ReactSelect></Col>
                    <Col md="3" lg="3" sm='6' xs="12">Connection Status: {mqttConnectionStatus}</Col>
                    <Col md="3" lg="3" sm='6' xs="12">{bmsDateTime?<><strong>Date Time</strong><br />{bmsDateTime}</>:''}</Col>
                    <Col md="3" lg="3" sm='6' xs="12"><Button onClick={publishMqtt}>Get Settings</Button></Col>
                    {/* <Col md="6" lg="6" sm='12' xs="12">Data Log: <Button>Start</Button><Button>Stop</Button><Button onClick={downloadCSV}>Download File</Button></Col> */}
                </Row>
                <div className="section section-tabs">
                    <Card style={{ marginRight: "10px" }}>
                        <Nav className="justify-content-center" role="tablist" tabs>
                            <NavItem>
                                <NavLink
                                    className={iconPills === "1" ? "active" : ""}
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIconPills("1");
                                    }}
                                >
                                    <strong>Fuel Data</strong>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={iconPills === "2" ? "active" : ""}
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIconPills("2");
                                    }}
                                >
                                    <strong>Cell Voltage</strong>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={iconPills === "3" ? "active" : ""}
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIconPills("3");
                                    }}
                                >
                                    <strong>Temperature Data</strong>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={iconPills === "4" ? "active" : ""}
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIconPills("4");
                                    }}
                                >
                                    <strong>Pin States</strong>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={iconPills === "5" ? "active" : ""}
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIconPills("5");
                                    }}
                                >
                                    <strong>Cell Settings</strong>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={iconPills === "6" ? "active" : ""}
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIconPills("6");
                                    }}
                                >
                                    <strong>Pack Settings</strong>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={iconPills === "7" ? "active" : ""}
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIconPills("7");
                                    }}
                                >
                                    <strong>Temperature Settings</strong>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={iconPills === "8" ? "active" : ""}
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIconPills("8");
                                    }}
                                >
                                    <strong>PDU Settings</strong>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={iconPills === "9" ? "active" : ""}
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIconPills("9");
                                    }}
                                >
                                    <strong>Warning Settings</strong>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={iconPills === "10" ? "active" : ""}
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIconPills("10");
                                    }}
                                >
                                    <strong>Manufacturing Data</strong>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Card>
                </div>
                <div className='ble-tab-content'>
                    <TabContent
                        className="text-center"
                        activeTab={"iconPills" + iconPills}
                    >
                        <TabPane tabId="iconPills1">
                            <h6>Fuel Data</h6>
                            <Row>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Discharge Current</span><strong>{fuelDataObj.packDischargeCurrent} mA</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Charge Current</span><strong>{fuelDataObj.packChargeCurrent} mA</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>MCP ADC Value</span><strong>{fuelDataObj.ADC1}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Voltage</span><strong>{fuelDataObj.packVoltage} mV</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Current</span><strong>{fuelDataObj.ADC2} mA</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Average Current</span><strong>{fuelDataObj.current} mA</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>SOC</span><strong>{fuelDataObj.soc} %</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Actual User Capacity</span><strong>{fuelDataObj.capacity} mAh</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Available Float mAh</span><strong>{fuelDataObj.floatmAh} mAh</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Design Capacity</span><strong>{fuelDataObj.packCapacity} mAh</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>SOH</span><strong>{fuelDataObj.soh} %</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>CC Offset Value</span><strong>{fuelDataObj.CCmAh}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Cycle Count (mAh)</span><strong>{fuelDataObj.cycleCountmAh} mAh</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>DisCharge Current e-Factor</span><strong>{fuelDataObj.DCF}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Charging Current e-Factor</span><strong>{fuelDataObj.CCF}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Cycle Count</span><strong>{fuelDataObj.CC}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>BMS Reset Counter</span><strong>{fuelDataObj.BRC}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Available Capacity</span><strong>{fuelDataObj.mAh} mAh</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Balance Car Temperature</span><strong>{fuelDataObj.BCT} °C</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>LDO Sense</span><strong>{fuelDataObj.LDO/10} V</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Driver Voltage</span><strong>{fuelDataObj.DV/10} V</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>last Fault</span><strong>{lastFault}</strong>
                                    </Card>
                                </Col>

                            </Row>
                        </TabPane>
                        <TabPane tabId="iconPills2">
                            {cellVoltageState ? <BatteryCells valueDataItem={cellVoltageState} dataFrom={"BLEView"} /> : "no voltage yet"}
                        </TabPane>
                        <TabPane tabId="iconPills3">
                            {tempObj ? <Temperature valueDataItem={tempObj} dataFrom={"BLEView"} /> : "no temp Data"}
                        </TabPane>
                        <TabPane tabId="iconPills4">
                            <h6>Pin State</h6>
                            <Row>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Charge FET</span><strong>{pinObj.Charge_FET}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>DisCharge FET</span><strong>{pinObj.DisCharge_FET}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>PreCharge FET</span><strong>{pinObj.PreCharge_FET}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>V5 Supply</span><strong>{pinObj.V5_Supply}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>SCD PIN</span><strong>{pinObj.SCD_PIN}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>BMS Power Mode</span><strong>{pinObj.BMSPowerMode}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Battery Pack State</span><strong>{pinObj.BatteryPackState}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>SOC Ind Present</span><strong>{pinObj.SOCIndPresent}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>PIN Ignition State</span><strong>{pinObj.PIN_INGITION_State}</strong>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="iconPills5">
                            <h6>Cell Setting</h6>
                            <Row>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Start Balancing Voltage</span><strong>{cellSetting.start_balancing_voltage}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Difference Min Max</span><strong>{cellSetting.diff_min_max}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Cell Full Charge Voltage</span><strong>{cellSetting.cell_full_charge_voltage}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Cell Nominal Voltage</span><strong>{cellSetting.cell_nominal_voltage}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Cell Over Voltage Limit</span><strong>{cellSetting.cell_over_voltage_limit}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Cell Over Voltage Release</span><strong>{cellSetting.cell_over_voltage_release}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Cell Over Voltage Delay</span><strong>{cellSetting.cell_over_voltage_delay}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Cell Under Voltage</span><strong>{cellSetting.cell_under_Voltage}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Cell Under Voltage Release</span><strong>{cellSetting.cell_under_voltage_release}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Cell Under Voltage Delay</span><strong>{cellSetting.cell_under_voltage_delay}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Hard Cell Over Voltage</span><strong>{cellSetting.hard_cell_over_voltage}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Hard Cell Under Voltage</span><strong>{cellSetting.hard_cell_under_voltage}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Cell Unbalance Protection</span><strong>{cellSetting.cell_unbalanceProtection}</strong>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="iconPills6">
                            <h6>Pack Setting</h6>
                            <Row>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Baud Rate</span><strong>{packSetting.baud_rate}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Number of Temperatures</span><strong>{packSetting.pack_numbe_of_tempratures}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Type of Cell</span><strong>{packSetting.pack_type_of_cell}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Initial Total Capacity</span><strong>{packSetting.initial_total_capacity}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Cycle Capacity</span><strong>{packSetting.pack_cycle_capacity}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Full Charge Voltage</span><strong>{packSetting.pack_full_charge_voltage}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Charge End Voltage</span><strong>{packSetting.pack_charge_end_voltage}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Discharge Rate</span><strong>{packSetting.pack_discharge_rate}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Over Voltage</span><strong>{packSetting.pack_over_voltage}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Over Voltage Release</span><strong>{packSetting.pack_over_voltage_release}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Over Voltage Delay</span><strong>{packSetting.pack_over_voltage_delay}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Under Voltage</span><strong>{packSetting.pack_under_voltage}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Under Voltage Release</span><strong>{packSetting.pack_under_voltage_release}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Under Voltage Delay</span><strong>{packSetting.pack_under_voltage_delay}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Hard Over Voltage</span><strong>{packSetting.pack_hard_over_voltage}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Hard Under Voltage</span><strong>{packSetting.pack_hard_under_voltage}</strong>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="iconPills7">
                            <h6>Temperature Setting</h6>
                            <Row>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Charge Over Temperature</span><strong>{tempSetting.charge_over_temp}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Charge Over Temperature Release</span><strong>{tempSetting.charge_over_temp_release}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Charge Over Temperature Delay</span><strong>{tempSetting.charge_over_temp_delay}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Charge Under Temperature</span><strong>{tempSetting.charge_under_temp}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Charge Under Temperature Release</span><strong>{tempSetting.charge_under_temp_release}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Charge Under Temperature Delay</span><strong>{tempSetting.charge_under_temp_delay}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>DisCharge over Temperature</span><strong>{tempSetting.discharge_over_temp}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>DisCharge Over Temperature Release</span><strong>{tempSetting.discharge_over_temp_release}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>DisCharge Over Temperature Delay</span><strong>{tempSetting.discharge_over_temp_delay}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>DisCharge Under Temperature</span><strong>{tempSetting.discharge_under_temp}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>DisCharge Under Temperature Release</span><strong>{tempSetting.discharge_under_temp_release}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>DisCharge Under Temperature Delay</span><strong>{tempSetting.discharge_under_temp_delay}</strong>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="iconPills8">
                            <h6>PDU Setting</h6>
                            <Row>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Over Charge Current</span><strong>{pduSetting.over_charge_current}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Charge Over Current Delay</span><strong>{pduSetting.charge_over_current_delay}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Charge Over Current Release Delay</span><strong>{pduSetting.charge_over_current_release_delay}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Over DisCharge Current</span><strong>{pduSetting.over_discharge_current}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>DisCharge Over Current Delay</span><strong>{pduSetting.discharge_over_current_delay}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>DisCharge Over Current Release Delay</span><strong>{pduSetting.discharge_over_current_release_delay}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Hard Charge Current</span><strong>{pduSetting.hard_charge_current}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Hard Discharge Current</span><strong>{pduSetting.hard_discharge_current}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Reserve Capacity 1</span><strong>{pduSetting.reserve_capacity_1}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Reserve Capacity 2</span><strong>{pduSetting.reserve_capacity_2}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>SCR Release Time</span><strong>{pduSetting.scr_realease_time}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Hard Time Delay</span><strong>{pduSetting.hard_time_delay}</strong>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="iconPills9">
                            <h6>Warning Setting</h6>
                            <Row>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Cell Over Voltage Warning Alarm</span><strong>{warningSetting.cellOverVoltageWarningAlarm}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Cell Under Voltage Warning Alarm</span><strong>{warningSetting.cellUnderVoltageWarningAlarm}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Cell Charging Over Temperature Warning Alarm</span><strong>{warningSetting.cellChargingOverTempWarningAlarm}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Cell Charging Under Temperature Warning Alarm</span><strong>{warningSetting.cellChargingUnderTempWarningAlarm}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Cell Discharging Over Temperature Warning Alarm</span><strong>{warningSetting.cellDischargingOverTempWarningAlarm}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Cell Discharging Under Temperature Warning Alarm</span><strong>{warningSetting.cellDischargingUnderTempWarningAlarm}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Over Voltage Warning Alarm</span><strong>{warningSetting.packOverVoltageWarningAlarm}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Pack Under Voltage Warning Alarm</span><strong>{warningSetting.packUnderVoltageWarningAlarm}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Charging Over Current Warning Alarm</span><strong>{warningSetting.chargingOverCurrentWarningAlarm}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>DisCharging Over Current Warning Alarm</span><strong>{warningSetting.dischargingOverCurrentWarningAlarm}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>SOC Warning Alarm</span><strong>{warningSetting.socWarningAlarm}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>SOH Warning Alarm</span><strong>{warningSetting.sohWarningAlarm}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>PDU Over Temp Warning Alarm</span><strong>{warningSetting.pduOverTempWarningAlarm}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Short Circuit Warning Alarm</span><strong>{warningSetting.shortCircuitWarningAlarm}</strong>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="iconPills10">
                            <h6>Manufacturing Data</h6>
                            <Row>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Manufacturing Date</span><strong>{ManufacturingData.manufacturing_date}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Warranty Lapse Date</span><strong>{ManufacturingData.warranty_lapse_date}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Last Firmware Date</span><strong>{ManufacturingData.last_firmware_date}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Model Number</span><strong>{ManufacturingData.model_no}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Board Revision</span><strong>{ManufacturingData.board_revesion}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Firmware Revision</span><strong>{ManufacturingData.firmware_revision}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>No of Slaves</span><strong>{ManufacturingData.no_of_slaves}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>TV Battery Serial Number</span><strong>{ManufacturingData.tv_battery_serial_no}</strong>
                                    </Card>
                                </Col>
                                <Col md="3" lg="3" sm='6' xs="12">
                                    <Card>
                                        <span>Device ID</span><strong>{ManufacturingData.device_id?ManufacturingData.device_id:'Not Found'}</strong>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        </div>
    )
}

export default BLEView