import {
  Container,
  Navbar,
} from "reactstrap";

import React from "react";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: "transparent",
    };
    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.sidebarToggle = React.createRef();
  }
  logOut = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };
  clickNotificationBell = () => {
    window.location.href = "/admin/notifications";
  };
  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: "transparent",
      });
    } else {
      this.setState({
        color: "dark",
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  dropdownToggle(e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }
  // getBrand() {
  //   let brandName = "Dashboard";
  //   routes.map((prop, key) => {
  //     if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
  //       brandName = prop.name;
  //     }
  //     return null;
  //   });
  //   return brandName;
  // }
  openSidebar() {
    document.documentElement.classList.toggle("nav-open");
    this.sidebarToggle.current.classList.toggle("toggled");
  }
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "dark",
      });
    } else {
      this.setState({
        color: "transparent",
      });
    }
  }
  componentDidMount() {
    // this.handleUserDetails();
    // this.handleNotificationCount();
    window.addEventListener("resize", this.updateColor.bind(this));
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.sidebarToggle.current.classList.toggle("toggled");
    }
  }
  render() {
    // let notificationcount = this.state.notificationData;
    // let apiData = this.state.resultData;
    // if(apiData)
    // {
    // const notificationCount= JSON.parse(notificationcount).data;
    // const dataItem = JSON.parse(apiData).data;
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "dark"
            : this.state.color
        }
        expand="lg"
        className={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "navbar-absolute fixed-top"
            : "navbar-absolute fixed-top " +
            (this.state.color === "transparent" ? "navbar-transparent " : "")
        }
      >
        <Container fluid>
          {/* <div className="navbar-wrapper"> */}
          <div className="navbar-toggle" style={{ marginTop: "15px" }}>
            <button
              type="button"
              ref={this.sidebarToggle}
              className="navbar-toggler"
              onClick={() => this.openSidebar()}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          {/* <NavbarBrand>{this.getBrand()}</NavbarBrand> */}
          {/* </div> */}
          {/* <NavbarToggler onClick={this.toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse
            isOpen={this.state.isOpen}
            navbar
            className="justify-content-end"
          >
            <Nav navbar>
              <NavItem>
                <Button onClick={this.logOut}>Log Out</Button>
              </NavItem>
            </Nav>
          </Collapse> */}
        </Container>
      </Navbar>
    );
  }
  // else {
  //   return (
  //     <div>
  //       <h2>Loading...</h2>
  //     </div>
  //   );
  // }
  // }
}

export default Header;
