import React, { useState, useEffect } from 'react'
import BmsDevices from './BmsDevices';
import { API_URL } from 'variables/general';
import EVDevices from './EVDevices';
import {Card, Nav, NavItem, NavLink} from 'reactstrap'
function BmsDevicesTab(props) {
    useEffect(() => {
        getPackData();
    }, []);
    const [iconPills, setIconPills] = React.useState("1");
    const [showContent,setShowContent] = React.useState()
    const [packData, setPackData] = useState('')
    const [showName, setShowName] = useState("");
    const getPackData = (val) => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Bearer " + window.localStorage.getItem("token")
        );
        let requestOptions;
        props.location.state
            ? (requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify({ subOwner: props.location.state }),
            })
            : (requestOptions = {
                method: "GET",
                headers: myHeaders,
            });
        fetch(API_URL + "/bms/battery_packs", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setShowName(result.data.data[0].subOwner.toUpperCase());
                setPackData(result.data.data);
                setShowContent(
                    <BmsDevices packData={result.data.data}  location={props.location}/>
                  )
                // packDataRecv(result.data.data);
            })
            .catch((error) => {
                console.log('error',error)
            }
            );
    };
    if (packData)
        return (
            <div>
                {window.localStorage.getItem("role") === "sub_owner" ? (
                    ""
                ) : (
                    <h3
                        style={{
                            justifyContent: "center",
                            textAlign: "center",
                            backgroundColor: "#6da2f7",
                            margin: "15px 20%",
                            borderRadius: "10px",
                            borderColor: "#17a2b8",
                            borderBottomStyle: "solid",
                            borderWidth: "5px",
                            color: "white",
                            fontWeight: "bolder",
                        }}
                    >
                        {showName}
                    </h3>
                )}



<div className="section section-tabs">

<Nav className="justify-content-center" role="tablist" tabs>
  <NavItem>
    <NavLink
      className={iconPills === "1" ? "active" : ""}
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        e.preventDefault();
        setIconPills('1')
        setShowContent(
            <BmsDevices packData={packData}  location={props.location}/>
          );
      }}
    >
      <strong>Battery Tracker</strong>
    </NavLink>
  </NavItem>
  <NavItem>
    <NavLink
      className={iconPills === "2" ? "active" : ""}
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        e.preventDefault();
        setIconPills('2')
        setShowContent(
          <EVDevices location={props.location}/>
          );
      }}
    >
      <strong>EV Tracker</strong>
    </NavLink>
  </NavItem>
  </Nav>
  <div style={{textAlign:'center'}}>
      {showContent}
    </div>
</div>
                
            </div>
        )
    else return <div>loading...</div>
}

export default BmsDevicesTab