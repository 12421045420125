import {
  Button,
  Col,
  Row,
  Table,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap";
import React, { Component } from "react";

import { API_URL } from "variables/general";
import { textSpanContainsPosition } from "typescript";

class AddSettings extends Component {
  constructor() {
    super();
    this.state = {
      BalanceStartVoltage: null,
      BalanceWindow: null,
      CellFullChrVoltage: null,
      CellNominalVoltage: null,
      CellUnderVoltage: null,
      CellOverVoltage: null,
      CellOVRelease: null,
      CellOVDelay: null,
      HardCellOverVoltage: null,
      CellUVRelease: null,
      CellUVDelay: null,
      HardCellUnderVoltage: null,
      PackNum: null,
      NumberOfTemp: null,
      TypeOfCell: null,
      DesignCapacity: null,
      CycleCapacity: null,
      FullChargeVol: null,
      ChargeEndVol: null,
      DischargingRate: null,
      PackOverVoltage: null,
      PackOVRelease: null,
      PackOVDelay: null,
      HardPackOverVoltage: null,
      PackUnderVoltage: null,
      PackUVRelease: null,
      PackUVDelay: null,
      HardPackUnderVoltage: null,
      ChgOverTemp: null,
      ChgOTRelease: null,
      ChgOTDelay: null,
      ChgLowTemp: null,
      ChgUTRelease: null,
      ChgUTDelay: null,
      DisOverTemp: null,
      DsgOTRelease: null,
      DsgOTDelay: null,
      DisLowTemp: null,
      DsgUTRelease: null,
      DsgUTDelay: null,
      OverChargeCurrent: null,
      ChgOCDelay: null,
      ChgOCRDelay: null,
      HardChgOverCurrent: null,
      OverDisCurrent: null,
      DsgOCDelay: null,
      DsgOCRDelay: null,
      HardDsgOverCurrent: null,
      ReserveCapacityS1: null,
      ReserveCapacityS2: null,
      SCReleaseTime: null,
      HardTime: null,
      UniqueId: null,
    };
  }

  getBalanceStartVoltage = (event) => {
    if (event < 0 || event > 5000)
      alert("Please set values in the given limits");
    else this.setState({ BalanceStartVoltage: event });
  };
  getBalanceWindow = (event) => {
    if (event < 0 || event > 255)
      alert("Please set values in the given limits");
    else this.setState({ BalanceWindow: event });
  };
  getCellFullChrVoltage = (event) => {
    if (event < 0 || event > 5000)
      alert("Please set values in the given limits");
    else this.setState({ CellFullChrVoltage: event });
  };
  getCellNominalVoltage = (event) => {
    if (event < 0 || event > 5000)
      alert("Please set values in the given limits");
    else this.setState({ CellNominalVoltage: event });
  };
  getCellUnderVoltage = (event) => {
    if (event < 0 || event > 5000)
      alert("Please set values in the given limits");
    else this.setState({ CellUnderVoltage: event });
  };
  getCellOverVoltage = (event) => {
    if (event < 0 || event > 5000)
      alert("Please set values in the given limits");
    else this.setState({ CellOverVoltage: event });
  };
  getCellOVRelease = (event) => {
    if (event < 0 || event > 5000)
      alert("Please set values in the given limits");
    else this.setState({ CellOVRelease: event });
  };
  getCellOVDelay = (event) => {
    if (event < 0 || event > 65534)
      alert("Please set values in the given limits");
    else this.setState({ CellOVDelay: event });
  };
  getHardCellOverVoltage = (event) => {
    if (event < 0 || event > 5000)
      alert("Please set values in the given limits");
    else this.setState({ HardCellOverVoltage: event });
  };
  getCellUVRelease = (event) => {
    if (event < 0 || event > 5000)
      alert("Please set values in the given limits");
    else this.setState({ CellUVRelease: event });
  };
  getCellUVDelay = (event) => {
    if (event < 0 || event > 65534)
      alert("Please set values in the given limits");
    else this.setState({ CellUVDelay: event });
  };
  getHardCellUnderVoltage = (event) => {
    if (event < 0 || event > 5000)
      alert("Please set values in the given limits");
    else this.setState({ HardCellUnderVoltage: event });
  };
  getPackNum = (event) => {
    if (event < 0 || event > 655355)
      alert("Please set values in the given limits");
    else this.setState({ PackNum: event });
  };
  getNumberOfTemp = (event) => {
    if (event < 0 || event > 255)
      alert("Please set values in the given limits");
    else this.setState({ NumberOfTemp: event });
  };
  getTypeOfCell = (event) => {
    if (event < 0 || event > 20) alert("Please set values in the given limits");
    else this.setState({ TypeOfCell: event });
  };
  getDesignCapacity = (event) => {
    if (event < 0 || event > 655355)
      alert("Please set values in the given limits");
    else this.setState({ DesignCapacity: event });
  };
  getCycleCapacity = (event) => {
    if (event < 0 || event > 655355)
      alert("Please set values in the given limits");
    else this.setState({ CycleCapacity: event });
  };
  getFullChargeVol = (event) => {
    if (event < 0 || event > 655355)
      alert("Please set values in the given limits");
    else this.setState({ FullChargeVol: event });
  };
  getChargeEndVol = (event) => {
    if (event < 0 || event > 655355)
      alert("Please set values in the given limits");
    else this.setState({ ChargeEndVol: event });
  };
  getDischargingRate = (event) => {
    if (event < 0 || event > 50) alert("Please set values in the given limits");
    else this.setState({ DischargingRate: event });
  };
  getPackOverVoltage = (event) => {
    if (event < 0 || event > 655355)
      alert("Please set values in the given limits");
    else this.setState({ PackOverVoltage: event });
  };
  getPackOVRelease = (event) => {
    if (event < 0 || event > 655355)
      alert("Please set values in the given limits");
    else this.setState({ PackOVRelease: event });
  };
  getPackOVDelay = (event) => {
    if (event < 0 || event > 65535)
      alert("Please set values in the given limits");
    else this.setState({ PackOVDelay: event });
  };
  getHardPackOverVoltage = (event) => {
    if (event < 0 || event > 655355)
      alert("Please set values in the given limits");
    else this.setState({ HardPackOverVoltage: event });
  };
  getPackUnderVoltage = (event) => {
    if (event < 0 || event > 655355)
      alert("Please set values in the given limits");
    else this.setState({ PackUnderVoltage: event });
  };
  getPackUVRelease = (event) => {
    if (event < 0 || event > 655355)
      alert("Please set values in the given limits");
    else this.setState({ PackUVRelease: event });
  };
  getPackUVDelay = (event) => {
    if (event < 0 || event > 65535)
      alert("Please set values in the given limits");
    else this.setState({ PackUVDelay: event });
  };
  getHardPackUnderVoltage = (event) => {
    if (event < 0 || event > 655355)
      alert("Please set values in the given limits");
    else this.setState({ HardPackUnderVoltage: event });
  };
  getChgOverTemp = (event) => {
    if (event < -40 || event > 120)
      alert("Please set values in the given limits");
    else this.setState({ ChgOverTemp: event });
  };
  getChgOTRelease = (event) => {
    if (event < -40 || event > 120)
      alert("Please set values in the given limits");
    else this.setState({ ChgOTRelease: event });
  };
  getChgOTDelay = (event) => {
    if (event < 0 || event > 65535)
      alert("Please set values in the given limits");
    else this.setState({ ChgOTDelay: event });
  };
  getChgLowTemp = (event) => {
    if (event < -40 || event > 120)
      alert("Please set values in the given limits");
    else this.setState({ ChgLowTemp: event });
  };
  getChgUTRelease = (event) => {
    if (event < -40 || event > 120)
      alert("Please set values in the given limits");
    else this.setState({ ChgUTRelease: event });
  };
  getChgUTDelay = (event) => {
    if (event < 0 || event > 65535)
      alert("Please set values in the given limits");
    else this.setState({ ChgUTDelay: event });
  };
  getDisOverTemp = (event) => {
    if (event < -40 || event > 120)
      alert("Please set values in the given limits");
    else this.setState({ DisOverTemp: event });
  };
  getDsgOTRelease = (event) => {
    if (event < -40 || event > 120)
      alert("Please set values in the given limits");
    else this.setState({ DsgOTRelease: event });
  };
  getDsgOTDelay = (event) => {
    if (event < 0 || event > 65535)
      alert("Please set values in the given limits");
    else this.setState({ DsgOTDelay: event });
  };
  getDisLowTemp = (event) => {
    if (event < -40 || event > 120)
      alert("Please set values in the given limits");
    else this.setState({ DisLowTemp: event });
  };
  getDsgUTRelease = (event) => {
    if (event < -40 || event > 120)
      alert("Please set values in the given limits");
    else this.setState({ DsgUTRelease: event });
  };
  getDsgUTDelay = (event) => {
    if (event < 0 || event > 65535)
      alert("Please set values in the given limits");
    else this.setState({ DsgUTDelay: event });
  };
  getOverChargeCurrent = (event) => {
    if (event < 0 || event > 655355)
      alert("Please set values in the given limits");
    else this.setState({ OverChargeCurrent: event });
  };
  getChgOCDelay = (event) => {
    if (event < 0 || event > 65535)
      alert("Please set values in the given limits");
    else this.setState({ ChgOCDelay: event });
  };
  getChgOCRDelay = (event) => {
    if (event < 0 || event > 65535)
      alert("Please set values in the given limits");
    else this.setState({ ChgOCRDelay: event });
  };
  getHardChgOverCurrent = (event) => {
    if (event < 0 || event > 655355)
      alert("Please set values in the given limits");
    else this.setState({ HardChgOverCurrent: event });
  };
  getOverDisCurrent = (event) => {
    if (event < 0 || event > 655355)
      alert("Please set values in the given limits");
    else this.setState({ OverDisCurrent: event });
  };
  getDsgOCDelay = (event) => {
    if (event < 0 || event > 65535)
      alert("Please set values in the given limits");
    else this.setState({ DsgOCDelay: event });
  };
  getDsgOCRDelay = (event) => {
    if (event < 0 || event > 65535)
      alert("Please set values in the given limits");
    else this.setState({ DsgOCRDelay: event });
  };
  getHardDsgOverCurrent = (event) => {
    if (event < 0 || event > 655355)
      alert("Please set values in the given limits");
    else this.setState({ HardDsgOverCurrent: event });
  };
  getReserveCapacityS1 = (event) => {
    if (event < 0 || event > 100)
      alert("Please set values in the given limits");
    else this.setState({ ReserveCapacityS1: event });
  };
  getReserveCapacityS2 = (event) => {
    if (event < 0 || event > 100)
      alert("Please set values in the given limits");
    else this.setState({ ReserveCapacityS2: event });
  };
  getSCReleaseTime = (event) => {
    if (event < 0 || event > 65535)
      alert("Please set values in the given limits");
    else this.setState({ SCReleaseTime: event });
  };
  getHardTime = (event) => {
    if (event < 0 || event > 65535)
      alert("Please set values in the given limits");
    else this.setState({ HardTime: event });
  };
  getUniqueId = (event) => {
    this.setState({ UniqueId: event });
  };
  postSettings = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({
        id: this.state.UniqueId,
        BalanceStartVoltage: this.state.BalanceStartVoltage,
        BalanceWindow: this.state.BalanceWindow,
        CellFullChrVoltage: this.state.CellFullChrVoltage,
        CellNominalVoltage: this.state.CellNominalVoltage,
        CellUnderVoltage: this.state.CellUnderVoltage,
        CellOverVoltage: this.state.CellOverVoltage,
        CellOVRelease: this.state.CellOVRelease,
        CellOVDelay: this.state.CellOVDelay,
        HardCellOverVoltage: this.state.HardCellOverVoltage,
        CellUVRelease: this.state.CellUVRelease,
        CellUVDelay: this.state.CellUVDelay,
        HardCellUnderVoltage: this.state.HardCellUnderVoltage,
        PackNum: this.state.PackNum,
        NumberOfTemp: this.state.NumberOfTemp,
        TypeOfCell: this.state.TypeOfCell,
        DesignCapacity: this.state.DesignCapacity,
        CycleCapacity: this.state.CycleCapacity,
        FullChargeVol: this.state.FullChargeVol,
        ChargeEndVol: this.state.ChargeEndVol,
        DischargingRate: this.state.DischargingRate,
        PackOverVoltage: this.state.PackOverVoltage,
        PackOVRelease: this.state.PackOVRelease,
        PackOVDelay: this.state.PackOVDelay,
        HardPackOverVoltage: this.state.HardPackOverVoltage,
        PackUnderVoltage: this.state.PackUnderVoltage,
        PackUVRelease: this.state.PackUVRelease,
        PackUVDelay: this.state.PackUVDelay,
        HardPackUnderVoltage: this.state.HardPackUnderVoltage,
        ChgOverTemp: this.state.ChgOverTemp,
        ChgOTRelease: this.state.ChgOTRelease,
        ChgOTDelay: this.state.ChgOTDelay,
        ChgLowTemp: this.state.ChgLowTemp,
        ChgUTRelease: this.state.ChgUTRelease,
        ChgUTDelay: this.state.ChgUTDelay,
        DisOverTemp: this.state.DisOverTemp,
        DsgOTRelease: this.state.DsgOTRelease,
        DsgOTDelay: this.state.DsgOTDelay,
        DisLowTemp: this.state.DisLowTemp,
        DsgUTRelease: this.state.DsgUTRelease,
        DsgUTDelay: this.state.DsgUTDelay,
        OverChargeCurrent: this.state.OverChargeCurrent,
        ChgOCDelay: this.state.ChgOCDelay,
        ChgOCRDelay: this.state.ChgOCRDelay,
        HardChgOverCurrent: this.state.HardChgOverCurrent,
        OverDisCurrent: this.state.OverDisCurrent,
        DsgOCDelay: this.state.DsgOCDelay,
        DsgOCRDelay: this.state.DsgOCRDelay,
        HardDsgOverCurrent: this.state.HardDsgOverCurrent,
        ReserveCapacityS1: this.state.ReserveCapacityS1,
        ReserveCapacityS2: this.state.ReserveCapacityS2,
        SCReleaseTime: this.state.SCReleaseTime,
        HardTime: this.state.HardTime,
      }),
    };
    fetch(API_URL + "/bms/settings_profile", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          settingsData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  render() {
    return window.localStorage.getItem("role") === "super_user" ? (
      <div>
        <UncontrolledTooltip target="BalanceStartVoltage">
          Balance Start after this max cell voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="BalanceWindow">
          Balnce window min max cell
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CellFullChrVoltage ">
          Cell full charge Voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CellNominalVoltage">
          Cell Nominal Voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CellUnderVoltage">
          Cell Under Voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CellOverVoltage">
          Cell Over Voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CellOVRelease">
          Cell Over Voltage Release
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CellOVDelay">
          Cell Over Voltage delay
        </UncontrolledTooltip>
        <UncontrolledTooltip target="HardCellOverVoltage">
          Instant Cut Over voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CellUnderVoltage">
          Cell Under Voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CellUVRelease">
          Cell Under Voltage Release
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CellUVDelay">
          Cell Under Voltage delay
        </UncontrolledTooltip>
        <UncontrolledTooltip target="HardCellUnderVoltage">
          Instant Cut Under voltage
        </UncontrolledTooltip>

        <UncontrolledTooltip target="PackNum">
          BMS Number (ID)
        </UncontrolledTooltip>
        <UncontrolledTooltip target="NumberOfTemp">
          Number of temp Used
        </UncontrolledTooltip>
        <UncontrolledTooltip target="TypeOfCell">
          Type of cell chemistry (Details Below)
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DesignCapacity">
          Pack Capacity (mAh)
        </UncontrolledTooltip>
        <UncontrolledTooltip target="CycleCapacity">
          Max charge discharge cycle
        </UncontrolledTooltip>
        <UncontrolledTooltip target="FullChargeVol">
          Pack Full Charge Voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ChargeEndVol">
          Charge End Voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DischargingRate">
          Max Discharge Rate
        </UncontrolledTooltip>
        <UncontrolledTooltip target="PackOverVoltage">
          Pack Over voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="PackOVRelease">
          Pack over voltage Release
        </UncontrolledTooltip>
        <UncontrolledTooltip target="PackOVDelay">
          Pack Over voltage Delay
        </UncontrolledTooltip>
        <UncontrolledTooltip target="HardPackOverVoltage">
          Instant Cut Pack Over voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="PackUnderVoltage">
          Pack Under voltage
        </UncontrolledTooltip>
        <UncontrolledTooltip target="PackUVRelease">
          Pack Under voltage Release
        </UncontrolledTooltip>
        <UncontrolledTooltip target="PackUVDelay">
          Pack Under voltage Delay
        </UncontrolledTooltip>
        <UncontrolledTooltip target="HardPackUnderVoltage">
          Instant Cut Pack Under voltage
        </UncontrolledTooltip>

        <UncontrolledTooltip target="ChgOverTemp">
          Cell Over Temperature Limit at the time of charging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ChgOTRelease">
          Cell Over Temperature Release Limit at the time of charging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ChgOTDelay">
          Cell Over Temperature Limit delay at the time of charging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ChgLowTemp">
          Cell Under Temperature Limit at the time of charging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ChgUTRelease">
          Cell Under Temperature Release Limit at the time of charging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ChgUTDelay ">
          Cell Under Temperature Limit delay at the time of charging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DisOverTemp">
          Cell Over Temperature Limit at the time of Discharging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DsgOTRelease">
          Cell Over Temperature Release Limit at the time of Discharging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DsgOTDelay">
          Cell Over Temperature Limit delay at the time of Discharging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DisLowTemp">
          Cell Under Temperature Limit at the time of Discharging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DsgUTRelease">
          Cell Under Temperature Release Limit at the time of Discharging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DsgUTDelay">
          Cell Under Temperature Limit delay at the time of Discharging
        </UncontrolledTooltip>

        <UncontrolledTooltip target="OverChargeCurrent ">
          Over Charging Current
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ChgOCDelay">
          Over Charging Current delay
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ChgOCRDelay">
          Over Charging Release Time if current is below release Value
        </UncontrolledTooltip>
        <UncontrolledTooltip target="HardChgOverCurrent">
          Instant current CutOff Limit at the time of Charging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="OverDisCurrent">
          Over DisCharging Current
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DsgOCDelay">
          Over DisCharging Current delay
        </UncontrolledTooltip>
        <UncontrolledTooltip target="DsgOCRDelay">
          Over DisCharging Release Time if current is below release Value
        </UncontrolledTooltip>
        <UncontrolledTooltip target="HardDsgOverCurrent">
          Instant current CutOff Limit at the time of DisCharging
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ReserveCapacityS1">
          Reserve 1 Enable Value
        </UncontrolledTooltip>
        <UncontrolledTooltip target="ReserveCapacityS2">
          Reserve 2 Enable Value
        </UncontrolledTooltip>
        <UncontrolledTooltip target="SCReleaseTime">
          Not Use
        </UncontrolledTooltip>
        <UncontrolledTooltip target="HardTime">Not Use</UncontrolledTooltip>

        <div className="main-margin">
          <h3>Settings</h3>
          <strong>Unique Id:</strong>&ensp;
          <input
            onChange={(event) => this.getUniqueId(event.target.value)}
            placeholder="Unique Id"
            value={this.state.UniqueId}
          ></input>
          <br />
          <br />
          <Row>
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              <h5 style={{ padding: "5px" }}>Cell Settings</h5>
              <Table responsive>
                <tr>
                  <td>
                    <strong>Balance Start Voltage:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getBalanceStartVoltage(event.target.value)
                      }
                      placeholder="Balance Start Voltage"
                      value={this.state.BalanceStartVoltage}
                    ></input>
                  </td>

                  <td>
                    <label>(0-5000) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="BalanceStartVoltage"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Balance Window:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getBalanceWindow(event.target.value)
                      }
                      placeholder="Balance Window"
                      value={this.state.BalanceWindow}
                    ></input>
                  </td>

                  <td>
                    <label>(0-255) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="BalanceWindow"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Cell Full Charge Voltage:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getCellFullChrVoltage(event.target.value)
                      }
                      placeholder="Cell Full Charge Voltage"
                      value={this.state.CellFullChrVoltage}
                    ></input>
                  </td>

                  <td>
                    <label>(0-5000) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="CellFullChrVoltage"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Cell Nominal Voltage:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getCellNominalVoltage(event.target.value)
                      }
                      placeholder="Cell Nominal Voltage"
                      value={this.state.CellNominalVoltage}
                    ></input>
                  </td>

                  <td>
                    <label>(0-5000) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="CellNominalVoltage"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Cell Under Voltage:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getCellUnderVoltage(event.target.value)
                      }
                      placeholder="Cell Under Voltage"
                      value={this.state.CellUnderVoltage}
                    ></input>
                  </td>

                  <td>
                    <label>(0-5000) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="CellUnderVoltage"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Cell Over Voltage:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getCellOverVoltage(event.target.value)
                      }
                      placeholder="Cell Over Voltage"
                      value={this.state.CellOverVoltage}
                    ></input>
                  </td>

                  <td>
                    <label>(0-5000) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="CellOverVoltage"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Cell Over Voltage Release:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getCellOVRelease(event.target.value)
                      }
                      placeholder="Cell Over Voltage Release"
                      value={this.state.CellOVRelease}
                    ></input>
                  </td>

                  <td>
                    <label>(0-5000) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="CellOVRelease"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Cell Over Voltage Delay:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getCellOVDelay(event.target.value)
                      }
                      placeholder="Cell Over Voltage Delay"
                      value={this.state.CellOVDelay}
                    ></input>
                  </td>

                  <td>
                    <label>(0-65534) s</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="CellOVDelay"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Hard Cell Over Voltage:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getHardCellOverVoltage(event.target.value)
                      }
                      placeholder="Hard Cell Over Voltage"
                      value={this.state.HardCellOverVoltage}
                    ></input>
                  </td>

                  <td>
                    <label>(0-5000) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="HardCellOverVoltage"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Cell Under Voltage Release:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getCellUVRelease(event.target.value)
                      }
                      placeholder="Cell Under Voltage Release"
                      value={this.state.CellUVRelease}
                    ></input>
                  </td>

                  <td>
                    <label>(0-5000) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="CellUVRelease"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Cell Under Voltage Delay:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getCellUVDelay(event.target.value)
                      }
                      placeholder="Cell Under Voltage Delay"
                      value={this.state.CellUVDelay}
                    ></input>
                  </td>

                  <td>
                    <label>(0-5000) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="CellUVDelay"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Hard Cell Under Voltage:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getHardCellUnderVoltage(event.target.value)
                      }
                      placeholder="Hard Cell Under Voltage"
                      value={this.state.HardCellUnderVoltage}
                    ></input>
                  </td>

                  <td>
                    <label>(0-5000) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="HardCellUnderVoltage"
                    ></i>
                  </td>
                </tr>
                <tr></tr>
              </Table>
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              <h5 style={{ padding: "5px" }}>Pack Settings</h5>
              <Table responsive>
                <tr>
                  <td>
                    <strong>Pack Num:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) => this.getPackNum(event.target.value)}
                      placeholder="Pack Num"
                      value={this.state.PackNum}
                    ></input>
                  </td>

                  <td>
                    <label>(0-655355) Number</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="PackNum"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Number Of Temp:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getNumberOfTemp(event.target.value)
                      }
                      placeholder="Number Of Temp"
                      value={this.state.NumberOfTemp}
                    ></input>
                  </td>

                  <td>
                    <label>(0-255) Number</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="NumberOfTemp"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Type Of Cell:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getTypeOfCell(event.target.value)
                      }
                      placeholder="Type Of Cell"
                      value={this.state.TypeOfCell}
                    ></input>
                  </td>

                  <td>
                    <label>(0-20) Type Of Cell</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="TypeOfCell"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Design Capacity:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getDesignCapacity(event.target.value)
                      }
                      placeholder="Design Capacity"
                      value={this.state.DesignCapacity}
                    ></input>
                  </td>

                  <td>
                    <label>(0-655355) mAh</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="DesignCapacity"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Cycle Capacity:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getCycleCapacity(event.target.value)
                      }
                      placeholder="Cycle Capacity"
                      value={this.state.CycleCapacity}
                    ></input>
                  </td>

                  <td>
                    <label>(0-655355) Number</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="CycleCapacity"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Full Charge Vol:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getFullChargeVol(event.target.value)
                      }
                      placeholder="Full Charge Vol"
                      value={this.state.FullChargeVol}
                    ></input>
                  </td>

                  <td>
                    <label>(0-655355) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="FullChargeVol"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Charge End Vol:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getChargeEndVol(event.target.value)
                      }
                      placeholder="Charge End Vol"
                      value={this.state.ChargeEndVol}
                    ></input>
                  </td>

                  <td>
                    <label>(0-655355) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="ChargeEndVol"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Discharging Rate:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getDischargingRate(event.target.value)
                      }
                      placeholder="Discharging Rate"
                      value={this.state.DischargingRate}
                    ></input>
                  </td>

                  <td>
                    <label>(0-50) Discharging Rate</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="DischargingRate"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Pack Over Voltage:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getPackOverVoltage(event.target.value)
                      }
                      placeholder="Pack Over Voltage"
                      value={this.state.PackOverVoltage}
                    ></input>
                  </td>

                  <td>
                    <label>(0-655355) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="PackOverVoltage"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Pack Over Voltage Release:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getPackOVRelease(event.target.value)
                      }
                      placeholder="Pack Over Voltage Release"
                      value={this.state.PackOVRelease}
                    ></input>
                  </td>

                  <td>
                    <label>(0-655355) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="PackOVRelease"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Pack Over Voltage Delay:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getPackOVDelay(event.target.value)
                      }
                      placeholder="Pack Over Voltage Delay"
                      value={this.state.PackOVDelay}
                    ></input>
                  </td>

                  <td>
                    <label>(0-65535) s</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="PackOVDelay"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Hard Pack Over Voltage:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getHardPackOverVoltage(event.target.value)
                      }
                      placeholder="Hard Pack Over Voltage"
                      value={this.state.HardPackOverVoltage}
                    ></input>
                  </td>

                  <td>
                    <label>(0-655355) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="HardPackOverVoltage"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Pack Under Voltage:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getPackUnderVoltage(event.target.value)
                      }
                      placeholder="Pack Under Voltage"
                      value={this.state.PackUnderVoltage}
                    ></input>
                  </td>

                  <td>
                    <label>(0-655355) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="PackUnderVoltage"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Pack Under Voltage Release:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getPackUVRelease(event.target.value)
                      }
                      placeholder="Pack Under Voltage Release"
                      value={this.state.PackUVRelease}
                    ></input>
                  </td>

                  <td>
                    <label>(0-655355) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="PackUVRelease"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Pack Under Voltage Delay:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getPackUVDelay(event.target.value)
                      }
                      placeholder="Pack Under Voltage Delay"
                      value={this.state.PackUVDelay}
                    ></input>
                  </td>

                  <td>
                    <label>(0-65535) s</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="PackUVDelay"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Hard Pack Under Voltage:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getHardPackUnderVoltage(event.target.value)
                      }
                      placeholder="Hard Pack Under Voltage"
                      value={this.state.HardPackUnderVoltage}
                    ></input>
                  </td>

                  <td>
                    <label>(0-655355) mV</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="HardPackUnderVoltage"
                    ></i>
                  </td>
                </tr>
                <tr></tr>
              </Table>
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              {" "}
              <h5 style={{ padding: "5px" }}>Temperature Settings</h5>
              <Table responsive>
                <tr>
                  <td>
                    <strong>Charge Over Temp:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getChgOverTemp(event.target.value)
                      }
                      placeholder="Charge Over Temp"
                      value={this.state.ChgOverTemp}
                    ></input>
                  </td>

                  <td>
                    <label>(-40-120) °C</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="ChgOverTemp"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Charge Over Temperature Release:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getChgOTRelease(event.target.value)
                      }
                      placeholder="ChargeOver Temperature Release"
                      value={this.state.ChgOTRelease}
                    ></input>
                  </td>

                  <td>
                    <label>(-40-120) °C</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="ChgOTRelease"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Charge Over Temperature Delay:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getChgOTDelay(event.target.value)
                      }
                      placeholder="ChargeOver Temperature Delay"
                      value={this.state.ChgOTDelay}
                    ></input>
                  </td>

                  <td>
                    <label>(0-65535) s</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="ChgOTDelay"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Charge Low Temp:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getChgLowTemp(event.target.value)
                      }
                      placeholder="Charge Low Temp"
                      value={this.state.ChgLowTemp}
                    ></input>
                  </td>

                  <td>
                    <label>(-40-120) °C</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="ChgLowTemp"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Charge Under Temperature Release:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getChgUTRelease(event.target.value)
                      }
                      placeholder="Charge Under Temperature Release"
                      value={this.state.ChgUTRelease}
                    ></input>
                  </td>

                  <td>
                    <label>(-40-120) °C</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="ChgUTRelease"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Charge Under Temperature Delay:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getChgUTDelay(event.target.value)
                      }
                      placeholder="Charge Under Temperature Delay"
                      value={this.state.ChgUTDelay}
                    ></input>
                  </td>

                  <td>
                    <label>(0-65535) s</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="ChgUTDelay"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Discharge Over Temp:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getDisOverTemp(event.target.value)
                      }
                      placeholder="Discharge Over Temp"
                      value={this.state.DisOverTemp}
                    ></input>
                  </td>

                  <td>
                    <label>(-40-120) °C</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="DisOverTemp"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Discharge Over Temperature Release:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getDsgOTRelease(event.target.value)
                      }
                      placeholder="DsgOver Temperature Release"
                      value={this.state.DsgOTRelease}
                    ></input>
                  </td>

                  <td>
                    <label>(-40-120) °C</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="DsgOTRelease"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Discharge Over Temperature Delay:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getDsgOTDelay(event.target.value)
                      }
                      placeholder="DsgOver Temperature Delay"
                      value={this.state.DsgOTDelay}
                    ></input>
                  </td>

                  <td>
                    <label>(0-65535) s</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="DsgOTDelay"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Discharge Low Temp:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getDisLowTemp(event.target.value)
                      }
                      placeholder="Discharge Low Temp"
                      value={this.state.DisLowTemp}
                    ></input>
                  </td>

                  <td>
                    <label>(-40-120) °C</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="DisLowTemp"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Discharge Under Temperature Release:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getDsgUTRelease(event.target.value)
                      }
                      placeholder="Discharge Under Temperature Release"
                      value={this.state.DsgUTRelease}
                    ></input>
                  </td>

                  <td>
                    <label>(-40-120) °C</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="DsgUTRelease"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Discharge Under Temperature Delay:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getDsgUTDelay(event.target.value)
                      }
                      placeholder="Discharge Under Temperature Delay"
                      value={this.state.DsgUTDelay}
                    ></input>
                  </td>

                  <td>
                    <label>(0-65535) s</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="DsgUTDelay"
                    ></i>
                  </td>
                </tr>
                <tr></tr>
              </Table>
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              {" "}
              <h5 style={{ padding: "5px" }}>PDU card Details</h5>
              <Table responsive>
                <tr>
                  <td>
                    <strong>Over Charge Current:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getOverChargeCurrent(event.target.value)
                      }
                      placeholder="Over Charge Current"
                      value={this.state.OverChargeCurrent}
                    ></input>
                  </td>

                  <td>
                    <label>(0 - 655355) mA</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="OverChargeCurrent"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Charge Over Charge Delay:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getChgOCDelay(event.target.value)
                      }
                      placeholder="Charge Over Charge Delay"
                      value={this.state.ChgOCDelay}
                    ></input>
                  </td>

                  <td>
                    <label>(0-65535) s</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="ChgOCDelay"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Charge Over Charge R Delay:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getChgOCRDelay(event.target.value)
                      }
                      placeholder="Charge Over Charge R Delay"
                      value={this.state.ChgOCRDelay}
                    ></input>
                  </td>

                  <td>
                    <label>(0-65535) s</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="ChgOCRDelay"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Hard Charge Over Current:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getHardChgOverCurrent(event.target.value)
                      }
                      placeholder="Hard Charge Over Current"
                      value={this.state.HardChgOverCurrent}
                    ></input>
                  </td>

                  <td>
                    <label>(0 - 655355) mA</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="HardChgOverCurrent"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Over Discharge Current:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getOverDisCurrent(event.target.value)
                      }
                      placeholder="Over Discharge Current"
                      value={this.state.OverDisCurrent}
                    ></input>
                  </td>

                  <td>
                    <label>(0 - 655355) mA</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="OverDisCurrent"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Discharge Over Charge Delay:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getDsgOCDelay(event.target.value)
                      }
                      placeholder="Discharge Over Charge Delay"
                      value={this.state.DsgOCDelay}
                    ></input>
                  </td>

                  <td>
                    <label>(0-65535) s</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="DsgOCDelay"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Discharge Over Charge R Delay:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getDsgOCRDelay(event.target.value)
                      }
                      placeholder="Discharge Over Charge R Delay"
                      value={this.state.DsgOCRDelay}
                    ></input>
                  </td>

                  <td>
                    <label>(0-65535) s</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="DsgOCRDelay"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Hard Discharge Over Current:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getHardDsgOverCurrent(event.target.value)
                      }
                      placeholder="Hard Discharge Over Current"
                      value={this.state.HardDsgOverCurrent}
                    ></input>
                  </td>

                  <td>
                    <label>(0 - 655355) mA</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="HardDsgOverCurrent"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Reserve Capacity S1:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getReserveCapacityS1(event.target.value)
                      }
                      placeholder="Reserve Capacity S1"
                      value={this.state.ReserveCapacityS1}
                    ></input>
                  </td>

                  <td>
                    <label>(0 - 100) Reserve Capacity 1</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="ReserveCapacityS1"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Reserve Capacity S2:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getReserveCapacityS2(event.target.value)
                      }
                      placeholder="Reserve Capacity S2"
                      value={this.state.ReserveCapacityS2}
                    ></input>
                  </td>

                  <td>
                    <label>(0 - 100) Reserve Capacity 2</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="ReserveCapacityS2"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>S C Release Time:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) =>
                        this.getSCReleaseTime(event.target.value)
                      }
                      placeholder="S C ReleaseTime"
                      value={this.state.SCReleaseTime}
                    ></input>
                  </td>

                  <td>
                    <label>(0 - 65535) s</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="SCReleaseTime"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Hard Time:</strong>
                  </td>
                  <td>
                    <input
                      onChange={(event) => this.getHardTime(event.target.value)}
                      placeholder="Hard Time"
                      value={this.state.HardTime}
                    ></input>
                  </td>

                  <td>
                    <label>(0 - 65535) s</label>
                    <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id="HardTime"
                    ></i>
                  </td>
                </tr>
              </Table>
            </Col>
          </Row>
        </div>
        <Button onClick={this.postSettings}>Submit</Button>
      </div>
    ) : (
      <div>You're Not Authorized</div>
    );
  }
}
export default AddSettings;
