import "components/MapPro.js/Map.css";

import { Button, Card, Col, Row } from "react-bootstrap";
import {
  Circle,
  GoogleMap,
  InfoBox,
  LoadScript,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import React, { useEffect, useLocation, useState } from "react";

import { API_URL } from "variables/general";
import { UncontrolledTooltip } from "reactstrap";
import ReactSelect from "react-select";
function ProGeoFence() {
  useEffect(()=>{
    handleDeviceData()
  },[])
  const [BMSID,setBMSID] = useState('')
  const [latitude, setlatitude] = useState('')
  const [longitude, setlongitude] = useState('')
  const [circleRadius, setcircleRadius] = useState('')
  const [alarmRange, setalarmRange] = useState('')
  const [ignitionSetting, setignitionSetting] = useState('')
  const [centerLat, setcenterLat] = useState(26)
  const [centerLong, setcenterLong] = useState(79)
  const [enable, setenable] = useState(false)
  const [batteryPackData, setBatteryPackData] = useState('')
  const [geoFenceData, setGeoFenceData] = useState('')
  const getBMSID = (event) => {
    setBMSID(event.value)
    showGeoFencing(event.value);
  };
  const getLatitude = (event) => {
    setlatitude(event)
  };
  const getLongitude = (event) => {
    setlongitude(event)
  };
  const getCircleRadius = (event) => {
    setcircleRadius(event)
  };
  const getAlarmRange = (event) => {
    setalarmRange(event)
  };
  const getIgnitionSetting = (event) => {
    setignitionSetting(event)
  };
  const handleDeviceData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(API_URL + "/bms/battery_packs", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setBatteryPackData(result.data.data)
      })
      .catch((error) => console.log("error", error));
  };

  const onSubmit = () => {
    if (!BMSID) alert("Missing BMS ID");
    else if (!latitude) alert("Missing Latitude");
    else if (!longitude) alert("Missing Longitude");
    else if (!circleRadius) alert("Missing Circle Radius");
    else if (!alarmRange) alert("Missing Alarm Range");
    else if (!ignitionSetting)
      alert("Missing Ignition Setting");
    else {
      let apiHeaders = new Headers();
      apiHeaders.append("Content-Type", "application/json");
      apiHeaders.append(
        "Authorization",
        "Bearer " + window.localStorage.getItem("token")
      );
      let requestOptions;
      requestOptions = {
        method: "POST",
        headers: apiHeaders,
        body: JSON.stringify({
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
          circleRadius: parseFloat(circleRadius),
          alarmRange: parseFloat(alarmRange),
          did: BMSID,
          actionGeoFence: parseInt(ignitionSetting),
          enable: enable,
        }),
      };
      fetch(API_URL + "/bms/bms_geo_fencing", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) alert(result.message);
          else alert("Something went wrong");
          setGeoFenceData(result)
          
        })
        .catch((error) => console.log("error", error));
    }
  }

  const showGeoFencing = (event) => {
    let bmsId = event;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({
        did: bmsId,
      }),
    };

    fetch(API_URL + "/bms/get_geo_fencing", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if(result.data.geoFence.length>0){
          setlatitude(result.data.geoFence[0].latitude)
          setlongitude(result.data.geoFence[0].longitude)
          setcircleRadius(result.data.geoFence[0].circleRadius)
          setalarmRange(result.data.geoFence[0].alarmRange)
          setenable(result.data.geoFence[0].enable)
          setignitionSetting(result.data.geoFence[0].actionGeoFence)
        }
      })
      .catch((error) => console.log("error", error));
  };
 
 
 
  {
    const apiKey = "AIzaSyDUob5ItT5zZ_lWzJVkW9_Rz9oTq-B9M74"
    let containerStyle = {
      width: "35vw",
      height: "35vh",
    };

    let center = {
      lat: centerLat,
      lng: centerLong,
    };
    if (batteryPackData) {
      let deviceList = [];
      for (let i = 0; i < batteryPackData.length; i++) {
        deviceList[i] = {
          value: batteryPackData[i].bmsId,
          label: batteryPackData[i].alias ? batteryPackData[i].alias : batteryPackData[i].bmsId,
        };
      }
      // console.log("enable", this.state.enable);
      return (
        <div>
          <div className="main-margin">
            <h5 style={{ textAlign: 'center' }}>Update Geo Fence Configuration</h5>
            <Card style={{ padding: "0 15px" }}>

              <Row>
                <Col>
                  <strong>Select BMS ID: </strong>
                  <Row><Col md='6' lg='6' xl='6' sm='12' xs='12'><ReactSelect options={deviceList} onChange={getBMSID} /></Col></Row>
                  <br />
                  <strong>Geo Fencing: </strong>
                  <Button
                    onClick={() =>
                      setenable(!enable)
                    }
                  >
                    {enable ? "Enabled" : "Disabled"}
                  </Button>
                  <br />
                  <br />
                  <strong>Latitude: </strong>
                  <input
                    disabled={!enable ? "disabled" : ""}
                    onChange={(event) => getLatitude(event.target.value)}
                    value={latitude}
                  ></input>
                  <br />
                  <br />
                  <strong>Longitude: </strong>
                  <input
                    disabled={!enable ? "disabled" : ""}
                    onChange={(event) => getLongitude(event.target.value)}
                    value={longitude}
                  ></input>
                  <br />
                  <br />
                  <strong>Enter Geo Fence Circle Radius(km): </strong>
                  <input
                    disabled={!enable ? "disabled" : ""}
                    type="number"
                    value={circleRadius}
                    onChange={(event) =>
                      getCircleRadius(event.target.value)
                    }
                  />
                  <br />
                  <br />
                  <strong>Enter Alarm Circle Radius(km): </strong>
                  <input
                    disabled={!enable ? "disabled" : ""}
                    type="number"
                    value={alarmRange}
                    onChange={(event) => getAlarmRange(event.target.value)}
                  />
                  <br />
                  <br />
                  <UncontrolledTooltip target="IgnitionControl">
                    Set what should happen if the Geo Fencing is breached.
                  </UncontrolledTooltip>
                  <strong>Ignition Control: </strong>
                  <i
                    class="fa fa-question-circle"
                    aria-hidden="true"
                    id="IgnitionControl"
                  ></i>
                  &ensp;
                  <select
                    disabled={!enable ? "disabled" : ""}
                    onChange={(event) =>
                      getIgnitionSetting(event.target.value)
                    }
                  >
                    {/* <option value="" selected disabled hidden>
                      Select
                    </option> */}
                    <option value={1} selected={ignitionSetting === 1?true:false}>Do nothing</option>
                    <option value={2} selected={ignitionSetting === 2?true:false}>Show Alarm Only</option>
                    <option value={3} selected={ignitionSetting === 3?true:false}>Set Ignition Off</option>
                  </select>
                  <br />
                  <br />
                  <button
                    // disabled={!enable ? "disabled" : ""}
                    onClick={onSubmit}
                    style={{
                      color: "#f4f3ef",
                      backgroundColor: "#009846",
                      borderRadius: "4px",
                      borderWidth: "0",
                      padding: "5px 15px",
                      margin: "10px 5px",
                    }}
                  >
                    Set
                  </button>
                </Col>
                <Col>
                  <strong>Circle Center</strong>
                  <LoadScript googleMapsApiKey={apiKey}>
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={5}
                      onClick={(ev) => {
                          setlatitude(ev.latLng.lat())
                          setlongitude(ev.latLng.lng())
                          setcenterLat(ev.latLng.lat())
                          setcenterLong(ev.latLng.lng())
                      }}
                    >
                      <Marker
                        position={{
                          lat: latitude,
                          lng: longitude,
                        }}
                      ></Marker>
                    </GoogleMap>
                  </LoadScript>
                  <strong>Input Coordinates or Select on Map</strong>
                </Col>
              </Row>
            </Card>
          </div>
        </div>
      );
    } else
      return (
        <div className="content">
          <div className="loadingContainer">
            <div className="ball1"></div>
            <div className="ball2"></div>
            <div className="ball3"></div>
            <div className="ball4"></div>
          </div>
        </div>
      );
  }
}

export default ProGeoFence;
