import AddMacAddress from "components/Inventory/BMSEntry/AddMacAddress";
import React from "react";
import { Row, Col, Button, Card } from "reactstrap";
import { useState } from "react";
import { Redirect } from "react-router";
import AddInventory from "./AddInventory";
import EditInventory from "./EditInventory";
import Template from "./Template/Template";
import InventoryView from "./InventoryView";
import MQTTdata from "./MQTTdata/MQTTdata";
import ViewCards from "./ViewCards";

function Inventory(props) {
  const [navigateBacktoModeSelectionPage, setNavigateBacktoModeSelectionPage] =
    useState(false);
  const [addMacAddress, setAddMacAddress] = useState(window.localStorage.getItem("role") === "sub_owner" ?false :true);
  const [showAddInventory, setshowAddInventory] = useState(false);
  const [showEditInventory, setshowEditInventory] = useState(false);
  const [showHome, setshowHome] = useState(window.localStorage.getItem("role") === "sub_owner" ?true:false);
  const [showTemplate, setshowTemplate] = useState(false);
  const [showMassInventory, setshowMassInventory] = useState(false);
  const [showCardDetails, setshowCardDetails] = useState(false);
  if (navigateBacktoModeSelectionPage)
    return <Redirect to="/mode-selection-page"></Redirect>;
  else
    return (
      <div>
        <div style={{ paddingTop: "1rem" }}>
          <span
            onClick={() => setNavigateBacktoModeSelectionPage(true)}
            style={{ paddingLeft: "1rem", cursor: "pointer" }}
          >
            <i class="fas fa-arrow-left"></i>&ensp;Mode Selection Page
          </span>
        </div>
        <h3 style={{ textAlign: "center" }}>Inventory Management</h3>
        <Row style={{ padding: "0 1rem" }}>
          {window.localStorage.getItem("role") === "sub_owner" ? (
            <>
              <Col xs="6" sm="4" md="3" lg="3" xl="2">
                <Card
                  style={{
                    padding: "15px",
                    margin: "10px",
                    backgroundColor: showHome ? "#32C85A" : "white",
                    fontWeight: showHome ? "bolder" : "",
                    color: showHome ? "white" : "#0faef2",
                    textAlign: "center",
                    borderRadius: "25px",
                    border: "solid",
                    borderColor: showHome ? "#32C85A" : "",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setshowHome(true);
                    setAddMacAddress(false);
                    setshowAddInventory(false);
                    setshowTemplate(false);
                    setshowEditInventory(false);
                    setshowMassInventory(false);
                    setshowCardDetails(false)
                  }}
                >
                  <h6>Home</h6>
                </Card>
              </Col>
              <Col xs="6" sm="4" md="3" lg="3" xl="2">
                <Card
                  style={{
                    padding: "15px",
                    margin: "10px",
                    backgroundColor: showTemplate ? "#32C85A" : "white",
                    fontWeight: showTemplate ? "bolder" : "",
                    color: showTemplate ? "white" : "#0faef2",
                    textAlign: "center",
                    borderRadius: "25px",
                    border: "solid",
                    borderColor: showTemplate ? "#32C85A" : "",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setshowHome(false);
                    setshowAddInventory(false);
                    setshowEditInventory(false);
                    setAddMacAddress(false);
                    setshowTemplate(true);
                    setshowMassInventory(false);
                    setshowCardDetails(false)
                  }}
                >
                  <h6>Template</h6>
                </Card>
              </Col>
              <Col xs="6" sm="4" md="3" lg="3" xl="2">
                <Card
                  style={{
                    padding: "15px",
                    margin: "10px",
                    backgroundColor: showAddInventory ? "#32C85A" : "white",
                    fontWeight: showAddInventory ? "bolder" : "",
                    color: showAddInventory ? "white" : "#0faef2",
                    textAlign: "center",
                    borderRadius: "25px",
                    border: "solid",
                    borderColor: showAddInventory ? "#32C85A" : "",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setshowHome(false);
                    setshowAddInventory(true);
                    setAddMacAddress(false);
                    setshowTemplate(false);
                    setshowEditInventory(false);
                    setshowMassInventory(false);
                    setshowCardDetails(false)
                  }}
                >
                  <h6>Add Battery Pack</h6>
                </Card>
              </Col>
              
            </>
          ) : (
            <>
              <Col xs="6" sm="4" md="3" lg="3" xl="2">
                <Card
                  style={{
                    padding: "15px",
                    margin: "10px",
                    backgroundColor: addMacAddress ? "#32C85A" : "white",
                    fontWeight: addMacAddress ? "bolder" : "",
                    color: addMacAddress ? "white" : "#0faef2",
                    textAlign: "center",
                    borderRadius: "25px",
                    border: "solid",
                    borderColor: addMacAddress ? "#32C85A" : "",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setshowHome(false);
                    setAddMacAddress(true);
                    setshowAddInventory(false);
                    setshowTemplate(false);
                    setshowEditInventory(false);
                    setshowMassInventory(false);
                    setshowCardDetails(false)
                  }}
                >
                  <h6>Add Mac Address</h6>
                </Card>
              </Col>
              <Col xs="6" sm="4" md="3" lg="3" xl="2">
                <Card
                  style={{
                    padding: "15px",
                    margin: "10px",
                    backgroundColor: showMassInventory ? "#32C85A" : "white",
                    fontWeight: showMassInventory ? "bolder" : "",
                    color: showMassInventory ? "white" : "#0faef2",
                    textAlign: "center",
                    borderRadius: "25px",
                    border: "solid",
                    borderColor: showMassInventory ? "#32C85A" : "",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setshowHome(false);
                    setshowAddInventory(false);
                    setshowEditInventory(false);
                    setAddMacAddress(false);
                    setshowTemplate(false);
                    setshowMassInventory(true);
                  }}
                >
                  <h6>Mass Inventory</h6>
                </Card>
              </Col>
            </>
          )}
          <Col xs="6" sm="4" md="3" lg="3" xl="2">
            <Card
              style={{
                padding: "15px",
                margin: "10px",
                backgroundColor: showEditInventory ? "#32C85A" : "white",
                fontWeight: showEditInventory ? "bolder" : "",
                color: showEditInventory ? "white" : "#0faef2",
                textAlign: "center",
                borderRadius: "25px",
                border: "solid",
                borderColor: showEditInventory ? "#32C85A" : "",
                cursor: "pointer",
              }}
              onClick={() => {
                setshowHome(false);
                setshowAddInventory(false);
                setshowEditInventory(true);
                setAddMacAddress(false);
                setshowTemplate(false);
                setshowMassInventory(false);
                setshowCardDetails(false)
              }}
            >
              <h6>Edit Inventory</h6>
            </Card>
          </Col>
          <Col xs="6" sm="4" md="3" lg="3" xl="2">
            <Card
              style={{
                padding: "15px",
                margin: "10px",
                backgroundColor: showCardDetails ? "#32C85A" : "white",
                fontWeight: showCardDetails ? "bolder" : "",
                color: showCardDetails ? "white" : "#0faef2",
                textAlign: "center",
                borderRadius: "25px",
                border: "solid",
                borderColor: showCardDetails ? "#32C85A" : "",
                cursor: "pointer",
              }}
              onClick={() => {
                setshowHome(false);
                setshowAddInventory(false);
                setshowEditInventory(false);
                setAddMacAddress(false);
                setshowTemplate(false);
                setshowMassInventory(false);
                setshowCardDetails(true)
              }}
            >
              <h6>Card Reports</h6>
            </Card>
          </Col>
        </Row>
        {showHome ? <InventoryView adminId={props.adminId} /> : ""}
        {window.localStorage.getItem("role") === "admin" ? 
           addMacAddress ? (
          <AddMacAddress adminId={props.adminId} />
        ) : (
          ""
        ) : (
          ""
        )}
        {showAddInventory ? <AddInventory adminId={props.adminId} /> : ""}
        {showEditInventory ? <EditInventory adminId={props.adminId} /> : ""}
        {showTemplate ? <Template adminId={props.adminId} /> : ""}
        {showMassInventory ? <MQTTdata adminId={props.adminId} /> : ""}
        {showCardDetails ? <ViewCards adminId={props.adminId} /> : ""}
      </div>
    );
}

export default Inventory;
