import { Bar, Line } from "react-chartjs-2";
import React, { useEffect } from "react";

import { AWS_URL } from "variables/general";
import Arrow from "assets/img/longarrow.png";
import Badge from "reactstrap/lib/Badge";
import Card from "reactstrap/lib/Card";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { Spinner } from "reactstrap";
import UpArrow from "assets/img/uparrow.png";
import { useState } from "react";
import { valueDataMContext, valueDataContext } from "./Dashboard";
import { useContext } from "react";

function BatteryCells(props) {
  // console.log("props=====================>", props)
  let valueDataItem = props.valueDataItem
  let dataFrom = props.dataFrom
  let valueDataM = useContext(valueDataMContext)
  if (valueDataItem) {
    if (valueDataM) {
      let cellValue = [];
      let cellLabel = [];
      let cellColor = [];
      let cellAverage;
      let cellSum = 0;
      let cellVoltageData = valueDataM.ext[0].cellTempData.cellVoltage
      for (let i = 0; i < cellVoltageData.length; i++) {
        cellSum = cellSum + cellVoltageData[i];
      }
      cellAverage = cellSum / cellVoltageData.length;
      let maxVoltage = cellVoltageData[0];
      let minVoltage = cellVoltageData[0];
      for (let i = 0; i < cellVoltageData.length; i++) {
        if (maxVoltage <= cellVoltageData[i]) maxVoltage = cellVoltageData[i];
      }
      for (let i = 0; i < cellVoltageData.length; i++) {
        if (minVoltage >= cellVoltageData[i]) minVoltage = cellVoltageData[i];
      }
      for (let i = 0; i < cellVoltageData.length; i++) {
        cellValue[i] = cellVoltageData[i];
      }
      for (let i = 0; i < cellVoltageData.length; i++) {
        cellLabel[i] = i + 1;
      }
      for (let i = 0; i < cellVoltageData.length; i++) {
        if (cellVoltageData[i] === maxVoltage) cellColor[i] = "#b8b209";
        else if (cellVoltageData[i] === minVoltage) cellColor[i] = "#078da8";
        else cellColor[i] = "rgba(10, 255, 10, 1)";
      }

      const batteryCellsChart = {
        data: () => {
          return {
            labels: cellLabel,
            datasets: [
              {
                label: "Cell Voltage (mV)",
                data: cellValue,
                backgroundColor: cellColor,
                borderColor: [],
                borderWidth: 0,
              },
            ],
          };
        },

        options: {
          legend: {
            display: false,
            position: "top",
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      };
      return (
        <div>
          <div>
            <Card>
              <div style={{ margin: "15px" }}>
                <div className="card-head">Cell Voltages</div>
                <Row>
                  <Col>
                    <div>
                      <Badge
                        style={{
                          backgroundColor: "#078da8",
                          color: "white",

                          margin: "10px",
                        }}
                      >
                        Min: {minVoltage}mV
                      </Badge>
                      <Badge
                        style={{
                          backgroundColor: "#b8b209",
                          color: "white",

                          margin: "10px",
                        }}
                      >
                        Max: {maxVoltage}mV
                      </Badge>
                      <Badge
                        style={{
                          backgroundColor: "rgba(100, 100, 100, 1)",
                          color: "white",

                          margin: "10px",
                        }}
                      >
                        Diff: {maxVoltage - minVoltage}mV
                      </Badge>
                      <Badge
                        style={{
                          backgroundColor: "rgba(125, 42, 89, 0.75)",
                          color: "white",

                          margin: "10px",
                        }}
                      >
                        Avg: {Math.round(cellAverage)}mV
                      </Badge>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Bar
                      data={batteryCellsChart.data}
                      options={batteryCellsChart.options}
                    />
                    <Row>
                      <Col md="3"></Col>
                      <Col md="3" className="card-head">
                        Cell Number
                      </Col>
                      <Col md="3">
                        {" "}
                        <img src={Arrow} />
                      </Col>
                      <Col md="3"></Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Card>
          </div>
        </div>
      );
    }
    let cellValue = [];
    let cellLabel = [];
    let cellColor = [];
    let cellAverage;
    let cellSum = 0;
    let cellDifference;
    let cellVoltageData 
    if(dataFrom === "BLEView")
    {
      // console.log("====================valueDataItem=================",valueDataItem)
      cellVoltageData = valueDataItem
    }
    else 
    cellVoltageData = JSON.parse(valueDataItem.cellVoltage);
    for (let i = 0; i < cellVoltageData.length; i++) {
      cellSum = cellSum + parseInt(cellVoltageData[i]);
    }
    cellAverage = cellSum / cellVoltageData.length;
    let maxVoltage = cellVoltageData[0];
    let minVoltage = cellVoltageData[0];
    for (let i = 0; i < cellVoltageData.length; i++) {
      if (maxVoltage <= cellVoltageData[i]) maxVoltage = cellVoltageData[i];
    }
    for (let i = 0; i < cellVoltageData.length; i++) {
      if (minVoltage >= cellVoltageData[i]) minVoltage = cellVoltageData[i];
    }
    for (let i = 0; i < cellVoltageData.length; i++) {
      cellValue[i] = cellVoltageData[i];
    }
    for (let i = 0; i < cellVoltageData.length; i++) {
      cellLabel[i] = i + 1;
    }
    for (let i = 0; i < cellVoltageData.length; i++) {
      if (cellVoltageData[i] === maxVoltage) cellColor[i] = "#b8b209";
      else if (cellVoltageData[i] === minVoltage) cellColor[i] = "#078da8";
      else cellColor[i] = "rgba(10, 255, 10, 1)";
    }

    const batteryCellsChart = {
      data: () => {
        return {
          labels: cellLabel,
          datasets: [
            {
              label: "Cell Voltage (mV)",
              data: cellValue,
              backgroundColor: cellColor,
              borderColor: [],
              borderWidth: 0,
            },
          ],
        };
      },

      options: {
        legend: {
          display: false,
          position: "top",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
    return (
      <div>
        <div>
          <Card>
            <div style={{ margin: "15px" }}>
              <div className="card-head">Cell Voltages</div>
              <Row>
                <Col>
                  <div>
                    <Badge
                      style={{
                        backgroundColor: "#078da8",
                        color: "white",

                        margin: "10px",
                      }}
                    >
                      Min: {minVoltage}mV
                    </Badge>
                    <Badge
                      style={{
                        backgroundColor: "#b8b209",
                        color: "white",

                        margin: "10px",
                      }}
                    >
                      Max: {maxVoltage}mV
                    </Badge>
                    <Badge
                      style={{
                        backgroundColor: "rgba(100, 100, 100, 1)",
                        color: "white",

                        margin: "10px",
                      }}
                    >
                      Diff: {maxVoltage - minVoltage}mV
                    </Badge>
                    <Badge
                      style={{
                        backgroundColor: "rgba(125, 42, 89, 0.75)",
                        color: "white",

                        margin: "10px",
                      }}
                    >
                      Avg: {Math.round(cellAverage)}mV
                    </Badge>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Bar
                    data={batteryCellsChart.data}
                    options={batteryCellsChart.options}
                  />
                  <Row>
                    <Col md="3"></Col>
                    <Col md="3" className="card-head">
                      Cell Number
                    </Col>
                    <Col md="3">
                      {" "}
                      <img src={Arrow} />
                    </Col>
                    <Col md="3"></Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </div>
    );
  }
  else {
    return (
      <div>
        <Spinner>Loading...</Spinner>
      </div>
    );
  }
}

export default BatteryCells;
